import React from 'react';
import { Box, IconButton } from '@mui/material';

import { DeleteIcon } from '../../../../assets/images';

type Props = {
  isAllowDelete: boolean;
  deleteAction: () => void;
};

const DeleteButton = ({ isAllowDelete, deleteAction }: Props) => {
  return (
    <Box className="asso_subsid_admin_profile_housekeeping_delete_button" sx={{ display: 'flex' }}>
      {isAllowDelete && (
        <IconButton sx={{ cursor: 'pointer', marginTop: '-10px' }} onClick={deleteAction}>
          <DeleteIcon height="35px" width="35px" />
        </IconButton>
      )}
    </Box>
  );
};

export default DeleteButton;
