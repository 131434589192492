import React, { useContext } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';

import { ADMIN_CONCESSION, DATE_FORMAT, LABEL_VALUE_TYPE } from '../../../constants/constants';

import { IAdminTransactionLog } from '../../../interfaces';

import {
  formatDateAsString,
  getDisplayVal,
  getAdminPersonalDisplayName,
  getAdminCurrentContext,
} from '../../../helpers';

import { ConcessionInProfileState } from '../../../context';

import { FieldContainer, GoBackButton } from '../../Common';
import { DetailsContainer, IDisplayDetails, LabelValueContainer } from '..';

const ConcessionDetail = () => {
  const theme = useTheme();
  const adminCurrentContext = getAdminCurrentContext();

  const fieldLabel = en.admin.adminConcession.fields;

  const {
    nominatedPaxListDropdownData: { selectedOptionItem: selectedDependant },
  } = useContext(ConcessionInProfileState) || {};
  const { concessionName, focusConcession } = adminCurrentContext.currentContextState || {};
  const { setFocusConcession } = adminCurrentContext.currentContextAction || {};

  const {
    displayName,
    stfdType,
    boardingPriority,
    bookingClass,
    regradeBoardingPriority,
    regradeClass,
    numberOfAllotmentSectors,
    focusQuota,
  } = focusConcession || {};

  const {
    firstName,
    middleName,
    lastName,
    relationship,
    dependentId: filterDependentId,
    name: employeeName,
  } = concessionName || {};

  const focusTravellerName = getAdminPersonalDisplayName({
    firstName,
    middleName,
    lastName,
  });

  const tableData = [
    {
      label: fieldLabel.name,
      value: filterDependentId === null ? employeeName : focusTravellerName,
    },
  ];

  const noAllotmentQuota = numberOfAllotmentSectors === -1;
  // 2. details data for display
  const ConcessionDetailsData: { details: IDisplayDetails[] } = {
    details: [
      {
        label: fieldLabel.concession,
        value: displayName,
        type: LABEL_VALUE_TYPE.STRING,
        column: 12,
      },
      {
        label: fieldLabel.relationship,
        value: relationship,
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.name,
        value: filterDependentId === null ? employeeName : focusTravellerName,
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.dependantType,
        value: filterDependentId === null ? ADMIN_CONCESSION.EMPLOYEE : selectedDependant?.beneficiaryType,
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.noOfTicketsAlloted,
        value: noAllotmentQuota ? fieldLabel.unlimited : focusQuota?.quota + '',
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.used,
        value: noAllotmentQuota ? en.common.empty : focusQuota?.used + '',
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.balance,
        value: noAllotmentQuota
          ? fieldLabel.unlimited
          : focusQuota?.quota !== undefined && focusQuota?.used !== undefined
          ? Number(focusQuota?.quota - focusQuota?.used) + ''
          : fieldLabel.unlimited,
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.stfdType,
        value: stfdType,
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.bookingPriority,
        value: boardingPriority,
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.bookingClass,
        value: bookingClass?.[0] || '',
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.regradePriority,
        value: regradeBoardingPriority,
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.regradeClass,
        value: regradeClass === '' ? fieldLabel.asBooked : regradeClass,
        type: LABEL_VALUE_TYPE.STRING,
        column: 8,
      },
      {
        label: fieldLabel.allotmentDate,
        value: formatDateAsString(focusQuota?.allotmentDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.travelFromDate,
        value: formatDateAsString(focusQuota?.startDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.travelToDate,
        value: formatDateAsString(focusQuota?.endDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
        type: LABEL_VALUE_TYPE.STRING,
        column: 4,
      },
      {
        label: fieldLabel.systemActions,
        value: focusQuota?.transactionLog?.map((item: IAdminTransactionLog, index: number) => {
          return (
            <Typography key={index} sx={{ color: theme.color.secondary.dark_grey.option_3 }} variant="headline_medium">
              {`${fieldLabel.action}: ${getDisplayVal(item?.action)}, ${fieldLabel.remark}: ${getDisplayVal(
                item?.remark,
              )}`}
            </Typography>
          );
        }),
        type: LABEL_VALUE_TYPE.STRING,
        column: 12,
      },
    ],
  };

  return (
    <>
      <GoBackButton
        buttonText={en.admin.adminConcession.backToConcessionList}
        onClick={() => setFocusConcession(null)}
      />
      <Box
        className="concession_field"
        sx={{
          height: '77px',
          px: 2,
          display: 'flex',
          margin: '19px auto 0',
          border: `2px solid ${theme.color.secondary.dark_grey.option_7}`,
          borderRadius: 1,
        }}
      >
        {tableData.map((item, index) => {
          return (
            <FieldContainer
              key={index}
              field={item}
              index={index}
              customStyles={{
                flex: index > 0 ? 2 : 1,
              }}
              customTextStyles={{
                textOverflow: index === 1 ? 'ellipsis' : 'none',
                overflow: 'hidden',
              }}
            />
          );
        })}
      </Box>

      <Box
        className="concession_detail"
        sx={{
          margin: '24px auto 0',
          position: 'relative',
        }}
      >
        {Object.entries(ConcessionDetailsData).map(([key, value]: [string, IDisplayDetails[]]) => {
          return (
            <DetailsContainer key={key} title={en.admin.adminConcession.concessionDetail}>
              <Grid container spacing={2}>
                {value.map((data: IDisplayDetails, index: number) => {
                  return <LabelValueContainer key={index} {...data} />;
                })}
              </Grid>
            </DetailsContainer>
          );
        })}
      </Box>
    </>
  );
};

export default ConcessionDetail;
