import React, { createContext, useState } from 'react';
import {
  IAdminNote,
  IAdminConcession,
  IDependentForAdmin,
  IAssoSubsidAdminEmployeeDetailRoot,
  IAssoSubsidAdminPersonalInformation,
  IAssoSubsidAdminAdminHousekeeping,
  IAssoSubsidAdminDependentHousekeepingCreate,
} from '../../interfaces';

interface IEmployeeStateType {
  employeeDetail: IAssoSubsidAdminEmployeeDetailRoot;
  employeeDependent: any;
  employeeNotes: IAdminNote[];
  focusNote: IAdminNote | null;

  // edit profile
  isEditingAdminProfile: boolean;
  editEmployeeDetail: any;
  editEmployeeError: any;
  housekeepingList: IAssoSubsidAdminAdminHousekeeping[];
  // admin concession
  concessionName: any;
  focusConcession: IAdminConcession | null;
  concessions: IAdminConcession[];

  selectedCompanyCode: string;
  originalHousekeepingListData: IAssoSubsidAdminAdminHousekeeping[];
  dependentsHousekeepingList: IAssoSubsidAdminAdminHousekeeping[];
  originalDependentsHousekeepingListData: IAssoSubsidAdminAdminHousekeeping[];
  editDependentsDetail: IAssoSubsidAdminDependentHousekeepingCreate | null;
  isEditingDependentProfile: boolean;
  editDependentId: string;
  isPassportInfoMissing: boolean;
  isExpiryDateEdited: boolean;
  isIssuedCountryEdited: boolean;
  isNationalityInPassportEdited: boolean;
}

interface IActionType {
  setEmployeeDetail: any;
  setEmployeeDependent: any;
  setEmployeeNotes: any;
  setFocusNote: any;

  // edit profile
  setIsEditingAdminProfile: any;
  setEditEmployeeDetail: any;
  setEditEmployeeError: any;

  // admin concession
  setConcessionName: any;
  setFocusConcession: any;
  setConcessions: any;

  clearEmployeeDetail?: any;

  updateNominationHistoryTabData?: any;

  updateQuotaHistoryTabData?: any;

  // Personal Information Tab related
  updatePersonalInfoTabData?: any;
  setProfileDetail?: any;
  setEmploymentDetail?: any;
  setBenefitDetail?: any;
  setPaymentDetail?: any;
  setFlownSuspensionDetail?: any;
  setContactDetail?: any;
  setPassportDetail?: any;

  setSelectedCompanyCode: any; // etp:5085 add: asso/subsid admin panel select company
  setHousekeepingList?: any;
  setOriginalHousekeepingListData?: any;
  setDependentsHousekeepingList?: any;
  setOriginalDependentsHousekeepingListData?: any;
  setEditDependentsDetail?: any;
  setIsEditingDependentProfile: any;
  setEditDependentId: any;
  setIsPassportInfoMissing: any;
  setIsExpiryDateEdited: any;
  setIsIssuedCountryEdited: any;
  setIsNationalityInPassportEdited: any;
}

const defaultEmployeeDetail: IAssoSubsidAdminEmployeeDetailRoot = {
  profileDetail: null,
  employmentDetail: null,
  benefitDetail: null,
  paymentDetail: null,
  flownSuspensionDetails: null,
  contactDetail: null,
  passportDetail: null,
  dependents: [],
  nominationHistory: [],
  quotaHistory: [],
};

const AssoSubsidAdminEmployeeState = createContext<IEmployeeStateType>({
  employeeDetail: defaultEmployeeDetail,
  employeeDependent: null,
  employeeNotes: [],
  focusNote: null,
  isEditingAdminProfile: false,
  editEmployeeDetail: null,
  editEmployeeError: null,
  focusConcession: null,
  concessionName: null,
  concessions: [],
  selectedCompanyCode: '',
  housekeepingList: [],
  originalHousekeepingListData: [],
  dependentsHousekeepingList: [],
  originalDependentsHousekeepingListData: [],
  editDependentsDetail: null,
  isEditingDependentProfile: false,
  editDependentId: '',
  isPassportInfoMissing: false,
  isExpiryDateEdited: false,
  isIssuedCountryEdited: false,
  isNationalityInPassportEdited: false,
});

const AssoSubsidAdminEmployeeAction = createContext<IActionType>({
  setEmployeeDetail: null,
  setEmployeeDependent: null,
  setEmployeeNotes: null,
  setFocusNote: null,
  setIsEditingAdminProfile: null,
  setEditEmployeeDetail: null,
  setEditEmployeeError: null,
  setConcessionName: null,
  setFocusConcession: null,
  setConcessions: null,

  clearEmployeeDetail: null,

  updateNominationHistoryTabData: null,

  updateQuotaHistoryTabData: null,

  updatePersonalInfoTabData: null,
  setProfileDetail: null,
  setEmploymentDetail: null,
  setBenefitDetail: null,
  setPaymentDetail: null,
  setFlownSuspensionDetail: null,
  setContactDetail: null,
  setPassportDetail: null,

  setSelectedCompanyCode: null,
  setHousekeepingList: null,
  setOriginalHousekeepingListData: null,
  setDependentsHousekeepingList: null,
  setOriginalDependentsHousekeepingListData: null,
  setEditDependentsDetail: null,
  setIsEditingDependentProfile: null,
  setEditDependentId: null,
  setIsPassportInfoMissing: null,
  setIsExpiryDateEdited: null,
  setIsIssuedCountryEdited: null,
  setIsNationalityInPassportEdited: null,
});

const AssoSubsidAdminEmployeeContextProvider = ({ children }: { children?: React.ReactNode }) => {
  /*
    Structure of employeeDetail:
    - profileDetail
    - employmentDetail
    - benefitDetail
    - paymentDetail
    - flownSuspensionDetails
    - contactDetail
    - passportDetail
    - profileHousekeepingDetail

    Additionally including:
    - employeeDependent
    - Nomination History
    - Quota History
  */
  const [employeeDetail, setEmployeeDetail] = useState<IAssoSubsidAdminEmployeeDetailRoot>(defaultEmployeeDetail); // Personal Information Tab data

  const [employeeDependent, setEmployeeDependent] = useState<IDependentForAdmin[] | null>(null); // Dependents Tab data

  const [employeeNotes, setEmployeeNotes] = useState<IAdminNote[]>([]);
  const [focusNote, setFocusNote] = useState<IAdminNote | null>(null);

  const [isEditingAdminProfile, setIsEditingAdminProfile] = useState<boolean>(false);
  const [editEmployeeDetail, setEditEmployeeDetail] = useState<any>(null);
  const [editEmployeeError, setEditEmployeeError] = useState<any>(null);

  const [focusConcession, setFocusConcession] = useState<IAdminConcession | null>(null);
  const [concessionName, setConcessionName] = useState<any>(null);

  const [concessions, setConcessions] = useState<IAdminConcession[]>([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState<string>('');
  const [housekeepingList, setHousekeepingList] = useState<IAssoSubsidAdminAdminHousekeeping[]>([]);
  const [originalHousekeepingListData, setOriginalHousekeepingListData] = useState<IAssoSubsidAdminAdminHousekeeping[]>(
    [],
  );
  const [dependentsHousekeepingList, setDependentsHousekeepingList] = useState<IAssoSubsidAdminAdminHousekeeping[]>([]);
  const [originalDependentsHousekeepingListData, setOriginalDependentsHousekeepingListData] = useState<
    IAssoSubsidAdminAdminHousekeeping[]
  >([]);
  const [editDependentsDetail, setEditDependentsDetail] = useState<IAssoSubsidAdminDependentHousekeepingCreate | null>(
    {},
  );
  const [isEditingDependentProfile, setIsEditingDependentProfile] = useState<boolean>(false);
  const [editDependentId, setEditDependentId] = useState<string>('');
  const [isExpiryDateEdited, setIsExpiryDateEdited] = useState<boolean>(false);
  const [isIssuedCountryEdited, setIsIssuedCountryEdited] = useState<boolean>(false);
  const [isNationalityInPassportEdited, setIsNationalityInPassportEdited] = useState<boolean>(false);
  const [isPassportInfoMissing, setIsPassportInfoMissing] = useState<boolean>(false);
  const clearEmployeeDetail = () => {
    setEmployeeDetail(defaultEmployeeDetail);
  };

  // Update Personal Information Tab whole data
  const updatePersonalInfoTabData = (payload: IAssoSubsidAdminPersonalInformation) => {
    const {
      profileDetail,
      employmentDetail,
      benefitDetail,
      paymentDetail,
      flownSuspensionDetails,
      contactDetail,
      passportDetail,
    } = payload;
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        profileDetail,
        employmentDetail,
        benefitDetail,
        paymentDetail,
        flownSuspensionDetails,
        contactDetail,
        passportDetail,
      };
    });
  };

  // Update Nomination History Tab whole data
  const updateNominationHistoryTabData = (payload: IAssoSubsidAdminEmployeeDetailRoot['nominationHistory']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        nominationHistory: payload,
      };
    });
  };

  // Update Quota History Tab whole data
  const updateQuotaHistoryTabData = (payload: IAssoSubsidAdminEmployeeDetailRoot['quotaHistory']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        quotaHistory: payload,
      };
    });
  };

  const setProfileDetail = (payload: IAssoSubsidAdminPersonalInformation['profileDetail']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        profileDetail: payload,
      };
    });
  };

  const setPassportDetail = (payload: IAssoSubsidAdminPersonalInformation['passportDetail']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        passportDetail: payload,
      };
    });
  };

  const setEmploymentDetail = (payload: IAssoSubsidAdminPersonalInformation['employmentDetail']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        employmentDetail: payload,
      };
    });
  };

  const setBenefitDetail = (payload: IAssoSubsidAdminPersonalInformation['benefitDetail']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        benefitDetail: payload,
      };
    });
  };

  const setPaymentDetail = (payload: IAssoSubsidAdminPersonalInformation['paymentDetail']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        paymentDetail: payload,
      };
    });
  };

  const setFlownSuspensionDetail = (payload: IAssoSubsidAdminPersonalInformation['flownSuspensionDetails']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        flownSuspensionDetails: payload,
      };
    });
  };

  const setContactDetail = (payload: IAssoSubsidAdminPersonalInformation['contactDetail']) => {
    setEmployeeDetail((prev: any) => {
      return {
        ...prev,
        contactDetail: payload,
      };
    });
  };

  return (
    <AssoSubsidAdminEmployeeState.Provider
      value={{
        employeeDependent,
        employeeDetail,
        employeeNotes,
        focusNote,
        isEditingAdminProfile,
        editEmployeeDetail,
        editEmployeeError,
        concessionName,
        focusConcession,
        concessions,
        selectedCompanyCode,
        housekeepingList,
        originalHousekeepingListData,
        dependentsHousekeepingList,
        originalDependentsHousekeepingListData,
        editDependentsDetail,
        isEditingDependentProfile,
        editDependentId,
        isPassportInfoMissing,
        isExpiryDateEdited,
        isIssuedCountryEdited,
        isNationalityInPassportEdited,
      }}
    >
      <AssoSubsidAdminEmployeeAction.Provider
        value={{
          setEmployeeDetail,
          setEmployeeDependent,
          setEmployeeNotes,
          setFocusNote,
          setIsEditingAdminProfile,
          setEditEmployeeDetail,
          setEditEmployeeError,
          setConcessionName,
          setFocusConcession,
          setConcessions,
          clearEmployeeDetail,
          updatePersonalInfoTabData,
          updateNominationHistoryTabData,
          updateQuotaHistoryTabData,
          setProfileDetail,
          setEmploymentDetail,
          setBenefitDetail,
          setPaymentDetail,
          setFlownSuspensionDetail,
          setContactDetail,
          setPassportDetail,
          setSelectedCompanyCode,
          setHousekeepingList,
          setOriginalHousekeepingListData,
          setDependentsHousekeepingList,
          setOriginalDependentsHousekeepingListData,
          setEditDependentsDetail,
          setIsEditingDependentProfile,
          setEditDependentId,
          setIsPassportInfoMissing,
          setIsExpiryDateEdited,
          setIsIssuedCountryEdited,
          setIsNationalityInPassportEdited,
        }}
      >
        {children}
      </AssoSubsidAdminEmployeeAction.Provider>
    </AssoSubsidAdminEmployeeState.Provider>
  );
};

export {
  AssoSubsidAdminEmployeeContextProvider,
  AssoSubsidAdminEmployeeState,
  AssoSubsidAdminEmployeeAction,
  defaultEmployeeDetail,
};
export type {
  IEmployeeStateType as IAssoSubsidAdminEmployeeStateType,
  IActionType as IAssoSubsidAdminEmployeeActionType,
};
