import React, { useState, useMemo, useEffect } from 'react';
import { Box, Dialog, IconButton, SxProps, Theme, useTheme } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';

import { use100vh } from 'react-div-100vh';

import { DATE_FORMAT, UI_METRICS_IN_PX } from '../../constants/constants';
import en from '../../translations/en';
import { DateIcon } from '../../assets/images';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { formatDMYDateWithSlash, formatDateAsString, formatStringToDate, getLocalDate } from '../../helpers';

import { DateSinglePicker, Footer, FormFieldLabel, Header } from '..';

const InputFieldDatePicker = ({
  selectedType,
  setSelectedValue,
  dateFieldProps,
  customStyles,
}: {
  selectedType: string;
  dateFieldProps: {
    defaultDate: string;
    errorMessage: string;
    disabled: boolean;
    minDate?: Date;
    maxDate?: Date;
    labelText?: string;
    errorMessagePaddingTop?: string;
  };
  customStyles?: SxProps<Theme>;
  setSelectedValue?: (selectType: string, value: string) => void;
}) => {
  const theme = useTheme();
  const screenHeight = use100vh();

  const { isDesktop } = useAppSelector(selectApp) || {};

  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<string>('');

  const dateInputFieldValue = dayjs(currentDate, DATE_FORMAT.ddmmyyyy);

  useEffect(() => {
    if (dateFieldProps.defaultDate) {
      setCurrentDate(dateFieldProps.defaultDate);
    }
  }, [dateFieldProps.defaultDate]);

  const calendarHeight = useMemo(() => {
    let height = 0;
    if (screenHeight) {
      const surplusHeight = 258; // 258 = dateTop(26) + dateNavHeight(30) + calendarOffset(50) + footerButton(92) + rdrMonthAndYearWrapper(60);
      const datePaddings = 32; // 32 top & bottom paddings
      if (screenHeight > UI_METRICS_IN_PX.inputFieldDatePickerDisplayHeight + datePaddings) {
        height = UI_METRICS_IN_PX.inputFieldDatePickerDisplayHeight - surplusHeight;
      } else {
        height = screenHeight - datePaddings - surplusHeight;
      }
    }
    return height;
  }, [screenHeight]);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Box sx={{ mt: 1 }}>
          <IconButton
            sx={{
              pt: '8px',
              zIndex: 1,
            }}
            onClick={() => {
              setIsOpenDatePicker(true);
            }}
          >
            <DateIcon />
          </IconButton>
          <DateField
            label={dateFieldProps.labelText}
            variant="standard"
            value={currentDate ? dateInputFieldValue : undefined}
            fullWidth
            disabled={dateFieldProps.disabled}
            onChange={(date: any) => {
              // Range of input box due to technical limitation can be from 1000-9999
              const isValidYear = dayjs(date).toDate().getFullYear() >= 1000;
              const changedDate = dayjs(date).toDate().toString();

              const formatDate = formatDateAsString(changedDate, DATE_FORMAT.date);

              if (isValidYear && changedDate !== 'Invalid Date') {
                setCurrentDate(formatDMYDateWithSlash(changedDate));
                setSelectedValue?.(selectedType, formatDate);
              }
            }}
            onClear={() => {
              setCurrentDate('');
              setSelectedValue?.(selectedType, '');
            }}
            minDate={undefined}
            maxDate={undefined}
            InputProps={{
              disableUnderline: true,
            }}
            clearable={currentDate !== ''}
            sx={{
              mt: -4,
              height: '32px', // input field height
              border: dateFieldProps.errorMessage
                ? `1px solid ${theme.color.utility.error.option_3}`
                : `1px solid ${theme.color.secondary.slate.option_3}`,
              borderRadius: '4px',
              paddingRight: '8px', // x icon position
              justifyContent: 'center',
              pl: 4,
              backgroundColor: dateFieldProps.disabled ? theme.color.utility.unselectable.option_7 : 'transparent',
              ...customStyles,
              '& .MuiInputBase-input': {
                '& .Mui-error': {
                  color: theme.color.secondary.grey.option_3,
                },
                '& .MuiInput-input': {
                  marginTop: '-4px',
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.color.secondary.grey.option_3,
                pl: 4,
                mt: '-8px',
                '&.MuiInputLabel-shrink': {
                  top: '10px',
                },
              },
            }}
          />

          {dateFieldProps.errorMessage && (
            <FormFieldLabel
              style={{
                marginTop: dateFieldProps.errorMessagePaddingTop || '4px',
                marginLeft: '4px',
                ...theme.typography.fine_print_regular,
              }}
              title={dateFieldProps.errorMessage}
              isError
            />
          )}
        </Box>
      </LocalizationProvider>

      <Dialog
        // if drag to mobile, close datePicker.
        // if drag from mobile to desktop, keep datePicker open status.
        open={isOpenDatePicker && isDesktop}
        sx={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiDialog-container': {
            alignItems: 'baseline',
            '& .MuiPaper-root': {
              my: 'auto',
              width: '100%',
              height: '715px',
              maxWidth: '478px',
              maxHeight: `calc(${screenHeight}px - 32px)`, // 32 -> top & bottom paddings
              borderRadius: 1,
              boxShadow: theme.boxShadow.important,
              overflow: 'hidden',
            },
          },
        }}
      >
        <Box
          sx={{
            px: 2,
            pt: 4,
            height: '100%',
          }}
        >
          <Header
            handleOnBack={() => {
              setIsOpenDatePicker(false);
            }}
          />
          <DateSinglePicker
            isDesktop={false}
            defaultDate={
              currentDate ? dateInputFieldValue.toDate() : getLocalDate() // if null date, default edit today
            }
            onChange={(date: Date) => {
              setCurrentDate(formatDMYDateWithSlash(date));
            }}
            calendarHeight={calendarHeight}
            showMonthAndYearDropDown={true}
            minDate={dateFieldProps.minDate}
            maxDate={dateFieldProps.maxDate}
          />

          <Footer
            primaryBtn={{
              isFullWidth: true,
              text: en.common.update,
              customOnClick: () => {
                setIsOpenDatePicker(false);

                if (currentDate === '') {
                  // case: open datePicker, not select any date then click update
                  setCurrentDate(formatDMYDateWithSlash(getLocalDate()));
                }

                setSelectedValue?.(
                  selectedType,
                  formatDateAsString(formatStringToDate(currentDate, DATE_FORMAT.ddmmyyyy), DATE_FORMAT.date),
                );
              },
            }}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default InputFieldDatePicker;
