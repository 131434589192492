import { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import { BOOKING_FLOW_PAGE_NO, PREPAID_AUTH_FLOW_TYPE } from '../../constants/constants';
import { BOOKING_PATHS, ROOT_PATHS } from '../../constants/paths';
import en from '../../translations/en';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp, setLoading, setRetrieveAndPaySuccessData, setNormalPrepaidSuccessData } from '../../slice/appSlice';
import { selectAuth } from '../../slice/authSlice';
import {
  selectBooking,
  setBookingStep,
  setRetrieveAndPayUnhappyFlowFinalPageControl,
  setIsCppBookingCreated,
  setIsCalledPrepaidAuth,
  setPrepaidAuthFlowType,
  setRetrieveAndPayCppParams,
  setIsTriggerReviewAndConfirmByNormalPrepaid,
} from '../../slice/bookingSlice';

import { prepaidBook, prepaidCancelBooking } from '../../services/prepaid';
import { showErrorAlert } from '../../services/api';

import { Div100vhView } from '../../components';

const PrepaidBookTransit = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { ern } = useAppSelector(selectAuth) || {};
  const { retrieveAndPaySuccessData } = useAppSelector(selectApp) || {};
  const { prepaidAuthFlowType } = useAppSelector(selectBooking) || {};

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const cppTpAppRef = searchParams.get('tpAppRef');
  const cppErrorMessage = searchParams.get('errorMessage');
  const cppErrorCode = searchParams.get('errorCode');
  const isPrepaidBookTransitRefresh = localStorage.getItem('isPrepaidBookTransitRefresh');

  const [transitPageProcessing, setTransitPageProcessing] = useState<{
    isLoading: boolean;
    errorMessage?: string;
  }>({
    isLoading: false,
    errorMessage: '',
  });

  // Unhappy flow > Show CPP errors dialog > Dismiss handling
  const showCppErrorDialogDismissFlow = async () => {
    // [normal prepaid] redirect to review and confirm page
    if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.NORMAL_PREPAID) {
      dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.confirmation));
      dispatch(setIsTriggerReviewAndConfirmByNormalPrepaid(true));

      navigate(BOOKING_PATHS.booking);
    }

    // [retrieve and pay] redirect to re-pricing page
    if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.RETRIEVE_AND_PAY) {
      dispatch(setBookingStep(null));

      /*
        - Set `isTriggerRedirectHomePage` to be true
          (1) Trigger by unhappy flow (CPP errors OR CPP back cases) and redirect to Home Page.
      */
      dispatch(
        setRetrieveAndPayUnhappyFlowFinalPageControl({
          isTriggerRedirectHomePage: true,
        }),
      );

      navigate(ROOT_PATHS.landing, { replace: true });
    }
  };

  // unhappy flow > prepaidBook API error -> Show errors dialog -> Dismiss handling
  const prepaidBookErrorDismissFlow = async () => {
    // [normal prepaid] redirect to review and confirm page
    if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.NORMAL_PREPAID) {
      dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.confirmation));
      dispatch(setIsTriggerReviewAndConfirmByNormalPrepaid(true));

      navigate(BOOKING_PATHS.booking);
    }

    // [retrieve and pay] redirect to re-pricing page
    if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.RETRIEVE_AND_PAY) {
      if (retrieveAndPaySuccessData?.recLoc) {
        dispatch(setBookingStep(null));

        /*
          - Set `isTriggerRedirectHomePage` to be true
            (1) Trigger by unhappy flow (CPP errors OR CPP back cases) and redirect to Home Page.
        */
        dispatch(
          setRetrieveAndPayUnhappyFlowFinalPageControl({
            isTriggerRedirectHomePage: true,
          }),
        );

        navigate(ROOT_PATHS.landing, { replace: true });
      }
    }
  };

  const cancelBookFlow = async () => {
    if (cppTpAppRef) {
      // Log processing in current page
      setTransitPageProcessing({
        isLoading: true,
        errorMessage: '',
      });

      const cppErrorWarning = cppErrorMessage
        ? `${cppErrorCode} ${cppErrorMessage} ${cppTpAppRef}`
        : `${cppErrorCode} ${cppTpAppRef}`;

      await prepaidCancelBooking({
        userId: ern,
        tpAppRef: cppTpAppRef,
        error: {
          reason: cppErrorWarning,
          errorCode: `${cppErrorCode || ''}`,
        },
      });

      setTransitPageProcessing({
        isLoading: false,
        errorMessage: '',
      });

      showErrorAlert({
        title: `${en.errorAlert.prepaidAuth}`,
        message: cppErrorWarning,
        errorStatus: `${cppErrorCode || ''}`, // TBC with backend
        dismissCallBackFunc: () => {
          showCppErrorDialogDismissFlow();
        },
      });
    }
  };

  const prepaidBookFlow = async () => {
    // error -
    // [normal prepaid]
    // show err dialog (dismiss)
    // redirect to review and confirm page ----- need to put the btn on review and confirm page.
    // re-try the flow

    // [retrieve and pay]
    // show err dialog (dismiss)
    // redirect to re-pricing page ------- can go view booking but need to re-call /pnr API.
    // re-try the flow
    if (cppTpAppRef) {
      // Log processing in current page
      setTransitPageProcessing({
        isLoading: true,
        errorMessage: '',
      });

      const response = await prepaidBook(ern, cppTpAppRef, prepaidBookErrorDismissFlow);

      // happy flow - redirect to booking completed page
      if (response?.booking) {
        // redirect to booking completed page
        // dispatch(setIsCalledPrepaidAuth(false)); // TBC not sure if need or not
        dispatch(setIsCppBookingCreated(true));
        dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.completed));

        // [normal prepaid] save booking data
        if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.NORMAL_PREPAID) {
          dispatch(
            setNormalPrepaidSuccessData({
              bookingResult: response,
              recLoc: response.booking.recLoc,
            }),
          );
        }

        // [retrieve and pay] save booking data
        if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.RETRIEVE_AND_PAY) {
          dispatch(
            setRetrieveAndPaySuccessData({
              bookingResult: response,
              recLoc: response.booking.recLoc,
            }),
          );
        }

        setTransitPageProcessing({
          isLoading: false,
          errorMessage: '',
        });

        // redirect to booking page
        navigate(BOOKING_PATHS.booking);
      }
    }
  };

  // Default show loading
  useEffect(() => {
    dispatch(setLoading(true));
    localStorage.removeItem('isIndexRefresh');
  }, []);

  // [CPP payment success/error]
  useEffect(() => {
    if (prepaidAuthFlowType && cppTpAppRef && !cppErrorMessage && !cppErrorCode) {
      window.history.replaceState(null, '', ROOT_PATHS.landing);
      // call prepaidBook instantly
      if (!isPrepaidBookTransitRefresh) prepaidBookFlow();
    } else if (prepaidAuthFlowType && cppTpAppRef && (cppErrorMessage || cppErrorCode)) {
      window.history.replaceState(null, '', ROOT_PATHS.landing);
      // [CPP payment error]
      if (!isPrepaidBookTransitRefresh) cancelBookFlow();
    } else if (!prepaidAuthFlowType) {
      // instant redirect to home page
      navigate(ROOT_PATHS.landing);
    }
  }, [prepaidAuthFlowType, cppTpAppRef, cppErrorMessage, cppErrorCode, isPrepaidBookTransitRefresh]);

  // Refresh handling > setup flag
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      // event.returnValue = 'Are you sure you want to leave this page?';

      // whatever the page is loading or error occurred after loaded
      if (transitPageProcessing.isLoading || (!transitPageProcessing.isLoading && transitPageProcessing.errorMessage)) {
        localStorage.setItem('isPrepaidBookTransitRefresh', 'true');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [transitPageProcessing]);

  // Refresh handling > execute redirection
  useEffect(() => {
    const flag = localStorage.getItem('isPrepaidBookTransitRefresh');
    if (flag) {
      localStorage.removeItem('isPrepaidBookTransitRefresh');
      // clear relate redux data
      dispatch(setIsCalledPrepaidAuth(false));
      dispatch(setRetrieveAndPayCppParams(null));
      dispatch(setBookingStep(null));

      dispatch(setPrepaidAuthFlowType(''));
      dispatch(setRetrieveAndPaySuccessData(null));
      dispatch(setNormalPrepaidSuccessData(null));

      // redirect to home page
      navigate(ROOT_PATHS.landing, { replace: true });
    }
  }, []);

  return (
    <Div100vhView
      sx={{
        backgroundColor: theme.color.secondary.light_slate.option_7,
      }}
    >
      <Box> </Box>
    </Div100vhView>
  );
};

export default PrepaidBookTransit;
