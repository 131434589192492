export function formatFileSize(bytes?: number): string {
  if (bytes === undefined || bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = (value: number, decimalPlaces: number) =>
    Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${dm(bytes / Math.pow(k, i), 2)} ${sizes[i]}`;
}

const fileToBase64 = async (file: File | null): Promise<string> => {
  if (!file) {
    return Promise.reject(new Error('No file provided'));
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      if (arrayBuffer) {
        const base64String = btoa(
          new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''),
        );
        resolve(base64String);
      } else {
        reject(new Error('Failed to read file as array buffer'));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export { fileToBase64 };
