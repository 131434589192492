import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';

import { Configuration } from 'configuration';

import {
  ADMIN_PERSONALINFO_SELECT_TYPE,
  BENEFIT_JOB_LEVEL_OPTIONS,
  INFINITE_DATE,
  LABEL_CATEGORY,
  LABEL_VALUE_TYPE,
  PAYMENT_CONCEPT,
  PAYMENT_MEDIUM,
  PAYMENT_STATUS_OPTIONS,
  ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST,
  ADMIN_PERSONALINFO_INPUT_TYPE,
  DATE_FORMAT,
  EMPTY_DROPDOWN_OPTION,
} from '../../../constants/constants';
import en from '../../../translations/en';

import {
  formatDMYDateWithSlash,
  formatDMYDateWithSpace,
  getDisplayLabel,
  getPaymentStatusLabelInfo,
  isPaymentMediumDirectDebit,
  isPaymentMediumPaypal,
  getAdminSecondLevelToggleList,
  isEmptyOrUndefined,
  formatDateAsString,
  checkValidDateRange,
  isEmptyField,
  getLocalDate,
} from '../../../helpers';

import { AssoSubsidAdminEmployeeAction, AssoSubsidAdminEmployeeState } from '../../../context';
import { useAppSelector } from '../../../app/hooks';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { AdminFlownSuspensionList, InactivateBillingAgreementContainer, AssoSubsidAdminProfileHousekeeping } from '.';
import AdminToggleButtonGroup from './AdminToggleButtonGroup';
import DetailsContainer from './DetailsContainer';
import LabelValueContainer, { IDisplayDetails } from './LabelValueContainer';

interface IMassagedPersonalInfoData {
  [key: string]: IDisplayDetails[];
}

interface IAdminPersonalInfoDetailsProps {
  defaultId: string;
}

const AssoSubsidAdminPersonalInfoDetails = (props: IAdminPersonalInfoDetailsProps) => {
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const [adminMaintainId, setAdminMaintainId] = useState<string>(props.defaultId);
  const [updatedSelectedValue, setUpdatedSelectedValue] = useState<any>(null);
  const [updatedInputValue, setUpdatedInputValue] = useState<{
    inputType: string;
    value: string;
  } | null>(null);

  const { employeeDetail, isEditingAdminProfile, editEmployeeDetail, editEmployeeError } =
    useContext(AssoSubsidAdminEmployeeState) || {};
  const { setEditEmployeeDetail } = useContext(AssoSubsidAdminEmployeeAction) || {};

  const {
    passportDetail: passportData,
    profileDetail,
    employmentDetail,
    paymentDetail,
    benefitDetail,
    contactDetail,
  } = employeeDetail || {};

  const PAYMENT_CONCEPT_OPTIONS = configurations.labels.filter(
    (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.paymentConcept,
  );

  const [PRP_CONFIGURATIONS_LABELS, FWN_CONFIGURATIONS_LABELS] = _.partition(
    configurations.labels.filter((item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.paymentMedium),
    (item: Configuration.CodeLabel) => item.code === PAYMENT_MEDIUM.CREDIT_CARD_PAYMENT,
  );

  const emptyMediumOptions: Configuration.CodeLabel[] = [
    { category: LABEL_CATEGORY.paymentMedium, code: en.common.empty, label: en.common.empty },
  ];
  const PAYMENT_MEDIUM_FWN_OPTIONS = [
    ...emptyMediumOptions,
    ...FWN_CONFIGURATIONS_LABELS.filter((item) => item.code !== PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT),
  ];
  const PAYMENT_MEDIUM_PRP_OPTIONS = [...emptyMediumOptions, ...PRP_CONFIGURATIONS_LABELS];

  const { personalInformation: personalInformationLbl } = en.admin.feature;
  const {
    profileDetails: profileDetailsLbl,
    employmentDetails: employmentDetailsLbl,
    paymentDetails: paymentDetailsLbl,
    benefitDetails: benefitDetailsLbl,
    flownSuspensionDetails: flownSuspensionDetailsLbl,
    contactDetails: contactDetailsLbl,
    passportDetails: passportDetailsLbl,
    profileHousekeepingDetails: profileHousekeepingDetailsLbl,
  } = personalInformationLbl;

  const {
    all: allToggleLbl,
    payment: paymentToggleLbl,
    flownSuspension: flownSuspensionToggleLbl,
    profileHousekeeping: profileHousekeepingToggleLbl,
  } = en.admin.adminToggleList;

  const handleUpdateSelect = (selectType: string, value: string) => {
    setUpdatedSelectedValue({ selectType, value });
  };

  const handleUpdateInput = (inputType: string, value: string) => {
    setUpdatedInputValue({ inputType, value });
  };

  const handleMatchCountryData = (countryCode?: string | null) => {
    return (
      configurations?.countries?.find((option: Configuration.DropdownOptionClient) => option.code === countryCode)
        ?.label || ''
    );
  };

  // check service end date id mandatory + is valid start end date range
  const checkValidServiceEndDate = () => {
    let errorMessage = '';

    if (
      (editEmployeeDetail?.employment?.resignationCode === undefined &&
        employmentDetail?.terminationActionReason &&
        editEmployeeDetail?.employment?.terminationDate === '') ||
      (editEmployeeDetail?.employment?.terminationDate === '' &&
        !isEmptyField(editEmployeeDetail?.employment?.resignationCode))
    ) {
      errorMessage = en.error.required;
    }

    if (editEmployeeDetail?.employment?.terminationDate && employmentDetail?.lastHireDate) {
      if (!checkValidDateRange(employmentDetail?.lastHireDate, editEmployeeDetail?.employment?.terminationDate)) {
        errorMessage = profileDetailsLbl.editProfilePopup.inputValidDateRange;
      }
    }

    return errorMessage;
  };

  // construct Payment Setup Status Label & Type
  const { labelValue: paymentStatusLabelValue, labelType: paymentStatusLabelType } = getPaymentStatusLabelInfo({
    profileData: profileDetail,
    editEmployeeDetail,
    isEditingAdminProfile,
  });

  const {
    STRING,
    FORM_SELECT,
    FORM_FIELD_TEXT_INPUT,
    FORM_DATE_PICKER,
    CODE_LABELS,
    BOOLEAN,
    FORM_FIELD_AUTO_COMPLETE,
  } = LABEL_VALUE_TYPE;

  // 2. Massage data for display
  const massagedPersonalInfoData: IMassagedPersonalInfoData = {
    profileDetails: [
      {
        label: profileDetailsLbl.title,
        value: profileDetail?.title,
        type: isEditingAdminProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.profileTitle,
          value: editEmployeeDetail?.profile?.title?.toUpperCase() ?? profileDetail?.title?.toUpperCase(),
        },
        selectOptions: configurations.labels.filter(
          (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.title,
        ),
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: profileDetailsLbl.firstName,
        value: profileDetail?.firstName,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.profileFirstName,
          value: (editEmployeeDetail?.profile?.firstName ?? profileDetail?.firstName) || '',
          isMandatoryField: true,
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: profileDetailsLbl.middleName,
        value: profileDetail?.middleName,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.profileMiddleName,
          value: (editEmployeeDetail?.profile?.middleName ?? profileDetail?.middleName) || '',
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: profileDetailsLbl.surname,
        value: profileDetail?.lastName,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.profileLastName,
          value: (editEmployeeDetail?.profile?.lastName ?? profileDetail?.lastName) || '',
          isMandatoryField: true,
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: profileDetailsLbl.preferredFirstName,
        value: profileDetail?.firstNamePreferred,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.preferredLastName,
        value: profileDetail?.lastNamePreferred,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.gender,
        value: profileDetail?.gender,
        type: isEditingAdminProfile ? FORM_SELECT : CODE_LABELS,
        configurationField: LABEL_CATEGORY.gender,
        confidential: true,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.profileGender,
          value: editEmployeeDetail?.profile?.gender ?? profileDetail?.gender?.toUpperCase(),
        },
        selectOptions: configurations.labels.filter(
          (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.gender,
        ),
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: profileDetailsLbl.dateOfBirth,
        value: formatDMYDateWithSpace(profileDetail?.dateOfBirth),
        type: isEditingAdminProfile ? FORM_DATE_PICKER : STRING,
        confidential: true,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.profileDateOfBirth,
          value: editEmployeeDetail?.profile?.dateOfBirth
            ? formatDMYDateWithSlash(editEmployeeDetail?.profile?.dateOfBirth)
            : formatDateAsString(profileDetail?.dateOfBirth, DATE_FORMAT.ddmmyyyy),
          errorMessage: editEmployeeDetail?.profile?.dateOfBirth === '' ? en.error.required : '',
          maxDate: getLocalDate(),
        },
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: profileDetailsLbl.employeeID,
        value: profileDetail?.employeeId,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.company,
        value: profileDetail?.hiringCompany,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.countryOfResidence,
        value: profileDetail?.countryOfResidential,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.maritalStatus,
        value: profileDetail?.maritalStatus,
        type: STRING,
        options: en.labels.maritalStatusOptions, // Need to get from configuration
        column: 4,
      },
    ],
    employmentDetails: [
      {
        label: employmentDetailsLbl.jobLevel,
        value: employmentDetail?.jobLevel,
        type: isEditingAdminProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.employmentJobLevel,
          value: editEmployeeDetail?.employment?.jobLevel ?? employmentDetail?.jobLevel,
        },
        selectOptions: BENEFIT_JOB_LEVEL_OPTIONS,
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: employmentDetailsLbl.jobTitle,
        value: employmentDetail?.jobTitle,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.employmentJobTitle,
          value: (editEmployeeDetail?.employment?.jobTitle ?? employmentDetail?.jobTitle) || '',
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: employmentDetailsLbl.department,
        // [ETP-5085] Display Asso subsid company code as department
        value: profileDetail?.hiringCompany,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.contractType,
        value: employmentDetail?.contractType,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.employmentType,
        value: employmentDetail?.employmentType,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.poiType,
        value: employmentDetail?.personOfInterestType,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.officerCode,
        value: employmentDetail?.officerCode,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.hrStatus,
        value: employmentDetail?.hrStatus,
        type: STRING,
        options: en.labels.employmentStatusOptions,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.serviceStartDate,
        value: formatDMYDateWithSpace(employmentDetail?.lastHireDate),
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.serviceEndDate,
        value: formatDMYDateWithSpace(employmentDetail?.terminationDate),
        type: isEditingAdminProfile ? FORM_DATE_PICKER : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.employmentTerminationDate,
          value: editEmployeeDetail?.employment?.terminationDate
            ? formatDMYDateWithSlash(editEmployeeDetail?.employment?.terminationDate)
            : formatDateAsString(employmentDetail?.terminationDate, DATE_FORMAT.ddmmyyyy),
          // need to validate date range with serviceStartDate
          errorMessage: checkValidServiceEndDate(),
        },
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: employmentDetailsLbl.travelEndDate,
        value: formatDMYDateWithSpace(employmentDetail?.travelEndDate),
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.resignationCode,
        value: employmentDetail?.terminationActionReason,
        type: isEditingAdminProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.employmentResignationCode,
          value: editEmployeeDetail?.employment?.resignationCode ?? employmentDetail?.terminationActionReason,
          // should not be empty if serviceEndDate is not empty
          errorMessage:
            (editEmployeeDetail?.employment?.terminationDate &&
              isEmptyField(editEmployeeDetail?.employment?.resignationCode)) ||
            (editEmployeeDetail?.employment?.terminationDate === undefined &&
              employmentDetail?.terminationActionReason &&
              isEmptyField(editEmployeeDetail?.employment?.resignationCode))
              ? en.error.required
              : '',
        },
        selectOptions: [
          ...EMPTY_DROPDOWN_OPTION,
          ...configurations.labels.filter(
            (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.resignationCode,
          ),
        ],
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: employmentDetailsLbl.employeeCategory,
        value: employmentDetail?.employeeClassification,
        type: isEditingAdminProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.employmentEmployeeCategory,
          value: editEmployeeDetail?.employment?.employeeCategory ?? employmentDetail?.employeeClassification,
        },
        selectOptions: [
          ...EMPTY_DROPDOWN_OPTION,
          ...configurations.labels.filter(
            (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.employeeCategory,
          ),
        ],
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: employmentDetailsLbl.fullOrPartTime,
        value: employmentDetail?.fullPartTime,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.regularOrTemp,
        value: employmentDetail?.regularTemporary,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.domicilePort,
        value: employmentDetail?.domicilePort,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.recruitmentPort,
        value: employmentDetail?.recruitmentPort,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.countryOfEmployment,
        value: employmentDetail?.regulatoryRegion,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.workingLocation,
        value: employmentDetail?.location,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.homePort,
        value: employmentDetail?.homePort,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.houseStaff,
        value: employmentDetail?.isHouseStaff,
        options: [en.common.yes, en.common.no],
        type: BOOLEAN,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.directAppraiser,
        value: employmentDetail?.reportEmployeeId,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.directAppraiser,
          value: (editEmployeeDetail?.employment?.directAppraiser ?? employmentDetail?.reportEmployeeId) || '',
          // [ETP-5605] Direct appraiser field to optional. If there is value, follow checking applied in story
          isMandatoryField: false,
        },
        setOnChange: handleUpdateInput,
      },
    ],
    paymentDetails: [
      {
        label: paymentDetailsLbl.paymentConcept,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentConcept, paymentDetail?.paymentConcept),
        type: isEditingAdminProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept,
          value: editEmployeeDetail?.payment?.paymentConcept ?? paymentDetail?.paymentConcept,
        },
        selectOptions: PAYMENT_CONCEPT_OPTIONS,
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: paymentDetailsLbl.paymentMedium,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentMedium, paymentDetail?.paymentMedium),
        type: isEditingAdminProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.paymentMedium,
          value: editEmployeeDetail?.payment?.paymentMedium ?? profileDetail?.paymentMedium,
        },
        selectOptions:
          (editEmployeeDetail?.payment?.paymentConcept ?? profileDetail?.paymentConcept) === PAYMENT_CONCEPT.FLOWN
            ? PAYMENT_MEDIUM_FWN_OPTIONS
            : PAYMENT_MEDIUM_PRP_OPTIONS,
        setSelectedValue: handleUpdateSelect,
      },
      // Payment set up status is just for direct debit if edit mode
      ...(isPaymentMediumDirectDebit({
        // select direct
        ...(!editEmployeeDetail?.payment?.paymentMedium && { profileData: profileDetail }),
        ...(editEmployeeDetail?.payment?.paymentMedium && { editEmployeeDetail }),
      }) ||
      isPaymentMediumPaypal({
        // select paypal
        ...(!editEmployeeDetail?.payment?.paymentMedium && { profileData: profileDetail }),
        ...(editEmployeeDetail?.payment?.paymentMedium && { editEmployeeDetail }),
      })
        ? [
            {
              label: paymentDetailsLbl.paymentSetUpStatus,
              value: paymentStatusLabelValue,
              options: [en.common.yes, en.common.no],
              type: paymentStatusLabelType,
              confidential: false,
              column: editEmployeeDetail?.payment?.paymentConcept === PAYMENT_MEDIUM.MANUAL_PAYMENT ? 0 : 4,
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.paymentStatus,
                // if select "Direct Debit"(boolean type), will need value checking
                value:
                  // use edit data first
                  editEmployeeDetail?.payment?.paymentStatus ||
                  // use BE profile data if not edit
                  PAYMENT_STATUS_OPTIONS.find((item) => item.value === profileDetail?.directDebitStatus)?.label ||
                  // default "empty"
                  en.common.empty,
              },
              selectOptions: PAYMENT_STATUS_OPTIONS,
              setSelectedValue: handleUpdateSelect,
            },
          ]
        : [{ label: '', value: '', type: BOOLEAN, confidential: false, column: 4 }]),
    ],
    benefitDetails: [
      {
        // dt job level
        label: benefitDetailsLbl.benefitJobLevelDuty,
        value: benefitDetail?.benefitDTJobLevel?.jobLevel,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        // dt from date
        label: benefitDetailsLbl.effectiveFromDateDuty,
        value:
          // if jobLevel is null, show "--". or show backend date (date is null show infinite date)
          // same logic as dt to date, lt from & to date
          formatDMYDateWithSpace(
            !benefitDetail?.benefitDTJobLevel?.jobLevel ? '' : benefitDetail?.benefitDTJobLevel?.from ?? INFINITE_DATE,
          ),
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        // dt to date
        label: benefitDetailsLbl.effectiveToDateDuty,
        value: formatDMYDateWithSpace(
          !benefitDetail?.benefitDTJobLevel?.jobLevel ? '' : benefitDetail?.benefitDTJobLevel?.to ?? INFINITE_DATE,
        ),
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        // lt job level
        label: benefitDetailsLbl.benefitJobLevelLeisure,
        value: benefitDetail?.benefitLTJobLevel.jobLevel,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        // lt from date
        label: benefitDetailsLbl.effectiveFromDateLeisure,
        value: formatDMYDateWithSpace(
          !benefitDetail?.benefitLTJobLevel.jobLevel ? '' : benefitDetail?.benefitLTJobLevel.from ?? INFINITE_DATE,
        ),
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        // lt to date
        label: benefitDetailsLbl.effectiveToDateLeisure,
        value: formatDMYDateWithSpace(
          !benefitDetail?.benefitLTJobLevel.jobLevel ? '' : benefitDetail?.benefitLTJobLevel.to ?? INFINITE_DATE,
        ),
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.profileStatus,
        value: benefitDetail?.isActive,
        options: [en.common.active, en.common.inactive],
        type: BOOLEAN,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.profileType,
        value: benefitDetail?.type,
        type: STRING,
        confidential: false,
        column: 8,
      },
      {
        label: benefitDetailsLbl.age,
        value: benefitDetail?.age || en.common.empty,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.yearOfService,
        value: isEmptyOrUndefined(benefitDetail?.yearOfService)
          ? en.common.empty
          : `${benefitDetail?.yearOfService?.years} ${en.dateUnit.years} ${benefitDetail?.yearOfService?.months} ${en.dateUnit.months} ${benefitDetail?.yearOfService?.days} ${en.dateUnit.days}`,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.companySeniorityDate,
        value: formatDMYDateWithSpace(benefitDetail?.companySeniorityDate),
        type: STRING,
        confidential: false,
        column: 4,
      },
    ],
    flownSuspensionDetails: [
      {
        label: isEditingAdminProfile
          ? `${flownSuspensionDetailsLbl.newFlownSuspensionStartDate} ${benefitDetailsLbl.dateEditFormat}`
          : flownSuspensionDetailsLbl.flownSuspensionStartDate,
        value: en.common.empty,
        type: isEditingAdminProfile ? FORM_DATE_PICKER : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate,
          value: editEmployeeDetail?.flownSuspension?.flownSuspensionStartDate
            ? formatDMYDateWithSlash(editEmployeeDetail?.flownSuspension?.flownSuspensionStartDate)
            : '',
          errorMessage: editEmployeeError?.flownSuspension?.from,
        },
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: isEditingAdminProfile
          ? `${flownSuspensionDetailsLbl.newFlownSuspensionEndDate} ${benefitDetailsLbl.dateEditFormat}`
          : flownSuspensionDetailsLbl.flownSuspensionEndDate,
        value: en.common.empty,
        type: isEditingAdminProfile ? FORM_DATE_PICKER : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionEndDate,
          value: editEmployeeDetail?.flownSuspension?.flownSuspensionEndDate
            ? formatDMYDateWithSlash(editEmployeeDetail?.flownSuspension?.flownSuspensionEndDate)
            : '',
          errorMessage: editEmployeeError?.flownSuspension?.to,
        },
        setSelectedValue: handleUpdateSelect,
      },
    ],
    contactDetails: [
      {
        label: contactDetailsLbl.personalEmail,
        value: contactDetail?.personalEmail,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.officeEmail,
        value: contactDetail?.businessEmail,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.phoneNumberMobile,
        value: contactDetail?.mobilePhone,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.phoneNumberOffice,
        value: contactDetail?.workPhone,
        type: STRING,
        confidential: false,
        column: 4,
      },
    ],
    passportDetails: [
      {
        label: passportDetailsLbl.passportNumber,
        value: passportData?.passportNumber,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportNumber,
          value: (editEmployeeDetail?.passport?.passportNumber ?? passportData?.passportNumber) || '',
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: passportDetailsLbl.firstOtherNameAsInPassport,
        value: passportData?.passportFirstName || passportData?.passportLastName,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportFirstName,
          value:
            (editEmployeeDetail?.passport?.firstName ??
              (passportData?.passportFirstName || passportData?.passportLastName)) ||
            '',
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: passportDetailsLbl.surnameAsInPassport,
        value: passportData?.passportLastName,
        type: isEditingAdminProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportSurname,
          value: (editEmployeeDetail?.passport?.surname ?? passportData?.passportLastName) || '',
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: passportDetailsLbl.passportExpiryDate,
        value: formatDMYDateWithSpace(passportData?.passportExpirationDate),
        type: isEditingAdminProfile ? FORM_DATE_PICKER : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.passportExpiryDate,
          value: editEmployeeDetail?.passport?.expiryDate
            ? formatDMYDateWithSlash(editEmployeeDetail?.passport?.expiryDate)
            : formatDateAsString(passportData?.passportExpirationDate, DATE_FORMAT.ddmmyyyy),
          errorMessage: '',
        },
        setSelectedValue: handleUpdateSelect,
      },
      {
        label: passportDetailsLbl.nationalityInPassport,
        value: passportData?.passportNationality,
        type: isEditingAdminProfile ? FORM_FIELD_AUTO_COMPLETE : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportNationality,
          value: editEmployeeDetail?.passport?.nationality
            ? handleMatchCountryData(editEmployeeDetail?.passport?.nationality?.code)
            : handleMatchCountryData(passportData?.passportNationality),
          dropdownOptions: configurations?.countries,
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: passportDetailsLbl.issuedCountry,
        value: passportData?.passportCountry,
        type: isEditingAdminProfile ? FORM_FIELD_AUTO_COMPLETE : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportIssuedCountry,
          value: editEmployeeDetail?.passport?.issuedCountry
            ? handleMatchCountryData(editEmployeeDetail?.passport?.issuedCountry?.code)
            : handleMatchCountryData(passportData?.passportCountry),
          dropdownOptions: configurations?.countries,
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: passportDetailsLbl.countryOfResidence,
        value: profileDetail?.countryOfResidential,
        type: isEditingAdminProfile ? FORM_FIELD_AUTO_COMPLETE : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportCountryOfResidence,
          value: editEmployeeDetail?.passport?.countryOfResidential
            ? handleMatchCountryData(editEmployeeDetail?.passport?.countryOfResidential?.code)
            : handleMatchCountryData(profileDetail?.countryOfResidential),
          dropdownOptions: configurations?.countries,
        },

        setOnChange: handleUpdateInput,
      },
    ],
    profileHousekeepingDetails: [],
  };

  // The second toggle bar tab array
  const secondToggleBarTabList = getAdminSecondLevelToggleList();

  useEffect(() => {
    setUpdatedInputValue(null);
    setUpdatedSelectedValue(null);
  }, [isEditingAdminProfile]);

  useEffect(() => {
    // update edit mode data
    if (updatedSelectedValue || !isEmptyOrUndefined(updatedInputValue)) {
      const updatedSelectType = updatedSelectedValue?.selectType;
      const updatedSelectValue = updatedSelectedValue?.value;

      // payment edit
      const paymentConceptValue = editEmployeeDetail?.payment?.paymentConcept;
      const paymentMediumValue = editEmployeeDetail?.payment?.paymentMedium;
      const paymentStatusValue = editEmployeeDetail?.payment?.paymentStatus;

      const flownSuspensionStartDate = editEmployeeDetail?.flownSuspension?.flownSuspensionStartDate; // add suspension start date
      const flownSuspensionEndDate = editEmployeeDetail?.flownSuspension?.flownSuspensionEndDate; // add suspension end date

      // check is update profile fields
      const isUpdateProfileFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.profileTitle ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.profileGender ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.profileDateOfBirth ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.profileFirstName ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.profileMiddleName ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.profileLastName;

      // check is update employment fields
      const isUpdateEmploymentFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentJobLevel ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentTerminationDate ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentResignationCode ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentEmployeeCategory ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.employmentJobTitle ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.directAppraiser;

      // check is update payment fields
      const isUpdatePaymentFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentMedium ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentStatus;

      // check is edit flown suspension fields
      const isUpdateFlownSuspensionFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspension ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionEndDate;

      // check is update passport fields
      const isUpdatePassportFields =
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportNumber ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportFirstName ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportSurname ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.passportExpiryDate ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportNationality ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportIssuedCountry ||
        updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportCountryOfResidence;

      // handle select data
      setEditEmployeeDetail({
        ...editEmployeeDetail,

        ...(isUpdateProfileFields && {
          profile: {
            ...editEmployeeDetail?.profile,
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.profileTitle && {
              title: _.upperFirst(_.toLower(updatedSelectValue)),
            }),
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.profileGender && {
              gender: updatedSelectValue,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.profileFirstName && {
              firstName: updatedInputValue?.value,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.profileMiddleName && {
              middleName: updatedInputValue?.value,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.profileLastName && {
              lastName: updatedInputValue?.value,
            }),
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.profileDateOfBirth && {
              dateOfBirth: updatedSelectValue,
            }),
          },
        }),

        ...(isUpdateEmploymentFields && {
          employment: {
            ...editEmployeeDetail?.employment,
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentJobLevel && {
              jobLevel: updatedSelectValue,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.employmentJobTitle && {
              jobTitle: updatedInputValue?.value,
            }),
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentTerminationDate && {
              terminationDate: updatedSelectValue,
            }),
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentResignationCode && {
              resignationCode: updatedSelectValue,
            }),
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.employmentEmployeeCategory && {
              employeeCategory: updatedSelectValue,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.directAppraiser && {
              directAppraiser: updatedInputValue?.value,
            }),
          },
        }),

        ...(isUpdatePaymentFields && {
          payment: {
            paymentConcept:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept
                ? updatedSelectValue
                : paymentConceptValue ?? profileDetail?.paymentConcept,
            paymentMedium:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept
                ? updatedSelectValue === PAYMENT_CONCEPT.FLOWN
                  ? en.common.empty // need to clear medium select data if payment concept is changed
                  : PAYMENT_MEDIUM_PRP_OPTIONS[1].code // if payment concept is prepaid, set default medium to credit card
                : updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentMedium
                ? updatedSelectValue
                : paymentMediumValue ?? profileDetail?.paymentMedium ?? en.common.empty,
            paymentStatus:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentStatus
                ? updatedSelectValue
                : paymentStatusValue,
          },
        }),

        ...(isUpdateFlownSuspensionFields && {
          flownSuspension: {
            flownSuspensionStartDate:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate
                ? updatedSelectValue
                : flownSuspensionStartDate,
            flownSuspensionEndDate:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionEndDate
                ? updatedSelectValue
                : flownSuspensionEndDate,
          },
        }),

        ...(isUpdatePassportFields && {
          passport: {
            ...editEmployeeDetail?.passport,
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportNumber && {
              passportNumber: updatedInputValue?.value,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportFirstName && {
              firstName: updatedInputValue?.value,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportSurname && {
              surname: updatedInputValue?.value,
            }),
            ...(updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.passportExpiryDate && {
              expiryDate: updatedSelectValue,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportNationality && {
              nationality: updatedInputValue?.value,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportIssuedCountry && {
              issuedCountry: updatedInputValue?.value,
            }),
            ...(updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.passportCountryOfResidence && {
              countryOfResidential: updatedInputValue?.value,
            }),
          },
        }),
      });
    }
  }, [updatedSelectedValue, updatedInputValue]);

  return (
    <>
      <AdminToggleButtonGroup
        list={secondToggleBarTabList}
        onClick={(e) => {
          setAdminMaintainId(e as string);
        }}
        defaultValue={ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.all}
      />

      {/* Profile Detail */}
      {Object.entries(massagedPersonalInfoData).map(([key, value]: [string, IDisplayDetails[]]) => {
        const matchItem = secondToggleBarTabList.find((item) => item.id === adminMaintainId);
        const isFlownSuspensionItem =
          matchItem?.title === flownSuspensionToggleLbl ||
          (matchItem?.title === allToggleLbl && key === flownSuspensionDetailsLbl.title);

        const isShowBillingAgreementItem =
          matchItem?.title === paymentToggleLbl ||
          (matchItem?.title === allToggleLbl && key === paymentDetailsLbl.title);

        const isProfileHousekeepingItem =
          matchItem?.title === profileHousekeepingToggleLbl ||
          (matchItem?.title === allToggleLbl && key === profileHousekeepingDetailsLbl.title);
        if (
          matchItem?.title === allToggleLbl ||
          key.toUpperCase()?.includes(matchItem?.title?.replace(/\s/g, '').toUpperCase() || '')
        ) {
          return (
            <>
              <DetailsContainer
                key={key}
                title={personalInformationLbl[key as keyof typeof personalInformationLbl].heading}
              >
                <Grid container spacing={2}>
                  {value.map((data: IDisplayDetails, index: number) => {
                    return <LabelValueContainer key={index} {...data} />;
                  })}
                </Grid>
              </DetailsContainer>

              {isShowBillingAgreementItem && <InactivateBillingAgreementContainer />}
              {isFlownSuspensionItem && <AdminFlownSuspensionList />}
              {isProfileHousekeepingItem && <AssoSubsidAdminProfileHousekeeping />}
            </>
          );
        }
      })}
    </>
  );
};

export default AssoSubsidAdminPersonalInfoDetails;
