import { useContext, useEffect, useState } from 'react';
import { Box, useTheme, Typography, IconButton } from '@mui/material';
import { uniqWith, isEqual, partition } from 'lodash';

import {
  reduxForm,
  Field,
  getFormValues,
  initialize,
  FormErrors,
  getFormSyncErrors,
  getFormMeta,
  change,
} from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import { Configuration } from 'configuration';
import en from '../../../../translations/en';
import {
  FORM,
  LABEL_CATEGORY,
  RELATIONSHIP_OTHERS_CODE,
  BENEFICIARY_TYPE_CODE,
  NOMINATION_TYPE_TO_BACKEND,
} from '../../../../constants/constants';
import { BackIcon, BasicInfoIcon } from '../../../../assets/images';

import { ICompanionInfoDetails, IDependent, INominationDetails } from '../../../../interfaces';
import { IDoubleFormFieldInput } from '../../../../components/InputSelect/DoubleFormFieldInput';
import { IFormTextInput } from '../../../../components/InputSelect/ReduxFormTextInput';

import { RootState } from '../../../../app/store';

import {
  stringAlphabeticSort,
  validateRequiredFields,
  getDisplayName,
  isAssoSubsid,
  getLabelKeyByProfileType,
} from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectConfiguration } from '../../../../slice/configurationSlice';
import { selectUser } from '../../../../slice/userSlice';

import { NominationAction, NominationState } from '../../../../context';

import {
  ReduxFormTextInput,
  ReduxFormSelectInput,
  DateOfBirthDatePicker,
  PersonalOrPassportNameInput,
  DoubleFormFieldInput,
} from '../../../../components';
import { NominationPageTitle, ParagraphMasterContainer } from '../../../../containers';

interface ILimitInputRange {
  min: number;
  max: number;
}

const genericYearAndMonthFieldError =
  en.userProfile.nomination.companionRegistration.personalDetails.yearMonthKnownErrorMessage;

const currentForm = FORM.companionPersonal;

const yearKnownFieldLimitInputRange: ILimitInputRange = {
  min: 0,
  max: 99,
};

const monthKnownFieldLimitInputRange: ILimitInputRange = {
  min: 0,
  max: 11,
};

const validateLimitInputRange = ({ value, min, max }: { value: string; min: number; max: number }) => {
  // assume empty value is out of range
  if (value === undefined) return true;

  const parsedIntValue = parseInt(value);
  return parsedIntValue < min || parsedIntValue > max;
};

const validateYearMonthKnown = ({
  values,
  errors,
  yearKnownFieldLimitInputRange,
  monthKnownFieldLimitInputRange,
}: {
  values: ICompanionInfoDetails;
  errors: FormErrors<ICompanionInfoDetails | null>;
  yearKnownFieldLimitInputRange: ILimitInputRange;
  monthKnownFieldLimitInputRange: ILimitInputRange;
}) => {
  const yearKnownFieldValue = values?.yearKnown as string;
  const monthKnownFieldValue = values?.monthKnown as string;

  const isYearFieldOutOfInputRange = validateLimitInputRange({
    value: yearKnownFieldValue,
    min: yearKnownFieldLimitInputRange.min,
    max: yearKnownFieldLimitInputRange.max,
  });

  const isMonthFieldOutOfInputRange = validateLimitInputRange({
    value: monthKnownFieldValue,
    min: monthKnownFieldLimitInputRange.min,
    max: monthKnownFieldLimitInputRange.max,
  });

  // both fields out of range OR both fields empty
  if (
    (isYearFieldOutOfInputRange && isMonthFieldOutOfInputRange) ||
    (!parseInt(yearKnownFieldValue) && !parseInt(monthKnownFieldValue))
  ) {
    errors.monthKnown = genericYearAndMonthFieldError;
    errors.yearKnown = genericYearAndMonthFieldError;
    errors.mixedYearKnownAndMonthKnownFields = genericYearAndMonthFieldError;
  } else {
    // Month known field out of range + Year known field valid
    if (isMonthFieldOutOfInputRange && !isYearFieldOutOfInputRange) {
      errors.monthKnown = genericYearAndMonthFieldError;
      errors.mixedYearKnownAndMonthKnownFields = genericYearAndMonthFieldError;
    }

    // Year known field out of range + Month known field valid
    if (isYearFieldOutOfInputRange && !isMonthFieldOutOfInputRange) {
      errors.yearKnown = genericYearAndMonthFieldError;
      errors.mixedYearKnownAndMonthKnownFields = genericYearAndMonthFieldError;
    }
  }
};

const validatePersonal = (values: ICompanionInfoDetails | null): FormErrors<ICompanionInfoDetails | null> => {
  const errors: FormErrors<ICompanionInfoDetails | null> = {};

  if (values) {
    validateRequiredFields(values, ['relationship', 'title', 'firstName', 'lastName', 'gender'], errors);

    if (!values.firstName) {
      errors.firstName = en.userProfile.nomination.companionRegistration.personalDetails.personalFirstNameErrorLabel;
    }

    // [ETP-4847] Year known field & Month known field validation
    validateYearMonthKnown({
      values,
      errors,
      yearKnownFieldLimitInputRange,
      monthKnownFieldLimitInputRange,
    });
  }

  return errors;
};

const CompanionPersonalContainer = (props: any) => {
  const {
    companionInfoFormValues,
    formSyncErrors,
    formMeta,
  }: {
    companionInfoFormValues: ICompanionInfoDetails | null;
    formSyncErrors: any;
    formMeta: any;
  } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { dependent, profile } = useAppSelector(selectUser) || {};

  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};
  const { routeTo, isSelectDependentRelationship } = companionRegistrationFlow || {};
  const { step: routeToStep } = routeTo || {};

  const [allowRegisterList, setAllowRegisterList] = useState<IDependent[]>([]);

  const companionRegistrationLbl = en.userProfile.nomination.companionRegistration;
  const personalDetailLbl = companionRegistrationLbl.personalDetails;

  const labelKeyByProfileType = getLabelKeyByProfileType(profile);
  const profileTypeLabelKeyForPersonalDetail =
    personalDetailLbl?.[labelKeyByProfileType as keyof typeof personalDetailLbl];
  const descriptionContentByProfileType = (profileTypeLabelKeyForPersonalDetail as { descriptionContent: [] })
    .descriptionContent;

  const {
    relationship,
    selectedDependant,
    dependantType,
    title,
    firstName,
    lastName,
    dateOfBirth,
    middleName,
    gender,
    yearKnown,
    monthKnown,
  } = companionInfoFormValues || {};

  // [ETP-4847] Year and Month known field
  type TYearAndMonthKnownField = Partial<IFormTextInput>;
  const yearAndMonthKnownFieldError = (formSyncErrors as { mixedYearKnownAndMonthKnownFields?: string })
    ?.mixedYearKnownAndMonthKnownFields;

  /* display the mixed up error message logic when:
      (1) Checking the form meta field are visited (condition: visited is true and active is false)
        AND
      (2) either one of field (empty input or input) OR input the value out of range
    */
  const isVisitedYearOrMonthKnownField =
    (formMeta?.yearKnown?.visited && !formMeta?.yearKnown?.active) ||
    (formMeta?.monthKnown?.visited && !formMeta?.monthKnown?.active);

  const isYearFieldOutOfInputRange = validateLimitInputRange({
    value: yearKnown as string,
    min: yearKnownFieldLimitInputRange.min,
    max: yearKnownFieldLimitInputRange.max,
  });

  const isMonthFieldOutOfInputRange = validateLimitInputRange({
    value: monthKnown as string,
    min: monthKnownFieldLimitInputRange.min,
    max: monthKnownFieldLimitInputRange.max,
  });

  const isDefaultNotVisitYearAndMonthFieldValue = !isVisitedYearOrMonthKnownField && !yearKnown && !monthKnown;

  // prevent default show error message when the Year & Month fields are not visited and empty value, otherwise proceed to show the error message by the next condition - `yearAndMonthKnownFieldError` logic
  const isShowMixedErrorMessage = !isDefaultNotVisitYearAndMonthFieldValue;

  // Year and Month known field placeholder
  const yearKnownFieldPlaceholder = `${companionRegistrationLbl.personalDetails.fields.yearKnown} (${yearKnownFieldLimitInputRange.min}-${yearKnownFieldLimitInputRange.max})`;
  const monthKnownFieldPlaceholder = `${companionRegistrationLbl.personalDetails.fields.monthKnown} (${monthKnownFieldLimitInputRange.min}-${monthKnownFieldLimitInputRange.max})`;

  const yearAndMonthKnownFieldConfig: IDoubleFormFieldInput<TYearAndMonthKnownField> = {
    formFieldGroupList: [
      {
        component: ReduxFormTextInput,
        fieldProps: {
          name: 'yearKnown',
          isNum: true,
          maxLength: 2,
          title: yearKnownFieldPlaceholder,
          isHideErrorLabel: true,
          // trigger the input field border color to red when input invalid
          isError:
            isVisitedYearOrMonthKnownField &&
            ((formSyncErrors as { yearKnown?: string })?.yearKnown ||
              /* 
              check default already failure case.
              example: Input incorrect value, then back to previous step and proceed to Personal form page again.
             */
              isYearFieldOutOfInputRange),
        },
      },
      {
        component: ReduxFormTextInput,
        fieldProps: {
          name: 'monthKnown',
          isNum: true,
          maxLength: 2,
          title: monthKnownFieldPlaceholder,
          isHideErrorLabel: true,
          // trigger the input field border color to red when input invalid
          isError:
            isVisitedYearOrMonthKnownField &&
            ((formSyncErrors as { monthKnown?: string })?.monthKnown ||
              /* 
              check default already failure case.
              example: Input incorrect value, then back to previous step and proceed to Personal form page again.
             */
              isMonthFieldOutOfInputRange),
        },
      },
    ],
    mixedErrorMessage: (isShowMixedErrorMessage && yearAndMonthKnownFieldError) || '',
  };

  const transformedRelationshipData = () => {
    if (isAssoSubsid(profile)) {
      return [...(configurations?.assoSubsidRelationships || [])];
    } else {
      const uniqueRelationships = uniqWith(
        allowRegisterList.map((item: IDependent) => {
          return { code: item.relationship, label: item.relationship };
        }),
        isEqual,
      ).sort((a, b) => stringAlphabeticSort(a.label, b.label));

      // companion, other relationship data
      const [genericRelationship, otherRelationship] = partition(
        configurations?.relationships,
        (item) => item.code !== RELATIONSHIP_OTHERS_CODE,
      );

      // sorted allow register list + companion + otherRelationship
      return [...uniqueRelationships, ...genericRelationship, ...otherRelationship];
    }
  };

  const transformedDependentData = () => {
    return allowRegisterList
      .filter((item) => item.relationship === relationship?.code)
      .map((dependent) => {
        return {
          code: dependent.dependentId,
          label: getDisplayName({
            firstName: dependent.firstName,
            middleName: dependent.middleName,
            lastName: dependent.lastName,
          }),
        };
      });
  };

  useEffect(() => {
    dispatch(
      initialize(
        currentForm,
        {
          // [ETP-4717] hardcoded first
          dependantType: companionRegistrationLbl.personalDetails.fields.travelCompanion,
        },
        true,
      ),
    );

    // get not nominated dependents
    const companions = dependent.filter((item) => item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion);

    // [ETP-5596]
    /* 
      Remark: filter out logic
      - dependent with isActive = false
      - dependent with AUTO_NOMINATED nomination type
      - dependent with multiple active nomination record (current + next year)
      - dependent with companion type(has pHubDependentId) because that registered as companion, mentioned "Phub-travel companion" in story
    */
    const allowDependentList = dependent.filter((item) => {
      const autoNominatedDependent = item?.nominationDetails.find(
        (nominationDetailItem: INominationDetails) =>
          nominationDetailItem.nominationType === NOMINATION_TYPE_TO_BACKEND.autoNominated,
      );

      const multipleActiveNominationRecord = item?.nominationDetails?.filter(
        (nominationDetailItem: INominationDetails) => nominationDetailItem.isActive,
      );

      if (
        !item.isActive ||
        multipleActiveNominationRecord.length >= 2 ||
        autoNominatedDependent ||
        item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion
      ) {
        return false;
      } else {
        // filter out the dependent with companion type(has pHubDependentId) because that registered as companion
        return companions.every((companion) => item.dependentId !== companion.pHubDependentId);
      }
    });

    setAllowRegisterList(allowDependentList);
  }, []);

  useEffect(() => {
    const basePersonalInfo = {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      dateOfBirth,
    };
    const baseCompanionInfo = {
      ...basePersonalInfo,
      middleName: middleName?.trim() || '', // middle name as optional input
      relationship,
      dependantType,
      title,
      gender,
      yearKnown,
      monthKnown,
    };

    const baseValid = !formSyncErrors?.mixedYearKnownAndMonthKnownFields && relationship?.code && dependantType;

    if (
      relationship &&
      BENEFICIARY_TYPE_CODE.companion !== relationship?.code &&
      RELATIONSHIP_OTHERS_CODE !== relationship?.code
    ) {
      // is selected relationship with dependent
      const isDependentInputValid = baseValid && selectedDependant?.code;
      const targetDependent = allowRegisterList.find((item) => item.dependentId === selectedDependant?.code);

      if (isDependentInputValid && targetDependent) {
        // is selected dependent
        const title = configurations?.labels.find(
          (item) => item.category === LABEL_CATEGORY.title && item.label === targetDependent?.title,
        );
        const gender = configurations?.labels.find(
          (item) => item.category === LABEL_CATEGORY.gender && item.code === targetDependent?.gender,
        );
        const passportCountry = configurations.countries.find((item) => item.code === targetDependent?.passportCountry);

        setCompanionRegistrationFlow({
          ...companionRegistrationFlow,
          isPersonalInputValid: isDependentInputValid,
          isSelectDependentRelationship: true,
          companionRegistrationData: {
            ...companionRegistrationFlow.companionRegistrationData,
            companionInfo: {
              ...baseCompanionInfo,
              ...targetDependent,
              relationship,
              title,
              gender,
              selectedDependant,
            },
            companionPassport: {
              passportNumber: targetDependent?.passportNumber,
              passportFirstName: targetDependent?.passportName.firstName,
              passportLastName: targetDependent?.passportName.lastName,
              passportExpirationDate: targetDependent?.passportExpirationDate,
              passportNationality: passportCountry,
              passportCountry,
            },
          },
        });
      }
    } else {
      // is selected relationship with companion
      const isCompanionInputValid =
        baseValid && Object.values(basePersonalInfo).every(Boolean) && title?.code && gender?.code;
      if (isCompanionInputValid) {
        setCompanionRegistrationFlow({
          ...companionRegistrationFlow,
          isPersonalInputValid: isCompanionInputValid,
          isSelectDependentRelationship: false,
          companionRegistrationData: {
            ...companionRegistrationFlow.companionRegistrationData,
            companionInfo: baseCompanionInfo,
          },
        });
      } else {
        setCompanionRegistrationFlow({
          ...companionRegistrationFlow,
          isPersonalInputValid: isCompanionInputValid,
        });
      }
    }
  }, [companionInfoFormValues, formSyncErrors]);

  return (
    <Box className="companion_personal_container">
      {!isDesktop && (
        <IconButton
          onClick={() => {
            setCompanionRegistrationFlow({
              ...companionRegistrationFlow,
              routeTo: {
                step: routeToStep - 1,
              },
            });
          }}
          sx={{ py: 1.625, pl: 0 }}
        >
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Box
        sx={{
          ...(isDesktop
            ? {
                mt: 2,
                mb: 1,
                width: '488px',
                mx: 'auto',
              }
            : {
                mt: 2.25,
              }),
        }}
      >
        <NominationPageTitle
          title={en.userProfile.nomination.nomineesDetail.contentTitle}
          sxProps={{
            pb: 2.5,
          }}
        />

        <ParagraphMasterContainer content={descriptionContentByProfileType} paragraphVariant="body_1_regular" />

        <Box sx={{ display: 'flex', alignItems: 'center', mb: isDesktop ? 0.5 : 1.5, mt: isDesktop ? 0.5 : 0 }}>
          <Box sx={{ display: 'flex', width: 20, height: 20, mr: 0.5, mt: 0.375 }}>
            <BasicInfoIcon width={16} height={16} />
          </Box>

          <Typography
            variant="body_1_medium"
            sx={{
              color: theme.color.secondary.dark_grey.option_3,
            }}
          >
            {companionRegistrationLbl.personalDetails.header}
          </Typography>
        </Box>

        <ParagraphMasterContainer
          content={companionRegistrationLbl.personalDetails.descriptionContentWhichUnderPersonalDetailHeader}
          paragraphVariant="body_1_regular"
        />

        {/* // [ETP-4847] Year and Month known field */}
        {/* // the interface `IFormTextInput` inside type `TYearAndMonthKnownField` is come from `ReduxFormTextInput` */}
        <DoubleFormFieldInput<TYearAndMonthKnownField> {...yearAndMonthKnownFieldConfig} />

        <Field
          component={ReduxFormSelectInput}
          name="relationship"
          title={personalDetailLbl.fields.relationship}
          options={transformedRelationshipData()}
          onChange={(relationship: any) => {
            setCompanionRegistrationFlow({
              ...companionRegistrationFlow,
              isSelectDependentRelationship: ![BENEFICIARY_TYPE_CODE.companion, RELATIONSHIP_OTHERS_CODE].includes(
                relationship.code,
              ),
              companionRegistrationData: null,
              isPersonalInputValid: false,
              isPassportInputValid: false,
            });

            // reset personal redux-form
            dispatch(change(currentForm, 'selectedDependant', ''));
            dispatch(change(currentForm, 'firstName', ''));
            dispatch(change(currentForm, 'lastName', ''));
            dispatch(change(currentForm, 'dateOfBirth', ''));
            dispatch(change(currentForm, 'middleName', ''));
            dispatch(
              change(currentForm, 'title', {
                code: '',
                label: '',
              }),
            );
            dispatch(
              change(currentForm, 'gender', {
                code: '',
                label: '',
              }),
            );

            // reset passport
            dispatch(initialize(FORM.passportDetails, {}));
          }}
        />

        {isSelectDependentRelationship && (
          <Field
            component={ReduxFormSelectInput}
            name="selectedDependant"
            title={personalDetailLbl.fields.dependant}
            options={transformedDependentData()}
          />
        )}

        <Field
          component={ReduxFormTextInput}
          name="dependantType"
          title={personalDetailLbl.fields.dependantType}
          isReadOnly={true}
        />

        {!isSelectDependentRelationship && (
          <>
            <Field
              component={ReduxFormSelectInput}
              name="title"
              title={personalDetailLbl.fields.title}
              options={configurations?.labels?.filter(
                (label: Configuration.CodeLabel) => label.category === LABEL_CATEGORY.title,
              )}
            />

            <PersonalOrPassportNameInput name="firstName" title={personalDetailLbl.fields.firstName} />
            <PersonalOrPassportNameInput name="middleName" title={personalDetailLbl.fields.middleName} />
            <PersonalOrPassportNameInput name="lastName" title={personalDetailLbl.fields.surname} />

            <DateOfBirthDatePicker isPositionUnset={true} />

            <Field
              component={ReduxFormSelectInput}
              name="gender"
              title={personalDetailLbl.fields.gender}
              options={configurations?.labels?.filter(
                (label: Configuration.CodeLabel) => label.category === LABEL_CATEGORY.gender,
              )}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

const form = reduxForm({
  form: currentForm,
  validate: validatePersonal,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(CompanionPersonalContainer);

export default connect((state: RootState) => ({
  companionInfoFormValues: getFormValues(currentForm)(state),
  formMeta: getFormMeta(currentForm)(state),
  formSyncErrors: getFormSyncErrors(currentForm)(state),
}))(form);
