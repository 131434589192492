import { Backdrop, Box, Typography, useTheme } from '@mui/material';
import FlightLottieAnimation from '../containers/Common/FlightLottieAnimation';

const toCapitalize = (s: string | undefined): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getHideName = (value: string, displayNum: number, symbols: string) => {
  const newValList = value.split(' ');
  const tempList: string[] = [];
  newValList.map((item: string) => {
    const val =
      item.slice(0, displayNum) + item.slice(displayNum, item.length).replaceAll(RegExp(/[a-zA-Z]/g, 'ig'), symbols);
    tempList.push(val);
  });
  return tempList.join(' ');
};

const getCurrentHostNameAndUrl = () => {
  const { protocol, hostname, port, href } = window.location;

  const currentHostName = `${protocol}//${hostname}${port ? ':' + port : ''}`;
  const currentUrl = href;

  return {
    currentHostName,
    currentUrl,
  };
};

const getFlightAnimation = (message?: string) => {
  const theme = useTheme();

  return (
    <Backdrop
      sx={{
        color: theme.palette.bgColor.main,
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
      open={true}
    >
      <Box
        sx={{
          width: message ? '245px' : '200px',
          height: message ? '252px' : '190px',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: '150px',
            height: '150px',
          }}
        >
          <FlightLottieAnimation />
        </Box>
        {message && (
          <Typography
            variant="navigation_regular"
            color={theme.color.secondary.dark_grey.option_1}
            sx={{ width: '150px', textAlign: 'center' }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};

function getUniqueNestedFieldValues<T>(arrayOfObjects: T[], nestedFieldName: string): any[] {
  if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
    return [];
  }

  const uniqueValues = new Set<any>();

  function extractNestedFieldValue(obj: any, fieldPath: string): any {
    if (!obj) return;
    const fieldParts = fieldPath.split('.');

    for (const part of fieldParts) {
      if (obj[part] !== undefined) {
        obj = obj[part];
      } else {
        return; // Field doesn't exist in the object
      }
    }

    return obj;
  }

  for (const obj of arrayOfObjects) {
    const fieldValue = extractNestedFieldValue(obj, nestedFieldName);
    if (fieldValue !== undefined) {
      uniqueValues.add(fieldValue);
    }
  }

  // Convert the Set back to an array if needed
  return Array.from(uniqueValues);
}

const isNonProd = () => {
  console.log(window.config.cxDeployEnv);
  return ['d0', 'd1', 'd2', 't0', 't1', 't2', 't3']?.includes(window.config.cxDeployEnv);
};

const setConcessionYearRequestParam = (concessionYear?: string) => {
  return concessionYear ? { concessionYear } : {};
};

export {
  toCapitalize,
  getHideName,
  getCurrentHostNameAndUrl,
  getFlightAnimation,
  getUniqueNestedFieldValues,
  isNonProd,
  setConcessionYearRequestParam,
};
