import React from 'react';
import { Box, Grid, useTheme, SxProps, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { FormButton } from '..';
import { UI_STYLES } from '../../constants/constants';
import { selectApp } from '../../slice/appSlice';
import { useAppSelector } from '../../app/hooks';

export interface ISearchBtnProps {
  leftChild?: React.ReactNode;
  primaryBtn?: {
    text: string;
    route?: string;
    status?: boolean;
    colour?: string;
    customOnClick?: () => void;
    isFullWidth?: boolean;
    customButtonStyles?: any;
    restProps?: any;
  };
  secondaryBtn?: {
    text: string;
    customOnClick?: () => void;
    isFullWidth?: boolean;
    customButtonStyles?: any;
  };
  customStyles?: any;
  leftColumnSx?: SxProps<Theme>;
}

const Footer = ({ leftColumnSx, leftChild, primaryBtn, secondaryBtn, customStyles }: ISearchBtnProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isDesktop } = useAppSelector(selectApp);

  const primaryBtnOnClick = () => {
    if (primaryBtn?.route) {
      navigate(primaryBtn?.route);
    }
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        px: 2,
        py: 2,
        background: 'white',
        minHeight: UI_STYLES.footerHeight,
        ...customStyles,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          ...leftColumnSx,
        }}
      >
        {leftChild}

        {primaryBtn && (
          <FormButton
            theme={theme}
            color="success"
            colour="green"
            variant="contained"
            size="large"
            onClick={primaryBtn.customOnClick || primaryBtnOnClick}
            disabled={!!primaryBtn.status}
            customStyles={{
              marginLeft: 'auto',
              borderRadius: 8,
              minWidth: '165px',
              width: '198px',
              height: 44,
              ...primaryBtn.customButtonStyles,
              ...(primaryBtn.isFullWidth && { width: '100%' }),
            }}
            {...primaryBtn.restProps}
          >
            {primaryBtn.text}
          </FormButton>
        )}
      </Box>

      {secondaryBtn && (
        <FormButton
          theme={theme}
          color="primary"
          colour="outline"
          variant="outlined"
          size="large"
          onClick={secondaryBtn.customOnClick}
          customStyles={{
            marginLeft: 0,
            borderRadius: 8,
            minWidth: '165px',
            height: 44,
            marginTop: isDesktop ? 0 : 8,
            ...(secondaryBtn.isFullWidth && { width: '100%' }),
            ...secondaryBtn.customButtonStyles,
          }}
        >
          {secondaryBtn.text}
        </FormButton>
      )}
    </Grid>
  );
};

Footer.defaultProps = {
  customStyles: {},
};

export default Footer;
