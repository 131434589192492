import React, { useState } from 'react';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { CheckBoxCheckIcon, CheckBoxIcon } from '../../../assets/images';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import {
  selectUser,
  setIsOpenUpdateContactDetailsDialog,
  setIsConfirmUpdateContactDetails,
} from '../../../slice/userSlice';

import { FormButton } from '../../../components';

const UpdateContactDetailsDialog = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const [isAgreeToUpdateContactChecked, setIsAgreeToUpdateContactChecked] = useState<boolean>(false);
  const { isOpenUpdateContactDetailsDialog } = useAppSelector(selectUser) || {};

  const { updateContactDetails, updateContactNoticeMessage } = en.userProfile.personal.contactInfo;

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'baseline',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: isDesktop ? '478px' : '343px',
            borderRadius: 2,
            m: 0,
            px: isDesktop ? 4.5 : 3,
            py: 2,
            top: '22%',
            overflowX: 'hidden',
            boxShadow: theme.boxShadow.important,
          },
        },
      }}
      open={!!isOpenUpdateContactDetailsDialog}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="title_1_bold"
          sx={{
            px: 0,
            py: 2,
            color: theme.palette.text.secondary,
          }}
        >
          {updateContactDetails}
        </Typography>
        <DialogContent sx={{ px: 0, pt: 1, pb: 2 }}>
          <Typography
            variant="body_1_regular"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            <Box sx={{ my: 1, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ mt: -1, ml: -1 }}>
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root': {
                      color: theme.status.selected,
                    },
                  }}
                  checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                  icon={<CheckBoxIcon stroke={theme.status.selected} />}
                  color="info"
                  checked={isAgreeToUpdateContactChecked}
                  onChange={() => setIsAgreeToUpdateContactChecked(!isAgreeToUpdateContactChecked)}
                />
              </Box>

              <Typography
                variant="body_2_regular"
                sx={{
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {updateContactNoticeMessage}
              </Typography>
            </Box>
          </Typography>
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          p: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormButton
          theme={theme}
          colour="outline"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? '195px' : '140px',
            height: '44px',
            '&:hover': {
              backgroundColor: 'white',
              boxShadow: 'none',
            },
          }}
          onClick={() => {
            // close dialog
            dispatch(setIsOpenUpdateContactDetailsDialog(false));
            setIsAgreeToUpdateContactChecked(false);
          }}
          sx={{
            ml: 0,
          }}
        >
          {en.common.cancel}
        </FormButton>

        <FormButton
          theme={theme}
          colour="green"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? '195px' : '140px',
            height: '44px',
          }}
          onClick={() => {
            // close dialog
            dispatch(setIsOpenUpdateContactDetailsDialog(false));
            setIsAgreeToUpdateContactChecked(false);

            // save "confirm" click action
            dispatch(setIsConfirmUpdateContactDetails(true));
          }}
          disabled={!isAgreeToUpdateContactChecked}
          sx={{
            ml: 0,
          }}
        >
          {en.common.confirm}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateContactDetailsDialog;
