import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { Configuration } from 'configuration';

import en from '../../../../translations/en';
import {
  LABEL_VALUE_TYPE,
  ADMIN_PERSONALINFO_SELECT_TYPE,
  ADMIN_PERSONALINFO_INPUT_TYPE,
  DATE_FORMAT,
} from '../../../../constants/constants';

import { IDependentForAdminPassport } from '../../../../interfaces/admin';

import { formatDateAsString, getLocalDate } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectConfiguration } from '../../../../slice/configurationSlice';
import { AssoSubsidAdminEmployeeState, AssoSubsidAdminEmployeeAction } from '../../../../context';

import { IDisplayDetails, DetailsContainer, LabelValueContainer } from '../../PersonalInfo';

interface IMassagedPersonalInfoData {
  passportDetails: IDisplayDetails[];
}

interface IEditPassportDetail {
  passportNumber: string;
  passportSurname: string;
  passportFirstName: string;
  passportExpiryDate: string;
  issuedCountry: string;
  nationalityInPassport: string;
}

const AssoSubsidDependentsPassportDetail = ({ passportDetails }: IDependentForAdminPassport) => {
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const {
    setEditDependentsDetail,
    setIsPassportInfoMissing,
    setIsExpiryDateEdited,
    setIsIssuedCountryEdited,
    setIsNationalityInPassportEdited,
  } = useContext(AssoSubsidAdminEmployeeAction) || {};
  const {
    isEditingDependentProfile,
    editDependentsDetail,
    isExpiryDateEdited,
    isIssuedCountryEdited,
    isNationalityInPassportEdited,
  } = useContext(AssoSubsidAdminEmployeeState) || {};
  const fieldLabel = en.admin.feature.dependent;
  const { passportDetails: passportDetailsLbl } = fieldLabel;
  const { STRING, FORM_DATE_PICKER, FORM_FIELD_TEXT_INPUT, FORM_FIELD_AUTO_COMPLETE } = LABEL_VALUE_TYPE;
  const [isPassportEdited, setIsPassportEdited] = useState<boolean>(false);
  const checkPassportField = (passportInfo: IEditPassportDetail) => {
    const passportValue = Object.values(passportInfo);
    // either passportInfo is all empty or all filled
    return (
      passportValue.every((value) => value === null || value === '') ||
      !passportValue.some((value) => value === null || value === '')
    );
  };

  useEffect(() => {
    const editedPassportDetail: IEditPassportDetail = {
      passportNumber: editDependentsDetail?.passportNumber ?? passportDetails.passportNumber,
      passportSurname: editDependentsDetail?.passportSurname ?? passportDetails.surnameAsInPassport,
      passportFirstName: editDependentsDetail?.passportFirstName ?? passportDetails.firstOtherNameAsInPassport,
      passportExpiryDate: isExpiryDateEdited
        ? formatDateAsString(editDependentsDetail?.passportExpiryDate, DATE_FORMAT.ddmmyyyy)
        : formatDateAsString(passportDetails.passportExpiryDate, DATE_FORMAT.ddmmyyyy),
      issuedCountry: isIssuedCountryEdited
        ? handleMatchCountryData(editDependentsDetail?.issuedCountry?.code)
        : handleMatchCountryData(passportDetails.issuedCountry),
      nationalityInPassport: isNationalityInPassportEdited
        ? handleMatchCountryData(editDependentsDetail?.nationalityInPassport?.code)
        : handleMatchCountryData(passportDetails.nationalityInPassport),
    };
    setIsPassportInfoMissing(!checkPassportField(editedPassportDetail));

    if (
      editDependentsDetail?.passportFirstName ||
      editDependentsDetail?.passportSurname ||
      editDependentsDetail?.passportNumber ||
      editDependentsDetail?.passportExpiryDate ||
      editDependentsDetail?.issuedCountry ||
      editDependentsDetail?.nationalityInPassport
    ) {
      setIsPassportEdited(true);
    } else setIsPassportEdited(false);
  }, [editDependentsDetail]);

  const handleUpdateInput = (inputType: string, value: string) => {
    setEditDependentsDetail({
      ...editDependentsDetail,
      [inputType]: value,
    });
  };

  const handleUpdateAutoCompleteInput = (inputType: string, value: string) => {
    setEditDependentsDetail({
      ...editDependentsDetail,
      [inputType]: value,
    });
    if (inputType === ADMIN_PERSONALINFO_SELECT_TYPE.issuedCountry) {
      setIsIssuedCountryEdited(true);
    } else if (inputType === ADMIN_PERSONALINFO_SELECT_TYPE.nationalityInPassport) {
      setIsNationalityInPassportEdited(true);
    }
  };

  const handleUpdateDateSelect = (selectType: string, value: string) => {
    setEditDependentsDetail({
      ...editDependentsDetail,
      passportExpiryDate: value,
    });
    setIsExpiryDateEdited(true);
  };

  const handleMatchCountryData = (countryCode?: string | null) => {
    return (
      configurations?.countries?.find((option: Configuration.DropdownOptionClient) => option.code === countryCode)
        ?.label || ''
    );
  };

  const MassagedPersonalInfoData: IMassagedPersonalInfoData = {
    passportDetails: [
      {
        label: passportDetailsLbl.passportNumber,
        value: passportDetails.passportNumber,
        type: isEditingDependentProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportNumber,
          value: (editDependentsDetail?.passportNumber ?? passportDetails.passportNumber) || '',
          isMandatoryField: isPassportEdited,
        },
        setOnChange: handleUpdateInput,
      },

      {
        label: passportDetailsLbl.firstOtherNameAsInPassport,
        value: passportDetails.firstOtherNameAsInPassport,
        type: isEditingDependentProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportFirstName,
          value: (editDependentsDetail?.passportFirstName ?? passportDetails.firstOtherNameAsInPassport) || '',
          isMandatoryField: isPassportEdited,
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: passportDetailsLbl.surnameAsInPassport,
        value: passportDetails.surnameAsInPassport,
        type: isEditingDependentProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.passportSurname,
          value: (editDependentsDetail?.passportSurname ?? passportDetails.surnameAsInPassport) || '',
          isMandatoryField: isPassportEdited,
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: passportDetailsLbl.passportExpiryDate,
        value: passportDetails.passportExpiryDate,
        type: isEditingDependentProfile ? FORM_DATE_PICKER : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.passportExpiryDate,
          value: isExpiryDateEdited
            ? formatDateAsString(editDependentsDetail?.passportExpiryDate, DATE_FORMAT.ddmmyyyy)
            : formatDateAsString(passportDetails.passportExpiryDate, DATE_FORMAT.ddmmyyyy),
          minDate: getLocalDate(),
          isMandatoryField: isPassportEdited,
        },
        setSelectedValue: handleUpdateDateSelect,
      },
      {
        label: passportDetailsLbl.nationalityInPassport,
        value: passportDetails.nationalityInPassport,
        type: isEditingDependentProfile ? FORM_FIELD_AUTO_COMPLETE : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.nationalityInPassport,
          value: isNationalityInPassportEdited
            ? handleMatchCountryData(editDependentsDetail?.nationalityInPassport?.code)
            : handleMatchCountryData(passportDetails.nationalityInPassport),
          dropdownOptions: configurations?.countries,
          isMandatoryField: isPassportEdited,
        },
        setOnChange: handleUpdateAutoCompleteInput,
      },
      {
        label: passportDetailsLbl.issuedCountry,
        value: passportDetails.issuedCountry,
        type: isEditingDependentProfile ? FORM_FIELD_AUTO_COMPLETE : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.issuedCountry,
          value: isIssuedCountryEdited
            ? handleMatchCountryData(editDependentsDetail?.issuedCountry?.code)
            : handleMatchCountryData(passportDetails.issuedCountry),
          dropdownOptions: configurations?.countries,
          isMandatoryField: isPassportEdited,
        },
        setOnChange: handleUpdateAutoCompleteInput,
      },
    ],
  };

  return (
    <>
      <Box
        className="asso_subsid_passport_dependent_detail"
        sx={{
          margin: '24px auto 0',
          position: 'relative',
        }}
      >
        <Box>
          <DetailsContainer title={fieldLabel.passportDetails.heading}>
            <Grid container spacing={2}>
              {MassagedPersonalInfoData.passportDetails.map((data: IDisplayDetails, index: number) => {
                return <LabelValueContainer key={index} {...data} />;
              })}
            </Grid>
          </DetailsContainer>
        </Box>
      </Box>
    </>
  );
};

export default AssoSubsidDependentsPassportDetail;
