import { useContext, useMemo } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import _ from 'lodash';

import en from '../../../../translations/en';
import { NOMINATION_TYPE, NOMINATION_YEAR_TYPE } from '../../../../constants/constants';

import { INominationViewModeItem, IOALEligibilitySelectedData } from '../../../../interfaces';

import {
  getDisplayName,
  sortByDependents,
  checkIsNextYearNominationFlow,
  getNominationPeriodRecordByYearType,
} from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectUser } from '../../../../slice/userSlice';
import { NominationState } from '../../../../context';

import { NominationDependentList } from '../..';

interface INominationDependentListData {
  dependentName: string;
  status: string;
  isPrimaryLabel?: boolean;
}

const NominationReviewAndConfirmContainer = () => {
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser) || {};

  const { nominationTransformData, nominationFlow, nominationChangeTypeData } = useContext(NominationState) || {};
  const { sortedDependents } = nominationFlow.nominationSelectionData || [];

  const isNextYearNominationFlow = checkIsNextYearNominationFlow();

  const futureNominationYear = getNominationPeriodRecordByYearType(profile, NOMINATION_YEAR_TYPE.future)
    ?.nominationYearFrom;

  const baseNominationLbl = en.userProfile.nomination.nominationFlow;
  const nextYearNominationLbl = en.userProfile.nomination.nextYearNominationFlow;
  const nominationFlowLbl = isNextYearNominationFlow ? nextYearNominationLbl : baseNominationLbl;

  const mainTitle = `${(
    baseNominationLbl?.[nominationFlow.nominationFlowSelectedType as keyof typeof baseNominationLbl] as {
      title: string;
    }
  )?.title} ${isNextYearNominationFlow ? futureNominationYear : ''}`;

  const headerTitle = nominationFlowLbl.confirmation;

  const toBeChangeTypeDependent = nominationChangeTypeData.find(
    (dependent: INominationViewModeItem) => dependent.isSelected,
  );

  const nominateDependentToBeChangedList = useMemo(() => {
    return sortByDependents(nominationTransformData, nominationChangeTypeData, sortedDependents);
  }, [nominationTransformData, nominationChangeTypeData]);

  const changeTypeLabel =
    nominationFlow.nominationFlowSelectedType === NOMINATION_TYPE.plusOne
      ? `${baseNominationLbl.fromTravelNomineeLabel} <br />${baseNominationLbl.toPlusOneLabel}`
      : `${baseNominationLbl.fromPlusOneToLabel} <br />${baseNominationLbl.travelNomineeLabel}`;

  const transformedDependentListData: INominationDependentListData[] = _.cloneDeep(
    nominateDependentToBeChangedList || [],
  )
    .filter((dependent: INominationViewModeItem) => {
      return dependent.isSelected;
    })
    .map((dependent: INominationViewModeItem) => {
      const matchedOAL = nominationFlow.oalEligibilityTickedDependents?.find(
        (oal: IOALEligibilitySelectedData) => oal.dependentId === dependent.dependentId,
      );

      return {
        dependentName: getDisplayName({ firstName: dependent.firstName, lastName: dependent.lastName }),
        status: '',
        relationship: dependent.beneficiaryType,
        // indicate "to be added" dependents
        ...(!dependent.isLocked && {
          isPrimaryLabel: true,
          status: matchedOAL?.isAssignedOAL // check if assign aol to this dependent or not
            ? `${baseNominationLbl.toBeAdded} <br />${baseNominationLbl.oalAssigned}`
            : baseNominationLbl.toBeAdded,
        }),
        // show change type label
        ...(toBeChangeTypeDependent &&
          toBeChangeTypeDependent.dependentId === dependent.dependentId && {
            isPrimaryLabel: true,
            status: changeTypeLabel,
          }),
      };
    });

  return (
    <>
      <Box
        sx={{
          mb: 9.5,
          ...(isDesktop && {
            width: '488px',
            mx: 'auto',
          }),
        }}
      >
        <Typography
          variant="headline_medium"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
            mb: 2,
          }}
        >
          {headerTitle}
        </Typography>

        <NominationDependentList
          dependentList={transformedDependentListData}
          mainTitle={mainTitle}
          dependentNameCustomStyles={{
            maxWidth: '56%',
          }}
          statusCustomStyles={{
            maxWidth: '44%',
            textAlign: 'right',
          }}
        />
      </Box>
    </>
  );
};

export default NominationReviewAndConfirmContainer;
