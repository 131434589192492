import { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { BOOKING_FLOW_PAGE_NO, PREPAID_AUTH_FLOW_TYPE } from '../../constants/constants';
import { BOOKING_PATHS, ROOT_PATHS } from '../../constants/paths';
import en from '../../translations/en';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp, setLoading, setRetrieveAndPaySuccessData, setNormalPrepaidSuccessData } from '../../slice/appSlice';
import { selectAuth } from '../../slice/authSlice';
import {
  selectBooking,
  setBookingStep,
  setRetrieveAndPayUnhappyFlowFinalPageControl,
  setIsCalledPrepaidAuth,
  setPrepaidAuthFlowType,
  setRetrieveAndPayCppParams,
  setIsTriggerReviewAndConfirmByNormalPrepaid,
} from '../../slice/bookingSlice';

import { prepaidCancelBooking } from '../../services/prepaid';

import { Div100vhView } from '../../components';

const PrepaidBookTransitBack = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { ern } = useAppSelector(selectAuth) || {};
  const { retrieveAndPaySuccessData } = useAppSelector(selectApp) || {};
  const { prepaidAuthFlowType, tpAppRef: cppTpAppRefFromRedux } = useAppSelector(selectBooking) || {};

  const isPrepaidBookTransitBackRefresh = localStorage.getItem('isPrepaidBookTransitBackRefresh');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transitPageProcessing, setTransitPageProcessing] = useState<{
    isLoading: boolean;
    errorMessage?: string;
  }>({
    isLoading: false,
    errorMessage: '',
  });

  const cancelBookFlow = async () => {
    if (cppTpAppRefFromRedux) {
      // Log processing in current page
      setTransitPageProcessing({
        isLoading: true,
        errorMessage: '',
      });

      await prepaidCancelBooking({
        userId: ern,
        tpAppRef: cppTpAppRefFromRedux,
        error: {
          reason: en.payment.prepaid.cppBackReason,
          errorCode: '',
        },
      });

      setTransitPageProcessing({
        isLoading: false,
        errorMessage: '',
      });

      // [normal prepaid] save booking data
      if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.NORMAL_PREPAID) {
        // redirect to review and confirm page
        dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.confirmation));
        dispatch(setIsTriggerReviewAndConfirmByNormalPrepaid(true));

        navigate(BOOKING_PATHS.booking);
      }

      // [retrieve and pay] save booking data
      if (prepaidAuthFlowType === PREPAID_AUTH_FLOW_TYPE.RETRIEVE_AND_PAY) {
        if (retrieveAndPaySuccessData?.recLoc) {
          dispatch(setBookingStep(null));

          /*
            - Set `isTriggerRedirectHomePage` to be true
              (1) Trigger by unhappy flow (CPP errors OR CPP back cases) and redirect to Home Page.
          */
          dispatch(
            setRetrieveAndPayUnhappyFlowFinalPageControl({
              isTriggerRedirectHomePage: true,
            }),
          );

          navigate(ROOT_PATHS.landing, { replace: true });
        }
      }
    }
  };

  // Default show loading
  useEffect(() => {
    dispatch(setLoading(true));
    localStorage.removeItem('isIndexRefresh');
  }, []);

  // [CPP payment error]
  useEffect(() => {
    if (prepaidAuthFlowType && cppTpAppRefFromRedux) {
      if (!isPrepaidBookTransitBackRefresh) cancelBookFlow();
    } else {
      // instant redirect to home page
      navigate(ROOT_PATHS.landing);
    }
  }, [isPrepaidBookTransitBackRefresh, prepaidAuthFlowType, cppTpAppRefFromRedux]);

  // Refresh handling > setup flag
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      // event.returnValue = 'Are you sure you want to leave this page?';

      // whatever the page is loading or error occurred after loaded
      if (transitPageProcessing.isLoading || (!transitPageProcessing.isLoading && transitPageProcessing.errorMessage)) {
        localStorage.setItem('isPrepaidBookTransitBackRefresh', 'true');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [transitPageProcessing]);

  // Refresh handling > execute redirection
  useEffect(() => {
    const flag = localStorage.getItem('isPrepaidBookTransitBackRefresh');
    if (flag) {
      localStorage.removeItem('isPrepaidBookTransitBackRefresh');

      // clear relate redux data
      dispatch(setIsCalledPrepaidAuth(false));
      dispatch(setRetrieveAndPayCppParams(null));
      dispatch(setBookingStep(null));

      dispatch(setPrepaidAuthFlowType(''));
      dispatch(setRetrieveAndPaySuccessData(null));
      dispatch(setNormalPrepaidSuccessData(null));

      // redirect to home page
      navigate(ROOT_PATHS.landing, { replace: true });
    }
  }, []);

  return (
    <Div100vhView
      sx={{
        backgroundColor: theme.color.secondary.light_slate.option_7,
      }}
    >
      <Box> </Box>
    </Div100vhView>
  );
};

export default PrepaidBookTransitBack;
