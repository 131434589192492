import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import en from '../../../../translations/en';

type Props = {
  isExpand: boolean;
  setIsExpand: (e: boolean) => void;
};

const ExpandButton = ({ isExpand, setIsExpand }: Props) => {
  const theme = useTheme();
  const { viewLess, viewMore } = en.booking.nonEnglishCharacter;

  return (
    <Box
      className="asso_subsid_admin_dependents_housekeeping_expand_button"
      sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}
    >
      <Typography
        variant="body_2_bold"
        sx={{
          color: theme.color.utility.link.option_3,
          cursor: 'pointer',
        }}
        onClick={() => setIsExpand(!isExpand)}
      >
        {isExpand ? viewLess : viewMore}
      </Typography>
    </Box>
  );
};

export default ExpandButton;
