import { ADMIN_PATHS } from '../../constants/paths';
import { IRouteConfig } from '../../interfaces';
import AdminGuide from './AdminGuide';
import AdminEmployee from './AdminEmployee';
import AdminAssoSubsidEmployee from './AdminAssoSubsidEmployee';
import AdminTravelHistoryReport from './AdminTravelHistoryReport';
import AdminAssoSubsidTravelHistoryReport from './AdminAssoSubsidTravelHistoryReport';
import AdminUploadAssoSubsidEmployeeProfile from './AdminUploadAssoSubsidEmployeeProfile';

const adminRouteConfig: IRouteConfig[] = [
  {
    path: ADMIN_PATHS.adminGuide,
    component: AdminGuide,
    exact: false,
  },
  {
    path: ADMIN_PATHS.adminEmployee,
    component: AdminEmployee,
    exact: false,
  },
  {
    path: ADMIN_PATHS.adminAssoSubsidEmployee,
    component: AdminAssoSubsidEmployee,
    exact: false,
  },
  {
    path: ADMIN_PATHS.adminTravelHistory,
    component: AdminTravelHistoryReport,
    exact: false,
  },
  {
    path: ADMIN_PATHS.adminUploadAssoSubsidEmployeeProfile,
    component: AdminUploadAssoSubsidEmployeeProfile,
    exact: false,
  },
  {
    path: ADMIN_PATHS.adminAssoSubsidTravelHistory,
    component: AdminAssoSubsidTravelHistoryReport,
    exact: false,
  },
];

export default adminRouteConfig;
