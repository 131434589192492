import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { DATE_FORMAT } from '../../../constants/constants';

import { IQuotaHistory } from '../../../interfaces';

import {
  formatDateAsString,
  getAdminPersonalDisplayName,
  getDisplayVal,
  getAdminCurrentContext,
} from '../../../helpers';

import { BaseTable } from '../../../components';

const QuotaHistory = () => {
  const theme = useTheme();
  const adminCurrentContext = getAdminCurrentContext();

  const fieldLabel = en.admin.quotaHistory;

  const currentContextState = adminCurrentContext.currentContextState || {};

  const quotaHistory = currentContextState?.employeeDetail?.quotaHistory || [];

  const tableSchema = [
    {
      key: 'name',
      isHiddenField: false,
    },
    {
      key: 'dependentType',
      isHiddenField: false,
    },
    {
      key: 'concessionName',
      isHiddenField: false,
    },
    {
      key: 'travelFromDate',
      isHiddenField: false,
    },
    {
      key: 'travelToDate',
      isHiddenField: false,
    },
    {
      key: 'profileRevision',
      isHiddenField: false,
    },
    {
      key: 'allottedTicket',
      isHiddenField: false,
    },
    {
      key: 'used',
      isHiddenField: false,
    },
    {
      key: 'allotmentYear',
      isHiddenField: false,
    },
  ];

  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          pb: 0.25,
          px: 0.5,
          width: 'auto',
          flex: item.key === 'name' || item.key === 'concessionName' ? 1.5 : 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
        },
        isHiddenField: item.isHiddenField,
      };
    });
  }, [quotaHistory]);

  const transformedTableContents = useMemo(() => {
    const tmpData = _.cloneDeep(quotaHistory) || [];

    return tmpData.map(
      (
        dataItem: IQuotaHistory & {
          name?: string;
        },
      ) => {
        dataItem.travelFromDate = getDisplayVal(
          formatDateAsString(dataItem.travelFromDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
        );
        dataItem.travelToDate = getDisplayVal(
          formatDateAsString(dataItem.travelToDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
        );
        dataItem.dependentType = dataItem.dependentType === null ? '' : getDisplayVal(dataItem.dependentType);
        dataItem.name = getAdminPersonalDisplayName({
          lastName: dataItem.lastName,
          firstName: dataItem.firstName,
          middleName: dataItem.middleName,
        });

        return {
          sxProps: {
            display: 'flex',
          },
          hover: false,
          columns: tableSchema.map(({ key, isHiddenField }) => {
            const currentValue = dataItem?.[key as keyof IQuotaHistory];
            const newValue = `${currentValue}`;

            return {
              columnkey: key,
              value: newValue,
              sxProps: {
                py: 1.25,
                px: 0.5,
                width: 'auto',
                flex: key === 'name' || key === 'concessionName' ? 1.5 : 1,
                overflow: 'hidden',
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
              },
              isHiddenField,
            };
          }),
        };
      },
    );
  }, [quotaHistory]);

  return (
    <Box
      className="quota_history_list"
      sx={{
        width: '100%',
      }}
    >
      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: -1 }}
        tableContents={transformedTableContents}
      />
    </Box>
  );
};

export default QuotaHistory;
