import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { Configuration } from 'configuration';
import en from '../../../../translations/en';
import {
  SALUTATION,
  ADMIN_PERSONALINFO_SELECT_TYPE,
  LABEL_CATEGORY,
  LABEL_VALUE_TYPE,
  RELATIONSHIP,
} from '../../../../constants/constants';

import { IDependentForAdminProfile } from '../../../../interfaces/admin';
import { AssoSubsidAdminEmployeeState, AssoSubsidAdminEmployeeAction } from '../../../../context';
import { IDisplayDetails, DetailsContainer, LabelValueContainer } from '../../PersonalInfo';
import { useAppSelector } from '../../../../app/hooks';
import { selectConfiguration } from '../../../../slice/configurationSlice';
interface IMassagedPersonalInfoData {
  profileDetails: IDisplayDetails[];
}

const AssoSubsidDependentsProfileDetail = ({ profileDetails }: IDependentForAdminProfile) => {
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { setEditDependentsDetail } = useContext(AssoSubsidAdminEmployeeAction) || {};
  const { editDependentsDetail } = useContext(AssoSubsidAdminEmployeeState) || {};
  const { isEditingDependentProfile } = useContext(AssoSubsidAdminEmployeeState) || {};
  const fieldLabel = en.admin.feature.dependent;
  const { profileDetails: profileDetailsLbl } = fieldLabel;
  const genderList = configurations.labels.filter(
    (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.gender,
  );
  let relationshipCode = '';
  const relationshipConfigOption: Configuration.CodeLabel[] = [];
  configurations?.relationshipConfig.forEach((item: Configuration.relationshipConfig) => {
    if (
      item?.beneficiaryType === profileDetails?.dependentType &&
      item?.relationship === profileDetails?.relationship
    ) {
      relationshipCode = item.relationshipCode;
    }

    const needCompareWithDependentType =
      profileDetails.dependentType === RELATIONSHIP.spouse ? item.relationship : item.beneficiaryType;
    if (needCompareWithDependentType === profileDetails.dependentType)
      relationshipConfigOption.push({
        label: item.relationship,
        code: item.relationshipCode,
        category: item.beneficiaryTypeCode,
      });
  });

  const { STRING, FORM_SELECT, FORM_FIELD_TEXT_INPUT, BOOLEAN } = LABEL_VALUE_TYPE;
  const handleUpdateValue = (selectType: string, value: string) => {
    setEditDependentsDetail({
      ...editDependentsDetail,
      [selectType]: value,
    });
  };

  const handleUpdateInput = (inputType: string, value: string) => {
    setEditDependentsDetail({
      ...editDependentsDetail,
      [inputType]: value,
    });
  };

  const capitalizeFirstLetter = (title: string) => {
    return title.charAt(0).toUpperCase() + title.toLowerCase().slice(1);
  };

  const MassagedPersonalInfoData: IMassagedPersonalInfoData = {
    profileDetails: [
      {
        label: profileDetailsLbl.dependentType,
        value: profileDetails.dependentType,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.title,
        value: profileDetails.title,
        type: isEditingDependentProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.title,
          value: editDependentsDetail?.title || capitalizeFirstLetter(profileDetails.title),
        },
        selectOptions: SALUTATION,
        setSelectedValue: handleUpdateValue,
      },
      {
        label: profileDetailsLbl.firstName,
        value: profileDetails.firstName,
        type: isEditingDependentProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: LABEL_CATEGORY.firstName,
          value: (editDependentsDetail?.firstName ?? profileDetails.firstName) || '',
          isMandatoryField: true,
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: profileDetailsLbl.middleName,
        value: profileDetails.middleName,
        type: isEditingDependentProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: LABEL_CATEGORY.middleName,
          value: (editDependentsDetail?.middleName ?? profileDetails.middleName) || '',
        },
        setOnChange: handleUpdateInput,
      },
      {
        label: profileDetailsLbl.surname,
        value: profileDetails.surname,
        type: isEditingDependentProfile ? FORM_FIELD_TEXT_INPUT : STRING,
        confidential: false,
        column: 4,
        defaultInputProps: {
          type: LABEL_CATEGORY.surname,
          value: (editDependentsDetail?.surName ?? profileDetails.surname) || '',
          isMandatoryField: true,
        },
        setOnChange: handleUpdateInput,
      },

      {
        label: profileDetailsLbl.relationship,
        value: profileDetails.relationship,
        type: isEditingDependentProfile ? FORM_SELECT : STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.relationship,
          value: editDependentsDetail?.relationship || relationshipCode || '',
        },
        selectOptions: relationshipConfigOption,
        setSelectedValue: handleUpdateValue,
      },
      {
        label: profileDetailsLbl.gender,
        value: profileDetails.gender,
        type: isEditingDependentProfile ? FORM_SELECT : STRING,
        confidential: true,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.gender,
          value: editDependentsDetail?.gender ? editDependentsDetail?.gender?.toUpperCase() : profileDetails.gender,
        },
        selectOptions: genderList,
        setSelectedValue: handleUpdateValue,
      },
      {
        label: profileDetailsLbl.dateOfBirth,
        value: profileDetails.dateOfBirth,
        type: STRING,
        confidential: true,
        column: 8,
      },
      {
        label: profileDetailsLbl.validFrom,
        value: profileDetails.validFrom,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.validTo,
        value: profileDetails.validTo,
        type: STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.dependentStatus,
        value: profileDetails.dependentStatus,
        type: BOOLEAN,
        options: en.labels.employmentStatusOptions,
        confidential: false,
        column: 4,
      },
    ],
  };

  return (
    <>
      <Box
        className="asso_subsid_profile_dependent_detail"
        sx={{
          margin: '24px auto 0',
          position: 'relative',
        }}
      >
        {Object.entries(MassagedPersonalInfoData).map(([key, value]: [string, IDisplayDetails[]]) => {
          return (
            <Box key={key}>
              <DetailsContainer
                title={
                  (
                    fieldLabel[key as keyof typeof fieldLabel] as {
                      heading: string;
                    }
                  )?.heading
                }
              >
                <Grid container spacing={2}>
                  {value.map((data: IDisplayDetails, index: number) => {
                    return <LabelValueContainer key={index} {...data} />;
                  })}
                </Grid>
              </DetailsContainer>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default AssoSubsidDependentsProfileDetail;
