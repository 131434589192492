import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';

import en from '../../../translations/en';

import { IAdminNote } from '../../../interfaces';

import { getAdminCurrentContext } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../slice/userSlice';

import { FieldContainer, GoBackButton } from '../../Common';

const NotesDetail = ({
  createdDate,
  lastModifiedDate,
  modifiedById,
  modifiedByErn,
  modifiedByName,
  type,
  subject,
  description,
  setIsPopupActive,
}: IAdminNote & {
  setIsPopupActive: (value: boolean) => void;
}) => {
  const theme = useTheme();
  const fieldLabel = en.admin.notes.fields;
  const { role } = useAppSelector(selectUser) || {};

  const adminCurrentContext = getAdminCurrentContext();
  const { setFocusNote } = adminCurrentContext.currentContextAction;

  const tableData = [
    { label: fieldLabel.createdDate, value: createdDate || '' },
    { label: fieldLabel.lastModifiedDate, value: lastModifiedDate || '' },
    { label: fieldLabel.modifiedByName, value: `${modifiedByName} (${modifiedByErn})` || '' },
    { label: fieldLabel.subject, value: subject || '' },
  ];

  const detailsData = [
    { label: fieldLabel.type, value: type || '' },
    { label: fieldLabel.subject, value: subject || '' },
    {
      label: fieldLabel.description,
      value: description.replace('/\\/g', '<br/>') || '',
    },
  ];

  return (
    <>
      <GoBackButton buttonText={en.admin.notes.backToNoteList} onClick={() => setFocusNote(null)} />
      <Box
        className="note_field"
        sx={{
          minHeight: '77px',
          px: 2,
          display: 'flex',
          margin: '19px auto 0',
          border: `2px solid ${theme.color.secondary.dark_grey.option_7}`,
          borderRadius: 1,
        }}
      >
        {tableData.map((item, index) => {
          return (
            <FieldContainer
              key={index}
              field={item}
              index={index}
              customStyles={{
                flex: index > 1 ? 2 : 1,
              }}
              customTextStyles={{
                textOverflow: index === 3 ? 'ellipsis' : 'none',
                overflow: 'hidden',
              }}
            />
          );
        })}
      </Box>
      <Box
        className="note_detail"
        sx={{
          margin: '24px auto 0',
          position: 'relative',
        }}
      >
        <Typography
          variant="title_2_bold"
          sx={{
            mb: 1,
            height: '36px',
            lineHeight: '36px',
            display: 'inline-flex',
            color: theme.color.secondary.dark_grey.option_1,
          }}
        >
          {en.admin.notes.notesDetails}
        </Typography>
        {modifiedById === role.id && (
          <Button
            sx={{
              top: 0,
              right: 0,
              width: '88px',
              height: '36px',
              position: 'absolute',
              color: theme.status.selected,
              border: `2px solid ${theme.status.selected}`,
              '&: hover': {
                background: 'white',
                border: `2px solid ${theme.status.selected}`,
              },
            }}
            variant="outlined"
            onClick={() => setIsPopupActive(true)}
          >
            <Typography variant="navigation_bold" sx={{ lineHeight: 'initial' }}>
              {en.common.edit}
            </Typography>
          </Button>
        )}
        {detailsData.map((item, index) => {
          return (
            <FieldContainer
              key={index}
              field={item}
              index={index}
              customStyles={{
                flex: index + 1 === tableData.length ? 2 : 1,
              }}
              customTextStyles={{
                whiteSpace: 'break-spaces',
                overflowWrap: 'break-word',
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

export default NotesDetail;
