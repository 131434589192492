import { Employee } from 'employee';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  getProfile,
  getPassport,
  getEndorser,
  endorseAssoSubsidEmployee,
  endorseAdminAssoSubsidEmployee,
} from '../services/user';

import {
  getProfile as getProfileAdmin,
  getPassport as getPassportAdmin,
  getEndorser as getEndorserAdmin,
} from '../services/admin/bookingUser';

import {
  getProfile as getProfileDelegation,
  getPassport as getPassportDelegation,
  getEndorser as getEndorserDelegation,
} from '../services/delegation/bookingUser';

export const getProfileThunk = createAsyncThunk('bookingUser/getProfile', async (userId: string) => {
  return await getProfile(userId);
});

export const getProfileAdminThunk = createAsyncThunk('bookingUser/getProfileAdmin', async (userId?: string) => {
  if (!userId) {
    return;
  }

  return await getProfileAdmin(userId);
});

export const getProfileDelegationThunk = createAsyncThunk(
  'bookingUser/getProfileDelegation',
  async (userId: string) => {
    return await getProfileDelegation(userId);
  },
);

export const getPassportThunk = createAsyncThunk(
  'bookingUser/getPassport',
  async (params: { userId: string; expirationDate?: string }) => {
    return await getPassport(params.userId, params.expirationDate);
  },
);

export const getPassportAdminThunk = createAsyncThunk(
  'bookingUser/getPassportAdmin',
  async (params: { userId?: string; expirationDate?: string }) => {
    if (!params.userId) {
      return;
    }

    return await getPassportAdmin(params.userId, params.expirationDate);
  },
);

export const getPassportDelegationThunk = createAsyncThunk(
  'bookingUser/getPassportDelegation',
  async (params: { userId: string; expirationDate?: string }) => {
    return await getPassportDelegation(params.userId, params.expirationDate);
  },
);

export const getEndorserThunk = createAsyncThunk('bookingUser/getEndorser', async (userId: string) => {
  return await getEndorser(userId);
});

export const getEndorserAdminThunk = createAsyncThunk('bookingUser/getEndorserAdmin', async (userId?: string) => {
  return await getEndorserAdmin(userId);
});

export const getEndorserDelegationThunk = createAsyncThunk(
  'bookingUser/getEndorseDelegation',
  async (userId: string) => {
    return await getEndorserDelegation(userId);
  },
);

export const endorseAssoSubsidEmployeeThunk = createAsyncThunk(
  'bookingUser/endorseAssoSubsidEmployee',
  async (params: { endorsingStaffEmployeeId: string; endorsingStaffGalaCXyId: string; userId: string }) => {
    return await endorseAssoSubsidEmployee(
      params.endorsingStaffEmployeeId,
      params.endorsingStaffGalaCXyId,
      params.userId,
    );
  },
);

export const endorseAdminAssoSubsidEmployeeThunk = createAsyncThunk(
  'bookingUser/endorseAdminAssoSubsidEmployee',
  async (params: { endorsingStaffEmployeeId: string; endorsingStaffGalaCXyId: string; userId: string | undefined }) => {
    return await endorseAdminAssoSubsidEmployee(
      params.endorsingStaffEmployeeId,
      params.endorsingStaffGalaCXyId,
      params.userId,
    );
  },
);
export interface IBookingUserSlice {
  profile: Employee.Profile | null;
  passports: Employee.PassportInfo[];
  endorser: string | null;
  supervisorEmployeeId: string | null;
  supervisorGalaCXyId: string | null;
  supervisorName: string | null;
}

const initialState: IBookingUserSlice = {
  profile: null,
  passports: [],
  endorser: '',
  supervisorEmployeeId: '',
  supervisorGalaCXyId: '',
  supervisorName: '',
};

export const bookingUserSlice = createSlice({
  name: 'bookingUser',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileThunk.pending, (state) => {
        state.profile = initialState.profile;
      })
      .addCase(getProfileAdminThunk.pending, (state) => {
        state.profile = initialState.profile;
      })
      .addCase(getProfileDelegationThunk.pending, (state) => {
        state.profile = initialState.profile;
      })
      .addCase(getPassportThunk.pending, (state) => {
        state.passports = initialState.passports;
      })
      .addCase(getPassportAdminThunk.pending, (state) => {
        state.passports = initialState.passports;
      })
      .addCase(getPassportDelegationThunk.pending, (state) => {
        state.passports = initialState.passports;
      })
      .addCase(getEndorserThunk.pending, (state) => {
        state.endorser = initialState.endorser;
      })
      .addCase(getEndorserAdminThunk.pending, (state) => {
        state.endorser = initialState.endorser;
      })
      .addCase(getEndorserDelegationThunk.pending, (state) => {
        state.endorser = initialState.endorser;
      })
      .addCase(endorseAssoSubsidEmployeeThunk.pending, (state) => {
        state.supervisorEmployeeId = initialState.supervisorEmployeeId;
        state.supervisorGalaCXyId = initialState.supervisorGalaCXyId;
        state.supervisorName = initialState.supervisorName;
      })
      .addCase(endorseAdminAssoSubsidEmployeeThunk.pending, (state) => {
        state.supervisorEmployeeId = initialState.supervisorEmployeeId;
        state.supervisorGalaCXyId = initialState.supervisorGalaCXyId;
        state.supervisorName = initialState.supervisorName;
      })
      .addCase(getProfileThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.profile = payload;
        }
      })
      .addCase(getProfileAdminThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.profile = payload;
        }
      })
      .addCase(getProfileDelegationThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.profile = payload;
        }
      })
      .addCase(getPassportThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.passports = payload;
        }
      })
      .addCase(getPassportAdminThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.passports = payload;
        }
      })
      .addCase(getPassportDelegationThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.passports = payload;
        }
      })
      .addCase(getEndorserThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.endorser = payload.supervisorGalaCXyId;
          state.supervisorEmployeeId = payload.supervisorEmployeeId;
          state.supervisorGalaCXyId = payload.supervisorGalaCXyId;
          state.supervisorName = payload.supervisorName;
        }
      })
      .addCase(getEndorserAdminThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.endorser = payload.supervisorGalaCXyId;
          state.supervisorEmployeeId = payload.supervisorEmployeeId;
          state.supervisorGalaCXyId = payload.supervisorGalaCXyId;
          state.supervisorName = payload.supervisorName;
        }
      })
      .addCase(getEndorserDelegationThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.endorser = payload.supervisorGalaCXyId;
        }
      })
      .addCase(endorseAssoSubsidEmployeeThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.supervisorEmployeeId = payload.employeeId || '';
          state.supervisorGalaCXyId = payload.galaCXyId || '';
          state.supervisorName = payload.name || '';
        }
      })
      .addCase(endorseAdminAssoSubsidEmployeeThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.supervisorEmployeeId = payload.employeeId || '';
          state.supervisorGalaCXyId = payload.galaCXyId || '';
          state.supervisorName = payload.name || '';
        }
      });
  },
});

const { reset } = bookingUserSlice.actions;

const selectBookingUser = (state: RootState) => state.bookingUser;

export { reset, selectBookingUser };

export default bookingUserSlice.reducer;
