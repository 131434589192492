import React, { useContext, useMemo, useState } from 'react';

import { ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS } from '../../../../constants/constants';
import en from '../../../../translations/en';
import { IAssoSubsidAdminAdminHousekeeping } from '../../../../interfaces';

import { AssoSubsidAdminEmployeeAction, AssoSubsidAdminEmployeeState } from '../../../../context';

import { AdminActionButton } from '../../../Common';
import { CancelDialog } from '../../../../components';

type Props = {
  dependentId: string;
  isDependentPersonalInfoActive?: boolean;
};

const DeactivateButton = ({ dependentId, isDependentPersonalInfoActive }: Props) => {
  const { deactivateButton, confirmDeactivateProfileDialog } =
    en.admin.feature.personalInformation.profileHousekeepingDetails;
  const { isEditingDependentProfile, dependentsHousekeepingList, editDependentsDetail } =
    useContext(AssoSubsidAdminEmployeeState) || {};
  const { setEditDependentsDetail } = useContext(AssoSubsidAdminEmployeeAction) || {};
  const { isDeactivateProfileConfirmButtonClicked } = editDependentsDetail || {};

  const [isShowCancelPopup, setIsShowCancelPopup] = useState<boolean>(false);

  const isFetchingDeactivateProfile = useMemo(() => {
    const housekeepingListStatus = [
      ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.PENDING,
      ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.COMPLETED,
      ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.IN_PROGRESS,
    ];
    return (
      dependentsHousekeepingList?.some((obj: IAssoSubsidAdminAdminHousekeeping) =>
        housekeepingListStatus.includes(obj.status),
      ) || isDependentPersonalInfoActive === false
    );
  }, [dependentsHousekeepingList]);

  return (
    <>
      <AdminActionButton
        textLabel={deactivateButton}
        onClick={() => {
          setIsShowCancelPopup(true);
        }}
        isEnabled={
          isEditingDependentProfile && !isDeactivateProfileConfirmButtonClicked && !isFetchingDeactivateProfile
        }
      />
      <CancelDialog
        open={isShowCancelPopup}
        showCancelIcon={false}
        handleConfirmAction={() => {
          setEditDependentsDetail({
            ...editDependentsDetail,
            isDeactivateProfileConfirmButtonClicked: true,
            dependentId,
          });
        }}
        handleCloseDialog={() => {
          setIsShowCancelPopup(false);
        }}
        dialogContent={{
          title: confirmDeactivateProfileDialog.title,
          message: confirmDeactivateProfileDialog.description,
          noTitle: en.common.no,
          yesTitle: confirmDeactivateProfileDialog.yes,
        }}
      />
    </>
  );
};

export default DeactivateButton;
