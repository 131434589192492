import { Box, SxProps, useTheme, Typography, Breadcrumbs, Theme } from '@mui/material';

import { RightArrowIcon } from '../../assets/images';
import { UI_STYLES } from '../../constants/constants';

import { ICommonProgressbarStepList } from '../../interfaces';

import { DefaultStepIcon } from '.';

const DesktopProgressbar = ({
  stepList,
  currentStep = 0,
  customStyles,
}: {
  stepList: ICommonProgressbarStepList[]; // object array
  currentStep: number; // number.
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Breadcrumbs
      sx={{
        display: 'flex',
        height: UI_STYLES.overlayHeaderHeightSearchCriteria,
        backgroundColor: 'white',
        ...customStyles,
      }}
      separator={
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <RightArrowIcon fill={theme.color.secondary.dark_grey.option_5} />
        </Box>
      }
      aria-label="breadcrumb"
    >
      {stepList.map((stepItem: ICommonProgressbarStepList, index: number) => {
        const isActiveStep = currentStep === stepItem.step;
        const isCompletedStep = currentStep > stepItem.step;

        const baseLabelSxProps = {
          color:
            isActiveStep || isCompletedStep
              ? theme.palette.cathayJadeLight.contrastText
              : theme.color.secondary.dark_grey.option_3,
          fontSize: '16px',
          fontWeight: isActiveStep ? 'medium' : 'regular',
          lineHeight: '24px',
        };

        return (
          <Box key={stepItem.step} sx={{ display: 'flex', alignItems: 'center' }}>
            <DefaultStepIcon
              active={isActiveStep}
              completed={isCompletedStep}
              step={index + 1}
              icon={null}
              stepLabelVariant={isActiveStep ? 'body_1_bold' : 'body_1_regular'}
            />
            <Typography
              variant="body_1_regular"
              sx={{
                ml: 1,
                ...baseLabelSxProps,
              }}
            >
              {stepItem.label}
            </Typography>
          </Box>
        );
      })}
    </Breadcrumbs>
  );
};

export default DesktopProgressbar;
