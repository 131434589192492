import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { IDependentForAdmin } from '../../../interfaces/admin';

import { getAdminCurrentContext, getAdminMatchedDependent } from '../../../helpers';

import { DependentsDetail, DependentsList } from './';

const AdminDependents = () => {
  const adminCurrentContext = getAdminCurrentContext();

  const [selectDependent, setSelectDependent] = useState<null | IDependentForAdmin>(null);
  const [validDependList, setValidDependList] = useState<null | IDependentForAdmin[]>([]);

  const { employeeDependent } = adminCurrentContext.currentContextState || {};

  const handleRowDataOnClick = (rowData: any) => {
    const matchedResult = getAdminMatchedDependent(rowData, employeeDependent);
    setSelectDependent(matchedResult || null);
  };

  useEffect(() => {
    const [nominatedDependList, notNominatedDependList] = _.partition(
      employeeDependent,
      (item: IDependentForAdmin) => item.isCurrentNominee,
    );
    setValidDependList([...nominatedDependList, ...notNominatedDependList]);
  }, [employeeDependent]);

  useEffect(() => {
    // after inactiveNomination api call, need to refresh dependent detail page
    if (selectDependent !== null && employeeDependent?.length > 0) {
      const matchedResult = getAdminMatchedDependent(selectDependent, employeeDependent);
      setSelectDependent(matchedResult || null);
    }
  }, [employeeDependent]);

  return (
    <>
      {selectDependent ? (
        <DependentsDetail {...selectDependent} setSelectDependent={setSelectDependent} />
      ) : (
        <DependentsList
          dataList={validDependList as IDependentForAdmin[]}
          handleRowDataOnClick={handleRowDataOnClick}
        />
      )}
    </>
  );
};

export default AdminDependents;
