import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST, ADMIN_CONCESSION } from '../constants/constants';
import { USER_PROFILE_PATHS, ADMIN_PATHS } from '../constants/paths';

import {
  ILtConcession,
  INominatedPassenger,
  IRestrictionBeneficiaryType,
  IAllotmentDetails,
  IAssoSubsidAdminProfileDetail,
  IAssoSubsidAdminBenefitDetail,
  IAdminConcession,
  INominationDetails,
} from '../interfaces';
import { DependentInfo as IDependentInfo } from 'booking';

import {
  getEssConcessionPageNominatedPaxList,
  getEssConcessionPageDefaultEmployeePaxData,
  getSingleActiveNominationRecord,
  getAdminPersonalDisplayName,
} from '../helpers';

import { useAppSelector } from '../app/hooks';
import { selectAuth } from '../slice/authSlice';
import { selectUser } from '../slice/userSlice';
import { selectConfiguration } from '../slice/configurationSlice';

import { getConcession, getAllotmentDetails } from '../services/user';
import { getAssoSubsidConcessions, getConcessions } from '../services/admin/employee';

type IEmployeeProfileDetailForConcession = Pick<
  IAssoSubsidAdminProfileDetail,
  'employeeId' | 'revision' | 'hiringCompany' | 'firstName' | 'middleName' | 'lastName'
> &
  Pick<IAssoSubsidAdminBenefitDetail, 'age'>;

export type INominatedPaxList = INominatedPassenger | IDependentInfo;

interface IConcessionInProfileStateType {
  employeeProfileDetailForConcession: IEmployeeProfileDetailForConcession;
  isTriggerRefreshConcessionYearDropdownData: boolean;
  currentContextLocation: string;
  concessionYearDropdownData: any;
  concessionWithAllotmentData: any;
  nominatedPaxListDropdownData: any;
}

interface IActionType {
  setEmployeeProfileDetailForConcession: any;
  setIsTriggerRefreshConcessionYearDropdownData: any;
  setCurrentContextLocation: any;
  setConcessionYearDropdownData: any;
  setConcessionWithAllotmentData: any;
  setNominatedPaxListDropdownData: any;
  cleanUpCurrentContextData: any;
}

const defaultOriginConcessionWithAllotmentData = {
  concession: [],
  allotment: [],
  restrictionBeneficiaryType: [],
  defaultPaxData: null,
};

const defaultEmployeeProfileDetailForConcession = {
  employeeId: '',
  revision: 0,
  hiringCompany: '',
  firstName: '',
  middleName: '',
  lastName: '',
  age: 0,
};

const ConcessionInProfileState = createContext<IConcessionInProfileStateType>({
  currentContextLocation: '',
  employeeProfileDetailForConcession: defaultEmployeeProfileDetailForConcession,
  isTriggerRefreshConcessionYearDropdownData: false,
  concessionYearDropdownData: null,
  concessionWithAllotmentData: {
    isFetching: false,
    originData: defaultOriginConcessionWithAllotmentData,
  },
  nominatedPaxListDropdownData: null,
});

const ConcessionInProfileAction = createContext<IActionType>({
  setEmployeeProfileDetailForConcession: null,
  setIsTriggerRefreshConcessionYearDropdownData: null,
  setCurrentContextLocation: null,
  setConcessionYearDropdownData: null,
  setConcessionWithAllotmentData: null,
  setNominatedPaxListDropdownData: null,
  cleanUpCurrentContextData: null,
});

export interface IConcessionYearDropdownData {
  dropdownOptions: string[];
  selectedOptionItem: string | null;
}

export interface INominatedPaxListDropdownData {
  dropdownOptions: INominatedPaxList[];
  selectedOptionItem: INominatedPaxList | null;
}

export interface IConcessionWithAllotmentData {
  isFetching: boolean;
  originData: {
    concession: ILtConcession[] | IAdminConcession[];
    allotment: IAllotmentDetails[];
    restrictionBeneficiaryType: IRestrictionBeneficiaryType[];
    defaultPaxData: any; // INominatedPaxListDropdownData['selectedOptionItem'];
  };
}

const ConcessionInProfileContextProvider = ({ children }: { children?: React.ReactNode }) => {
  /* 
    New codes here
  */
  const { ern: userId } = useAppSelector(selectAuth) || {};
  const {
    configurations: { concessionYears },
  } = useAppSelector(selectConfiguration) || {};
  const { profile } = useAppSelector(selectUser) || {};

  const [currentContextLocation, setCurrentContextLocation] =
    useState<IConcessionInProfileStateType['currentContextLocation']>('');

  const [employeeProfileDetailForConcession, setEmployeeProfileDetailForConcession] =
    useState<IEmployeeProfileDetailForConcession>(defaultEmployeeProfileDetailForConcession);

  const [isTriggerRefreshConcessionYearDropdownData, setIsTriggerRefreshConcessionYearDropdownData] =
    useState<boolean>(false);

  const [concessionYearDropdownData, setConcessionYearDropdownData] = useState<IConcessionYearDropdownData>({
    dropdownOptions: [],
    selectedOptionItem: null,
  });

  const [nominatedPaxListDropdownData, setNominatedPaxListDropdownData] = useState<INominatedPaxListDropdownData>({
    dropdownOptions: [],
    selectedOptionItem: null,
  });

  // concession with allotment data
  const [concessionWithAllotmentData, setConcessionWithAllotmentData] = useState<IConcessionWithAllotmentData>({
    isFetching: false,
    originData: defaultOriginConcessionWithAllotmentData,
  });

  const isAssoSubsidAdminPage = location?.pathname === ADMIN_PATHS.adminAssoSubsidEmployee;
  const isAdminConcessionPage =
    (location.pathname === ADMIN_PATHS.adminEmployee || isAssoSubsidAdminPage) &&
    currentContextLocation === ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.concession;
  const isESSConcessionDetailPage = location.pathname === USER_PROFILE_PATHS.concessionDetails;

  const isConcessionDetail = isESSConcessionDetailPage || isAdminConcessionPage;

  const transformedNominationPassengerList = (nominatedPaxList: IDependentInfo[]) => {
    /*
      filter out some dependent by following conditions:
      - isActive = false (personalInfo not active)
      - empty nominationDetails (not nominated)
      - nominationDetails record isActive = false (not nominated)
    */

    const filteredNominatedPaxList = nominatedPaxList
      .filter((item: IDependentInfo) => {
        return (
          item.isActive &&
          item.nominationDetails.length > 0 &&
          item.nominationDetails?.some((nominationDetailsItem: INominationDetails) => nominationDetailsItem.isActive)
        );
      })
      .map((item: IDependentInfo) => {
        const matchedNominationRecord = getSingleActiveNominationRecord(item.nominationDetails);

        return {
          ...item,
          label: getAdminPersonalDisplayName({
            firstName: item?.firstName,
            lastName: item?.lastName,
            middleName: item?.middleName,
          }),
          isAssignedOAL: matchedNominationRecord?.isAssignedOAL,
          nominationType: matchedNominationRecord?.nominationType,
        };
      });

    return filteredNominatedPaxList;
  };

  // CX admin page | Asso/subisd admin page concession API fetch handling
  const fetchConcessionInAdminPage = async () => {
    if (!concessionYearDropdownData.selectedOptionItem) return;

    const selectedYear = concessionYearDropdownData.selectedOptionItem;

    const requestParams = {
      userId: employeeProfileDetailForConcession.employeeId,
      profileRevision: employeeProfileDetailForConcession.revision,
      concessionYear: selectedYear,
    };

    const result = isAssoSubsidAdminPage
      ? await getAssoSubsidConcessions(requestParams)
      : await getConcessions(requestParams);

    const { firstName, lastName, middleName, age } = employeeProfileDetailForConcession;
    const employeeName = getAdminPersonalDisplayName({
      firstName,
      lastName,
      middleName,
    });

    const defaultPaxData = {
      label: employeeName,
      name: employeeName,
      relationship: ADMIN_CONCESSION.EMPLOYEE,
      dependentId: null,
      value: null,
      beneficiaryTypeCode: ADMIN_CONCESSION.EMP,
      age,
    };

    const tmpNominatedPaxList = (result?.nominationList as IDependentInfo[]) || [];
    const tmpConcessionList = (result?.concessionList as IAdminConcession[]) || [];

    const nominatedPassengerList = transformedNominationPassengerList(tmpNominatedPaxList);

    setNominatedPaxListDropdownData((prevState: any) => {
      return {
        ...prevState,
        /*
          Remark: including EMP as first option

          TODO:
          for the `any` type, it's a temporary solution because of EMP data is different with existing nominatedPassengerList.
          However, EMP data would not be injected more fields which is unused in the dropdown.
        */
        dropdownOptions: [defaultPaxData].concat(nominatedPassengerList as any),
        // re-set Employee as default selected when the year is changed & after fetching API
        selectedOptionItem: defaultPaxData,
      };
    });

    setConcessionWithAllotmentData({
      isFetching: false,
      originData: {
        concession: tmpConcessionList,
        allotment: [], // unused data list
        restrictionBeneficiaryType: [], // unused data list
        defaultPaxData,
      },
    });

    // end refresh trigger
    setIsTriggerRefreshConcessionYearDropdownData(false);
  };

  // ESS concession page concession API fetch handling
  const fetchConcessionInProfilePage = async () => {
    if (!concessionYearDropdownData.selectedOptionItem) return;

    const selectedYear = concessionYearDropdownData.selectedOptionItem;

    // fetch API when selected Year
    const [concessionDataResp, quotaDataResp] = await Promise.all([
      getConcession(userId, selectedYear),
      getAllotmentDetails(userId, selectedYear),
    ]);

    // API resp data exist & no error
    if (concessionDataResp && quotaDataResp) {
      const tmpLtData = _.cloneDeep(concessionDataResp?.leisureTravel);
      const {
        paxList: tmpNominatedPaxList,
        concession: tmpLtConcession,
        restrictionBeneficiaryType: tmpRestrictionBeneficiaryType,
      } = tmpLtData;

      // assign to nominated passenger list dropdown options & default EMP selected
      const nominatedPassengerList = getEssConcessionPageNominatedPaxList(tmpNominatedPaxList as INominatedPaxList[]);

      const defaultPaxData = getEssConcessionPageDefaultEmployeePaxData(profile, nominatedPassengerList);

      setNominatedPaxListDropdownData((prevState: INominatedPaxListDropdownData) => {
        return {
          ...prevState,
          dropdownOptions: nominatedPassengerList,
          // re-set Employee as default selected when the year is changed & after fetching API
          selectedOptionItem: defaultPaxData,
        };
      });

      setConcessionWithAllotmentData({
        isFetching: false,
        originData: {
          concession: tmpLtConcession as ILtConcession[],
          allotment: quotaDataResp as IAllotmentDetails[],
          restrictionBeneficiaryType: tmpRestrictionBeneficiaryType as IRestrictionBeneficiaryType[],
          defaultPaxData,
        },
      });
    } else {
      // API error

      // clear the previous concession data display & show `notFindConcession` message in `ConcessionDetailContainer`
      setConcessionWithAllotmentData({
        isFetching: false,
        originData: defaultOriginConcessionWithAllotmentData,
      });
    }
  };

  // fetch API to get nominated passenger list, concession data and quota data
  const getConcessionQuotaWithPaxListData = async () => {
    if (!concessionYearDropdownData.selectedOptionItem) return;

    // set loading state
    setConcessionWithAllotmentData({
      isFetching: true,
      originData: defaultOriginConcessionWithAllotmentData,
    });

    // pre-set default EMP as traveller
    setNominatedPaxListDropdownData((prevState: INominatedPaxListDropdownData) => {
      return {
        ...prevState,
        selectedOptionItem: concessionWithAllotmentData.originData.defaultPaxData,
      };
    });

    // CX admin page, Asso/subsid admin page handling
    if (isAdminConcessionPage) {
      await fetchConcessionInAdminPage();
    } else {
      await fetchConcessionInProfilePage();
    }
  };

  const cleanUpCurrentContextData = () => {
    // prevent execute below when previous year data is empty
    if (!concessionYearDropdownData.dropdownOptions) return;

    setIsTriggerRefreshConcessionYearDropdownData(false);
    setCurrentContextLocation('');

    setConcessionYearDropdownData({
      dropdownOptions: [],
      selectedOptionItem: null,
    });

    setConcessionWithAllotmentData({
      isFetching: false,
      originData: defaultOriginConcessionWithAllotmentData,
    });

    setNominatedPaxListDropdownData({
      dropdownOptions: [],
      selectedOptionItem: null,
    });
  };

  // Clean up all data when leave the page (ESS Concession, CX Admin, Asso/subsid Admin pages) in [ETP-5619]
  useEffect(() => {
    if (!isConcessionDetail) {
      cleanUpCurrentContextData();
    }
  }, [isConcessionDetail]);

  // initialize Concession Year dropdown options
  useEffect(() => {
    // stop following action when not access into ESS Concession detail page | CX Admin page > Concession tab | Asso/subsid Admin page > Concession tab
    if (!isConcessionDetail) return;

    if (concessionYears?.length > 0) {
      // initialize dropdown data when access into ESS Concession detail page | CX Admin page > Concession tab | Asso/subsid Admin page > Concession tab
      if (isESSConcessionDetailPage || (isAdminConcessionPage && isTriggerRefreshConcessionYearDropdownData)) {
        const [currentYearNum] = concessionYears;
        const newDropdownData = {
          dropdownOptions: concessionYears,
          selectedOptionItem: currentYearNum,
        };

        // set loading for first time to access the page
        setConcessionWithAllotmentData({
          isFetching: true,
          originData: defaultOriginConcessionWithAllotmentData,
        });

        setConcessionYearDropdownData(newDropdownData);
      }
    } else {
      // empty all context data when empty concession years data
      cleanUpCurrentContextData();
    }
  }, [isConcessionDetail, concessionYears, isTriggerRefreshConcessionYearDropdownData]);

  // trigger Concession & allotment quota data fetching
  useEffect(() => {
    // stop following action when not access into ESS Concession detail page | CX Admin page > Concession tab | Asso/subsid Admin page > Concession tab
    if (!isConcessionDetail) return;

    // fetch concession API
    getConcessionQuotaWithPaxListData();
  }, [isConcessionDetail, concessionYearDropdownData.selectedOptionItem]);

  return (
    <ConcessionInProfileState.Provider
      value={{
        currentContextLocation,
        employeeProfileDetailForConcession,
        concessionYearDropdownData,
        concessionWithAllotmentData,
        nominatedPaxListDropdownData,
        isTriggerRefreshConcessionYearDropdownData,
      }}
    >
      <ConcessionInProfileAction.Provider
        value={{
          setCurrentContextLocation,
          setEmployeeProfileDetailForConcession,
          setConcessionYearDropdownData,
          setConcessionWithAllotmentData,
          setNominatedPaxListDropdownData,
          cleanUpCurrentContextData,
          setIsTriggerRefreshConcessionYearDropdownData,
        }}
      >
        {children}
      </ConcessionInProfileAction.Provider>
    </ConcessionInProfileState.Provider>
  );
};

export { ConcessionInProfileContextProvider, ConcessionInProfileState, ConcessionInProfileAction };
