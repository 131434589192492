import { Box, useTheme, Typography, Checkbox, SxProps, Theme } from '@mui/material';
import { CheckBoxCheckIcon, CheckBoxIcon } from '../../../../assets/images';

import { selectApp } from '../../../../slice/appSlice';
import { useAppSelector } from '../../../../app/hooks';

import { IParagraphMasterContainerProps } from '../../../Common/Paragraph/ParagraphMasterContainer';

import { NominationPageTitle, ParagraphMasterContainer } from '../../..';

interface IPolicySectionList {
  title?: string;
  descriptionContent: IParagraphMasterContainerProps['content'];
  isShowDisclaimerCheckbox?: boolean;
  disclaimerCheckboxOnChange?: () => void;
  boxItemCustomStyle?: SxProps<Theme>;
  isAgreeDisclaimer?: boolean;
}

interface IDisclaimerContainerProps {
  containerCustomStyle?: SxProps<Theme>;
  title: string;
  description?: IParagraphMasterContainerProps['content'];
  policySectionList: IPolicySectionList[];
}

const DisclaimerContainer = ({
  containerCustomStyle,
  title,
  description,
  policySectionList,
}: IDisclaimerContainerProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const boxItemStyle = {
    p: 2,
    width: '100%',
    mx: 'auto',
    background: 'white',
    boxShadow: theme.boxShadow.important,
    borderRadius: '8px',
    mb: 2,
  };

  return (
    <Box className="disclaimer_container" sx={containerCustomStyle}>
      <Box
        sx={{
          mb: '40%',
          ...(isDesktop && {
            width: '488px',
            mx: 'auto',
          }),
        }}
      >
        {title && <NominationPageTitle title={title} sxProps={{ pb: 2 }} />}

        {description && (
          <ParagraphMasterContainer
            content={description}
            paragraphVariant="body_1_regular"
            paragraphTypoSxProps={{
              color: theme.color.secondary.dark_grey.option_1,
            }}
          />
        )}

        {policySectionList.length > 0 &&
          policySectionList.map((item: IPolicySectionList, index: number) => (
            <Box
              key={index}
              sx={{
                ...boxItemStyle,
                ...item.boxItemCustomStyle,
              }}
            >
              <Box
                sx={{
                  ...(item.isShowDisclaimerCheckbox && {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }),
                }}
              >
                {item?.title && (
                  <Typography sx={{ pb: 2, color: theme.color.secondary.dark_grey.option_1 }} variant="body_1_bold">
                    {item.title}
                  </Typography>
                )}

                {item.isShowDisclaimerCheckbox && (
                  <Checkbox
                    sx={{
                      ml: '-9px',
                      mt: '-6px',
                      '&.MuiCheckbox-root': {
                        color: theme.status.selected,
                      },
                    }}
                    checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                    icon={<CheckBoxIcon stroke={theme.status.selected} />}
                    color="info"
                    checked={item.isAgreeDisclaimer}
                    onChange={item.disclaimerCheckboxOnChange}
                  />
                )}

                <ParagraphMasterContainer
                  content={item.descriptionContent}
                  multiParagraphContainerSxProps={{ p: 0, my: 0 }}
                  paragraphTypoSxProps={{
                    color: theme.color.secondary.dark_grey.option_1,
                  }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default DisclaimerContainer;
