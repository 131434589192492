import React, { useContext } from 'react';
import { Drawer, Box, IconButton, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SAVE_DATA_TO, UI_STYLES } from '../../../constants/constants';
import { ROOT_PATHS } from '../../../constants/paths';
import en from '../../../translations/en';
import { LeftArrowIcon, OverlayBackground } from '../../../assets/images';
import { OVERLAY_VARIANT } from '../Common/BookingFlowOverlay';

import { IBookingFlowOverlayData } from '../../../interfaces';
import { selectBooking, setStartDate } from '../../../slice/bookingSlice';
import { selectApp } from '../../../slice/appSlice';
import { BookingSummaryAction, BookingSummaryState } from '../../../context';
import { useAppSelector } from '../../../app/hooks';

import { Div100vhView, DrawerOverlay, Header, NavBar } from '../../../components';
import { RebookEditSearchDate } from '.';

const RebookFlowOverlay = ({
  handleClose,
  showOverlay,
  isShowBackArrow = false,
  title,
  backTitle,
  component: Component,
  data,
  variant = OVERLAY_VARIANT.BOOKING_FLOW,
}: {
  handleClose: () => void;
  showOverlay: boolean;
  isShowBackArrow?: boolean;
  title?: string;
  backTitle: string;
  component: React.ComponentType<any>;
  data?: IBookingFlowOverlayData;
  variant?: string;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const isEditSearch: boolean = data?.saveTo === SAVE_DATA_TO.context || false;
  const { rebookFlightDate, isShowRebookSearchOverlay } = useContext(BookingSummaryState) || {};
  const { setIsShowRebookSearchOverlay, setRebookFlightDate } = useContext(BookingSummaryAction) || {};
  const { startDate } = useAppSelector(selectBooking) || {};

  const isHomePage = useLocation().pathname === ROOT_PATHS.landing;
  const isBookingCompleted = variant === OVERLAY_VARIANT.BOOKING_COMPLETED;

  const backgroundHeight = isBookingCompleted
    ? UI_STYLES.overlayHeaderHeightBookingCompleted
    : `calc(${UI_STYLES.searchBarResultPageHeight} + 8px)`;
  const backgroundSize = isBookingCompleted ? '200%' : '100%';
  const backgroundPosition = isBookingCompleted ? '42% 20%' : '50% 20%';

  return (
    <Drawer
      anchor="bottom"
      open={showOverlay}
      onClose={handleClose}
      sx={{
        '&.MuiDrawer-root': {
          '& .MuiDrawer-paper': {
            width: '100%',
            background: isEditSearch ? 'transparent' : 'auto',
          },
        },
        zIndex: 1300, // drawer z-index is 1200, need to greater than 1200
      }}
    >
      <Div100vhView
        sx={{
          overflow: 'hidden',
          backgroundColor: 'transparent',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {isDesktop && (
          <NavBar
            isShowNavBar={false}
            handleCloseOverlay={() => {
              isHomePage && window.location.reload();
            }}
          />
        )}
        {isDesktop && (
          <Box
            sx={{
              px: 22,
              width: '100%',
              height: UI_STYLES.overlayHeaderHeightSearchCriteria,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: theme.boxShadow.important,
            }}
          >
            <Header
              leftChild={
                <Typography
                  color={theme.color.utility.link.option_3}
                  fontWeight="bold"
                  sx={{
                    pl: 1,
                    cursor: 'pointer',
                  }}
                  onClick={handleClose}
                >
                  {backTitle}
                </Typography>
              }
              handleOnBack={handleClose}
              customStyles={{
                width: '100%',
              }}
            />
          </Box>
        )}

        <Box
          sx={
            isDesktop
              ? {
                  width: '100%',
                }
              : {
                  background: `${'linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),'}url(${OverlayBackground})`,
                  width: '100%',
                  height: backgroundHeight,
                  backgroundSize,
                  display: 'flex',
                  position: 'relative',
                  backgroundPosition,
                  px: 2,
                  pt: 2,
                }
          }
        >
          {isShowBackArrow && (
            <Box sx={{ mr: 2 }}>
              <IconButton
                onClick={data?.handleBackClick || handleClose}
                sx={{ p: 0, mt: data?.customStyle?.backIconMt }}
              >
                <LeftArrowIcon fill={theme.palette.primary.light} />
              </IconButton>
            </Box>
          )}
          <Box sx={{ width: '100%', mt: data?.customStyle?.titleMt }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {title && (
                <Typography
                  variant={data?.customStyle?.titleVariant || 'title_2_bold'}
                  sx={{ color: theme.palette.primary.light }}
                >
                  {title}
                </Typography>
              )}
            </Box>
          </Box>

          {data?.isShowEditButton && (
            <Box>
              <IconButton
                sx={{
                  color: theme.palette.primary.light,
                  textAlign: 'right',
                  width: '45px',
                  pr: 0,
                }}
                onClick={() => {
                  data?.handleEditClick?.();
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  {en.common.edit}
                </Typography>
              </IconButton>
            </Box>
          )}

          <Box
            sx={{
              background: 'transparent',
              position: 'fixed',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              left: '0',
              bottom: '0',
            }}
          >
            <Component
              handleBackClick={handleClose}
              handleFooterClick={() => {
                data?.handleFooterClick?.();
              }}
              {...data}
            />
          </Box>
        </Box>

        {!isDesktop && (
          <DrawerOverlay
            showOverlay={isShowRebookSearchOverlay}
            handleClose={() => {
              setRebookFlightDate(startDate);
              setIsShowRebookSearchOverlay(false);
            }}
            title={en.booking.edit.title}
            component={RebookEditSearchDate}
            data={{
              bookingSummaryData: data?.bookingSummaryData,
              saveTo: SAVE_DATA_TO.context,
              handleSearchClick: () => {
                dispatch(setStartDate(rebookFlightDate));
                setIsShowRebookSearchOverlay(false);
              },
              customStyles: {
                mx: 2,
              },
            }}
          />
        )}
      </Div100vhView>
    </Drawer>
  );
};

RebookFlowOverlay.defaultProps = {
  title: '',
};

export default RebookFlowOverlay;
