import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';

import { Configuration } from 'configuration';

import {
  ADMIN_HISTORY_REPORT_ALL_TYPE,
  ADMIN_PERSONALINFO_SELECT_TYPE,
  DATE_FORMAT,
  LABEL_CATEGORY,
  UI_STYLES,
} from '../../constants/constants';
import en from '../../translations/en';

import {
  checkValidDateRange,
  compareDate,
  formatDateAsString,
  getLocalDate,
  getAssoSubsidEligibleCompanyList,
} from '../../helpers';

import { useAppSelector } from '../../app/hooks';
import { selectConfiguration } from '../../slice/configurationSlice';
import { selectUser } from '../../slice/userSlice';
import { getAssoSubsidTravelHistoryReport } from '../../services/admin/report';

import {
  ScrollableView,
  AutoCompleteBox,
  BoldButtonTypography,
  InputFieldDatePicker,
  ReduxFormSelectInput,
} from '../../components';

const AdminAssoSubsidTravelHistoryReport = () => {
  const screenHeight = use100vh();
  const theme = useTheme();
  const BOX_WIDTH = '240px';
  const BOX_TEXT_HEIGHT = '48px';

  const { adminRoles, role } = useAppSelector(selectUser) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const adminRole = adminRoles.find((item) => item.id === role.id);

  const companyOptions = getAssoSubsidEligibleCompanyList(adminRole?.company || [], configurations.companies);

  const scrollViewRef = useRef<HTMLDivElement>(null);

  // 5 input fields
  const [companyCode, setCompanyCode] = useState<string>('');
  const [ernValue, setErnValue] = useState<string>('');
  const [assoSubsidHistoryReportFromDate, setAssoSubsidHistoryReportFromDate] = useState<string>('');
  const [assoSubsidhistoryReportToDate, setAssoSubsidHistoryReportToDate] = useState<string>('');
  const [travelType, setTravelType] = useState<string>('');
  const [errorToDateMessage, setErrorToDateMessage] = useState<string>('');
  const [isShowSearchTips, setIsShowSearchTips] = useState<boolean>(false);

  const { title, companyLbl } = en.admin.feature.assoSubsidTravelHistoryReport;
  const {
    submit,
    travelType: travelTypeLbl,
    inputValidDateRange,
    travelFrom,
    travelTo,
  } = en.admin.feature.travelHistoryReport;
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;
  const { maintainEmployeeDetails } = en.admin.feature;

  const allTravelTypeOptions: Configuration.CodeLabel[] = ADMIN_HISTORY_REPORT_ALL_TYPE;

  const BOOKING_TYPE_OPTIONS = configurations.labels.filter(
    (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.bookingType,
  );

  const TRAVEL_TYPE_OPTIONS = [...allTravelTypeOptions, ...BOOKING_TYPE_OPTIONS];

  const isSubmitButtonDisabled = useMemo(() => {
    // all 5 filed need to be filled
    return !(
      companyCode &&
      assoSubsidHistoryReportFromDate &&
      assoSubsidhistoryReportToDate &&
      travelType &&
      ernValue.length > 0
    );
  }, [companyCode, assoSubsidHistoryReportFromDate, assoSubsidhistoryReportToDate, travelType, ernValue]);

  const searchTips = useMemo(() => {
    return (
      isShowSearchTips && (
        <Typography
          variant="body_1_medium"
          sx={{ width: '100%', mt: 5 }}
          color={theme.color.secondary.dark_grey.option_1}
        >
          {`${en.booking.travelType.noErnResult} ${en.booking.travelType.tryAgain}`}
        </Typography>
      )
    );
  }, [isShowSearchTips]);

  useEffect(() => {
    if (companyOptions.length === 1) setCompanyCode(companyOptions[0].code);
  }, []);

  const getAssoSubsidTravelHistoryReportAction = async () => {
    let errorDateMessage = '';
    if (!checkValidDateRange(assoSubsidHistoryReportFromDate, assoSubsidhistoryReportToDate)) {
      errorDateMessage = editProfilePopup.inputValidDateRange;
    } else if (compareDate(assoSubsidhistoryReportToDate, assoSubsidHistoryReportFromDate) > 365) {
      // error message: 'Travel date range should be within 1 year.'
      errorDateMessage = inputValidDateRange;
    }
    setErrorToDateMessage(errorDateMessage);

    if (!errorDateMessage) {
      const today = getLocalDate();
      const downloadedFileName = `AssoSubsid_Travel_History_Report_${ernValue}_${formatDateAsString(
        today,
        DATE_FORMAT.yyyymmdd_hhmmss,
      )}.csv`;

      const report = await getAssoSubsidTravelHistoryReport({
        hiringCompany: companyCode,
        employeeId: ernValue,
        from: assoSubsidHistoryReportFromDate,
        to: assoSubsidhistoryReportToDate,
        type: travelType,
      });

      if (report) {
        // The report was empty array if no profile found or invalid admin access, otherwise it is csv string
        if (_.isEmpty(report)) {
          setIsShowSearchTips(true);
        } else {
          isShowSearchTips && setIsShowSearchTips(false);
          const url = window.URL.createObjectURL(new Blob([report]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', downloadedFileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    }
  };

  return (
    <Box
      className="assoSubsid_travel_history_report"
      component={ScrollableView}
      sx={{
        pt: 2,
        pb: 10,
        background: theme.palette.primary.light,
        height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${UI_STYLES.indicatorBarHeight} )`,
      }}
      ref={scrollViewRef}
    >
      <Box sx={{ width: UI_STYLES.historyReportWidth, mx: 'auto', mt: 2 }}>
        <Typography variant="large_title_1_bold" color={theme.color.secondary.dark_grey.option_1}>
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mt: 5,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mb: 2 }}>
            <Box sx={{ width: '480px', mr: 2 }}>
              <ReduxFormSelectInput
                input={{
                  value: companyCode,
                  onChange: (input: Configuration.CodeLabel) => {
                    setCompanyCode(input.code);
                  },
                }}
                title={companyLbl}
                options={companyOptions}
                autocomplete={{
                  customOpen: true,
                }}
                customInputStyle={{
                  mt: 0,
                  mb: 0,
                }}
              />
            </Box>
            <Box sx={{ width: BOX_WIDTH }}>
              <AutoCompleteBox
                label={maintainEmployeeDetails.ern}
                options={[]}
                value={ernValue}
                inputValue={ernValue}
                onChange={(newValue: { code: string } | null): void => {
                  const upperValue = newValue?.code ? newValue.code.toUpperCase() : '';
                  setErnValue(upperValue);
                }}
                onInputChange={(newInputValue) => {
                  setErnValue(newInputValue.toUpperCase());
                }}
                dropDownListPop={() => null}
                renderOption={undefined}
                customStyles={{
                  pl: 0,
                  '.MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: theme.color.secondary.grey.option_3,
                    },
                  },
                }}
                textFieldStyle={{
                  height: BOX_TEXT_HEIGHT,
                  borderRadius: 0.5,
                  background: 'white',
                  border: `1px solid ${theme.color.secondary.slate.option_3}`,
                }}
              />
            </Box>
          </Box>

          <Box sx={{ width: BOX_WIDTH, height: BOX_TEXT_HEIGHT, mr: 2 }}>
            <InputFieldDatePicker
              selectedType={ADMIN_PERSONALINFO_SELECT_TYPE.assoSubsidTravelHistoryReportFrom}
              dateFieldProps={{
                defaultDate: '',
                errorMessage: '',
                disabled: false,
                labelText: travelFrom,
              }}
              customStyles={{
                height: BOX_TEXT_HEIGHT,
                mt: -5,
                pl: 4,
              }}
              setSelectedValue={(_, value: string) => setAssoSubsidHistoryReportFromDate(value)}
            />
          </Box>

          <Box sx={{ width: BOX_WIDTH, height: BOX_TEXT_HEIGHT, mr: 2 }}>
            <InputFieldDatePicker
              selectedType={ADMIN_PERSONALINFO_SELECT_TYPE.assoSubsidTravelHistoryReportTo}
              dateFieldProps={{
                defaultDate: '',
                errorMessage: errorToDateMessage,
                disabled: false,
                labelText: travelTo,
                errorMessagePaddingTop: '-10px',
              }}
              customStyles={{
                height: BOX_TEXT_HEIGHT,
                mt: -5,
                pl: 4,
              }}
              setSelectedValue={(_, value: string) => setAssoSubsidHistoryReportToDate(value)}
            />
          </Box>

          <ReduxFormSelectInput
            input={{
              value: travelType,
              onChange: (input: Configuration.CodeLabel) => {
                setTravelType(input.code);
              },
            }}
            title={travelTypeLbl}
            options={TRAVEL_TYPE_OPTIONS}
            autocomplete={{
              customOpen: true,
            }}
            customInputStyle={{
              width: BOX_WIDTH,
              height: BOX_TEXT_HEIGHT,
              mt: 0,
              mb: 0,
              mr: 2,
            }}
          />

          <IconButton sx={{ p: 0 }} onClick={getAssoSubsidTravelHistoryReportAction} disabled={isSubmitButtonDisabled}>
            <BoldButtonTypography
              textLabel={submit}
              customStyles={{
                mt: -0.5,
                height: BOX_TEXT_HEIGHT,
                background: isSubmitButtonDisabled
                  ? theme.color.utility.unselectable.option_7
                  : theme.color.utility.link.option_3,
                color: isSubmitButtonDisabled ? theme.palette.text.disabled : theme.palette.bgColor.main,
              }}
            />
          </IconButton>
        </Box>
        {searchTips}
      </Box>
    </Box>
  );
};

export default AdminAssoSubsidTravelHistoryReport;
