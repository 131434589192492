import { AUTH_API, HTTP_REQUEST_TYPE, USER_API, NOMINATION_API, ADMIN_API } from '../constants/api';
import en from '../translations/en';

import { sendHttpRequest } from './api';

import { LOGIN_MODE } from '../constants/constants';

import { setConcessionYearRequestParam } from '../helpers/common';

const getProfile = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.personalInfo,
    apiTitle: en.errorAlert.getProfile,
    payload: { userId },
  });
  return resp;
};

const editContactDetail = async (
  userId: string,
  contactDetails: {
    personalEmail: string | null;
    mobilePhone: string | null;
  },
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: USER_API.editContactDetail,
    apiTitle: en.errorAlert.editContactDetail,
    payload: {
      userId,
      contactDetails,
    },
  });
  return resp;
};

const getPassport = async (userId: string, expirationDate?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.passportInfo,
    apiTitle: en.errorAlert.getPassport,
    payload: { userId, expirationDate },
  });
  return resp;
};

const getEndorser = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.endorsingStaff,
    apiTitle: en.errorAlert.getEndorser,
    payload: { userId },
  });
  return resp;
};

const getConcession = async (userId: string, concessionYear?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.concession,
    apiTitle: en.errorAlert.getConcession,
    payload: { userId, ...setConcessionYearRequestParam(concessionYear) },
  });
  return resp;
};

const validateEndorser = async (endorsingStaffGalaCXyId: string, userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: USER_API.endorsingStaff,
    apiTitle: en.errorAlert.validateEndorser,
    payload: { endorsingStaffGalaCXyId, userId },
  });
  return resp;
};

const endorseAssoSubsidEmployee = async (
  endorsingStaffEmployeeId: string,
  endorsingStaffGalaCXyId: string,
  userId: string,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: USER_API.endorsingStaff,
    apiTitle: en.errorAlert.validateEndorser,
    payload: { endorsingStaffEmployeeId, endorsingStaffGalaCXyId, userId },
  });
  return resp;
};

const endorseAdminAssoSubsidEmployee = async (
  endorsingStaffEmployeeId: string,
  endorsingStaffGalaCXyId: string,
  userId: string | undefined,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.endorsingStaff,
    apiTitle: en.errorAlert.validateEndorser,
    payload: { endorsingStaffEmployeeId, endorsingStaffGalaCXyId, userId },
  });
  return resp;
};

const changeRole = async (id: string, type: string, needLoading?: boolean) => {
  const loginMode = sessionStorage.getItem(LOGIN_MODE.key);
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: AUTH_API.changeRole,
    apiTitle: en.errorAlert.changeRole,
    payload: { id, type, ...(loginMode ? { loginMode } : {}) },
    needLoading,
  });
  return resp;
};

const getRole = async () => {
  const loginMode = sessionStorage.getItem(LOGIN_MODE.key);
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: AUTH_API.getRole,
    apiTitle: en.errorAlert.getRole,
    payload: loginMode ? { loginMode } : {},
  });
  return resp;
};

const getPaymentHistory = async (payload: any) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.paymentHistory,
    apiTitle: en.errorAlert.paymentHistory,
    payload,
  });

  return resp;
};

const getDependent = async (userId: string, expirationDate?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.dependent,
    apiTitle: en.errorAlert.getDependent,
    payload: { userId, expirationDate },
  });
  return resp;
};

const getAllotmentDetails = async (userId: string, concessionYear?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.allotment,
    apiTitle: en.errorAlert.getAllotmentDetails,
    payload: { userId, ...setConcessionYearRequestParam(concessionYear) },
  });
  return resp;
};

const addCompanionRegistration = async ({
  userId,
  companionInfo,
  companionPassport,
  nominationYear,
}: {
  userId: string;
  companionInfo: {
    yearKnown?: number;
    monthKnown?: number;
    relationshipCode?: string;
    dependentId?: string;
    title?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: string;
    gender?: string;
  };
  companionPassport?: {
    passportNumber: string;
    passportFirstName: string;
    passportLastName: string;
    passportExpirationDate: string;
    passportNationality: string;
    passportCountry: string;
  };
  nominationYear: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: NOMINATION_API.companionRegistration,
    apiTitle: en.errorAlert.addCompanionRegistration,
    payload: { userId, companionInfo, companionPassport, nominationYear },
  });
  return resp;
};

const editCompanionPassport = async (
  userId: string,
  requestParams: {
    dependentId: string;
    passportCountry: string;
    passportNumber: string;
    passportExpirationDate: string;
    passportNationality: string;
  },
) => {
  const { dependentId, passportCountry, passportNumber, passportExpirationDate, passportNationality } =
    requestParams || {};
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: NOMINATION_API.editCompanionPassport,
    apiTitle: en.errorAlert.editCompanionPassport,
    payload: {
      userId,
      dependentId,
      passportCountry,
      passportNumber,
      passportExpirationDate,
      passportNationality,
    },
  });
  return resp;
};

const getNominationViewModeList = async (userId: string, nominationYear: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: NOMINATION_API.nominationViewModeList,
    apiTitle: en.errorAlert.nominationViewModeList,
    payload: { userId, nominationYear },
    needLoading: false,
  });
  return resp;
};

const getNominationSelectionList = async (userId: string, nominationType: string, nominationYear: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: NOMINATION_API.nominationSelectionList,
    apiTitle: en.errorAlert.nominationSelectionList,
    payload: { userId, nominationType, nominationYear },
    needLoading: true,
  });
  return resp;
};

const saveNomination = async (
  userId: string,
  requestParams: {
    nominationType: string;
    dependentList: {
      dependentId: string;
    }[];
  },
  nominationYear: string, // in backend is refer to the `nominationYearFrom`
) => {
  const { nominationType, dependentList } = requestParams || {};
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: NOMINATION_API.saveNomination,
    apiTitle: en.errorAlert.saveNomination,
    payload: { userId, nominationType, dependentList, nominationYear },
    needLoading: true,
  });
  return resp;
};

const updateSetupCountryOfResidential = async (userId: string, countryOfResidential: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: USER_API.countryOfResidential,
    apiTitle: en.errorAlert.updateSetupCountryOfResidential,
    payload: { userId, countryOfResidential },
  });
  return resp;
};

const validateEligibleUpdateCountryOfResidential = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.validateEligibleUpdateCountryOfResidential,
    apiTitle: en.errorAlert.updateSetupCountryOfResidential,
    payload: { userId },
  });
  return resp;
};

export {
  getProfile,
  getPassport,
  editContactDetail,
  getEndorser,
  validateEndorser,
  endorseAssoSubsidEmployee,
  endorseAdminAssoSubsidEmployee,
  getConcession,
  changeRole,
  getRole,
  getPaymentHistory,
  getDependent,
  getAllotmentDetails,
  addCompanionRegistration,
  editCompanionPassport,
  getNominationViewModeList,
  getNominationSelectionList,
  saveNomination,
  updateSetupCountryOfResidential,
  validateEligibleUpdateCountryOfResidential,
};
