import { useEffect, useMemo, useState } from 'react';

import {
  DATE_FORMAT,
  PAYMENT_CONCEPT,
  PAYMENT_MEDIUM,
  PAYMENT_SETUP_STATUS,
  USER_PROFILE_TYPE,
  USER_ROLE,
} from '../constants/constants';

import en from '../translations/en';

import { useAppSelector } from '../app/hooks';

import { formatDateAsString, isHideLtFeatures, isAssoSubsid } from '../helpers';
import { selectUser } from '../slice/userSlice';

export interface IPaymentMethodIndicator {
  isShowWarning: boolean;
  paymentConcept: PAYMENT_CONCEPT | string;
  paymentMedium: PAYMENT_MEDIUM | string;
  paymentSetUpStatus: PAYMENT_SETUP_STATUS | string;
  paymentSetUpWarningMessage: string;
  paymentValidated: boolean | null;
  isError: boolean;
  isSuspensionStatus: boolean; // ETP-2431
  isDirectDebitSetupSuspended: boolean;
  replaceTarget: string;
  isRetireeWidowRole?: boolean;
}

/*
  Mainly to check some payment method has setup or not
*/
export const usePaymentMethodIndicator = () => {
  const { profile, role, concession } = useAppSelector(selectUser) || {};
  const { leisureTravel } = concession || {};
  // const dispatch = useAppDispatch();

  const [paymentMethodIndicator, setPaymentMethodIndicator] = useState<IPaymentMethodIndicator>({
    isShowWarning: false,
    paymentConcept: '',
    paymentMedium: '',
    paymentSetUpStatus: '', //PAYMENT_SETUP_STATUS.NOT_YET_SET_UP,
    paymentSetUpWarningMessage: '',
    isError: false, //TODO handle error exception case
    isSuspensionStatus: false,
    isDirectDebitSetupSuspended: false,
    paymentValidated: null,
    replaceTarget: '',
  });

  const isSelfRole = role.type === USER_ROLE.self;
  const isRetireeWidowRole =
    isSelfRole && !!profile?.type && !![USER_PROFILE_TYPE.retiree, USER_PROFILE_TYPE.widow].includes(profile.type);
  const isHideLtFeaturesControl = useMemo(() => {
    return isHideLtFeatures({
      isDelegation: role.type === USER_ROLE.delegation,
      isEmptyLtConcession: leisureTravel?.length === 0,
    });
  }, [role, leisureTravel]);

  // ETP-2431
  // validate payment concept is flown
  const isFlownPaymentConcept = useMemo(() => {
    return profile?.paymentConcept === PAYMENT_CONCEPT.FLOWN;
  }, [profile?.paymentConcept]);

  // validate is flown + paypal
  const isPaypalWithFlown = useMemo(() => {
    return isFlownPaymentConcept && profile?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT;
  }, [profile?.paymentConcept, profile?.paymentMedium]);

  // validate is flown + direct debit
  const isDirectDebitWithFlown = useMemo(() => {
    return isFlownPaymentConcept && profile?.paymentMedium === PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT;
  }, [profile?.paymentConcept, profile?.paymentMedium]);

  // validate is suspension
  const isSuspensionStatus = useMemo(() => {
    return !!profile?.isShowFlownSuspensionMessage;
  }, [profile?.isShowFlownSuspensionMessage]);

  // [ETP-4372] indicate retiree/widow not yet setup country of residence message
  const isNotYetSetupCountryOfResidence =
    isRetireeWidowRole &&
    profile &&
    (!profile.retireeWidow?.countryOfResidential ||
      (profile.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT && !profile.isPayPalBillingAgreementSetup));

  const isNotYetSetupPayPalBillingAgreement =
    isRetireeWidowRole &&
    profile &&
    !profile.isPayPalBillingAgreementSetup &&
    profile.retireeWidow &&
    (profile.retireeWidow.backendCalculatePaymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT ||
      profile.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT);

  const showingPaymentSetupWarning = () => {
    if (isNotYetSetupCountryOfResidence) {
      return en.payment.countryOfResidential.commonWarning;
    } else if (isNotYetSetupPayPalBillingAgreement) {
      return en.payment.countryOfResidential.paypalCommonWarning;
    }
    return '';
  };

  // validate the setup indicator logic
  useEffect(() => {
    // [ETP-4372] indicatory Retiree/Widow setup country of residence
    if (isRetireeWidowRole) {
      setPaymentMethodIndicator({
        isRetireeWidowRole,
        isShowWarning: !!showingPaymentSetupWarning(),
        paymentConcept: profile?.paymentConcept || '',
        paymentMedium: profile?.paymentMedium || '',
        // please note that retiree/ widow under Admin modifiy the payment medium, will do have the case is direct debit
        paymentSetUpStatus:
          (profile?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT && profile.isPayPalBillingAgreementSetup) ||
          profile?.paymentMedium === PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT
            ? PAYMENT_SETUP_STATUS.COMPLETED
            : '',
        paymentSetUpWarningMessage: showingPaymentSetupWarning(),
        isError: false,
        isSuspensionStatus,
        isDirectDebitSetupSuspended: false,
        paymentValidated: profile?.paymentValidated || null,
        replaceTarget: en.payment.replaceTarget,
      });
      return;
    }

    if (profile?.paymentConcept && profile?.paymentMedium) {
      /*
        Indicate payment method cases, only allow:
        - payment concept is `FWN` + payment medium is `DDP`
        - payment concept is `FWN` + payment medium is `PPP`
      */
      const isInPaymentMethodScope = isPaypalWithFlown || isDirectDebitWithFlown;

      // Check the employee/ retiree/ widow have completed the PayPal Billing Agreement setup or not
      const isPaypalNotSetup = isPaypalWithFlown && profile?.isPayPalBillingAgreementSetup !== true; // prevent isPayPalBillingAgreementSetup is undefined
      const isPaypalUnableRetrieve = profile?.paymentValidated === null;

      /**
       * directDebitStatus is the reliable source to identify directDebit being setup or not
       * we will fade out directDebitIndicator since it is relying on iFly, iFly is going to terminate soon
       *
       * [updated on 31 Jan, 2024] deprecated the field `directDebitIndicator` in home|flight search result| profile pages
       */
      const isDirectDebitSetupSuspended = isDirectDebitWithFlown && profile?.directDebitStatus === 'N';
      const isDirectDebitNotSetup = isDirectDebitWithFlown && !profile?.directDebitStatus;
      let warningMessage = '';

      if (isPaypalWithFlown) {
        // [ETP-4266] shown generic message or not yet setup message
        const enPaymentPaypalLbl = en.payment.paypal;
        warningMessage = isPaypalUnableRetrieve
          ? enPaymentPaypalLbl.unableRetrievePaypalDetailWarning
          : enPaymentPaypalLbl.commonWarning;
      }

      if (isDirectDebitWithFlown) {
        if (isDirectDebitSetupSuspended) {
          warningMessage = en.payment.directDebit.rejectedWarning;
        } else if (isDirectDebitNotSetup) {
          warningMessage = en.payment.directDebit.commonWarning;
        }
      }

      // ETP-2431
      if (isSuspensionStatus) {
        const contentMsg = isAssoSubsid(profile)
          ? en.userProfile.flownSuspension.assoSubsidContentMessage
          : en.userProfile.flownSuspension.contentMessage;

        warningMessage = contentMsg
          .replace(
            en.userProfile.flownSuspension.startDate,
            formatDateAsString(profile.etpFlownSuspensionStartDate, DATE_FORMAT.ddmmmyyyy) || '',
          )
          .replace(
            en.userProfile.flownSuspension.endDate,
            formatDateAsString(profile.etpFlownSuspensionEndDate, DATE_FORMAT.ddmmmyyyy) || '',
          );
      }

      let statusLabel = '';

      // Flown Paypal
      if (isPaypalWithFlown) {
        statusLabel = isPaypalNotSetup ? PAYMENT_SETUP_STATUS.NOT_YET_SET_UP : PAYMENT_SETUP_STATUS.COMPLETED;
        if (isPaypalUnableRetrieve) {
          statusLabel = PAYMENT_SETUP_STATUS.UNABLE_RETRIEVE;
        }
      }

      // Flown Direct debit
      if (isDirectDebitWithFlown) {
        statusLabel = PAYMENT_SETUP_STATUS.COMPLETED;

        if (isDirectDebitNotSetup) statusLabel = PAYMENT_SETUP_STATUS.NOT_YET_SET_UP;
        if (isDirectDebitSetupSuspended) statusLabel = PAYMENT_SETUP_STATUS.SUSPENDED;
      }

      // [ETP-4266]
      let paymentMethodReplaceTarget = '';

      // [ETP-4526] for direct debit -> Suspended (means directDebitStatus is null)
      if (isDirectDebitSetupSuspended) {
        paymentMethodReplaceTarget = en.payment.directDebit.myCasesLink; // my cases link
      }

      // [ETP-4526] other case for paypal
      if (!isDirectDebitSetupSuspended && !isPaypalUnableRetrieve) {
        paymentMethodReplaceTarget = en.payment.replaceTarget; // click here
      }

      let paymentValidated: boolean | null = null;
      if (!isPaypalUnableRetrieve) {
        paymentValidated = profile?.paymentValidated;
      }

      setPaymentMethodIndicator({
        isShowWarning:
          isSuspensionStatus ||
          (!isHideLtFeaturesControl &&
            isSelfRole &&
            isInPaymentMethodScope &&
            (isPaypalWithFlown
              ? isPaypalNotSetup || isPaypalUnableRetrieve
              : isDirectDebitNotSetup || isDirectDebitSetupSuspended)),
        paymentConcept: profile?.paymentConcept,
        paymentMedium: profile?.paymentMedium || '',
        paymentSetUpStatus: statusLabel,
        paymentSetUpWarningMessage: warningMessage,
        isError: false,
        isSuspensionStatus,
        isDirectDebitSetupSuspended,
        paymentValidated,
        replaceTarget: paymentMethodReplaceTarget,
      });
      return;
    } else {
      // empty concept & medium
      setPaymentMethodIndicator({
        isShowWarning: isSelfRole || !isHideLtFeaturesControl,
        paymentConcept: profile?.paymentConcept || '',
        paymentMedium: profile?.paymentMedium || '',
        paymentSetUpStatus: PAYMENT_SETUP_STATUS.ERROR,
        paymentSetUpWarningMessage: '',
        isError: true,
        isSuspensionStatus,
        isDirectDebitSetupSuspended: false,
        paymentValidated: profile?.paymentValidated || null,
        replaceTarget: en.payment.replaceTarget,
      });
      return;
    }
  }, [
    concession,
    profile?.paymentConcept,
    profile?.paymentValidated,
    profile?.paymentMedium,
    profile?.isShowFlownSuspensionMessage,
  ]);

  return [paymentMethodIndicator, setPaymentMethodIndicator] as const;
};
