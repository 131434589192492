import { useState } from 'react';

import { getAdminCurrentContext } from '../../../helpers';

import { NotesDetail, NotesList, NotesPopup } from './';

const AdminNotes = () => {
  const [isPopupActive, setIsPopupActive] = useState<boolean>(false);

  const adminCurrentContext = getAdminCurrentContext();
  const { focusNote } = adminCurrentContext.currentContextState;

  return (
    <>
      {focusNote ? (
        <NotesDetail {...focusNote} setIsPopupActive={setIsPopupActive} />
      ) : (
        <NotesList setIsPopupActive={setIsPopupActive} />
      )}
      {isPopupActive && <NotesPopup setIsPopupActive={setIsPopupActive} />}
    </>
  );
};

export default AdminNotes;
