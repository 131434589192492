import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Link, Grid, ListItem, Typography, IconButton, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { BackIcon, ExclamationIcon } from '../../../assets/images';
import { FONT_WEIGHT } from '../../../constants/font';
import { USER_PROFILE_PATHS } from '../../../constants/paths';
import { USER_PROFILE_DETAILS } from '../../../constants/constants';

import { handleBackToUserProfile, isAssoSubsid, isRetireeWidow } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';

import { FormButton } from '../../../components';

export interface IInformationListItem {
  key: string;
  value?: string | null;
  isEdit?: boolean;
  isShowWarning?: boolean;
  isShow?: boolean; // ETP-2431
  isReadOnly?: boolean;
  isValidEmail?: boolean;
  isPhoneNum?: boolean;
}
interface IInformationListProps {
  title: string;
  listData: IInformationListItem[];
}

const InformationList = ({ title, listData }: IInformationListProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { profile } = useAppSelector(selectUser);
  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};

  const isPaymentMethod = title === en.userProfile.employmentDetail.paymentMethod.title;
  const isContactsInfoPage = title === en.userProfile.personal.contactInfo.title;

  // etp-6021 updated: show "edit" button condition: is retiree/widow or is assoSubsid
  const isProfileCanEditable = profile && (isRetireeWidow(profile) || isAssoSubsid(profile));

  const containerSx = {
    mt: 2,
  };

  const listItemSx = {
    px: 1,
    pb: 1.5,
    pt: 0,
  };

  const cardSx = {
    mt: 2,
    maxWidth: { sm: USER_PROFILE_DETAILS.width, xs: 'auto' },
    borderRadius: 1,
    pt: 2,
    pl: 1,
    pr: 2,
    boxShadow: theme.boxShadow.important,
    bgcolor: theme.palette.primary.light,
  };

  return (
    <Box sx={{ ...(isDesktop && { width: USER_PROFILE_DETAILS.width }) }}>
      {!isDesktop && (
        <IconButton onClick={() => handleBackToUserProfile(navigate)} sx={{ py: 1.625, pl: 0 }}>
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}
      <Box sx={containerSx}>
        <Box sx={cardSx}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="headline_medium"
              sx={{
                color: theme.color.secondary.dark_grey.option_1,
                pb: 2,
                px: 1,
              }}
            >
              {title}
            </Typography>
            {isContactsInfoPage && isProfileCanEditable && (
              <FormButton
                theme={theme}
                customStyles={{
                  height: '18px',
                  width: '27px',
                  minWidth: '27px',
                  fontSize: '0.875rem',
                  lineHeight: '18px',
                  fontWeight: FONT_WEIGHT.bold,
                  padding: 0,
                }}
                colour="transparent"
                onClick={() => navigate(USER_PROFILE_PATHS.editContactDetail)}
              >
                {en.common.edit}
              </FormButton>
            )}
          </Box>

          {/* ETP-2431 */}
          {!isLoading &&
            listData.map((item: IInformationListItem) => {
              if (isPaymentMethod && !item?.isShow) return;
              return (
                <Box key={item.key}>
                  <ListItem sx={listItemSx} secondaryAction={item.isEdit && <Link href="/">{en.common.edit}</Link>}>
                    <Grid container direction="column">
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme.color.secondary.grey.option_2,
                        }}
                      >
                        {item.key || en.common.empty}
                      </Typography>
                      <Typography
                        variant="body_1_medium"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: theme.color.secondary.dark_grey.option_3,
                        }}
                      >
                        {item.value || en.common.empty}
                        {item?.isShowWarning && (
                          <Box
                            component="span"
                            sx={{
                              ml: 'auto',
                            }}
                          >
                            <ExclamationIcon width="16" height="16" fill={theme.color.utility.error.option_3} />
                          </Box>
                        )}
                      </Typography>
                    </Grid>
                  </ListItem>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default InformationList;
