import React, { useEffect, useMemo, useState } from 'react';
import { Box, useTheme, Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { UI_STYLES, DATE_FORMAT } from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import { DownArrowInheritIcon } from '../../../assets/images';

import { INominationHistory } from '../../../interfaces';

import {
  formatDateAsString,
  getFilterOptions,
  getAdminPersonalDisplayName,
  getAdminCurrentContext,
} from '../../../helpers';

import { BaseTable } from '../../../components';

const NominationHistory = () => {
  const theme = useTheme();
  const adminCurrentContext = getAdminCurrentContext();

  const fieldLabel = en.admin.nominationHistory;

  const [filterData, setFilterData] = useState<INominationHistory[]>([]);
  const [filterRelationship, setFilterRelationship] = useState<string>('');
  const [filterLastUpdatedBy, setFilterLastUpdatedBy] = useState<string>('');

  const currentContextState = adminCurrentContext.currentContextState || {};

  const currentNominationHistories = currentContextState?.employeeDetail?.nominationHistory || [];

  const relationshipByOptions = getFilterOptions(currentNominationHistories, 'relationship');
  const lastUpdatedByOptions = getFilterOptions(currentNominationHistories, 'lastUpdatedBy');

  const tableSchema = [
    {
      key: 'name',
      isHiddenField: false,
    },
    {
      key: 'relationship',
      isHiddenField: false,
    },
    {
      key: 'age',
      isHiddenField: false,
    },
    {
      key: 'nominationFrom',
      isHiddenField: false,
    },
    {
      key: 'nominationTo',
      isHiddenField: false,
    },
    {
      key: 'lastUpdatedBy',
      isHiddenField: false,
    },
    {
      key: 'lastUpdateDate',
      isHiddenField: false,
    },
    {
      key: 'remark',
      isHiddenField: false,
    },
  ];

  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          pb: 0.25,
          px: 0.5,
          width: 'auto',
          ...(item.key === 'age' && {
            maxWidth: 80,
          }),
          flex: 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
        },
        isHiddenField: item.isHiddenField,
      };
    });
  }, [filterData]);

  const transformedTableContents = useMemo(() => {
    const tmpData = _.cloneDeep(filterData);

    return tmpData.map(
      (
        dataItem: INominationHistory & {
          name?: string;
        },
      ) => {
        dataItem.lastUpdateDate = formatDateAsString(dataItem.lastUpdateDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date);
        dataItem.nominationFrom = formatDateAsString(dataItem.nominationFrom, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date);
        dataItem.nominationTo = formatDateAsString(dataItem.nominationTo, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date);
        dataItem.name = getAdminPersonalDisplayName({
          lastName: dataItem.lastName,
          firstName: dataItem.firstName,
          middleName: dataItem.middleName,
        });

        return {
          sxProps: {
            display: 'flex',
            minHeight: '62px',
          },
          hover: false,
          columns: tableSchema.map(({ key, isHiddenField }) => {
            const currentValue = dataItem?.[key as keyof INominationHistory];
            const newValue = `${currentValue}`;

            return {
              columnkey: key,
              value: newValue,
              sxProps: {
                py: 1.25,
                px: 0.5,
                width: 'auto',
                ...(key === 'age' && {
                  maxWidth: 80,
                }),
                flex: 1,
                overflow: 'hidden',
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
              },
              isHiddenField,
            };
          }),
        };
      },
    );
  }, [filterData]);

  // filter by "relationship" or "lastUpdatedBy"
  useEffect(() => {
    const tempData = currentNominationHistories?.filter((item: INominationHistory) => {
      const isRelationship = !filterRelationship || filterRelationship === item.relationship;
      const LastUpdatedBy = !filterLastUpdatedBy || filterLastUpdatedBy === item.lastUpdatedBy;
      return isRelationship && LastUpdatedBy;
    });
    setFilterData(tempData);
  }, [filterRelationship, filterLastUpdatedBy, currentNominationHistories]);

  return (
    <Box
      className="nomination_history_list"
      sx={{
        mt: 4,
        width: '100%',
      }}
    >
      <Autocomplete
        options={relationshipByOptions}
        popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
        clearOnEscape
        disablePortal
        sx={{
          width: '143px',
          height: '48px',
          float: 'left',
          mr: 2,
          '.MuiFilledInput-root': {
            height: UI_STYLES.concessionTravellerHeight,
            borderRadius: 0.5,
            border: `2px solid ${theme.color.secondary.slate.option_3}`,
            background: theme.palette.primary.light,
            color: theme.color.secondary.dark_grey.option_3,
            paddingTop: '0px',
            '&.Mui-error': {
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
            },
            '&.Mui-selected, &:hover, &.Mui-filled': {
              background: 'white',
            },
            '&.Mui-focused': {
              background: theme.palette.primary.light,
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
              '&.Mui-error': {
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
              },
            },
            '&.Mui-disabled': {
              background: theme.color.utility.unselectable.option_7,
            },
            '&: after, &: before': {
              display: 'none',
            },
            // input style
            '.MuiFilledInput-input': {
              mt: 1.5,
              fontWeight: FONT_WEIGHT.medium,
              fontSize: theme.typography.body_2_medium.fontSize,
              color: theme.color.secondary.dark_grey.option_1,
            },
          },
          '.MuiInputLabel-root': {
            top: '-2px',
            color: theme.color.secondary.grey.option_3,
            fontSize: theme.typography.body_2_medium.fontSize,
            '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
              color: theme.color.secondary.grey.option_3,
            },
          },
        }}
        onChange={(_, newValue) => {
          setFilterRelationship(newValue?.value || '');
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              height: UI_STYLES.concessionTravellerHeight,
            }}
            variant="filled"
            {...params}
            label={fieldLabel.relationship || ''}
          />
        )}
      />
      <Autocomplete
        options={lastUpdatedByOptions}
        popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
        clearOnEscape
        disablePortal
        sx={{
          width: '200px',
          height: '48px',
          float: 'left',
          '.MuiFilledInput-root': {
            height: UI_STYLES.concessionTravellerHeight,
            borderRadius: 0.5,
            border: `2px solid ${theme.color.secondary.slate.option_3}`,
            background: theme.palette.primary.light,
            color: theme.color.secondary.dark_grey.option_3,
            paddingTop: '0px',
            '&.Mui-error': {
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
            },
            '&.Mui-selected, &:hover, &.Mui-filled': {
              background: 'white',
            },
            '&.Mui-focused': {
              background: theme.palette.primary.light,
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
              '&.Mui-error': {
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
              },
            },
            '&.Mui-disabled': {
              background: theme.color.utility.unselectable.option_7,
            },
            '&: after, &: before': {
              display: 'none',
            },
            // input style
            '.MuiFilledInput-input': {
              mt: 1.5,
              fontWeight: FONT_WEIGHT.medium,
              fontSize: theme.typography.body_2_medium.fontSize,
              color: theme.color.secondary.dark_grey.option_1,
            },
          },
          '.MuiInputLabel-root': {
            top: '-2px',
            color: theme.color.secondary.grey.option_3,
            fontSize: theme.typography.body_2_medium.fontSize,
            '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
              color: theme.color.secondary.grey.option_3,
            },
          },
        }}
        onChange={(_, newValue) => {
          setFilterLastUpdatedBy(newValue?.value || '');
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              height: UI_STYLES.concessionTravellerHeight,
            }}
            variant="filled"
            {...params}
            label={fieldLabel.lastUpdatedBy || ''}
          />
        )}
      />

      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
      />
    </Box>
  );
};

export default NominationHistory;
