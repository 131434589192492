import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, SxProps, Theme } from '@mui/material';
import { shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';

import { BOOKING_STATUS, MULTIPLE_WARNING_TYPE, UI_STYLES, USER_ROLE } from '../../../constants/constants';
import theme from '../../../style/theme';
import en from '../../../translations/en';
import { CompletedOverlay, CompletedBackground } from '../../../assets/images';

import { Frontend, Pnr } from 'booking';
import { BookingSummaryAction, BookingSummaryState } from '../../../context';
import { ROOT_PATHS } from '../../../constants/paths';

import { useAppSelector } from '../../../app/hooks';
import { selectApp, setRetrieveAndPaySuccessData, setNormalPrepaidSuccessData } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import {
  selectBooking,
  setBookingCompleted,
  setBookingStep,
  setTpAppRef,
  setViewBookingApplicationId,
  setIsCalledPrepaidAuth,
  setRetrieveAndPayCppParams,
  setPrepaidAuthFlowType,
} from '../../../slice/bookingSlice';

import { Footer, NavBar, ScrollableView, ShadowContent } from '../../../components';
import BookingCompletedHeader from './BookingCompletedHeader';
import { BookingSummaryOverlay, FlightInfo } from '../..';
import MultipleWarning from '../Common/MultipleWarning';

const VerticalLine = ({ style }: { style?: SxProps<Theme> }) => {
  return (
    <Box
      sx={{
        width: '4px',
        ml: 20,
        height: '41px',
        bgcolor: theme.color.cabin_class.business.option_5,
        ...style,
      }}
    />
  );
};

interface IBookingCompletedProps {
  bookingResult: {
    booking: Frontend.ParsedBooking;
    isAllowCancel: boolean;
  };
  isRebook?: boolean;
  isRetrieveAndPay?: boolean;
}

const BookingCompleted = ({ bookingResult, isRebook, isRetrieveAndPay }: IBookingCompletedProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bookingSelected, setBookingSelected] = useState<Frontend.ParsedBooking | null>(null);
  const [booking, setBooking] = useState(bookingResult?.booking || {});

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { role } = useAppSelector(selectUser) || {};
  const { bookingCompleted } = useAppSelector(selectBooking) || {};

  const screenHeight = use100vh();

  const OALMessage = en.multipleWarning.OAL.OALWarning;

  const { bookingSummaryData, isExpandWarning } = useContext(BookingSummaryState) || {};
  const { setIsFlightRebook, setIsOpenRebookCompleted, setIsExpandWarning } = useContext(BookingSummaryAction) || {};

  const {
    status: bookingStatus,
    recLoc,
    type,
    ticketsBySegment,
    etpPassengerList,
  } = isRebook ? bookingSummaryData : booking || {};

  const isPendingSupport = bookingStatus === BOOKING_STATUS.pendingSupport;

  const isOWOALFlight = ticketsBySegment[0]?.flightNum === en.multipleWarning.open;
  const OALCode = ticketsBySegment[0].carrier;

  const { type: roleType } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  useEffect(() => {
    sessionStorage.removeItem('searchResultCache');
  }, []);

  useEffect(() => {
    // browser size change, load data from redux to keep open drawer
    if (bookingCompleted) {
      setBookingSelected(bookingCompleted);
    }
  }, [isDesktop]);

  useEffect(() => {
    setBooking(bookingResult?.booking || {});
  }, [booking]);

  // ------------ for 2915 start ------------
  let rebookTicketsBySegment: Pnr.TicketBySegment[] = [];
  if (isRebook) {
    const { outwardFlight } = useAppSelector(selectBooking, shallowEqual) || {};
    const {
      arrivalDate,
      arrivalTime,
      departureTime,
      departureDate,
      flightNo,
      marketingCompany,
      numberOfStops,
      aircraft,
    } = outwardFlight || {};

    rebookTicketsBySegment = _.cloneDeep(ticketsBySegment);
    rebookTicketsBySegment[0].arrivalDate = arrivalDate || '';
    rebookTicketsBySegment[0].arrivalTime = arrivalTime || '';
    rebookTicketsBySegment[0].departureDate = departureDate || '';
    rebookTicketsBySegment[0].departureTime = departureTime || '';
    rebookTicketsBySegment[0].flightNum = flightNo || '';
    rebookTicketsBySegment[0].carrier = marketingCompany || '';
    rebookTicketsBySegment[0].numOfStops = numberOfStops || 0;
    rebookTicketsBySegment[0].aircraftType = aircraft || '';
  }

  const clearRetrieveAndPayData = () => {
    // Potential bug: when the user directly click home, the state cannot be reset, Need to rework after refactor

    // Clear Booking Step
    dispatch(setIsCalledPrepaidAuth(false));
    dispatch(setRetrieveAndPayCppParams(null));

    dispatch(setBookingStep(null));

    dispatch(setPrepaidAuthFlowType(''));
    dispatch(setRetrieveAndPaySuccessData(null));
    dispatch(setNormalPrepaidSuccessData(null));

    dispatch(setViewBookingApplicationId(''));
    dispatch(setTpAppRef(''));
  };

  // ------------ for 2915 end ------------

  return (
    <Box
      sx={{
        ...(isDesktop && {
          width: '100%',
          height: screenHeight,
          backgroundImage: `url(${CompletedBackground})`,
          backgroundSize: 'cover',
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          ...(isDesktop && {
            backgroundColor: `url(${CompletedOverlay})`,
            backgroundSize: 'cover',
            background:
              'linear-gradient(270deg, #FFFFFF 34.2%, rgba(255, 255, 255, 0.9) 47.64%, rgba(255, 255, 255, 0) 100%)',
          }),
        }}
      >
        {(isRebook || isRetrieveAndPay) && isDesktop && <NavBar isShowNavBar={false} />}
        <Grid
          component={ScrollableView}
          sx={{
            p: isRebook && isDesktop ? 0 : 2,
            mt: isDesktop || (isDesktop && isRebook) ? 0 : isAdminDelegation ? -8 : -13,

            height: isDesktop
              ? `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${UI_STYLES.desktopFooterHeight} - ${
                  isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'
                })`
              : isPendingSupport
              ? `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingCompleted} - ${
                  isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'
                } - 128px + 156px)`
              : `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingCompleted} - ${
                  isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'
                } - 128px + 104px)`,
          }}
        >
          {isDesktop ? (
            <Box sx={{ display: 'flex', height: 1 }}>
              <Box sx={{ display: 'flex', width: '50vw' }} />
              <Box
                sx={{
                  display: 'flex',
                  width: '50vw',
                  flexDirection: 'column',
                }}
              >
                {isOWOALFlight || isPendingSupport ? (
                  <Box sx={{ display: 'flex', width: '343px', ml: 10, mb: 2 }}>
                    <MultipleWarning
                      onChange={() => {
                        setIsExpandWarning(!isExpandWarning);
                      }}
                      type={isOWOALFlight ? MULTIPLE_WARNING_TYPE.otherAirline : undefined}
                      isExpand={isExpandWarning}
                      data={[
                        {
                          ...(isOWOALFlight && {
                            headingCode: OALCode,
                          }),
                        },
                        {
                          ...(isPendingSupport && {
                            warningMessage: en.booking.confirmation.ticketWillUpdate,
                          }),
                        },
                      ]}
                      messageFormatter={OALMessage}
                    />
                  </Box>
                ) : (
                  <Box sx={{ height: '41px', display: 'flex', mt: -2 }}>
                    <VerticalLine style={{ ml: 21 }} />
                    <VerticalLine />
                  </Box>
                )}
                <Box
                  sx={{
                    width: '343px',
                    ml: 10,
                    border: `4px solid ${theme.color.cabin_class.business.option_5}`,
                    boxShadow: theme.boxShadow.important,
                    borderRadius: 1,
                    px: 2.5,
                    pt: 2.5,
                  }}
                >
                  <FlightInfo
                    bookingStatus={bookingStatus}
                    recLoc={recLoc}
                    type={type}
                    ticketsBySegment={isRebook ? rebookTicketsBySegment : ticketsBySegment}
                    isViewBookingDetails={true}
                    showPax={true}
                    etpPassengerList={etpPassengerList}
                  />
                </Box>

                <Box
                  sx={{
                    width: '343px',
                    mx: 10,
                    mt: isPendingSupport ? 0 : 5,
                    mb: 10,
                  }}
                >
                  <BookingCompletedHeader {...booking} />
                </Box>
              </Box>
            </Box>
          ) : (
            // mobile view
            <>
              {(isOWOALFlight || isPendingSupport) && (
                <Box sx={{ mb: 2 }}>
                  <MultipleWarning
                    type={isOWOALFlight ? MULTIPLE_WARNING_TYPE.otherAirline : undefined}
                    isExpand={isExpandWarning}
                    messageFormatter={OALMessage}
                    onChange={() => {
                      setIsExpandWarning(!isExpandWarning);
                    }}
                    data={[
                      {
                        ...(isOWOALFlight && {
                          headingCode: OALCode,
                        }),
                      },
                      {
                        ...(isPendingSupport && {
                          warningMessage: en.booking.confirmation.ticketWillUpdate,
                        }),
                      },
                    ]}
                  />
                </Box>
              )}
              <Box component={ShadowContent}>
                <FlightInfo
                  bookingStatus={isRebook ? '' : bookingStatus} // etp-2976 hardcode
                  recLoc={recLoc}
                  type={type}
                  ticketsBySegment={isRebook ? rebookTicketsBySegment : ticketsBySegment}
                  isViewBookingDetails={true}
                  showPax={true}
                  etpPassengerList={etpPassengerList}
                />
              </Box>
            </>
          )}
        </Grid>

        <Footer
          primaryBtn={{
            isFullWidth: !isDesktop,
            text: en.booking.confirmation.backHomepage,
            customOnClick: () => {
              clearRetrieveAndPayData();
              if (isRebook || isRetrieveAndPay) {
                location.replace(ROOT_PATHS.landing);
              } else {
                navigate('/');
              }
            },
            customButtonStyles: {
              ...(isDesktop && {
                width: '220px',
              }),
            },
          }}
          secondaryBtn={
            !isPendingSupport
              ? {
                  isFullWidth: !isDesktop,
                  text: en.booking.confirmation.viewDetails,
                  customOnClick: () => {
                    if (isRebook) {
                      setIsFlightRebook(true);
                      setIsOpenRebookCompleted(false);
                    } else if (isRetrieveAndPay) {
                      clearRetrieveAndPayData();
                    } else {
                      setBookingSelected(booking);
                      dispatch(setBookingCompleted(booking));
                    }
                  },
                  customButtonStyles: {
                    ...(isDesktop && {
                      width: '220px',
                      position: 'absolute',
                      right: '414px',
                    }),
                  },
                }
              : undefined
          }
          customStyles={
            isDesktop && {
              boxShadow: theme.boxShadow.important,
              px: 22,
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
              flexDirection: 'row',
            }
          }
        />
      </Box>

      {bookingSelected && (
        <BookingSummaryOverlay
          data={{
            bookingResult,
            isFromCompleted: true,
            isRebookFromMyBooking: true,
          }}
          handleClose={() => {
            setBookingSelected(null);
            // setBookingCompleted(null);
            navigate('/');
          }}
          bookingSelected={bookingSelected}
        />
      )}
    </Box>
  );
};

BookingCompleted.defaultProps = {
  isRebook: false,
};

export default BookingCompleted;
