import React, { useContext } from 'react';
import en from '../../../translations/en';

import { IConcessionYearDropdownData } from '../../../context/ConcessionInProfileContext';

import { ConcessionInProfileState, ConcessionInProfileAction } from '../../../context';

import ConcessionDropdownContainer, { IConcessionDropdownContainer } from './ConcessionDropdownContainer';

type ISelectedOptionItem = IConcessionYearDropdownData['selectedOptionItem'];
type IDropdownContainer = IConcessionDropdownContainer<ISelectedOptionItem>;

interface ConcessionWithYear {
  autoCompleteDropdownCustomStyles?: IDropdownContainer['autoCompleteDropdownCustomStyles'];
  inputPropsCustomForTextField?: IDropdownContainer['inputPropsCustomForTextField'];
}

const ConcessionWithYear = ({ autoCompleteDropdownCustomStyles, inputPropsCustomForTextField }: ConcessionWithYear) => {
  const {
    concessionYearDropdownData: { dropdownOptions, selectedOptionItem },
  } = useContext(ConcessionInProfileState) || {};

  const { setConcessionYearDropdownData } = useContext(ConcessionInProfileAction) || {};

  const onChangeDropdownOptionItem = (newValue: ISelectedOptionItem) => {
    setConcessionYearDropdownData((prevState: IConcessionYearDropdownData) => {
      return {
        ...prevState,
        selectedOptionItem: newValue,
      };
    });
  };

  return (
    <ConcessionDropdownContainer<ISelectedOptionItem>
      {...{
        dropdownOptions,
        dropdownLabel: en.userProfile.concession.yearDropdownLabel,
        onChangeDropdownOptionItem,
        selectedOptionItem,
        inputPropsCustomForTextField,
        autoCompleteDropdownCustomStyles,
      }}
    />
  );
};

export default ConcessionWithYear;
