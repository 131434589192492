import { useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { NOMINATION_TYPE, NOMINATION_YEAR_TYPE } from '../../../../constants/constants';
import en from '../../../../translations/en';

import { INominationViewModeList } from '../../../../interfaces';
import {
  handleNominatedEligibleCarrier,
  injectNominationYearInText,
  checkIsNextYearNominationFlow,
  isAssoSubsid,
  getEligibleNominationTypeList,
  getNominationPeriodRecordByYearType,
} from '../../../../helpers';
import { NominationState } from '../../../../context';

import { selectUser } from '../../../../slice/userSlice';
import { selectApp } from '../../../../slice/appSlice';
import { useAppSelector } from '../../../../app/hooks';
import { selectConfiguration } from '../../../../slice/configurationSlice';

import { NominationPageTitle, NominationCartMasterContainer } from '../../..';

const NoEligibleNominationSection = () => {
  const theme = useTheme();
  return (
    <Typography variant="body_2_regular" lineHeight="24px" color={theme.color.secondary.dark_grey.option_3}>
      {en.userProfile.nomination.common.noEligibleNomination}
    </Typography>
  );
};

const NominationViewModeContainer = () => {
  const theme = useTheme();

  const { profile } = useAppSelector(selectUser) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};

  const { nominationFlow } = useContext(NominationState) || {};

  const isAssoSubsidProfile = isAssoSubsid(profile);

  const isNextYearNominationFlow = checkIsNextYearNominationFlow();

  const nominationPeriodRecord = getNominationPeriodRecordByYearType(
    profile,
    isNextYearNominationFlow ? NOMINATION_YEAR_TYPE.future : NOMINATION_YEAR_TYPE.current,
  );

  const pageTitle = injectNominationYearInText({
    targetText: en.userProfile.nomination.common.entryPointBaseTitle,
    nominationPeriodRecord,
  });

  // eligibleNominationTypeList is empty, show "No eligible nomination type is available."
  // eligibleNominationTypeList is not empty, hide "Travel nominee"/"Plus one" if maxNumberOfPerson is 0
  const eligibleNominationTypeList = getEligibleNominationTypeList(nominationFlow?.nominationViewModeData);

  return (
    <Box className="nomination_view_mode_container">
      <Box
        sx={{
          mb: 9.5,
          ...(isDesktop && {
            width: '488px',
            mx: 'auto',
            mt: 2,
          }),
        }}
      >
        <NominationPageTitle title={pageTitle} sxProps={{ ml: 0.5, mt: isDesktop ? 0 : -1.25, mb: 0.5 }} />

        {!isLoading &&
          eligibleNominationTypeList.map((nominationType: string) => {
            const mappingKey = NOMINATION_TYPE?.[nominationType as keyof typeof NOMINATION_TYPE];
            const matchedTypeObj =
              nominationFlow?.nominationViewModeData?.[mappingKey as keyof INominationViewModeList];

            return (
              <NominationCartMasterContainer
                key={nominationType}
                // insert eligibleCarrier
                list={handleNominatedEligibleCarrier(configurations, matchedTypeObj?.list || [], isAssoSubsidProfile)}
                maxNumberOfPerson={matchedTypeObj?.maxNumberOfPerson}
                nominationType={nominationType}
                isViewMode={true}
                containerCustomProps={{
                  ...(isNextYearNominationFlow && {
                    backgroundColor: theme.color.secondary.light_sand.option_6,
                  }),
                }}
              />
            );
          })}

        {!isLoading && eligibleNominationTypeList.length === 0 && <NoEligibleNominationSection />}
      </Box>
    </Box>
  );
};

export default NominationViewModeContainer;
