import React, { useContext, useEffect, useState } from 'react';
import { SxProps, Theme, Grid, Typography, useTheme, Link } from '@mui/material';
import 'dayjs/locale/en-gb';

import { Configuration } from 'configuration';
import en from '../../../translations/en';
import { LABEL_VALUE_TYPE } from '../../../constants/constants';
import { AdminAccessEmployeeState, AssoSubsidAdminEmployeeState } from '../../../context';

import {
  findDropdownOptionClient,
  getDisplayLabel,
  getDisplayVal,
  isEmptyField,
  validateRequired,
} from '../../../helpers';
import { useAppSelector } from '../../../app/hooks';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { InputFieldDatePicker, ReduxFormSelectInput, ReduxFormTextInput } from '../../../components';

interface IDisplayOptions {
  code: string;
  label: string;
}

interface IDisplayDetails {
  label: string;
  value: string | number | boolean | Date | undefined | null;
  column: number;
  options?: string[];
  configurationField?: string;
  confidential?: boolean;
  type?: LABEL_VALUE_TYPE;
  labelVariant?: string | any;
  valueVariant?: string | any;
  selectOptions?: IDisplayOptions[];
  defaultProps?: {
    type: string;
    value: string;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    errorMessage?: string;
    isMandatoryField?: boolean;
  };
  defaultInputProps?: {
    type: string;
    value: string;
    regexForInputOnChange?: object;
    isMandatoryField?: boolean;
    dropdownOptions?: Configuration.DropdownOptionClient[];
  };
  setSelectedValue?: (selectType: string, value: string) => void;
  customStyles?: SxProps<Theme>;
  setOnChange?: (inputType: string, value: string) => void;
}

interface ILabelValueContainer extends IDisplayDetails {
  customStyles?: SxProps<Theme>;
  labelTypoSxProps?: SxProps<Theme>;
}

const LabelValueContainer = ({
  label,
  value,
  type = LABEL_VALUE_TYPE.STRING,
  column = 4,
  options,
  configurationField,
  confidential = false,
  customStyles,
  labelVariant = 'body_2_regular',
  valueVariant = 'headline_medium',
  labelTypoSxProps,
  selectOptions = [],
  defaultProps = {
    type: '',
    value: '',
    disabled: false,
    errorMessage: '',
  },
  defaultInputProps = {
    type: '',
    value: '',
    regexForInputOnChange: {},
    isMandatoryField: false,
    dropdownOptions: [],
  },
  setSelectedValue,
  setOnChange,
}: ILabelValueContainer) => {
  const theme = useTheme();
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const { isEditingAdminProfile } = useContext(AdminAccessEmployeeState) || {};
  const { isEditingAdminProfile: isEditingAssoSubsidAdminProfile, isEditingDependentProfile } =
    useContext(AssoSubsidAdminEmployeeState) || {};

  const [isHide, setIsHide] = useState<boolean>(confidential);
  const [currentStartDate, setCurrentStartDate] = useState<string>(defaultProps?.value);

  useEffect(() => {
    if (isEditingAdminProfile) {
      setCurrentStartDate(defaultProps?.value);
    } else {
      setCurrentStartDate('');
    }
  }, [isEditingAdminProfile]);

  useEffect(() => {
    if (isEditingAssoSubsidAdminProfile) {
      setCurrentStartDate(defaultProps?.value);
      setIsHide(false);
    } else {
      setCurrentStartDate('');
    }
  }, [isEditingAssoSubsidAdminProfile]);

  useEffect(() => {
    if (isEditingDependentProfile) {
      setCurrentStartDate(defaultProps?.value);
      setIsHide(false);
    } else {
      setCurrentStartDate('');
    }
  }, [isEditingDependentProfile]);

  const ValueComponent = () => {
    switch (type) {
      case LABEL_VALUE_TYPE.STRING:
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3, mt: 0.5 }} variant={valueVariant}>
            {getDisplayVal(value)}
          </Typography>
        );
      case LABEL_VALUE_TYPE.BOOLEAN:
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3, mt: 0.5 }} variant={valueVariant}>
            {value ? options?.[0] : options?.[1]}
          </Typography>
        );
      case LABEL_VALUE_TYPE.CODE_LABELS:
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3, mt: 0.5 }} variant={valueVariant}>
            {getDisplayLabel(configurations.labels, configurationField, value as string)}
          </Typography>
        );
      case LABEL_VALUE_TYPE.COUNTRIES:
        // This type is on hold, BU side said just use the Code directly from backend
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3, mt: 1 }} variant={valueVariant}>
            {findDropdownOptionClient(configurations.countries, value as string)?.label}
          </Typography>
        );
      case LABEL_VALUE_TYPE.FORM_SELECT:
        return (
          <ReduxFormSelectInput
            input={{
              value: findDropdownOptionClient(selectOptions, defaultProps?.value ?? en.common.empty),
              onChange: (input: Configuration.CodeLabel) => {
                setSelectedValue?.(defaultProps?.type || '', input.code);
              },
            }}
            customInputStyle={{
              '& .MuiFilledInput-root': {
                height: '32px',
                '& .MuiSelect-select': {
                  height: '40px',
                },
              },
              '& .MuiSelect-icon': {
                top: 'calc(50% - 10px)',
              },
            }}
            options={selectOptions}
            meta={{
              touched: isEmptyField(defaultProps?.value),
              error: defaultProps?.errorMessage,
            }}
          />
        );
      case LABEL_VALUE_TYPE.FORM_DATE_PICKER:
        return (
          <InputFieldDatePicker
            selectedType={defaultProps?.type}
            dateFieldProps={{
              defaultDate: currentStartDate,
              errorMessage:
                (defaultProps?.isMandatoryField ? validateRequired(defaultProps?.value) : defaultProps?.errorMessage) ||
                '',
              errorMessagePaddingTop: '-16px',
              disabled: false,
              minDate: defaultProps?.minDate,
              maxDate: defaultProps?.maxDate,
            }}
            setSelectedValue={setSelectedValue}
          />
        );
      case LABEL_VALUE_TYPE.FORM_FIELD_TEXT_INPUT:
        return (
          <ReduxFormTextInput
            sx={{
              flex: 1,
              '& .MuiFilledInput-root': {
                '& .MuiFilledInput-input': {
                  paddingBottom: '25px',
                },
              },
              height: 30,
            }}
            {...defaultInputProps?.regexForInputOnChange}
            value={defaultInputProps?.value}
            input={{
              onChange: (input: string) => {
                setOnChange?.(defaultInputProps?.type, input);
              },
            }}
            meta={{
              touched: !!validateRequired(defaultInputProps?.value),
              error: defaultInputProps?.isMandatoryField ? validateRequired(defaultInputProps?.value) : '',
            }}
          />
        );
      case LABEL_VALUE_TYPE.FORM_FIELD_AUTO_COMPLETE:
        return (
          <ReduxFormSelectInput
            customInputStyle={{
              '& .MuiFilledInput-root': {
                height: '32px',
                '& .MuiFilledInput-input': {
                  padding: '0',
                },
              },
            }}
            {...defaultInputProps?.regexForInputOnChange}
            value={defaultInputProps?.value}
            input={{
              onChange: (input: string) => {
                setOnChange?.(defaultInputProps?.type, input);
              },
            }}
            options={defaultInputProps?.dropdownOptions}
            autocomplete={{
              matchFrom: 'start',
            }}
            meta={{
              touched: !!validateRequired(defaultInputProps?.value),
              error: defaultInputProps?.isMandatoryField ? validateRequired(defaultInputProps?.value) : '',
            }}
          />
        );
    }
  };

  return (
    <Grid
      className="label_value_grid"
      item
      xs={column}
      sx={{ display: 'flex', flexDirection: 'column', ...customStyles }}
    >
      <Typography
        sx={{
          color: theme.color.secondary.dark_grey.option_3,
          ...labelTypoSxProps,
        }}
        variant={labelVariant}
      >
        {label}
      </Typography>
      {isHide ? (
        <Link sx={{ cursor: 'pointer' }} onClick={() => setIsHide(false)}>
          <Typography variant="headline_medium">{en.common.view}</Typography>
        </Link>
      ) : (
        ValueComponent()
      )}
    </Grid>
  );
};

export default LabelValueContainer;

export type { IDisplayDetails };
