import React from 'react';
import { Typography, useTheme } from '@mui/material';

import en from '../../../../translations/en';

import { AlertIconOutlined } from '../../../../assets/images';

const DeactivateButtonHint = () => {
  const fieldLabel = en.admin.feature.personalInformation.profileHousekeepingDetails;
  const theme = useTheme();
  return (
    <Typography
      sx={{ color: theme.color.secondary.dark_grey, display: 'flex', lineHeight: '26px', marginTop: '3px' }}
      variant="navigation_regular"
    >
      <AlertIconOutlined
        style={{ display: 'block', width: '13px', height: '13px', marginTop: '7px', marginRight: '4px' }}
      />
      {fieldLabel.deactivateButtonHintText}
    </Typography>
  );
};

export default DeactivateButtonHint;
