import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, SwipeableDrawer, Modal, useTheme } from '@mui/material';
import _ from 'lodash';
import { Pnr } from 'booking';

import {
  BOOKING_STATUS,
  UI_STYLES,
  BOOKING_FOOTER_ACTION,
  GOOGLE_ANALYTICS_EVENTS,
  BOOKING_FLOW_PAGE_NO,
  NOMINATED_PASSENGER_AGE_TYPE,
  PREPAID_AUTH_FLOW_TYPE,
} from '../../../constants/constants';
import { BOOKING_PATHS } from '../../../constants/paths';
import {
  AlertIcon,
  CancelOnlyIcon,
  CancelRebookIcon,
  CancelRefundIcon,
  RemoveTravellersIcon,
  RetrieveAndPayIcon,
} from '../../../assets/images';
import en from '../../../translations/en';

import { Frontend } from 'booking';
import { bookingAction } from '../../../interfaces';

import {
  isLevelZEmployee,
  getTicketDetailsDataList,
  removedPassengers,
  checkRemainingTravellers,
  constructFlightSectors,
  isOWOALBooking,
  getLocalDate,
} from '../../../helpers';
import { retrieveAndPayAuthAction, processPrepaidAuthResponse } from '../../../helpers/prepaid';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectAuth } from '../../../slice/authSlice';
import {
  selectApp,
  setCancelBookingSuccessData,
  setRetrieveAndPaySuccessData,
  setSnackBarData,
} from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import {
  selectBooking,
  setBookingStep,
  setIsShowCancelDialog,
  setLeisureTravelDetails,
  setViewBookingApplicationId,
  setPrepaidAuthFlowType,
  setIsCalledPrepaidAuth,
  setTpAppRef,
} from '../../../slice/bookingSlice';
import { BookingSummaryState, BookingSummaryAction } from '../../../context';

import { getFareAndTravellerDetailsLT, removePassengerLT } from '../../../services/booking';

import {
  ScrollableView,
  CancelDialog,
  DesktopBreadcrumb,
  MoreActionsPopover,
  MoreActionContainer,
} from '../../../components';
import { BookingSummaryContent, ConfirmRemoveContainer } from '..';
import DesktopHeaderContainer from './DesktopHeaderContainer';

const BookingSummary = ({
  bookingSummary,
  refreshBookingError,
  isCheckedIn,
  isTicketExpired,
  handleCloseClick,
  getBookingAction,
}: {
  bookingSummary: Frontend.ParsedBooking;
  isCheckedIn: boolean | null | undefined;
  isTicketExpired: boolean | null | undefined;
  refreshBookingError: Pick<Frontend.Booking, 'amadeusError' | 'requestId' | 'applicationId'> | null | undefined;
  handleCloseClick: () => void;
  getBookingAction: (booking: any) => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { ern } = useAppSelector(selectAuth) || {};
  const navigate = useNavigate();

  const { leisureTravelDetails } = useAppSelector(selectBooking) || {};

  const {
    isOpenRemoveDialog,
    isOpenRemoveToCancelDialog,
    isOpenConfirmToCancelDialog,
    removalFormData,
    travellerList,
    removalTravellerList,
    isOpenRemovePaxMode,
    routeTo: removePaxModeRoute,
    bookingSummaryData: bookingsForDisplay,
    isOpenMoreAction,
    bookingEligibility,
    isRetrieveAndPayFlow,
  } = useContext(BookingSummaryState) || {};
  const {
    setIsOpenRemoveDialog,
    setIsOpenRemoveToCancelDialog,
    setIsOpenConfirmToCancelDialog,
    setTravellerList,
    setIsOpenRemovePaxMode,
    setRemovalTravellerList,
    setRouteTo,
    setRemovalFormData,
    setBookingSummaryData,
    setIsOpenRebookFlow,
    setIsOpenMoreAction,
    setIsRetrieveAndPayFlow,
    setIsOpenFlownRefundDialog,
  } = useContext(BookingSummaryAction) || {};

  const isRetrieveAndPayStatus = bookingsForDisplay?.prepaidStatus === BOOKING_STATUS.retrieveAndPay;

  const { profile } = useAppSelector(selectUser) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};

  const [bookingActionObjs, setBookingActionObjs] = useState<{
    [key: string]: bookingAction;
  }>({});

  const [footerActionList, setFooterActionList] = useState<{
    [key: string]: bookingAction;
  }>({});

  const isLevelZUser = isLevelZEmployee(profile);

  const { recLoc: bookingRecLoc, applicationId } = bookingSummary || {};
  const { flightNum } = bookingSummary?.ticketsBySegment?.[0] || {};
  const isOWOALFlight = isOWOALBooking(flightNum);

  const removePaxModeRouteStep = removePaxModeRoute?.step || 1;

  const remainingTravellerList = removalTravellerList?.filter((item: any) => !item.isRemoved) || [];

  const checkedInTravellerList = travellerList?.filter((item: any) => !item.isRemoved && !item.isAllowRemove) || [];

  const selectedAllTravellerList = removalTravellerList?.every((item: any) => item.isRemoved) || false;

  const { remainingPaxCanProceed } =
    checkRemainingTravellers({
      remainingTravellerList: _.concat(_.cloneDeep(remainingTravellerList), _.cloneDeep(checkedInTravellerList)),
    }) || false;

  const actionBaseProperty = {
    color: 'primary',
    colour: 'outline',
    variant: 'outlined',
    size: 'large',
  };
  const actionSeniorProperty = {
    color: 'success',
    colour: 'green',
    variant: 'contained',
  };

  const { isOnlyYoungChildLeft, isOnlyOneInfantLeft } =
    removedPassengers({
      passengers: _.concat(_.cloneDeep(remainingTravellerList), _.cloneDeep(checkedInTravellerList)),
    }) || {};

  const isShowContinueRemovePaxBtn = () => {
    let canContinue = true;

    if (isOnlyOneInfantLeft || isOnlyYoungChildLeft) {
      // if has form, need to fullFill from & checkBox
      const { bookingReference, salutation, lastName, firstName, isFormChecked } = removalFormData || {};

      if (bookingReference && bookingReference.length === 6 && salutation && lastName && firstName && isFormChecked) {
        canContinue = true;
      } else {
        canContinue = false;
      }
    }

    return canContinue && !!removalTravellerList?.find((item: any) => item.isRemoved);
  };

  const backToViewDetails = () => {
    // back to view booking detail
    setIsOpenRemovePaxMode(false);
    // clear traveller list from context
    setRemovalTravellerList([]);
    setRemovalFormData({
      bookingReference: '',
      salutation: null,
      lastName: '',
      firstName: '',
      isFormChecked: false,
      hasTravelWithData: false,
    });
  };

  const refreshViewDetails = () => {
    // will refresh myBooking / homepage upcoming list
    // and reload current view detail page
    dispatch(
      setCancelBookingSuccessData({
        flightNumber: '',
        isShow: false,
        removePaxLabel: getLocalDate() + '',
      }),
    );
  };

  const resetTravellers = (booking: any) => {
    // from removal traveller list
    const clonedList = _.cloneDeep(removalTravellerList);
    // need to reset getTicketDetailsDataList data, don't use ticketDetailsDataList
    const paxTravellers = getTicketDetailsDataList({
      ticketsBySegment: booking?.ticketsBySegment || [],
      etpPassengerList: booking?.etpPassengerList || [],
      configurations,
    });
    const travellerListPax = _.cloneDeep(paxTravellers);

    for (let i = 0; i < travellerListPax.length; i++) {
      if (!travellerListPax?.includes(clonedList[i]?.traveler?.ticketNum)) {
        // if not first time to remove, will enter here
        if (travellerListPax[i].isAllowRemove) {
          travellerListPax[i].isRemoved = true;
        }
      }
      for (let j = 0; j < clonedList.length; j++) {
        if (travellerListPax[i].traveler.ticketNum === clonedList[j].traveler.ticketNum) {
          // set removed list to
          travellerListPax[i].isRemoved = clonedList[j].isRemoved;
        }
      }
    }

    setTravellerList(travellerListPax);
    setIsOpenRemovePaxMode(false);
    setIsOpenRemoveDialog(false);
    setRouteTo({
      step: 1,
    });

    setRemovalFormData({
      ...removalFormData,
      bookingReference: '',
      salutation: null,
      lastName: '',
      firstName: '',
      isFormChecked: false,
    });

    // show `removed traveller` snackbar
    dispatch(
      setSnackBarData({
        message: enRemovePax.removedTravellers,
        isShow: true,
      }),
    );
  };

  // [retrieve and pay] start CPP auth flow
  const handleRetrieveAndPayAuthFlow = async () => {
    dispatch(setPrepaidAuthFlowType(PREPAID_AUTH_FLOW_TYPE.RETRIEVE_AND_PAY));
    dispatch(setIsCalledPrepaidAuth(true)); // TODO remove later cause useless on retrieve and pay

    // record the booking applicationId for reload
    const resp = await retrieveAndPayAuthAction({
      param: {
        userId: ern,
        applicationId: bookingSummary?.applicationId || '',
      },
    });

    if (resp) {
      // retrivev and pay 0 amount: Because 'CPP' does not allow 0 amount, created booking
      if (resp.booking) {
        setIsRetrieveAndPayFlow(false);

        dispatch(setPrepaidAuthFlowType(PREPAID_AUTH_FLOW_TYPE.RETRIEVE_AND_PAY));

        dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.completed));

        dispatch(
          setRetrieveAndPaySuccessData({
            bookingResult: {
              booking: resp.booking,
              bookingEligibility: resp.bookingEligibility,
            },
            recLoc: resp.booking.recLoc,
          }),
        );

        navigate(BOOKING_PATHS.booking);
        return;
      }
      // save as redux

      // [ETP-3120] Retrieve and Pay - Happy flow
      // let the frontend to recognize the action
      dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.viewBooking)); // TBC this variable if need on new flow
      dispatch(setViewBookingApplicationId(bookingSummary?.applicationId || ''));

      dispatch(
        setRetrieveAndPaySuccessData({
          bookingResult: {
            booking: bookingSummary,
          },
          recLoc: bookingSummary?.recLoc,
        }),
      );

      const { tp_app_ref: tpAppRefFromResp } = resp || {};

      // save as redux data
      dispatch(setTpAppRef(tpAppRefFromResp));

      // delay 0.5s for process above redux data
      await new Promise((resolve) => setTimeout(() => resolve(null), 500));

      processPrepaidAuthResponse(resp);
    }
  };

  // [retrieve and pay] kick start re-pricing page
  const handleRetrieveAndPayRePricingFlow = async () => {
    setIsOpenMoreAction(false);
    // Confirm and pay api call here

    const { concession, ticketsBySegment, etpPassengerList } = bookingsForDisplay;
    const { tickets, flightNum, carrier, arrivalDate, bookingParentClass } =
      (ticketsBySegment && ticketsBySegment[0]) || {};

    const flightSectors = constructFlightSectors(bookingsForDisplay.ticketsBySegment[0], {
      flightNo: flightNum,
      marketingCompany: carrier,
      arrivalDate: arrivalDate,
      bookingClass: bookingParentClass,
    });
    const travellerInfo: any = [];

    for (let i = 0; i < tickets.length; i++) {
      const latestTicket: Pnr.Ticket = tickets[i].latestTicket || {};
      const { paxObj } = latestTicket || {};

      const matchedEtpPax = etpPassengerList.find((p: any) => p.dependentId === paxObj.dependentId);

      // prevent removed pax being added in `travellerInfo`
      if (!matchedEtpPax?.isRemoved) {
        travellerInfo.push({
          ...paxObj,
          ...(matchedEtpPax?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant && {
            attachedTo: matchedEtpPax.attachedTo,
          }),
        });
      }
    }

    const results = await getFareAndTravellerDetailsLT(ern, concession?.id || '', flightSectors, travellerInfo);
    if (results) {
      dispatch(
        setLeisureTravelDetails({
          ...leisureTravelDetails,
          fareDetail: results.fare,
          seatOption: bookingsForDisplay.ticketsBySegment[0].bookingParentClass,
        }),
      );
      setIsRetrieveAndPayFlow(true);
    }
  };

  const { cancelBooking: enCancelBooking, confirmation: enConfirmation } = en.booking;
  const enRemovePax = enConfirmation.removePax;

  useEffect(() => {
    // to handle drag browser size mobile <-> desktop
    if (removalTravellerList && removalTravellerList.length > 0) {
      if (removePaxModeRouteStep === 2 && !isOpenRemoveDialog) {
        setRouteTo({
          step: 1,
        });
        setIsOpenRemoveDialog(true);
      } else if (removePaxModeRouteStep === 1 && isOpenRemoveDialog) {
        setRouteTo({
          step: 2,
        });
        setIsOpenRemoveDialog(false);
      }
    }
  }, [isDesktop]);

  useEffect(() => {
    if (bookingSummary && !bookingsForDisplay) {
      // keep use context data when browser size change
      setBookingSummaryData(bookingSummary);
    }

    if (bookingSummary && bookingSummary.etpPassengerList) {
      // if have travelWith data, set to redux form data
      const clonedPassengerList = _.cloneDeep(bookingSummary.etpPassengerList);
      // find first pax with travelWith data
      const withTravelIndex = clonedPassengerList.findIndex((pax) => pax.travelWith);

      if (withTravelIndex !== -1 && clonedPassengerList[withTravelIndex]) {
        const travelWith = clonedPassengerList[withTravelIndex].travelWith;

        setRemovalFormData({
          bookingReference: travelWith.recLoc,
          salutation: { code: travelWith.title, label: travelWith.title },
          lastName: travelWith.lastName,
          firstName: travelWith.firstName,
          isFormChecked: true,
          hasTravelWithData: true,
        });
      }
    }
  }, [bookingSummary]);

  const ticketDetailsDataList = useMemo(() => {
    return getTicketDetailsDataList({
      ticketsBySegment: bookingsForDisplay?.ticketsBySegment || [],
      etpPassengerList: bookingsForDisplay?.etpPassengerList || [],
      configurations,
    });
  }, [bookingsForDisplay?.ticketsBySegment, bookingsForDisplay?.etpPassengerList]);

  // save data in context. Display data in Traveller details.
  useEffect(() => {
    if (travellerList && travellerList.length === 0) {
      // save traveller list as removalTravellerList
      const existTravellerList =
        ticketDetailsDataList?.filter((item: any) => !item.isRemoved && item.isAllowRemove) || [];
      const clonedList = _.cloneDeep(existTravellerList);

      setTravellerList(ticketDetailsDataList);
      setRemovalTravellerList(clonedList);
    }
  }, [ticketDetailsDataList]);

  useEffect(() => {
    // 26 Sep[ETP-3409] Special handling: Cancel booking always on the right if there is cancel button and there are 2 buttons
    // Get an array of values from bookingEligibility
    const values = _.values(bookingEligibility);

    // Count how many of these values are true
    const numTrueValues = _.filter(values, (value) => value === true).length;

    // Create an object literal to map actions to their properties
    const actionMappings: {
      readonly [BOOKING_FOOTER_ACTION.cancel]: bookingAction;
      readonly [BOOKING_FOOTER_ACTION.rebook]: bookingAction;
      readonly [BOOKING_FOOTER_ACTION.refund]: bookingAction;
      readonly [BOOKING_FOOTER_ACTION.remove]: bookingAction;
      readonly [BOOKING_FOOTER_ACTION.retrieveAndPay]: bookingAction;
      [key: string]: bookingAction;
    } = {
      [BOOKING_FOOTER_ACTION.cancel]: {
        icon: <CancelOnlyIcon />,
        title: numTrueValues > 2 ? enConfirmation.cancelActions.cancelOnly : enCancelBooking.title,
        handleClick: () => {
          setIsOpenMoreAction(false);
          dispatch(setIsShowCancelDialog(true));
        },
        ...actionBaseProperty,
      },
      [BOOKING_FOOTER_ACTION.retrieveAndPay]: {
        title: enConfirmation.retrieveAndPay,
        icon: <RetrieveAndPayIcon />,
        ...actionSeniorProperty,
        handleClick: handleRetrieveAndPayRePricingFlow,
        ...actionBaseProperty,
      },
      [BOOKING_FOOTER_ACTION.rebook]: {
        title: enConfirmation.cancelActions.rebook.title,
        icon: <CancelRebookIcon />,
        handleClick: () => {
          setBookingSummaryData(bookingSummary);
          setIsOpenMoreAction(false);
          setIsOpenRebookFlow(true);
        },
        ...actionBaseProperty,
      },
      // display "cancelAndRefund" button & hide cancel button if prepaid booking allow cancel and refund, otherwise display "refund". Will display more button(e.g rebook) in the future
      [BOOKING_FOOTER_ACTION.refund]: {
        title:
          bookingEligibility?.CANCEL_BOOKING && bookingEligibility?.REFUND
            ? enConfirmation.cancelActions.refund.cancelAndRefund
            : enConfirmation.cancelActions.refund.refundOnly,
        icon: <CancelRefundIcon />,
        handleClick: () => {
          setIsOpenFlownRefundDialog(true);
          setIsOpenMoreAction(false);
        },
        ...actionBaseProperty,
      },
      [BOOKING_FOOTER_ACTION.remove]: {
        title: enRemovePax.removeTraveller,
        icon: <RemoveTravellersIcon />,
        handleClick: () => {
          setIsOpenRemovePaxMode(true);
          const existTravellerList = travellerList?.filter((item: any) => !item.isRemoved && item.isAllowRemove) || [];
          const clonedList = _.cloneDeep(existTravellerList);
          setRemovalTravellerList(clonedList);
          setIsOpenMoreAction(false);
        },
        ...actionBaseProperty,
      },
    };

    let allowActionObjs: { [key: string]: boolean };

    if (numTrueValues === 2 && bookingEligibility?.CANCEL_BOOKING) {
      // Logic for exactly 2 true values with CANCEL_BOOKING
      allowActionObjs = {
        REBOOK: bookingEligibility?.REBOOK,
        REFUND: bookingEligibility?.REFUND,
        REMOVE_PAX: bookingEligibility?.REMOVE_PAX,
        RETRIEVE_AND_PAY: bookingEligibility?.RETRIEVE_AND_PAY,
        CANCEL_BOOKING: bookingEligibility?.CANCEL_BOOKING,
      };
      allowActionObjs = _.pickBy(allowActionObjs || {}, (value) => {
        return value;
      });
    } else {
      // Logic for other cases
      allowActionObjs = _.pickBy(bookingEligibility || {}, (value) => {
        return value;
      });
    }

    // Use the actionMappings to create actionObjs
    const actionObjs = _.keys(allowActionObjs)
      .filter((key) => bookingEligibility?.[key])
      .reduce((previousObj, currentKey) => {
        let tempAction = { ...previousObj };
        // display "cancelAndRefund" button & hide cancel button if prepaid booking allow cancel and refund, otherwise display "refund". Will display more button(e.g rebook) in the future
        if (
          !(
            currentKey === BOOKING_FOOTER_ACTION.cancel &&
            bookingEligibility?.CANCEL_BOOKING &&
            bookingEligibility?.REFUND
          )
        ) {
          tempAction = { ...previousObj, [currentKey]: { ...actionMappings[currentKey] } };
        }
        return tempAction;
      }, {});

    setBookingActionObjs(actionObjs);
  }, [travellerList, removalTravellerList, bookingEligibility]);

  // TODO: improve this useEffect
  useEffect(() => {
    setFooterActionList(() => {
      let actions = null;
      if (isOpenRemovePaxMode) {
        actions = {
          cancel: {
            ...actionBaseProperty,
            ...(removePaxModeRouteStep === 1
              ? {
                  title: en.common.cancel,
                  handleClick: () => {
                    backToViewDetails();
                  },
                }
              : {
                  title: en.common.no,
                  handleClick: () => {
                    setRouteTo({
                      step: 1,
                    });
                  },
                }),
          },
          ...(isShowContinueRemovePaxBtn() && {
            continue: {
              ...actionBaseProperty,
              ...(removePaxModeRouteStep === 1
                ? {
                    ...actionSeniorProperty,
                    title: en.common.continue,
                    handleClick: async () => {
                      if (isDesktop) {
                        if (checkedInTravellerList.length > 0) {
                          // if has pax already check-in
                          setIsOpenRemoveDialog(true);
                        } else if (selectedAllTravellerList) {
                          // if select all pax, will cancel booking
                          setIsOpenRemoveToCancelDialog(true);
                        } else if (remainingPaxCanProceed) {
                          // open cancel popup page
                          setIsOpenRemoveDialog(true);
                        } else {
                          // not enough adult
                          setIsOpenConfirmToCancelDialog(true);
                        }
                      } else if (removePaxModeRouteStep === 1) {
                        // mobile action
                        if (checkedInTravellerList.length > 0) {
                          // if has pax already check-in
                          setRouteTo({ step: 2 });
                        } else if (selectedAllTravellerList) {
                          // if select all pax, will cancel booking
                          setIsOpenRemoveToCancelDialog(true);
                        } else if (remainingPaxCanProceed) {
                          // to next step
                          setRouteTo({ step: 2 });
                        } else {
                          // not enough adult
                          setIsOpenConfirmToCancelDialog(true);
                        }
                      } else {
                        // handle remove pax api call action
                        handleRemovePaxAction();
                      }
                    },
                  }
                : {
                    ...actionSeniorProperty,
                    title: enRemovePax.confirmRemovePaxDialog.yes,
                    handleClick: async () => {
                      handleRemovePaxAction();
                    },
                  }),
            },
          }),
        };
      } else if (isRetrieveAndPayFlow) {
        // retrieve and pay button handle
        actions = {
          cancel: {
            ...actionBaseProperty,
            title: en.common.cancel,
            handleClick: () => {
              setIsRetrieveAndPayFlow(false);
            },
          },
          continue: {
            title: en.common.confirm,
            handleClick: handleRetrieveAndPayAuthFlow,
          },
        };
      } else {
        actions =
          _.keys(bookingActionObjs).length > 2
            ? {
                moreAction: {
                  ...actionBaseProperty,
                  title: enConfirmation.cancelActions.moreActions,
                  handleClick: () => {
                    setIsOpenMoreAction(true);
                  },
                },
              }
            : _.clone(bookingActionObjs);
      }

      return actions;
    });
  }, [
    bookingActionObjs,
    isOpenRemovePaxMode,
    removePaxModeRouteStep,
    selectedAllTravellerList,
    isRetrieveAndPayFlow,
    removalFormData,
  ]);

  const dismissCallBackFunc = () => {
    // when click dismiss button
    setRouteTo({
      step: 1,
    });

    backToViewDetails();
    refreshViewDetails();
  };

  const handleRemovePaxAction = async () => {
    const travelWith =
      !removalFormData?.hasTravelWithData && (isOnlyOneInfantLeft || isOnlyYoungChildLeft)
        ? {
            firstName: removalFormData?.firstName,
            lastName: removalFormData?.lastName,
            recLoc: removalFormData?.bookingReference,
            title: removalFormData?.salutation.label,
          }
        : undefined;

    const forRemovePaxInfo = removalTravellerList?.filter((item: any) => item.isRemoved);

    const travellerInfo = forRemovePaxInfo.map((pax: any) => {
      const { beneficiaryTypeCode, dependentId, firstName, lastName, title } = pax.traveler || {};

      return {
        beneficiaryTypeCode,
        dependentId,
        firstName,
        lastName,
        title,
      };
    });

    const result = await removePassengerLT(ern, applicationId || '', travellerInfo, travelWith, dismissCallBackFunc);

    if (result) {
      const { booking } = result || {};

      resetTravellers(booking);
      getBookingAction(result);
    }
  };

  return (
    <>
      {bookingsForDisplay && (
        <Box
          className="booking_summary"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            component={ScrollableView}
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              ...(isDesktop
                ? {
                    width: '100%',
                    pt: 5,
                    px: 0,
                    mx: 'auto',
                  }
                : {
                    mt: -7.5,
                    px: 2,
                  }),
            }}
          >
            {isDesktop &&
              (isOpenRemovePaxMode ? (
                <Box
                  sx={{
                    mt: -5,
                    height: UI_STYLES.overlayHeaderHeightSearchCriteria,
                  }}
                >
                  <DesktopBreadcrumb step={removePaxModeRouteStep} customCrumbs={enRemovePax.breadcrumbs} />
                </Box>
              ) : (
                <DesktopHeaderContainer
                  bookingRecLoc={isOWOALFlight ? en.common.empty : bookingRecLoc}
                  handleClose={() => {
                    setTravellerList([]);
                    setRemovalTravellerList([]);

                    handleCloseClick?.();
                  }}
                />
              ))}
            {removePaxModeRouteStep === 2 ? (
              <Box sx={{ flex: 1 }}>
                <ConfirmRemoveContainer removalTravellerList={removalTravellerList} />
              </Box>
            ) : (
              <BookingSummaryContent
                bookingSummary={bookingsForDisplay}
                isLevelZUser={isLevelZUser || false}
                configurations={configurations}
                isCheckedIn={isCheckedIn}
                isTicketExpired={!!isTicketExpired}
                isAmadeusError={!!refreshBookingError}
                isOpenRemovePaxMode={isOpenRemovePaxMode}
                isRetrieveAndPayStatus={isRetrieveAndPayStatus}
              />
            )}
          </Box>
          {!_.isEmpty(footerActionList) && (
            <MoreActionContainer
              footerActionList={footerActionList}
              customStyles={
                isDesktop
                  ? {
                      px: 24.375,
                      boxShadow: theme.palette.boxShadow.dark,
                      borderRadius: '24px 24px 0px 0px',
                      height: UI_STYLES.desktopFooterHeight,
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }
                  : { p: 2 }
              }
              customButtonStyles={{
                marginLeft: '16px',
                '&:first-of-type': { marginLeft: 0 },
                ...(isDesktop ? { width: '198px' } : { flex: 1 }),
                padding: '7px',
              }}
            />
          )}
        </Box>
      )}

      {/* desktop - open remove dialog */}
      <CancelDialog
        open={isOpenRemoveDialog}
        showCancelIcon={false}
        handleConfirmAction={async () => {
          handleRemovePaxAction();
        }}
        handleCloseDialog={() => {
          setIsOpenRemoveDialog(false);
        }}
        dialogContent={{
          title: enRemovePax.breadcrumbs.removeTraveller,
          message: <ConfirmRemoveContainer removalTravellerList={removalTravellerList} />,
          yesTitle: enRemovePax.confirmRemovePaxDialog.yes,
          noTitle: en.common.no,
        }}
      />

      {/* open confirm to cancel dialog */}
      <CancelDialog
        open={isOpenRemoveToCancelDialog}
        IconComponent={<AlertIcon />}
        handleConfirmAction={() => {
          dispatch(setIsShowCancelDialog(true));
        }}
        handleCloseDialog={() => {
          setIsOpenRemoveToCancelDialog(false);
        }}
        dialogContent={{
          title: enCancelBooking.title,
          message: enRemovePax.removeAllPassengers,
          yesTitle: en.common.yesConfirm,
          noTitle: en.common.no,
        }}
      />

      {/* open confirm to cancel dialog -- not enough adult */}
      <CancelDialog
        open={isOpenConfirmToCancelDialog}
        IconComponent={<AlertIcon />}
        handleConfirmAction={() => {
          dispatch(setIsShowCancelDialog(true));
        }}
        handleCloseDialog={() => {
          setIsOpenConfirmToCancelDialog(false);
        }}
        dialogContent={{
          title: enCancelBooking.title,
          message: enRemovePax.removeAdultPassengers,
          yesTitle: en.common.yes,
          noTitle: en.common.no,
        }}
      />

      {
        // isBooked &&
        isOpenMoreAction && isDesktop ? (
          <Modal open={isOpenMoreAction}>
            <MoreActionsPopover
              key={GOOGLE_ANALYTICS_EVENTS.action.desktop}
              moreActionsObjs={bookingActionObjs}
              handleClose={() => {
                setIsOpenMoreAction(false);
              }}
              customStyles={{
                width: '478px',
                padding: '29px 32px 32px',
                mt: 10,
                mx: 'auto',
                borderRadius: 2,
                boxShadow: theme.boxShadow.important,
              }}
            />
          </Modal>
        ) : (
          <SwipeableDrawer
            open={isOpenMoreAction}
            onClose={() => {}}
            onOpen={() => {}}
            disableSwipeToOpen={false}
            anchor="bottom"
            sx={{
              '&.MuiDrawer-root': {
                '& .MuiDrawer-paper': {
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                },
              },
            }}
          >
            <MoreActionsPopover
              key={GOOGLE_ANALYTICS_EVENTS.action.mobile}
              moreActionsObjs={bookingActionObjs}
              handleClose={() => {
                setIsOpenMoreAction(false);
              }}
              customStyles={{
                padding: '16px 16px 29px',
              }}
            />
          </SwipeableDrawer>
        )
      }
    </>
  );
};

BookingSummary.defaultProps = {
  applicationId: '',
  isFromCompleted: false,
};

export default BookingSummary;
