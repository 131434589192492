import React, { useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { DATE_FORMAT, DATE_UNIT, SAVE_DATA_TO, TRAVEL_PURPOSE, USER_ROLE } from '../../../../constants/constants';
import { BOOKING_PATHS } from '../../../../constants/paths';
import en from '../../../../translations/en';

import {
  calculateLTMaxDepartureDate,
  formatDateAsString,
  formatStringToDate,
  getDepartureMaxDateIncludeBlockedDateByJenkins,
  getLocalDateAdding,
} from '../../../../helpers';

import {
  EditSearchBarState,
  EditSearchBarAction,
  BookingSummaryAction,
  BookingSummaryState,
} from '../../../../context';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setStartDate, selectBooking } from '../../../../slice/bookingSlice';
import { selectUser } from '../../../../slice/userSlice';
import { selectApp } from '../../../../slice/appSlice';

import { Footer, DateSinglePicker } from '../../../../components';

const DateSelection = ({
  handleFooterClick,
  saveTo = SAVE_DATA_TO.redux,
  isFromRebookFlow,
  isEditPopup,
}: {
  handleFooterClick?: () => void;
  saveTo?: string;
  isFromRebookFlow?: boolean;
  isEditPopup?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const isEditSearch = saveTo === SAVE_DATA_TO.context || false;

  const editSearchData = useContext(EditSearchBarState) || {};
  const { setEditStartDate } = useContext(EditSearchBarAction) || {};
  const { originalFlightDate, rebookFlightDate } = useContext(BookingSummaryState) || {};
  const { setOriginalFlightDate, setRebookFlightDate } = useContext(BookingSummaryAction) || {};

  const { startDate, travelDetails, travelType } = isEditSearch ? editSearchData : useAppSelector(selectBooking) || {};

  const [currentStartDate, setCurrentStartDate] = useState<string>(
    isFromRebookFlow && !isEditPopup ? originalFlightDate : isFromRebookFlow ? rebookFlightDate : startDate,
  );

  const { role, profile } = useAppSelector(selectUser) || {};
  const { type: roleType } = role || {};
  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  const isLT = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;

  const screenHeight = use100vh();

  const maxDate = useMemo(() => {
    const advancedDepartureDate = getLocalDateAdding(
      travelDetails.advanceDay,
      travelDetails?.advanceDayUnit || DATE_UNIT.DYS,
    );
    // etp-5972 add: calc lt can select max date
    const departureDate = isLT
      ? new Date(calculateLTMaxDepartureDate(profile, advancedDepartureDate))
      : advancedDepartureDate;

    return getDepartureMaxDateIncludeBlockedDateByJenkins(departureDate).maxDate;
  }, [travelDetails.advanceDay, travelDetails.advanceDayUnit]);

  const saveStartDate = () => {
    if (isDesktop && !isFromRebookFlow) {
      dispatch(setStartDate(currentStartDate));
    } else {
      if (isEditSearch) {
        setEditStartDate(currentStartDate);
        if (isFromRebookFlow) {
          if (isEditPopup) {
            setRebookFlightDate(currentStartDate);
          } else {
            setOriginalFlightDate(currentStartDate);
          }
        }
      } else {
        dispatch(setStartDate(currentStartDate));
      }
    }
    handleFooterClick?.();
  };

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          bgcolor: '#FDFDFD',
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
        }}
      >
        <DateSinglePicker
          isDesktop={false}
          defaultDate={new Date(formatStringToDate(currentStartDate, DATE_FORMAT.date))}
          onChange={(item: Date) => {
            setCurrentStartDate(formatDateAsString(item, DATE_FORMAT.date));
          }}
          minDate={getLocalDateAdding(-1)}
          maxDate={maxDate}
          calendarHeight={screenHeight ? screenHeight - (isAdminDelegation ? 301 : 213) : 0}
        />
      </Box>

      <Footer
        primaryBtn={{
          isFullWidth: true,
          text: en.common.continue,
          route: BOOKING_PATHS.travelTypeSelection,
          customOnClick: saveStartDate,
        }}
      />
    </Box>
  );
};

DateSelection.defaultProps = {
  isFromRebookFlow: false,
};

export default DateSelection;
