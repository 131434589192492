import { useRef, useState } from 'react';
import { Box, Typography, useTheme, SxProps, Theme } from '@mui/material';
import { SystemCssProperties } from '@mui/system';

import {
  UI_STYLES,
  NOT_APPLICABLE,
  ADMIN_ASSO_SUBSID_UPLOAD_STYLE,
  ADMIN_ASSO_SUBSID_STATUS,
} from '../../constants/constants';
import en from '../../translations/en';
import { CloseWithCircleBlackIcon, SuccessLoadingIcon, UploadFileIcon, CrossFillSmallIcon } from '../../assets/images';

import { fileToBase64, formatFileSize } from '../../helpers/file';
import { bulletPointStyle } from '../../helpers/message';

import { uploadAssSubiProfile } from '../../services/admin/user';

import { FormButton, CancelDialog } from '../../components';

const {
  title: pageTitle,
  tableHeaderName: tableHeaderNameLbl,
  tableHeaderFileSize: tableHeaderFileSizeLbl,
  uploadFile: uploadFileLbl,
  uploadButton: uploadButtonLbl,
  chooseFileButton: chooseFileButtonLbl,
  uploadSuccessful: uploadSuccessfulMessage,
  uploadFailed: uploadFailedMessage,
  acceptedFileFormat: acceptedFileFormatLbl,
  cancelRemoveDialogTitle,
  cancelRemoveDialogMessage,
} = en.admin.feature.uploadAssociateSubsidiaryEmployeeProfile;

const AdminUploadAssoSubsidEmployeeProfile = () => {
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileSelectInputKey, setFileSelectInputKey] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [errorResponseTxt, setErrorResponseTxt] = useState('');
  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const buttonMarginTop = selectedFile ? '16px' : 0;
  const uploadButtonColor = selectedFile ? ADMIN_ASSO_SUBSID_UPLOAD_STYLE.BLUE : ADMIN_ASSO_SUBSID_UPLOAD_STYLE.GREY;
  const chooseButtonColor = selectedFile
    ? ADMIN_ASSO_SUBSID_UPLOAD_STYLE.WHITE
    : ADMIN_ASSO_SUBSID_UPLOAD_STYLE.TRANSPARENT;
  const uploadButtonCursor = selectedFile
    ? ADMIN_ASSO_SUBSID_UPLOAD_STYLE.POINTER
    : ADMIN_ASSO_SUBSID_UPLOAD_STYLE.DEFAULT;
  const chooseButtonCursor = selectedFile
    ? ADMIN_ASSO_SUBSID_UPLOAD_STYLE.DEFAULT
    : ADMIN_ASSO_SUBSID_UPLOAD_STYLE.POINTER;
  const customStyles: SxProps<Theme> = {
    background: theme.color.utility.error.option_3,
  };
  const customBulletPointStyle = bulletPointStyle(customStyles);

  const buttonStyles = {
    borderStyle: 'solid',
    borderWidth: '2px',
    width: '120px',
    height: '50px',
    fontSize: '14px',
    lineHeight: '18px',
    borderRadius: '8px',
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file ?? null);
    setFileSelectInputKey((prevKey) => prevKey + 1);
  };

  const handleChooseFileClick = () => {
    if (fileInputRef.current && !selectedFile) {
      fileInputRef.current.click();
      setUploadStatus('');
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setUploadStatus('');
  };

  const handleUpload = async () => {
    const fileInputBase64 = await fileToBase64(selectedFile);
    const result = await uploadAssSubiProfile(fileInputBase64, (selectedFile as File).name);
    if (result && result.message && result.message.toLowerCase() === ADMIN_ASSO_SUBSID_STATUS.SUCCESS) {
      setUploadStatus(ADMIN_ASSO_SUBSID_STATUS.SUCCESS);
      setSelectedFile(null);
    } else {
      setUploadStatus(ADMIN_ASSO_SUBSID_STATUS.FAILED);
      if (result.detail) {
        setErrorResponseTxt(result.detail);
        setSelectedFile(null);
      }
    }
  };

  const handleUploadClick = async () => {
    if (selectedFile) {
      handleUpload();
    }
  };

  return (
    <Box className="admin_upload_asso_subsid_box" sx={{ ml: 22 }}>
      <Typography
        className="upload_profile_title"
        variant="large_title_1_bold"
        sx={{ width: UI_STYLES.adminMaintainWidth, marginTop: '32px', textAlign: 'left' }}
      >
        {pageTitle}
      </Typography>
      <Box
        className="upload_file_box"
        sx={{
          width: UI_STYLES.uploadFileBoxWidth,
          borderRadius: 1,
          marginTop: '40px',
          border: `1px solid ${theme.color.etp.option_5}`,
        }}
      >
        <Typography
          className="upload_file_title"
          variant="title_2_medium"
          sx={{ padding: '24px', pb: '0px', pt: '28px' }}
          color={theme.color.secondary.dark_grey.option_1}
        >
          {uploadFileLbl}
        </Typography>
        <Box className="table_button_box" sx={{ padding: '24px' }}>
          {selectedFile && (
            <Box
              className="upload_table_box"
              style={{
                width: UI_STYLES.uploadFileTableBoxWidth,
                border: `1px solid ${theme.color.etp.option_5}`,
                overflow: 'hidden',
                minHeight: '92px',
              }}
            >
              <Box
                className="upload_table_header"
                style={{
                  display: 'flex',
                  minHeight: '36px',
                  borderBottom: `1px solid ${theme.color.secondary.dark_grey.option_10}`,
                }}
              >
                <Box sx={{ width: '64px' }}></Box>
                <Box sx={{ width: UI_STYLES.uploadFileTableHeaderWidth, padding: '8px', pl: '12px' }}>
                  <Typography variant="body_1_medium">{tableHeaderNameLbl}</Typography>
                </Box>
                <Box sx={{ width: '100px', padding: '8px', pl: '12px' }}>
                  <Typography variant="body_1_medium">{tableHeaderFileSizeLbl}</Typography>
                </Box>
                <Box sx={{ width: '60px' }}></Box>
              </Box>
              <Box className="upload_table_content" style={{ display: 'flex', minHeight: '56px' }}>
                <Box sx={{ width: '64px', padding: '8px', pl: '12px' }}>
                  <UploadFileIcon />
                </Box>
                <Box sx={{ width: '846px', padding: '18px', pl: '12px', pr: '12px' }}>
                  <Typography
                    variant="body_1_regular"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: UI_STYLES.uploadFileNameMaxWidth,
                      display: 'inline-block',
                    }}
                  >
                    {selectedFile.name}
                  </Typography>
                </Box>
                <Box sx={{ width: '100px', padding: '18px', pl: '12px', pr: '12px' }}>
                  <Typography variant="body_1_regular">
                    {selectedFile.size ? formatFileSize(selectedFile.size) : NOT_APPLICABLE}
                  </Typography>
                </Box>
                <Box sx={{ width: '60px', pb: '10px', pl: '12px', pr: '12px', pt: '20px' }}>
                  <CloseWithCircleBlackIcon
                    onClick={() => {
                      setIsShowConfirmPopup(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <input
            className="upload_file_selector"
            key={fileSelectInputKey}
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            multiple={false}
            accept=".csv"
            onChange={handleFileChange}
          />
          <Box className="button_box" sx={{ marginTop: buttonMarginTop }}>
            <FormButton
              className="choose_file_button"
              style={{ cursor: chooseButtonCursor }}
              sx={{
                ...buttonStyles,
                mr: '16px',
                '& > *': {
                  padding: 0,
                },
              }}
              colour={chooseButtonColor}
              theme={theme}
              onClick={handleChooseFileClick}
            >
              {chooseFileButtonLbl}
            </FormButton>

            <FormButton
              className="upload_file_button"
              style={{ cursor: uploadButtonCursor }}
              sx={{
                ...buttonStyles,
              }}
              colour={uploadButtonColor}
              theme={theme}
              onClick={handleUploadClick}
            >
              {uploadButtonLbl}
            </FormButton>
            <Typography
              className="accept_file_text"
              variant="navigation_regular"
              sx={{
                marginTop: '4px',
                color: theme.color.secondary.dark_grey.option_9,
              }}
            >
              {acceptedFileFormatLbl}
            </Typography>
            {uploadStatus === ADMIN_ASSO_SUBSID_STATUS.SUCCESS && (
              <Box
                className="success_status_box"
                display="flex"
                alignItems="center"
                sx={{ padding: '16px', pl: '0px' }}
              >
                <SuccessLoadingIcon></SuccessLoadingIcon>
                <Typography variant="body_1_regular" sx={{ color: theme.color.primary.medium_jade.option_3 }}>
                  {uploadSuccessfulMessage}
                </Typography>
              </Box>
            )}

            {uploadStatus === ADMIN_ASSO_SUBSID_STATUS.FAILED && (
              <Box className="failed_status_box" sx={{ mt: '16px' }}>
                <Box display="flex" alignItems="center">
                  <CrossFillSmallIcon></CrossFillSmallIcon>
                  <Typography variant="body_1_regular" sx={{ color: theme.color.utility.error.option_3 }}>
                    {uploadFailedMessage}
                  </Typography>
                </Box>
                <Typography
                  className="error_response_text"
                  variant="body_1_medium"
                  sx={{
                    color: theme.color.utility.error.option_3,
                    ml: '34px',
                    mt: '8px',
                    '&::before': customBulletPointStyle as SystemCssProperties,
                  }}
                >
                  {errorResponseTxt}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <CancelDialog
        open={isShowConfirmPopup}
        handleConfirmAction={handleFileRemove}
        handleCloseDialog={() => {
          setIsShowConfirmPopup(false);
        }}
        showCancelIcon={false}
        dialogContent={{
          title: cancelRemoveDialogTitle,
          message: cancelRemoveDialogMessage,
          noTitle: en.common.cancel,
          yesTitle: en.common.yesConfirm,
        }}
      />
    </Box>
  );
};

export default AdminUploadAssoSubsidEmployeeProfile;
