import React, { useEffect, useState, useRef, useLayoutEffect, useContext, useMemo } from 'react';
import { Box, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import ReactGA from 'react-ga4';

import en from '../../../translations/en';
import theme from '../../../style/theme';
import {
  TRAVEL_PURPOSE,
  USER_ROLE,
  GOOGLE_ANALYTICS_EVENTS,
  SAVE_DATA_TO,
  DATE_FORMAT,
  UI_STYLES,
  PAYMENT_MEDIUM,
  HKG,
  DATE_UNIT,
  PAYMENT_SETUP_STATUS,
  TRAVEL_TYPES,
  ID_TICKET_TYPE,
} from '../../../constants/constants';
import { BOOKING_PATHS, ROOT_PATHS } from '../../../constants/paths';

import { SearchIcon } from '../../../assets/images';

import {
  formatDateAsString,
  formatStringToDate,
  getLocalDateAdding,
  getNonEnglishCharacterTravellers,
  isLtZoneType,
  getConcessionSelectionValue,
  isEmptyOrUndefined,
  handlePaymentAndFlownSuspensionMessageAction,
  getDepartureMaxDateIncludeBlockedDateByJenkins,
  isAssoSubsid,
  calculateLTMaxDepartureDate,
} from '../../../helpers';

import { IWarningArray } from '../../../interfaces';

import { usePaymentMethodIndicator, useEmailRegisterIndicator } from '../../../hooks';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  selectBooking,
  setStartDate,
  setDetail,
  setDestination,
  setTravellerInfo,
  setOrigin,
  setOutwardFlight,
  setLeisureTravelDetails,
  setType,
  setIsRefresh,
  setBookingStep,
  setIsCalledPrepaidAuth,
  setRetrieveAndPayCppParams,
  setPrepaidAuthFlowType,
  setViewBookingApplicationId,
  setTpAppRef,
} from '../../../slice/bookingSlice';
import {
  selectApp,
  setSearchBarInputWidth,
  setRetrieveAndPaySuccessData,
  setNormalPrepaidSuccessData,
} from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { setIsOpenBillingAgreementDialog } from '../../../slice/paypalSlice';
import {
  EditSearchBarState,
  EditSearchBarAction,
  TravelTypeListLtState,
  BookingSummaryState,
  BookingSummaryAction,
  CountryOfResidenceAction,
} from '../../../context';

import { DateSinglePicker, FormPopover, CommonWarning, ReduxFormTextInput } from '../../../components';
import {
  AdminRoleConcession,
  LocationSelection,
  ConcessionAndTraveler,
  AdminRoleAddTraveller,
  ConcessionSelectionContainer,
} from '../../../containers';
import { TravelTypeWithIcon, LeisureTravellerCount } from '../..';

const SearchBar = ({
  isResultPage,
  barStyle,
  curShowPop,
  setCurShowPop,
  performShowPopup,
  isFromRebookFlow,
  isRebookFromMyBooking,
}: // empOrNonEmpType,
{
  isResultPage: boolean;
  barStyle?: any;
  curShowPop?: any;
  setCurShowPop?: any;
  performShowPopup?: boolean;
  // etp-2976 add
  isFromRebookFlow?: boolean;
  isRebookFromMyBooking?: boolean;
  // empOrNonEmpType?: string;
}) => {
  const popUpRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const screenHeight = use100vh() || 0;
  const navigate = useNavigate();
  const location = useLocation();

  const editSearchData = useContext(EditSearchBarState) || {};
  const {
    setEditOrigin,
    setEditDestination,
    setEditStartDate,
    setEditIsRefresh,
    setEditTravelDetails,
    setEditTravelType,
    setEditLeisureTravelDetails,
    setEditTravellerDetails,
  } = useContext(EditSearchBarAction) || {};
  const { isShowDefaultTravelTypeOption } = useContext(TravelTypeListLtState) || {};

  const { rebookFlightDate } = useContext(BookingSummaryState) || {};
  const { setRebookFlightDate } = useContext(BookingSummaryAction) || {};

  const { setOpen: setOpenCountryOfResidenceDialog } = useContext(CountryOfResidenceAction) || {};

  const isSearchResultPage = location.pathname === BOOKING_PATHS.booking;

  const { searchBarInputWidth, isDesktop } = useAppSelector(selectApp) || {};
  const {
    origin,
    destination,
    startDate,
    isRefresh,
    travelDetails,
    travellerDetails,
    travelType,
    leisureTravelDetails,
  } = isSearchResultPage ? editSearchData : useAppSelector(selectBooking) || {};
  const { adultCount, childrenCount, type: ltType, sub: ltSub } = leisureTravelDetails || {};
  const { concession } = useAppSelector(selectUser) || {};
  const { role, profile } = useAppSelector(selectUser) || {};

  const [routeStep, setRouteStep] = useState(0);
  const [adminConcessionEmpOrNonEmpType, setAdminConcessionEmpOrNonEmpType] = useState(
    travelDetails.empOrNonEmpType || '',
  );
  const [isExpand, setIsExpand] = useState(false);
  const [warningArray, setWarningArray] = useState<IWarningArray[]>([]);

  const homePageStyle =
    useLocation().pathname === ROOT_PATHS.landing ? { mt: -6.5, mb: 0, background: 'white', pb: 0.5 } : {};
  const isDT = travelType === TRAVEL_PURPOSE.employeeDutyTravel;
  const isLT = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  const travelTypeTag = isDT
    ? en.booking.travelType.dutyTravel
    : isLT
    ? en.booking.travelType.leisureTravel.title
    : en.booking.travelType.other;

  const calcInfantsCount = leisureTravelDetails.allowSeatForInfant ? leisureTravelDetails.infantsCount : 0;
  const totalTravellerCount = (adultCount || 0) + (childrenCount || 0) + calcInfantsCount;
  const regulatoryRegion = profile?.regulatoryRegion;
  const isAdminRole = role.type === USER_ROLE.admin;
  const concessionSelectionValue = useMemo(() => {
    return getConcessionSelectionValue({
      isSearchResultPage,
      isLT,
      isDT,
      travelDetails,
      leisureTravelDetails,
      isShowDefaultTravelTypeOption,
    });
  }, [isLT, isDT, travelDetails, leisureTravelDetails, isShowDefaultTravelTypeOption]);

  // non-standby type means not `Zone` + not `SUBLO FOC` concession
  const isSubloadFocConcession = ltSub === ID_TICKET_TYPE.SUBLO && ltType === TRAVEL_TYPES.FOC;
  const isLtNonStandbyType = !isLtZoneType(ltType) && !isSubloadFocConcession && !isEmptyOrUndefined(ltType);

  const isSelectedOD = origin && destination;
  const isSelectedConcession =
    (isDT && travelDetails?.title) ||
    (isLT && (totalTravellerCount > 0 || !isLtNonStandbyType) && concessionSelectionValue);

  const isValid =
    isSelectedOD &&
    startDate &&
    isSelectedConcession &&
    // if isZone, 0 traveller can pass
    // TBC: if nonZone, traveller need to > 0
    (isAdminRole ? travellerDetails?.travellerInfo?.employee || travellerDetails?.travellerInfo?.salutation : true);

  const paxList = (concession?.paxList || []) as any;
  const isSelfRole = role.type === USER_ROLE.self;
  // handle delegation role
  const isDelegationRole = role.type === USER_ROLE.delegation;

  // for non english char label
  const nonEnglishCharHKGLbl = en.booking.nonEnglishCharacter.hkg;
  const nonEnglishCharNonHKGLbl = en.booking.nonEnglishCharacter.notHkg;

  const maxDate = useMemo(() => {
    const advancedDepartureDate = getLocalDateAdding(
      travelDetails.advanceDay,
      travelDetails?.advanceDayUnit || DATE_UNIT.DYS,
    );

    // etp-5972 add: calc lt can select max date
    const departureDate = isLT
      ? new Date(calculateLTMaxDepartureDate(profile, advancedDepartureDate))
      : advancedDepartureDate;

    return getDepartureMaxDateIncludeBlockedDateByJenkins(departureDate).maxDate;
  }, [travelDetails.advanceDay, travelDetails.advanceDayUnit, profile, isLT]);

  const updateSearchBarInputWidth = () => {
    const originInput = document.getElementById('origin-input');
    if (originInput) {
      dispatch(setSearchBarInputWidth(originInput.offsetWidth));
    }
  };

  useEffect(() => {
    setRouteStep(0);
  }, [curShowPop]);

  useEffect(() => {
    popUpRef.current?.click();
  }, [performShowPopup]);

  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  //Personal email indicator
  const [emailRegisterIndicator] = useEmailRegisterIndicator();

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSearchBarInputWidth);

    return () => window.removeEventListener('resize', updateSearchBarInputWidth);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updateSearchBarInputWidth();
    }, 500);
  }, [screenHeight, isDesktop]);

  useEffect(() => {
    const isShowNonEnglishCharacter = !!getNonEnglishCharacterTravellers(paxList, isDelegationRole ? true : false);

    let replaceTarget = en.payment.replaceTarget;
    const myCasesLinkPrefix = en.payment.directDebit.myCasesLink;
    if (paymentMethodIndicator?.isSuspensionStatus) {
      if (isAssoSubsid(profile)) {
        replaceTarget = ''; // ETP-5154
      } else {
        replaceTarget = en.payment.link; // ETP-2431
      }
    } else if (!paymentMethodIndicator.isDirectDebitSetupSuspended) replaceTarget = en.payment.replaceTarget;
    else replaceTarget = '';

    // [ETP-4266]
    if (paymentMethodIndicator?.paymentSetUpStatus === PAYMENT_SETUP_STATUS.UNABLE_RETRIEVE) {
      replaceTarget = '';
    }

    // [ETP-4526] suspended message
    if (paymentMethodIndicator?.paymentSetUpStatus === PAYMENT_SETUP_STATUS.SUSPENDED) {
      replaceTarget = myCasesLinkPrefix;
    }

    let array = [
      {
        id: 0,
        msgText: paymentMethodIndicator?.paymentSetUpWarningMessage,
        // handle `isPrepaid` & `isDirectDebitSetupSuspended` cases
        replaceTarget,
        actionFunc: () => {
          if (paymentMethodIndicator.isRetireeWidowRole) {
            if (
              profile &&
              (!profile.retireeWidow?.countryOfResidential ||
                (profile.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT && !profile.isPayPalBillingAgreementSetup))
            ) {
              setOpenCountryOfResidenceDialog(true);
            } else if (profile && !profile.isPayPalBillingAgreementSetup) {
              dispatch(setIsOpenBillingAgreementDialog(true));
            }
          } else {
            // Paypal flow - open billing agreement dialog
            if (paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT) {
              dispatch(setIsOpenBillingAgreementDialog(true));
            } else {
              handlePaymentAndFlownSuspensionMessageAction({
                paymentMethodIndicator,
              });
            }
          }
        },
      },
      {
        id: 1,
        msgText: isDelegationRole
          ? en.booking.nonEnglishCharacter.message
          : regulatoryRegion === HKG
          ? nonEnglishCharHKGLbl
          : nonEnglishCharNonHKGLbl,
      },
      {
        id: 2,
        msgText: emailRegisterIndicator?.emailRegisterMessage,
      },
    ];
    if (
      !(
        isSelfRole &&
        !isSearchResultPage &&
        paymentMethodIndicator?.isShowWarning &&
        paymentMethodIndicator?.paymentSetUpWarningMessage
      ) ||
      isAdminRole
    ) {
      array = array?.filter((item: IWarningArray) => item.id != 0);
    }

    // hide msg for admin role
    if (!isShowNonEnglishCharacter || isAdminRole) {
      array = array?.filter((item: IWarningArray) => item.id != 1);
    }
    if (!emailRegisterIndicator?.isShowWarning || !emailRegisterIndicator?.emailRegisterMessage) {
      array = array?.filter((item: IWarningArray) => item.id != 2);
    }
    setWarningArray(array);
  }, [paymentMethodIndicator, concession, role.type]);

  return (
    <Box
      sx={{
        height: 'auto',
        flexDirection: 'column',
        ...barStyle,
        ...homePageStyle,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {!isResultPage && (
          <Box
            component={Typography}
            variant="headline_medium"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 2,
              height: '24px',
              color: theme.color.secondary.dark_grey.option_2,
            }}
          >
            {en.home.yourTravelExperienceStartsHere}
          </Box>
        )}

        {/* TODO:// will supplement logic later */}
        {!!travelType &&
          (!!travelDetails?.title || !!leisureTravelDetails?.type) &&
          isShowDefaultTravelTypeOption &&
          !isResultPage && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1.5,
                position: 'absolute',
                right: '24px',
              }}
            >
              <TravelTypeWithIcon travelType={travelTypeTag} />
            </Box>
          )}
      </Box>

      {!isSearchResultPage &&
        !isFromRebookFlow &&
        (warningArray?.length > 1 ? (
          <Collapse in={isExpand} collapsedSize={`${18 * 2}px`}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CommonWarning
                isShowIcon={true}
                msgText={
                  en.booking.nonEnglishCharacter.defaultFirstMsg +
                  warningArray.length +
                  en.booking.nonEnglishCharacter.defaultSecondMsg
                }
              />
              <Typography
                variant="body_2_bold"
                sx={{
                  color: theme.color.utility.link.option_3,
                  cursor: 'pointer',
                  ml: -1,
                }}
                onClick={() => setIsExpand(!isExpand)}
              >
                {isExpand ? en.booking.nonEnglishCharacter.viewLess : en.booking.nonEnglishCharacter.viewMore}
              </Typography>
            </Box>
            {isExpand &&
              warningArray.map((item: IWarningArray, index: number) => {
                return (
                  <Box key={index} sx={{ ml: 3, mb: 2 }}>
                    <CommonWarning
                      isShowIcon={false}
                      msgText={item.msgText}
                      replaceTarget={item.replaceTarget}
                      actionFunc={item.actionFunc}
                    />
                  </Box>
                );
              })}
          </Collapse>
        ) : (
          warningArray?.length == 1 && (
            <CommonWarning
              msgText={warningArray[0].msgText}
              replaceTarget={warningArray[0].replaceTarget}
              actionFunc={warningArray[0].actionFunc}
            />
          )
        ))}

      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          width: '100%',
          ml: -3,
        }}
      >
        <LocationSelection isFromRebookFlow={isFromRebookFlow} isRebookFromMyBooking={isRebookFromMyBooking} />
        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <Box
            component="div"
            sx={{
              justifyContent: 'center',
              pl: 2,
              background: theme.color.secondary.light_slate.option_6,
              width: '100%',
              mr: 1,
              ml: isFromRebookFlow ? 0.75 : 0,
              mt: isFromRebookFlow && !isRebookFromMyBooking ? -1.5 : 0,
              borderRadius: 0.5,
              height: '48px',
            }}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              setCurShowPop?.('date');
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                event.stopPropagation();
                setCurShowPop?.('date');

                event.target?.click?.();
              }
            }}
          >
            <FormPopover
              label={en.booking.searchBar.date}
              value={formatDateAsString(
                isFromRebookFlow ? rebookFlightDate : startDate,
                DATE_FORMAT.ddmmmyyyy,
                DATE_FORMAT.date,
              )}
              style={{
                height: UI_STYLES.searchBarDatePickerOverlayHeight,
                width: '664px',
                left: '16px',
                ...(isSearchResultPage && {
                  mt: 2.5,
                }),
              }}
              component={
                curShowPop === 'date' && (
                  <DateSinglePicker
                    isDesktop
                    defaultDate={
                      new Date(formatStringToDate(isFromRebookFlow ? rebookFlightDate : startDate, DATE_FORMAT.date))
                    }
                    onChange={(date: Date) => {
                      const newStartDate = formatDateAsString(date, DATE_FORMAT.date);

                      if (isSearchResultPage) {
                        setEditStartDate(newStartDate);
                      } else {
                        if (isFromRebookFlow) {
                          setRebookFlightDate(newStartDate);
                        } else {
                          dispatch(setStartDate(newStartDate));
                        }
                      }
                    }}
                    minDate={getLocalDateAdding(-1)}
                    maxDate={maxDate}
                    months={2}
                  />
                )
              }
            />
          </Box>
        </Box>

        {isFromRebookFlow ? (
          <ReduxFormTextInput
            input={{
              value: 'Zone-CX', // etp-2976 hardcode
            }}
            title={en.booking.travelType.concessionTraveler}
            isReadOnly={true}
            customStyles={{
              mx: 0.5,
              mt: 0,
              height: UI_STYLES.homepageNavBarHeight,
              '& .MuiFilledInput-root': {
                border: 0,
              },
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: 'calc(21% + 72px)',
              height: '48px',
              background: theme.color.secondary.light_slate.option_6,
              alignItems: 'center',
              borderRadius: 0.5,
              pr: 1,
              ...(!isSearchResultPage && {
                mt: 1,
              }),
            }}
          >
            <Box
              component="div"
              ref={popUpRef}
              sx={{
                display: 'flex',
                flex: 1,
                width: 1,
                justifyContent: 'center',
                pl: 2,
                mr: 2,
                height: '48px',
                '& .MuiInput-input': {
                  textOverflow: 'ellipsis',
                },
              }}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                setCurShowPop?.('travel');
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.stopPropagation();
                  setCurShowPop?.('travel');

                  event.target?.click?.();
                }
              }}
            >
              <FormPopover
                performShowPopup={performShowPopup}
                label={
                  isAdminRole || isLT
                    ? en.booking.travelType.concessionTraveler
                    : en.booking.travelType.travelConcession
                }
                isDisabled={!travelDetails.title}
                value={concessionSelectionValue}
                style={{
                  minWidth: isLtNonStandbyType ? UI_STYLES.searchBarInputOverlayMinWidth : '0px',
                  width: `${isLtNonStandbyType ? (searchBarInputWidth + 16) * 3 : (searchBarInputWidth + 32) * 2}px`,
                  height: `${screenHeight * 0.5}px`,
                  minHeight: UI_STYLES.searchBarInputOverlayMinHeight,
                  left: `-${isLtNonStandbyType ? (searchBarInputWidth - 13) * 2 : searchBarInputWidth - 15}px`,
                  ...(isSearchResultPage && {
                    mt: 2.5,
                  }),
                }}
                component={
                  curShowPop === 'travel' ? (
                    isAdminRole ? (
                      routeStep === 0 ? (
                        <ConcessionAndTraveler
                          handleClick={(step) => {
                            setRouteStep(step);
                          }}
                          empOrNonEmpType={adminConcessionEmpOrNonEmpType}
                          setEmpOrNonEmpType={setAdminConcessionEmpOrNonEmpType}
                        />
                      ) : routeStep === 1 ? (
                        <AdminRoleConcession
                          handleBackClick={(empOrNonEmpType: any) => {
                            if (empOrNonEmpType) {
                              setAdminConcessionEmpOrNonEmpType(empOrNonEmpType);
                            }
                            setRouteStep(0);
                          }}
                        />
                      ) : (
                        <AdminRoleAddTraveller
                          handleBackClick={() => {
                            setRouteStep(0);
                          }}
                        />
                      )
                    ) : (
                      <ConcessionSelectionContainer
                        isMobile={false}
                        isSearchBar
                        saveTo={SAVE_DATA_TO.redux}
                        isShowOverlay
                      />
                    )
                  ) : undefined
                }
              />
            </Box>

            {isLtNonStandbyType && totalTravellerCount > 0 && !isEmptyOrUndefined(ltType) && (
              <Box sx={{ width: '72px', ml: -1 }}>
                <LeisureTravellerCount handleClick={() => {}} travellerCount={totalTravellerCount} />
              </Box>
            )}
          </Box>
        )}

        <IconButton
          className="desktop_flight_search_btn"
          sx={{
            justifySelf: 'flex-end',
            ml: 'auto',
            mr: -1,
            mt: isFromRebookFlow && !isRebookFromMyBooking ? -1.5 : 0,
          }}
          onClick={() => {
            if (!isValid) return;

            // [ETP-4379] clear normal prepaid flow + retrieve and pay flow related Redux data
            dispatch(setIsCalledPrepaidAuth(false));
            dispatch(setRetrieveAndPayCppParams(null));

            dispatch(setBookingStep(null));

            dispatch(setPrepaidAuthFlowType(''));
            dispatch(setRetrieveAndPaySuccessData(null));
            dispatch(setNormalPrepaidSuccessData(null));

            dispatch(setViewBookingApplicationId(''));
            dispatch(setTpAppRef(''));

            if (isSearchResultPage || isFromRebookFlow) {
              ReactGA.event({
                category: GOOGLE_ANALYTICS_EVENTS.category.searchFlow,
                action: `${GOOGLE_ANALYTICS_EVENTS.action.editSearch}${GOOGLE_ANALYTICS_EVENTS.action.desktop}`,
              });

              dispatch(setOutwardFlight(null));

              setEditOrigin?.(origin);
              setEditDestination?.(destination);
              setEditStartDate?.(isFromRebookFlow ? rebookFlightDate : startDate);
              setEditIsRefresh?.(!isRefresh);
              setEditTravelDetails?.(travelDetails);
              setEditLeisureTravelDetails?.(leisureTravelDetails);
              setEditTravelType?.(travelType);

              // Dispatch edited search bar data to redux
              dispatch(setOrigin(origin));
              dispatch(setDestination(destination));
              dispatch(setStartDate(isFromRebookFlow ? rebookFlightDate : startDate));
              dispatch(setIsRefresh(!isRefresh));
              dispatch(setType(travelType));
              dispatch(setDetail(travelDetails));
              dispatch(setLeisureTravelDetails(leisureTravelDetails));
              travellerDetails?.travellerInfo && dispatch(setTravellerInfo(travellerDetails.travellerInfo));

              !isFromRebookFlow && navigate(BOOKING_PATHS.booking);
            } else {
              ReactGA.event({
                category: GOOGLE_ANALYTICS_EVENTS.category.searchFlow,
                action: `${GOOGLE_ANALYTICS_EVENTS.action.search}${GOOGLE_ANALYTICS_EVENTS.action.desktop}`,
              });

              setEditOrigin?.(origin);
              setEditDestination?.(destination);
              setEditStartDate?.(startDate);
              setIsRefresh(!isRefresh);
              setEditTravelDetails?.(travelDetails);
              setEditLeisureTravelDetails?.(leisureTravelDetails);
              setEditTravelType?.(travelType);

              // save to context
              setEditTravellerDetails(travellerDetails);

              // clear booking step before access to flight search result page
              dispatch(setBookingStep(null));

              navigate(BOOKING_PATHS.booking);
            }
          }}
        >
          <SearchIcon
            fill={isValid ? theme.color.utility.link.option_3 : theme.color.utility.unselectable.option_5}
            width="48px"
            height="48px"
          />
        </IconButton>
      </Box>
    </Box>
  );
};

SearchBar.defaultProps = {
  isResultPage: false,
  barStyle: {
    my: 2,
    width: '73%',
    borderRadius: 2,
    boxShadow: theme.palette.boxShadow.dark,
    position: 'relative',
    display: 'flex',
    pl: 3,
    left: '50%',
    transform: 'translateX(-50%)',
  },
};

export default SearchBar;
