import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Employee } from 'employee';

import en from '../../../translations/en';
import { UI_STYLES } from '../../../constants/constants';

import { getAdminPersonalDisplayName, getAdminCurrentContext, checkIsAssoSubsidAdminPage } from '../../../helpers';

const AdminInformationBar = ({ employee }: { employee?: any }) => {
  const theme = useTheme();

  const adminCurrentContext = getAdminCurrentContext();

  const { employeeDetail } = adminCurrentContext.currentContextState || {};
  const isAssoSubsidAdminPage = checkIsAssoSubsidAdminPage();

  /*
  Remarks:
    personalInfo:
    only apply for CX Admin Maintain Employee Details page

    profileDetail:
    only apply for Asso/Subsid Admin Maintain Employee Details page

    passportDetail:
    - array type for CX Admin Maintain Employee Details page
    - object type for Asso/Subsid Admin Maintain Employee Details page
  */
  const { passportDetail, profileDetail, personalInfo } = employee || employeeDetail || {};

  const profileSource = employee?.personalInfo || profileDetail || personalInfo || {};
  const { firstName, middleName, lastName, employeeId } = profileSource;

  const companyCode = isAssoSubsidAdminPage
    ? employeeDetail?.profileDetail?.hiringCompany
    : employeeDetail?.profile?.hiringCompany;

  const employeePassport =
    (Array.isArray(passportDetail) &&
      passportDetail?.find((item: Employee.PassportInfo) => item.dependentId === null)) ||
    passportDetail ||
    {};

  const fullName = getAdminPersonalDisplayName({
    firstName,
    middleName,
    lastName,
  });

  return (
    <Box
      sx={{
        width: UI_STYLES.adminMaintainWidth,
        minHeight: '80px',
        bgcolor: theme.color.etp.option_3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 1,
      }}
    >
      <Box className="full_name_field" sx={{ pt: 2, pl: 2.5, width: '683px' }}>
        <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
          {en.admin.feature.maintainEmployeeDetails.fullNameLbl}
        </Typography>

        <Typography variant="title_1_bold" color={theme.palette.text.primary} sx={{ pb: '12px' }}>
          {employeePassport.passportName ?? fullName}
        </Typography>
      </Box>

      <Box className="employee_id_field" sx={{ display: 'flex', flexDirection: 'row', py: 2, pr: 3 }}>
        <Box sx={{ pr: 5, minWidth: '160px' }}>
          <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
            {en.admin.feature.maintainEmployeeDetails.employeeIDLbl}
          </Typography>

          <Typography variant="title_1_bold" color={theme.palette.text.primary}>
            {employeeId}
          </Typography>
        </Box>

        <Box sx={{ minWidth: '120px' }}>
          <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
            {en.admin.feature.maintainEmployeeDetails.companyLbl}
          </Typography>

          <Typography variant="title_1_bold" color={theme.palette.text.primary}>
            {companyCode}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminInformationBar;
