import React, { useContext } from 'react';
import en from '../../../translations/en';

import { INominatedPaxListDropdownData, INominatedPaxList } from '../../../context/ConcessionInProfileContext';

import { ConcessionInProfileState, ConcessionInProfileAction } from '../../../context';

import ConcessionDropdownContainer, { IConcessionDropdownContainer } from './ConcessionDropdownContainer';

type IDropdownContainer = IConcessionDropdownContainer<INominatedPaxList>;

interface IConcessionWithTraveller {
  autoCompleteDropdownCustomStyles?: IDropdownContainer['autoCompleteDropdownCustomStyles'];
  inputPropsCustomForTextField?: IDropdownContainer['inputPropsCustomForTextField'];
  extraOnChangeDropdownOptionItem?: (newValue: INominatedPaxList | null) => void;
}

const ConcessionWithTraveller = ({
  autoCompleteDropdownCustomStyles,
  inputPropsCustomForTextField,
  extraOnChangeDropdownOptionItem,
}: IConcessionWithTraveller) => {
  const {
    nominatedPaxListDropdownData: { dropdownOptions, selectedOptionItem },
  } = useContext(ConcessionInProfileState) || {};
  const { setNominatedPaxListDropdownData } = useContext(ConcessionInProfileAction) || {};

  const onChangeDropdownOptionItem = (newValue: INominatedPaxList | null) => {
    setNominatedPaxListDropdownData((prevState: INominatedPaxListDropdownData) => {
      return {
        ...prevState,
        selectedOptionItem: newValue,
      };
    });

    // as optional
    extraOnChangeDropdownOptionItem?.(newValue);
  };

  return (
    <ConcessionDropdownContainer<INominatedPaxList>
      {...{
        dropdownOptions,
        dropdownLabel: en.userProfile.concession.travellerDropdownLabel,
        onChangeDropdownOptionItem,
        selectedOptionItem,
        inputPropsCustomForTextField,
        autoCompleteDropdownCustomStyles,
      }}
    />
  );
};

export default ConcessionWithTraveller;
