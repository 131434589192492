import { Box, useTheme, IconButton } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import en from '../../../../translations/en';
import { BackIcon } from '../../../../assets/images';

import { getLabelKeyByProfileType } from '../../../../helpers';

import { selectApp } from '../../../../slice/appSlice';
import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../slice/userSlice';

import { NominationPageTitle, ParagraphMasterContainer } from '../../..';

const CompanionAcknowledgePolicyContainer = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser) || {};

  const labelKeyByProfileType = getLabelKeyByProfileType(profile);

  const companionRegistrationLbl = en.userProfile.nomination.companionRegistration;
  const acknowledgePolicyLbl = companionRegistrationLbl.acknowledgePolicy;

  const profileTypeLabelKeyForCompanionRegistrationLbl =
    acknowledgePolicyLbl?.[labelKeyByProfileType as keyof typeof acknowledgePolicyLbl];

  const descriptionContentByProfileType = (profileTypeLabelKeyForCompanionRegistrationLbl as { content: [] }).content;

  return (
    <Box className="companion_acknowledge_policy_container">
      {!isDesktop && (
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{ py: 1.625, pl: 0 }}
        >
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Box
        sx={{
          mb: '76px',
          ...(isDesktop
            ? {
                width: '488px',
                mx: 'auto',
                mt: 2,
              }
            : {
                mt: 2.25,
              }),
        }}
      >
        <NominationPageTitle title={acknowledgePolicyLbl.title} />

        <ParagraphMasterContainer
          content={descriptionContentByProfileType}
          paragraphTypoSxProps={{
            color: theme.color.secondary.dark_grey.option_1,
          }}
          multiParagraphContainerSxProps={{ p: 0 }}
          paragraphSxProps={{
            mt: isDesktop ? 1.5 : 0,
          }}
          paragraphVariant="body_1_regular"
        />
      </Box>
    </Box>
  );
};

export default CompanionAcknowledgePolicyContainer;
