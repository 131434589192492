import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { PARAGRAPH_TYPE } from '../../../constants/constants';

import {
  IParagraphSxProps,
  IReplaceContentProps,
  IContentProps,
  IParagraphVariantProps,
} from './ParagraphMasterContainer';

import { DefaultParagraph } from '..';

import { bulletPointStyle } from '../../../helpers/message';

interface IMultipleParagraphProps extends IParagraphSxProps, IParagraphVariantProps, IReplaceContentProps {
  multiContent: IContentProps[];
  paragraphTypoSxProps?: SxProps<Theme>;
  multiParagraphContainerSxProps?: SxProps<Theme>;
}

const MultipleParagraph = ({
  multiContent,
  replaceContent,
  paragraphSxProps,
  multiParagraphContainerSxProps,
  paragraphVariant,
  paragraphTypoSxProps,
}: IMultipleParagraphProps) => {
  const defaultBulletPointStyle = bulletPointStyle();
  return (
    <Box className="multiple_paragraph" component="ul" sx={multiParagraphContainerSxProps}>
      {multiContent.map((item, index) => {
        return (
          <Box
            key={index}
            className="multiple_paragraph_item"
            component="li"
            sx={{
              position: 'relative',
              listStyle: 'none',
              pl: 0,
              pb: 2,
              '&::before':
                item?.formatType.toLowerCase() === PARAGRAPH_TYPE.bulletPoints.toLowerCase()
                  ? defaultBulletPointStyle
                  : undefined,
              ...paragraphSxProps,
            }}
          >
            <DefaultParagraph
              key={index}
              text={item.text}
              replaceContent={replaceContent}
              paragraphVariant={paragraphVariant}
              paragraphTypoSxProps={paragraphTypoSxProps}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default MultipleParagraph;
