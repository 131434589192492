import React, { useContext, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { NOMINATION_TYPE_TO_BACKEND } from '../../../constants/constants';

import { IDependentForAdmin } from '../../../interfaces/admin';
import { AssoSubsidAdminEmployeeAction, AssoSubsidAdminEmployeeState } from '../../../context';

import { FieldContainer, GoBackButton, AdminActionButton } from '../../Common';

import {
  AssoSubsidAdminDependentsProfileHousekeeping,
  AssoSubsidDependentsProfileDetail,
  AssoSubsidDependentsPassportDetail,
  OpenUpNominationPopup,
} from './';

const AssoSubsidDependentsDetail = ({
  dataList,
  dependentId,
  name,
  age,
  nominationFrom,
  nominationTo,
  nominationStatus,
  nominationType,
  isCurrentNominee,
  profileDetails,
  passportDetails,
  setSelectDependent,
  pureDependentData,
}: IDependentForAdmin & {
  dataList: IDependentForAdmin[];
  setSelectDependent: (value: IDependentForAdmin | null) => void;
}) => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.dependent;
  const openUpNominationLbl = fieldLabel.openUpNomination;

  const { originalDependentsHousekeepingListData, isEditingDependentProfile } =
    useContext(AssoSubsidAdminEmployeeState) || {};
  const { setDependentsHousekeepingList, setEditDependentsDetail, setEditDependentId } =
    useContext(AssoSubsidAdminEmployeeAction) || {};

  const [isPopupOpenUpNomination, setIsPopupOpenUpNomination] = useState<boolean>(false);

  const tableData = [
    { label: fieldLabel.name, value: name, length: 2 },
    { label: fieldLabel.relationship, value: profileDetails?.relationship },
    { label: fieldLabel.age, value: `${age}` || en.common.empty },
    { label: fieldLabel.nominationFrom, value: nominationFrom },
    { label: fieldLabel.nominationTo, value: nominationTo },
    { label: fieldLabel.nominationStatus, value: nominationStatus },
  ];

  const checkIsEnableOpenUpNominationButton = () => {
    const isNominationTypeInvalid: boolean =
      _.isUndefined(nominationType) || nominationType === NOMINATION_TYPE_TO_BACKEND.autoNominated;

    return isCurrentNominee && !isNominationTypeInvalid;
  };

  const backToDependentListAction = () => {
    setSelectDependent(null);
    setEditDependentsDetail(null);
    setDependentsHousekeepingList(originalDependentsHousekeepingListData);
    setEditDependentId('');
  };

  // [ETP-5930] Jenkins control show/hide dependent Open up nomination button. default Show the button
  const isHideOpenUpNominationButton = window.config?.isAdminPageHideAllOpenUpNominationButton || false;

  return (
    <>
      {!isEditingDependentProfile && (
        <GoBackButton
          buttonText={en.admin.dependents.backToDependentList}
          onClick={() => backToDependentListAction()}
        />
      )}
      <Box
        className="asso_subsid_dependent_field"
        sx={{
          minHeight: '77px',
          px: 2,
          display: 'flex',
          margin: '19px auto 0',
          border: `2px solid ${theme.color.secondary.dark_grey.option_7}`,
          borderRadius: 1,
        }}
      >
        {tableData.map((item, index) => {
          return (
            <FieldContainer
              key={index}
              field={item}
              index={index}
              customStyles={{
                '&:not(:first-of-type)': {
                  flex: 1,
                },
                '&:first-of-type': {
                  flex: 2,
                },
              }}
            />
          );
        })}
      </Box>

      <Box className="asso_subsid_dependent_detail">
        <AssoSubsidDependentsProfileDetail profileDetails={profileDetails} />

        {!isHideOpenUpNominationButton && (
          <AdminActionButton
            textLabel={openUpNominationLbl.buttonLabel}
            onClick={() => {
              setIsPopupOpenUpNomination(true);
            }}
            customStyles={{
              mt: 2,
            }}
            isEnabled={checkIsEnableOpenUpNominationButton()}
          />
        )}

        {isPopupOpenUpNomination && pureDependentData && (
          <OpenUpNominationPopup
            setIsPopupOpenUpNomination={setIsPopupOpenUpNomination}
            data={{
              dependentName: name,
              relationShip: profileDetails?.relationship,
              dependentId,
              nominationFrom: pureDependentData.nominationFrom,
              nominationTo: pureDependentData.nominationTo,
              nominationType: pureDependentData.nominationType,
            }}
          />
        )}

        <AssoSubsidDependentsPassportDetail passportDetails={passportDetails} />

        <AssoSubsidAdminDependentsProfileHousekeeping
          {...{
            dependentId,
            dataList,
          }}
        />
      </Box>
    </>
  );
};

export default AssoSubsidDependentsDetail;
