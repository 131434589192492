import { SxProps, Theme, useTheme } from '@mui/material';
import { SystemCssProperties } from '@mui/system';
import { Frontend } from 'booking';
import { PREPAID_STATUS, USER_ROLE, BOOKING_STATUS } from '../constants/constants';
import en from '../translations/en';

const getCancelWarningMessage = (role?: string, roleName?: string, isPrepaid?: boolean, prepaidMes?: string) => {
  const { cancel } = en.booking;
  switch (role) {
    case USER_ROLE.self:
      return isPrepaid ? prepaidMes : cancel.userCancel;
    case USER_ROLE.admin:
      return `${cancel.genericCancel} ${roleName}`;
    case USER_ROLE.delegation:
      return cancel.delegateeCancel;
    case USER_ROLE.system:
      return cancel.systemCancel;
    default:
      return '';
  }
};

const getMatchWarningMessage = ({
  bookingStatus,
  isFlightRebook,
  prepaidStatus,
  bookingEligibility,
  isTicketExpired,
}: {
  bookingStatus: string | null | undefined;
  isFlightRebook: boolean;
  bookingEligibility: Frontend.BookingEligibility | null;
  prepaidStatus: string | null | undefined;
  isTicketExpired: boolean;
}) => {
  /**
   * Not refund & Cancelled & rebook & refund: This booking is cancelled by user. The tickets are still valid.
   *
   * Not refund & Cancelled & !rebook & refund: This booking is cancelled by user. The tickets are no longer valid.
   *
   * Refunded: The booking is pending for refund.
   *
   * Rebooked: You have rebooked with existing ticket.
   */

  const isAllowRebook = bookingEligibility?.REBOOK;
  const isAllowRefund = bookingEligibility?.REFUND;

  const isCancelled = prepaidStatus === PREPAID_STATUS.NOT_REFUND || PREPAID_STATUS.REFUNDED;
  const isRefunded = prepaidStatus === PREPAID_STATUS.REFUNDED;
  const isNotRefunded = prepaidStatus === PREPAID_STATUS.NOT_REFUND;
  const isRetrieveAndPay = prepaidStatus === PREPAID_STATUS.RETRIEVE_AND_PAY;

  let message = '';
  if (isFlightRebook) {
    // etp-2976 add for test
    message = en.booking.confirmation.cancelActions.rebook.haveRebooked;
  } else if (isNotRefunded && isCancelled && isAllowRebook && isAllowRefund) {
    message = en.booking.cancel.existRebookAndRefundWarning;
  } else if (isNotRefunded && isCancelled && isAllowRebook && !isAllowRefund) {
    message = en.booking.cancel.onlyRebookWarning;
  } else if (isNotRefunded && isCancelled && !isAllowRebook && isAllowRefund) {
    // Cancelled booking, but No refund
    message = en.booking.cancel.onlyRefundWarning;
    if (isTicketExpired) {
      // true: issue Date > 365, ticket expired
      message += en.booking.cancel.onlyRebookWarning;
    }
  } else if (isRefunded) {
    message = en.booking.confirmation.cancelActions.refund.refundWarningMsg;
  } else if (!isCancelled) {
    message = en.booking.cancel.existNotRefundWarning;
  } else if (isTicketExpired) {
    // issue Date > 365 ticket expired
    // case 1: no refund
    // case 2: Not yet cancel booking but refund, Booking past flight date
    message = en.booking.cancel.onlyRebookWarning;
  }

  // this bookingStatus value was controlled by `getBookingStatus()`
  if (isRetrieveAndPay && bookingStatus === BOOKING_STATUS.retrieveAndPay) {
    message = en.booking.retrieveAndPay.errorMessage;
  }
  return message;
};

const splitStringWithPlaceholders = (str: string): string[] => {
  // Regular expression to match the placeholders
  const regex = /{[^}]+}/g;

  // Array to store the parts of the string
  const parts: string[] = [];
  let lastIndex = 0;

  // Match all placeholders
  let match;
  while ((match = regex.exec(str)) !== null) {
    // Add the text before the placeholder
    if (match.index > lastIndex) {
      parts.push(str.slice(lastIndex, match.index));
    }

    // Add the placeholder
    parts.push(match[0]);

    // Update the last index to the end of the current match
    lastIndex = match.index + match[0].length;
  }

  // Add any remaining text after the last placeholder
  if (lastIndex < str.length) {
    parts.push(str.slice(lastIndex));
  }

  return parts;
};

const bulletPointStyle = (customStyles?: SxProps<Theme>): SystemCssProperties<Theme> => {
  const theme = useTheme();
  const defaultBulletPointStyle = {
    content: '""',
    display: 'inline-block',
    verticalAlign: 'middle',
    mr: 1,
    left: '4px',
    width: '4px',
    height: '4px',
    background: theme.color.secondary.dark_grey.option_3,
    borderRadius: '50%',
    ...customStyles,
  };
  return defaultBulletPointStyle as SystemCssProperties<Theme>;
};

export { getCancelWarningMessage, getMatchWarningMessage, splitStringWithPlaceholders, bulletPointStyle };
