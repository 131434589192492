import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { merge } from 'lodash';

import {
  TRAVEL_PURPOSE,
  TRAVELLER_TYPE,
  REMOVE_DIALOG_TYPE,
  HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  DATE_UNIT,
  USER_ROLE,
} from '../../../../constants/constants';
import { BOOKING_PATHS } from '../../../../constants/paths';
import en from '../../../../translations/en';

import { RADIO_BUTTON_GROUP_VARIANT } from '../../../../components/Button/RadioButtonGroup';

import { getDisplayName } from '../../../../helpers';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import { IButtonValue } from '../../../../interfaces';

import { EditSearchBarState, EditSearchBarAction } from '../../../../context';

import {
  setType,
  selectBooking,
  setTravellerInfo,
  initialState as bookingSliceInitialState,
  setDetail,
} from '../../../../slice/bookingSlice';
import { selectUser } from '../../../../slice/userSlice';

import { ScrollableView, RadioButtonGroup, CancelDialog } from '../../../../components';

const ConcessionAndTraveler = ({
  handleClick,
  empOrNonEmpType,
  setEmpOrNonEmpType,
}: {
  handleClick?: (step: number) => void;
  empOrNonEmpType: string;
  setEmpOrNonEmpType: any;
}) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const isSearchResultPage = location.pathname === BOOKING_PATHS.booking;
  const editSearchData = useContext(EditSearchBarState);
  const { setEditTravelDetails, setEditTravellerDetails } = useContext(EditSearchBarAction);

  const { travelType, travelDetails, travellerDetails } = isSearchResultPage
    ? editSearchData
    : useAppSelector(selectBooking) || {};
  const { role } = useAppSelector(selectUser) || {};
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean | string>(false);
  const isAdminRole = role.type === USER_ROLE.admin;

  const selectedConcession = travelType && travelDetails?.id;

  const travelTypeToggle = [
    {
      id: TRAVEL_PURPOSE.employeeDutyTravel,
      val: TRAVEL_PURPOSE.employeeDutyTravel,
      title: en.booking.travelType.dutyTravel,
    },
  ];

  // pre set travel type
  useEffect(() => {
    dispatch(setType(isAdminRole ? TRAVEL_PURPOSE.employeeDutyTravel : TRAVEL_PURPOSE.employeeLeisureTravel));
  }, [isAdminRole]);

  useEffect(() => {
    if (selectedConcession) {
      const travellerInfo = {
        type: empOrNonEmpType === TRAVELLER_TYPE.nonEmployee ? TRAVELLER_TYPE.nonEmployee : TRAVELLER_TYPE.employee,
      };

      if (!travellerDetails?.travellerInfo?.type) {
        if (isSearchResultPage) {
          setEditTravellerDetails(
            merge({}, travellerDetails, {
              travellerInfo,
            }),
          );
        } else {
          dispatch(setTravellerInfo(travellerInfo));
        }
      } else {
        if (isSearchResultPage) {
          setEditTravellerDetails(
            merge({}, travellerDetails, {
              travellerInfo,
            }),
          );
        }
      }
    } else {
      const travellerInfo = {
        type: '',
        employee: null,
        salutation: '',
        firstName: '',
        lastName: '',
      };
      if (isSearchResultPage) {
        setEditTravellerDetails(
          merge({}, travellerDetails, {
            travellerInfo,
          }),
        );
      } else {
        dispatch(setTravellerInfo(travellerInfo));
      }
      setEmpOrNonEmpType('');
    }
  }, [selectedConcession]);

  const employeeInfo = useMemo(() => {
    return {
      curType: travellerDetails?.travellerInfo?.type === TRAVELLER_TYPE.employee,
      complete: travellerDetails?.travellerInfo?.employee,
    };
  }, [travellerDetails?.travellerInfo?.type, travellerDetails?.travellerInfo?.employee]);

  const nonEmployeeInfo = useMemo(() => {
    return {
      curType: travellerDetails?.travellerInfo?.type === TRAVELLER_TYPE.nonEmployee,
      complete: travellerDetails?.travellerInfo?.salutation,
    };
  }, [travellerDetails?.travellerInfo]);

  const nonTraveler =
    (employeeInfo.curType && !employeeInfo.complete) ||
    (nonEmployeeInfo.curType && !nonEmployeeInfo.complete) ||
    !travellerDetails?.travellerInfo?.type;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          px: 1,
          pb: 2,
          borderRadius: '4px',
        }}
      >
        <Box
          component={ScrollableView}
          sx={{
            flex: 1,
          }}
        >
          <RadioButtonGroup
            options={travelTypeToggle}
            variant={RADIO_BUTTON_GROUP_VARIANT.CHIP}
            defaultVal={travelType}
            isChip
            onChange={(value: IButtonValue) => dispatch(setType(value.id))}
            customButtonStyle={{ height: '30px' }}
          />
          <Box sx={{ display: 'flex', mt: 1, px: 1 }}>
            <Typography variant="headline_medium" sx={{ flex: 1, color: theme.color.secondary.dark_grey.option_3 }}>
              {en.booking.travelType.travelConcession}
            </Typography>

            {selectedConcession && (
              <Typography
                component={Box}
                variant="body_1_bold"
                sx={{
                  color: theme.color.utility.link.option_3,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenRemoveDialog(true);
                }}
              >
                {en.booking.travelType.removeAndRestart}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minHeight: selectedConcession ? theme.spacing(10.5) : theme.spacing(9),
              borderRadius: theme.spacing(1),
              p: 2,
              mt: 2,
              mx: 1,
              border: selectedConcession
                ? `${theme.spacing(0.25)} solid ${theme.color.secondary.dark_grey.option_7}`
                : `${theme.spacing(0.25)} dashed ${theme.color.secondary.dark_grey.option_7}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              !selectedConcession && handleClick?.(1);
            }}
          >
            {selectedConcession ? (
              <Box sx={{ flex: 1 }}>
                <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_1}>
                  {travelDetails.title}
                </Typography>

                <Typography
                  component={Button}
                  variant="navigation_medium"
                  color={theme.color.utility.link.option_2}
                  sx={{
                    background: theme.color.secondary.light_slate.option_4,
                    borderRadius: 2,
                    py: 0.5,
                    px: 1.5,
                    mt: 0.5,
                    opacity: 0.8,
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                >
                  {travelDetails.subTitle}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body_1_medium" color={theme.color.utility.link.option_3}>
                {en.booking.travelType.selectConcession}
              </Typography>
            )}
          </Box>

          <Box sx={{ display: 'flex', mt: 3, px: 1 }}>
            {selectedConcession ? (
              <>
                <Typography variant="headline_medium" sx={{ flex: 1 }} color={theme.color.secondary.dark_grey.option_3}>
                  {nonEmployeeInfo.curType
                    ? en.booking.travelType.nonEmployeeTraveller
                    : en.booking.travelType.employeeTraveller}
                </Typography>

                {empOrNonEmpType === TRAVELLER_TYPE.both && (
                  <Typography
                    variant="body_1_bold"
                    sx={{
                      color: theme.color.utility.link.option_3,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setOpenRemoveDialog(REMOVE_DIALOG_TYPE.traveller);
                    }}
                  >
                    {nonEmployeeInfo.curType
                      ? en.booking.travelType.switchToEmployee
                      : en.booking.travelType.switchToNonEmployee}
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="headline_medium" sx={{ flex: 1 }} color={theme.color.secondary.dark_grey.option_6}>
                {en.booking.travelType.traveller}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minHeight: theme.spacing(nonTraveler ? 9 : 7),
              borderRadius: theme.spacing(1),
              pr: 0.25,
              mt: 2,
              mx: 1,
              border: nonTraveler
                ? `${theme.spacing(0.25)} dashed ${theme.color.secondary.dark_grey.option_7}`
                : `${theme.spacing(0.25)} solid ${theme.color.secondary.dark_grey.option_7}`,
            }}
          >
            {employeeInfo.curType && employeeInfo.complete && (
              <>
                <Box
                  sx={{
                    flex: 1,
                    ml: 2,
                    color: theme.color.secondary.dark_grey.option_1,
                  }}
                >
                  <Typography variant="body_1_medium" sx={{ lineHeight: '24px' }}>
                    {travellerDetails?.travellerInfo?.employee?.name}
                  </Typography>
                  <Typography variant="body_2_regular" sx={{ lineHeight: '18px' }}>
                    {travellerDetails?.travellerInfo?.employee?.ern}
                  </Typography>
                </Box>
                <Typography
                  variant="body_1_bold"
                  component={Button}
                  sx={{
                    p: 0,
                    width: '87px',
                    height: '44px',
                    color: theme.color.utility.link.option_3,
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  onClick={(event: any) => {
                    const travellerInfo = {
                      employee: null,
                    };
                    if (isSearchResultPage) {
                      setEditTravellerDetails(
                        merge({}, travellerDetails, {
                          travellerInfo,
                        }),
                      );
                    } else {
                      dispatch(setTravellerInfo(travellerInfo));
                    }
                    event.stopPropagation();
                  }}
                >
                  {en.common.remove}
                </Typography>
              </>
            )}
            {nonEmployeeInfo.curType && nonEmployeeInfo.complete && (
              <>
                <Box sx={{ flex: 1, ml: 2, width: '100%', py: 1 }}>
                  <Typography
                    variant="body_1_regular"
                    sx={{
                      lineHeight: '24px',
                      wordBreak: 'break-all',
                      width: '100%',
                      color: theme.color.secondary.dark_grey.option_1,
                    }}
                  >
                    {getDisplayName({
                      title: travelDetails?.travellerInfo?.salutation,
                      firstName: travellerDetails?.travellerInfo?.firstName,
                      lastName: travellerDetails?.travellerInfo?.lastName,
                    })}
                  </Typography>
                </Box>
                <Typography
                  variant="body_1_bold"
                  component={Button}
                  sx={{
                    p: 0,
                    width: '87px',
                    height: '44px',
                    color: theme.color.utility.link.option_3,
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  onClick={(event: any) => {
                    const travellerInfo = {
                      salutation: '',
                      firstName: '',
                      lastName: '',
                    };
                    if (isSearchResultPage) {
                      setEditTravellerDetails(
                        merge({}, travellerDetails, {
                          travellerInfo,
                        }),
                      );
                    } else {
                      dispatch(setTravellerInfo(travellerInfo));
                    }
                    event.stopPropagation();
                  }}
                >
                  {en.common.remove}
                </Typography>
              </>
            )}
            {nonTraveler && (
              <Typography
                variant="body_1_medium"
                component={Button}
                color={
                  selectedConcession ? theme.color.utility.link.option_3 : theme.color.secondary.dark_grey.option_6
                }
                sx={{
                  pl: 2,
                  width: '100%',
                  height: '100%',
                  textAlign: 'left',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={() => {
                  selectedConcession && handleClick?.(2);
                }}
              >
                {en.booking.travelType.addTraveller}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      {openRemoveDialog && (
        <CancelDialog
          open={!!openRemoveDialog}
          handleConfirmAction={() => {
            const newTravelDetailObj = {
              id: '',
              title: '', // clear title for `SearchBar` component display LT label on <FormPopover>
              advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
              advanceDayUnit: DATE_UNIT.DYS,
            };

            if (openRemoveDialog === REMOVE_DIALOG_TYPE.traveller) {
              const travellerInfo = {
                type: nonEmployeeInfo.curType ? TRAVELLER_TYPE.employee : TRAVELLER_TYPE.nonEmployee,
                employee: null,
                salutation: '',
                firstName: '',
                lastName: '',
              };
              if (isSearchResultPage) {
                setEditTravellerDetails(
                  merge({}, travellerDetails, {
                    travellerInfo,
                  }),
                );
              } else {
                dispatch(setTravellerInfo(travellerInfo));
              }
            } else {
              if (isSearchResultPage) {
                setEditTravelDetails({
                  ...bookingSliceInitialState.travelDetails,
                  ...newTravelDetailObj,
                });
              } else {
                dispatch(
                  setDetail({
                    ...bookingSliceInitialState.travelDetails,
                    ...newTravelDetailObj,
                  }),
                );
              }
            }
          }}
          handleCloseDialog={() => {
            setOpenRemoveDialog(false);
          }}
          dialogContent={
            openRemoveDialog === REMOVE_DIALOG_TYPE.traveller
              ? {
                  title: nonEmployeeInfo.curType
                    ? en.booking.travelType.switchToEmployee
                    : en.booking.travelType.switchToNonEmployee,
                  message: nonEmployeeInfo.curType
                    ? en.booking.travelType.switchEmployeeDetails
                    : en.booking.travelType.switchNonEmployeeDetails,
                  yesTitle: en.admin.traveller.yesSwitch,
                }
              : {
                  title: en.admin.concession.removeTitle,
                  message: en.admin.concession.removeDescription,
                  yesTitle: en.admin.concession.yesRemove,
                }
          }
        />
      )}
    </>
  );
};

export default ConcessionAndTraveler;
