// import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import { cloneDeep } from 'lodash';

import { ProfileLogoIcon, DelegationKeyIcon, SignOutIcon } from '../../assets/images';
import { ROOT_PATHS, USER_PROFILE_PATHS } from '../../constants/paths';
import { USER_ROLE, USER_PROFILE_TYPE } from '../../constants/constants';
import en from '../../translations/en';

import { IMenuItemData } from '../../interfaces';

import {
  logout,
  getShortDisplayName,
  getFullDisplayName,
  retrieveUserModeStatus,
  isRetiree,
  isRetireeSpecialProfile,
  getRetireeSwitchProfileLabel,
  handleRetireeSwitchEmployeeId,
} from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp, setSnackBarData } from '../../slice/appSlice';
import { selectUser } from '../../slice/userSlice';
import { setErn } from '../../slice/authSlice';

import { changeRole } from '../../services/user';

import { BottomNavBar, Div100vhView, Menu, ScrollableView } from '../../components';
import { RoleIndicatorBar } from '../../containers';

const UserUtility = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  // const { instance } = useMsal();

  const dispatch = useAppDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { delegatedBy, role, profile } = useAppSelector(selectUser) || {};
  const { employeeId = '', preferredFirstName, firstName, lastName, middleName } = profile?.personalInfo || {};
  const { retireeSpecialProfile } = profile || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(role.type);

  const profileItem = isAdminDelegation
    ? {
        mainText: en.accountMenu.personalAccount,
        subList: [
          {
            employeeId,
            name: getShortDisplayName({
              preferredFirstName,
              firstName,
              lastName,
              middleName,
            }),
            handleOnClick: async () => {
              const { user } = await changeRole(USER_ROLE.self, USER_ROLE.self);
              await retrieveUserModeStatus(user.ern, dispatch);
              if (location.pathname !== ROOT_PATHS.landing) {
                navigate(ROOT_PATHS.landing);
              } else {
                window.location.reload();
              }
            },
          },
        ],
        rightIcon: true,
        targetOnClick: () => {},
        svgIcon: <ProfileLogoIcon />,
      }
    : {
        mainText: en.userMenu.profile,
        rightIcon: true,
        targetOnClick: () => navigate(USER_PROFILE_PATHS.menu),
        svgIcon: <ProfileLogoIcon />,
      };

  const delegationItems = {
    mainText: en.accountMenu.delegation,
    subList: cloneDeep(delegatedBy)?.filter((item: any) => {
      item.handleOnClick = async () => {
        await changeRole(item.employeeId, USER_ROLE.delegation);
        if (location.pathname !== ROOT_PATHS.landing) {
          navigate(ROOT_PATHS.landing);
        } else {
          window.location.reload();
        }
      };
      item.name = getFullDisplayName(item);
      return item.employeeId !== role.id;
    }),
    rightIcon: true,
    targetOnClick: () => {},
    svgIcon: <DelegationKeyIcon />,
  };

  const menuItem: IMenuItemData[] = [
    profileItem,
    // for retiree profile switch between Retiree profile and Retiree special profile
    ...(profile && ((isRetiree(profile) && retireeSpecialProfile?.employeeId) || isRetireeSpecialProfile(profile))
      ? [
          {
            mainText: en.accountMenu.personalAccount,
            subList: [
              {
                employeeId: USER_PROFILE_TYPE.retireeSpecialProfile,
                name: getRetireeSwitchProfileLabel(profile),
                handleOnClick: async () => {
                  const employeeId = handleRetireeSwitchEmployeeId(profile, retireeSpecialProfile);
                  if (employeeId) {
                    dispatch(setErn(employeeId));

                    dispatch(
                      setSnackBarData({
                        message: en.userProfile.retireeSpecialProfile.switchProfileDialog.successfulSwitched,
                        isShow: true,
                        delay: 700,
                        customStyles: {
                          mb: isDesktop ? 9 : 10.5,
                        },
                      }),
                    );

                    if (location.pathname !== ROOT_PATHS.landing) {
                      navigate(ROOT_PATHS.landing);
                    }
                  }
                },
              },
            ],
            rightIcon: true,
            targetOnClick: () => {},
            svgIcon: <ProfileLogoIcon />,
          },
        ]
      : []),
    {
      mainText: en.userMenu.signOut,
      targetOnClick: () => {
        // logout(dispatch, instance);
        logout(dispatch);
      },
      svgIcon: <SignOutIcon />,
    },
  ];

  if (delegationItems?.subList?.length > 0) {
    menuItem.splice(1, 0, delegationItems);
  }

  return (
    <Div100vhView>
      <Grid component={ScrollableView} container sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
        <Grid container justifyContent="center" sx={{ height: '114px' }}>
          {!isDesktop && isAdminDelegation && (
            <RoleIndicatorBar
              customStyles={{
                px: 2.5,
                background: 'rgba(237, 237, 237, 0.7)',
              }}
            />
          )}
          <Grid item component={Menu} xs={8} menuItemList={menuItem} />
        </Grid>
      </Grid>
      {!isDesktop && <BottomNavBar />}
    </Div100vhView>
  );
};

export default UserUtility;
