import { HTTP_REQUEST_TYPE, ADMIN_API } from '../../constants/api';
import en from '../../translations/en';

import { sendHttpRequest } from '../api';

import { setConcessionYearRequestParam } from '../../helpers/common';

const getEmployee = async (payload: { userId?: string; galaCXyId?: string }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.employeeProfile,
    apiTitle: en.errorAlert.getEmployee,
    payload,
  });
  return resp;
};

const getSuggestion = async (payload: { userId?: string; galaCXyId?: string }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.suggestionList,
    apiTitle: en.errorAlert.getSuggestion,
    payload: payload,
  });
  return resp;
};

const getNotes = async ({ userId, profileRevision }: { userId: string; profileRevision: number }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.notes,
    apiTitle: en.errorAlert.getNotes,
    payload: { userId, profileRevision },
  });
  return resp;
};

const noteUpsert = async ({
  noteId,
  userId,
  profileRevision,
  type,
  subject,
  description,
}: {
  noteId?: string;
  userId: string;
  profileRevision: number;
  type: string;
  subject: string;
  description: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.noteUpsert,
    apiTitle: en.errorAlert.noteUpsert,
    payload: { noteId, userId, profileRevision, type, subject, description },
  });
  return resp;
};

const getConcessions = async ({
  userId,
  profileRevision,
  concessionYear,
}: {
  userId: string;
  profileRevision: number;
  concessionYear?: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.concessions,
    apiTitle: en.errorAlert.getConcessions,
    payload: { userId, profileRevision, ...setConcessionYearRequestParam(concessionYear) },
  });
  return resp;
};

const updateEmployee = async ({
  userId,
  profileRevision,
  benefitDTJobLevel,
  benefitLTJobLevel,
  paymentConcept,
  paymentMedium,
  directDebitStatus,
  addFlownSuspensionInfo,
  editFlownSuspensionList,
  deleteFlownSuspensionIds,
  yearOfServiceByAdmin,
  isAdminEditedYOS,
}: {
  userId: string;
  profileRevision: number;
  benefitDTJobLevel?: {
    jobLevel: string;
    from: string;
    to: string;
  };
  benefitLTJobLevel?: { jobLevel: string; from: string; to: string };
  paymentConcept?: string;
  paymentMedium?: string;
  directDebitStatus?: string | null;
  addFlownSuspensionInfo: {
    flownSuspensionStartDate: string;
    flownSuspensionEndDate: string;
  };
  editFlownSuspensionList: string[];
  deleteFlownSuspensionIds: string[];
  yearOfServiceByAdmin?: number;
  isAdminEditedYOS?: boolean | null;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.employeeUpdate,
    apiTitle: en.errorAlert.employeeUpdate,
    payload: {
      userId,
      profileRevision,
      benefitDTJobLevel,
      benefitLTJobLevel,
      paymentConcept,
      paymentMedium,
      directDebitStatus,
      addFlownSuspensionInfo,
      editFlownSuspensionList,
      deleteFlownSuspensionIds,
      yearOfServiceByAdmin,
      isAdminEditedYOS,
    },
  });
  return resp;
};

const updateAssoSubsidEmployee = async ({
  userId,
  profileRevision,
  profile,
  employment,
  passport,
  paymentConcept,
  paymentMedium,
  directDebitStatus,
  addFlownSuspensionInfo,
  editFlownSuspensionList,
  deleteFlownSuspensionIds,
  isDeactivateProfile,
  removePendingDeactivation,
}: {
  userId: string;
  profileRevision: number;
  profile?: {
    title?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    gender?: string;
    dateOfBirth?: string;
  };
  employment?: {
    jobLevel?: string;
    resignationCode?: string;
    directAppraiser?: string;
    jobTitle?: string;
    terminationDate?: string;
    employeeCategory?: string;
  };
  passport?: {
    passportNumber?: string;
    firstName?: string;
    surname?: string;
    expiryDate?: string;
    nationality?: string;
    issuedCountry?: string;
    countryOfResidential?: string;
  };
  paymentConcept?: string;
  paymentMedium?: string;
  directDebitStatus?: string | null;
  addFlownSuspensionInfo: {
    flownSuspensionStartDate: string;
    flownSuspensionEndDate: string;
  };
  editFlownSuspensionList: string[];
  deleteFlownSuspensionIds: string[];
  isDeactivateProfile: boolean;
  removePendingDeactivation?: boolean;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.assoSubsidHousekeepingUpdate,
    apiTitle: en.errorAlert.employeeUpdate,
    payload: {
      userId,
      profileRevision,
      profile,
      employment,
      passport,
      paymentConcept,
      paymentMedium,
      directDebitStatus,
      addFlownSuspensionInfo,
      editFlownSuspensionList,
      deleteFlownSuspensionIds,
      isDeactivateProfile,
      removePendingDeactivation,
    },
  });
  return resp;
};

const inactivateBillingAgreement = async ({ userId, profileRevision }: { userId: string; profileRevision: number }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.inactivateBillingAgreement,
    apiTitle: en.errorAlert.inactivateBillingAgreement,
    payload: { userId, profileRevision },
  });
  return resp;
};

const inactivateAssoSubsidBillingAgreement = async ({
  userId,
  profileRevision,
}: {
  userId: string;
  profileRevision: number;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.assoSubsidInactivateBillingAgreement,
    apiTitle: en.errorAlert.inactivateBillingAgreement,
    payload: { userId, profileRevision },
  });
  return resp;
};

const openUpNomination = async ({
  isAssoSubsidAdminPage = false,
  userId,
  profileRevision,
  dependentId,
  nominationFrom,
  nominationTo,
  nominationType,
  adminVerifiedQuotaUsage,
  remarks,
}: {
  isAssoSubsidAdminPage: boolean;
  userId: string;
  profileRevision: number;
  dependentId: string;
  nominationFrom: string;
  nominationTo: string;
  nominationType: string;
  adminVerifiedQuotaUsage: boolean;
  remarks?: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: isAssoSubsidAdminPage
      ? ADMIN_API.EMPLOYEE_USER_API.assoSubsidInactiveNomination
      : ADMIN_API.EMPLOYEE_USER_API.inactiveNomination,
    apiTitle: en.errorAlert.inactiveNomination,
    payload: {
      userId,
      profileRevision,
      dependentId,
      nominationFrom,
      nominationTo,
      nominationType,
      adminVerifiedQuotaUsage,
      remarks,
    },
  });
  return resp;
};

/** -------------------- asso/subsid api start --------------------*/
const getAssoSubsidEmployee = async (payload: { userId: string; hiringCompany: string }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.assoSubsidEmployeeProfile,
    apiTitle: en.errorAlert.getAssoSubsidEmployee,
    payload,
  });
  return resp;
};

const getAssoSubsidNotes = async ({ userId, profileRevision }: { userId: string; profileRevision: number }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.assoSubsidNotes,
    apiTitle: en.errorAlert.getAssoSubsidNotes,
    payload: { userId, profileRevision },
  });
  return resp;
};

const assoSubsidNoteUpsert = async ({
  noteId,
  userId,
  profileRevision,
  type,
  subject,
  description,
}: {
  noteId?: string;
  userId: string;
  profileRevision: number;
  type: string;
  subject: string;
  description: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.assoSubsidNoteUpsert,
    apiTitle: en.errorAlert.assoSubsidnoteUpsert,
    payload: { noteId, userId, profileRevision, type, subject, description },
  });
  return resp;
};

const getAssoSubsidConcessions = async ({
  userId,
  profileRevision,
  concessionYear,
}: {
  userId: string;
  profileRevision: number;
  concessionYear?: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.assoSubsidConcessions,
    apiTitle: en.errorAlert.getAssoSubsidConcessions,
    payload: { userId, profileRevision, ...setConcessionYearRequestParam(concessionYear) },
  });
  return resp;
};

const updateAssoSubsidDependentsInfo = async ({
  userId,
  profileRevision,
  dependentId,
  nominationFrom,
  nominationTo,
  nominationType,
  isDeactivateProfile,
  removePendingDeactivation,
  dependentInfo,
  passport,
}: {
  userId?: string;
  profileRevision?: number;
  dependentId?: string;
  nominationFrom?: string;
  nominationTo?: string;
  nominationType?: string;
  isDeactivateProfile?: boolean;
  removePendingDeactivation?: boolean;
  dependentInfo?: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    gender?: string;
    title?: string;
    relationship?: string;
    countryOfResidential?: string;
  };
  passport: {
    passportNumber?: string;
    passportExpirationDate?: string;
    passportNationality?: string;
    passportCountry?: string;
    passportFirstName?: string;
    passportLastName?: string;
  };
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.assoSubsidDependentsInfoUpdate,
    apiTitle: en.errorAlert.dependentUpdate,
    payload: {
      userId,
      profileRevision,
      dependentId,
      nominationFrom,
      nominationTo,
      nominationType,
      isDeactivateProfile,
      removePendingDeactivation,
      dependentInfo,
      passport,
    },
  });
  return resp;
};

/** -------------------- asso/subsid api end --------------------*/

export {
  getEmployee,
  getSuggestion,
  getNotes,
  noteUpsert,
  getConcessions,
  updateEmployee,
  inactivateBillingAgreement,
  openUpNomination,
  updateAssoSubsidEmployee,
  inactivateAssoSubsidBillingAgreement,
  getAssoSubsidEmployee,
  getAssoSubsidNotes,
  assoSubsidNoteUpsert,
  getAssoSubsidConcessions,
  updateAssoSubsidDependentsInfo,
};
