import React, { useContext } from 'react';
import { shallowEqual } from 'react-redux';

import { TRAVEL_PURPOSE } from '../../constants/constants';
import { ROOT_PATHS } from '../../constants/paths';

import { OVERLAY_VARIANT } from '../../containers/BookingFlow/Common/BookingFlowOverlay';

import { isAssoSubsid } from '../../helpers';

import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';
import { selectBooking } from '../../slice/bookingSlice';

import { SearchResultHeader, SearchResult } from '../../containers';

// DT pages:
import {
  BookingFlowContainer,
  DtTravelDetailsContainer,
  BookingCompleted,
  BookingCompletedHeader,
  DtSeatClassContainer,
} from '../../containers';

// LT pages:
import { LtSelectTraveller, LtConfirmation, DtConfirmation, LtSeatClassSelection } from '../../containers';

import en from '../../translations/en';

import { EditSearchBarAction } from '../../context';

const Booking = () => {
  const { profile } = useAppSelector(selectUser);
  const { travelType } = useAppSelector(selectBooking, shallowEqual) || {};
  const isLTType = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;

  const { setIsShowEditSearchOverlay } = useContext(EditSearchBarAction) || {};

  const resultPages: any = [
    {
      component: SearchResult,
      titleComponent: SearchResultHeader,
      handleEditClick: () => setIsShowEditSearchOverlay((prev: any) => !prev),
      handleBackClick: () => {
        window.location.replace(ROOT_PATHS.landing);
      },
    },
  ];

  const DTRoutePages: any = [
    ...resultPages,
    {
      component: DtSeatClassContainer,
      title: en.booking.flightClass.select,
    },
    {
      component: DtTravelDetailsContainer,
      title: isAssoSubsid(profile)
        ? en.booking.flightConfirmation.fillYourInfo
        : en.booking.flightConfirmation.fillDutyInfo,
    },
    {
      component: DtConfirmation,
      title: en.booking.flightConfirmation.reviewBooking,
    },
    {
      component: BookingCompleted,
      titleComponent: BookingCompletedHeader,
      variant: OVERLAY_VARIANT.BOOKING_COMPLETED,
    },
  ];

  const LTRoutePages: any = [
    ...resultPages,
    {
      component: LtSelectTraveller,
      title: en.booking.travelType.leisureTravel.headerTitles.createItinerary,
    },
    {
      component: LtSeatClassSelection,
      title: en.booking.travelType.leisureTravel.headerTitles.createItinerary,
    },
    {
      component: LtConfirmation,
      title: en.booking.flightConfirmation.reviewBooking,
    },
    {
      component: BookingCompleted,
      titleComponent: BookingCompletedHeader,
      variant: OVERLAY_VARIANT.BOOKING_COMPLETED,
    },
  ];

  return (
    <BookingFlowContainer
      variant={OVERLAY_VARIANT.BOOKING_FLOW}
      routePages={isLTType ? LTRoutePages : DTRoutePages}
      handleClose={() => {}}
    />
  );
};

export default Booking;
