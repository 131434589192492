import { useEffect } from 'react';

import { getAdminCurrentContext } from '../../../helpers';

import { ConcessionList, ConcessionDetail } from '.';

const AdminConcession = () => {
  const { currentContextState, currentContextAction } = getAdminCurrentContext();
  const { focusConcession } = currentContextState || {};
  const { setConcessions, setConcessionName, setFocusConcession } = currentContextAction || {};

  useEffect(() => {
    return () => {
      // Clean up the selected Concession detail context data
      setFocusConcession(null);
      setConcessions(null);
      setConcessionName(null);
    };
  }, []);

  return <>{focusConcession ? <ConcessionDetail /> : <ConcessionList />}</>;
};

export default AdminConcession;
