import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { imgAdminTableArrowRight } from '../../../assets/images';
import { LABEL_CATEGORY } from '../../../constants/constants';

import { getDisplayLabel } from '../../../helpers';
import { IDependentForAdmin } from '../../../interfaces/admin';
import { useAppSelector } from '../../../app/hooks';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { BaseTable } from '../../../components';

const DependentsList = ({
  dataList,
  handleRowDataOnClick,
}: {
  dataList: IDependentForAdmin[];
  handleRowDataOnClick: (rowData: any) => void;
}) => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.dependent;
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const tableSchema = [
    {
      key: 'name',
      isHiddenField: false,
    },
    {
      key: 'relationship',
      isHiddenField: false,
    },
    {
      key: 'nominationType',
      isHiddenField: false,
    },
    {
      key: 'age',
      isHiddenField: false,
    },
    {
      key: 'nominationFrom',
      isHiddenField: false,
    },
    {
      key: 'nominationTo',
      isHiddenField: false,
    },
    {
      key: 'nominationStatus',
      isHiddenField: false,
    },
  ];
  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item, index) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel] as string,
        sxProps: {
          pb: 0.25,
          pr: item.key === 'nominationStatus' ? 2 : 1,
          flex: index + 1 === tableSchema.length || item.key === 'name' || item.key === 'nominationType' ? 1.5 : 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
          width: '135px',
          ml: -1.5,
        },
        isHiddenField: item.isHiddenField,
        sortingFunc: null,
      };
    });
  }, [dataList]);

  const transformedTableContents = useMemo(() => {
    const tmpData = _.cloneDeep(dataList || []);
    return tmpData.map((dataItem: IDependentForAdmin) => {
      // [ETP-5135, ETP-5086] display on Current Nominee status
      dataItem.nominationType = dataItem.isCurrentNominee
        ? getDisplayLabel(configurations.labels, LABEL_CATEGORY.nomineeType, dataItem.nominationType)
        : en.common.empty;

      return {
        dependentId: dataItem.dependentId,
        pureDependentData: dataItem.pureDependentData,
        sxProps: {
          display: 'flex',
          position: 'relative',
          '&:: after': {
            content: `""`,
            width: '28px',
            height: '28px',
            position: 'absolute',
            right: 0,
            top: '50%',
            marginTop: '-14px',
            backgroundSize: 'cover',
            backgroundImage: `url(${imgAdminTableArrowRight})`,
          },
        },
        columns: tableSchema.map(({ key, isHiddenField }) => {
          const currentValue = dataItem?.[key as keyof IDependentForAdmin];
          const newValue = currentValue ?? en.common.empty;

          return {
            columnkey: key,
            value: newValue,
            sxProps: {
              py: 1.25,
              px: 0.5,
              pr: key === 'nominationStatus' ? 2 : 1,
              overflow: 'hidden',
              cursor: 'pointer',
              flex: key === 'name' || key === 'nominationType' ? 1.5 : 1,
              display: 'flex',
              alignItems: 'center',
              lineHeight: '22px',
              minHeight: '60px',
              width: '135px',
              '&: last-of-type': {
                flex: 1.5,
              },
            },
            isHiddenField,
          };
        }),
        rowOnClick: handleRowDataOnClick,
      };
    });
  }, [dataList]);

  return (
    <Box
      className="dependent_list"
      sx={{
        mt: 4,
        width: '100%',
      }}
    >
      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
      />
    </Box>
  );
};

export default DependentsList;
