import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import { ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS } from '../../../../constants/constants';
import en from '../../../../translations/en';
import { IAssoSubsidAdminAdminHousekeeping } from '../../../../interfaces';
import { IDependentForAdmin } from '../../../../interfaces/admin';

import { formatDMYDateWithSpace } from '../../../../helpers';
import { AssoSubsidAdminEmployeeAction, AssoSubsidAdminEmployeeState } from '../../../../context';

import DeleteButton from './DeleteButton';
import ExpandButton from './ExpandButton';
import DeactivateButton from './DeactivateButton';
import DeactivateButtonHint from './DeactivateButtonHint';

import { BaseTable } from '../../../../components';
import { DetailsContainer } from '../../PersonalInfo';

const TABLE_SCHEMA = [
  {
    key: 'type',
    isHiddenField: false,
  },
  {
    key: 'housekeepingDate',
    isHiddenField: false,
    sxProps: {
      maxWidth: 0.25,
    },
  },
  {
    key: 'createBy',
    isHiddenField: false,
    sxProps: {
      maxWidth: 0.25,
    },
  },
  {
    key: 'dependentName',
    isHiddenField: false,
  },
  {
    key: 'status',
    isHiddenField: false,
  },
  {
    key: 'action',
    isHiddenField: false,
    sxProps: {
      maxWidth: 100,
    },
  },
];

type Props = {
  dependentId: string;
  dataList: IDependentForAdmin[];
};

const AssoSubsidAdminDependentsProfileHousekeeping = ({ dependentId, dataList }: Props) => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.dependent;
  const tableColumnHeaders = en.admin.feature.personalInformation.profileHousekeepingDetails.tableColumnHeaders;
  const [isExpand, setIsExpand] = useState(false);
  const { isEditingDependentProfile, dependentsHousekeepingList, editDependentsDetail } =
    useContext(AssoSubsidAdminEmployeeState) || {};
  const { setEditDependentsDetail, setDependentsHousekeepingList } = useContext(AssoSubsidAdminEmployeeAction) || {};
  const { removePendingDeactivation, isDeactivateProfileConfirmButtonClicked } = editDependentsDetail || {};

  const removeDependentsHousekeepingData = () => {
    const tempList = dependentsHousekeepingList?.filter(
      (item) => item.status !== ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.PENDING,
    );
    setDependentsHousekeepingList(tempList);
    setEditDependentsDetail({
      ...editDependentsDetail,
      removePendingDeactivation: true,
      dependentId,
    });
  };

  useEffect(() => {
    const tempList = dependentsHousekeepingList?.filter((item) => item.dependentId === dependentId);
    setDependentsHousekeepingList(tempList);
  }, dependentsHousekeepingList);

  const matchedDependentRecord = useMemo(() => {
    return dataList.find((item) => item.dependentId === dependentId);
  }, [dataList]);

  const dependentName = matchedDependentRecord?.name;
  const isDependentPersonalInfoActive = matchedDependentRecord?.isPersonalInfoActive;

  // [ETP-5963] Jenkins control show/hide Employee+dependent deactivate button. default Show the button
  const isHideDeactivateButton = window.config?.isAssoSubsidAdminPageHideAllDeactivateButton || false;

  const getTableContentValue = (tableHeader: string, dataItem: IAssoSubsidAdminAdminHousekeeping) => {
    switch (tableHeader) {
      case 'housekeepingDate':
        return formatDMYDateWithSpace(dataItem.housekeepingDate);
      case 'type':
        return dataItem.type;
      case 'createBy':
        return `${dataItem.createdBy?.roleName} (${dataItem.createdBy?.employeeId})`;
      case 'dependentName':
        return `${dependentName}`;
      case 'status':
        return dataItem.status;
      case 'action':
        return (
          isEditingDependentProfile && (
            <DeleteButton
              isAllowDelete={dataItem.status === ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.PENDING}
              deleteAction={removeDependentsHousekeepingData}
            />
          )
        );
      default:
        return en.common.empty;
    }
  };

  const isViewButtonShown = useMemo(() => {
    return dependentsHousekeepingList?.length >= 2;
  }, [dependentsHousekeepingList]);

  const transformedTableHeaders = useMemo(() => {
    return TABLE_SCHEMA.map((item) => {
      return {
        label: tableColumnHeaders[item.key as keyof typeof tableColumnHeaders],
        sxProps: {
          fontSize: '0.875rem',
          alignItems: 'left',
          overflowX: 'visible',
          whiteSpace: 'nowrap',
          maxWidth: 100,
          px: 0.5,
          ...item.sxProps,
          justifyContent: 'end',
          minWidth: item.key === 'createBy' ? 220 : 200,
        },
        isHiddenField: item.isHiddenField,
      };
    });
  }, [dependentsHousekeepingList]);

  const transformedTableContents = useMemo(() => {
    const cloneDependentsHousekeepingListData = [...dependentsHousekeepingList];
    const tmpData = isExpand ? cloneDependentsHousekeepingListData : cloneDependentsHousekeepingListData.slice(0, 1);
    return tmpData.map((dataItem: IAssoSubsidAdminAdminHousekeeping) => {
      return {
        sxProps: {
          display: 'flex',
          position: 'relative',
          overflowX: 'visible',
        },
        hover: false,
        columns: TABLE_SCHEMA.map(({ key, isHiddenField }) => {
          const newValue = getTableContentValue(key, dataItem);
          return {
            columnkey: key,
            value: newValue,
            sxProps: {
              px: 0.5,
              fontSize: 14,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              alignItems: 'left',
              justifyContent: 'end',
              minWidth: key === 'createBy' ? 220 : 200,
            },
            isHiddenField,
          };
        }),
      };
    });
  }, [
    isViewButtonShown,
    isExpand,
    dependentsHousekeepingList,
    isEditingDependentProfile,
    removePendingDeactivation,
    isDeactivateProfileConfirmButtonClicked,
  ]);

  return (
    <Box
      className="asso_subsid_dependents_profile_housekeeping"
      sx={{
        mt: 1,
        width: '100%',
        overflowX: 'visible',
      }}
    >
      <Box sx={{ justifyContent: 'center', mb: '10px' }}>
        <DetailsContainer title={fieldLabel.profileHousekeeping.heading}>
          <Grid container spacing={2}></Grid>
        </DetailsContainer>
      </Box>

      {/* // [ETP-5963] Jenkins control show/hide Employee+dependent deactivate button. default Show the button */}
      {!isHideDeactivateButton && (
        <>
          <DeactivateButton
            {...{
              dependentId,
              isDependentPersonalInfoActive,
            }}
          />
          {isDeactivateProfileConfirmButtonClicked && <DeactivateButtonHint />}
        </>
      )}

      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
        tableContainerSXProps={{ overflowX: 'hidden !important' }}
      />

      {transformedTableContents.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Typography variant="body_2_bold" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
            {fieldLabel.emptyHouseKeepingListRecord}
          </Typography>
        </Box>
      )}
      {isViewButtonShown && <ExpandButton isExpand={isExpand} setIsExpand={setIsExpand} />}
    </Box>
  );
};

export default AssoSubsidAdminDependentsProfileHousekeeping;
