import React, { useContext } from 'react';
import { Box } from '@mui/material';

import en from '../../../../translations/en';

import { getLabelKeyByProfileType } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../slice/userSlice';
import { NominationAction, NominationState } from '../../../../context';

import { DisclaimerContainer } from '../../..';

const NominationDisclaimerContainer = () => {
  const { profile } = useAppSelector(selectUser) || {};

  const { setNominationFlow } = useContext(NominationAction) || {};
  const { nominationFlow } = useContext(NominationState) || {};
  const { isAgreeDisclaimer } = nominationFlow || {};

  const nominationFlowDisclaimerPageLbl = en.userProfile.nomination.common.nominationFlowDisclaimer;

  const disclaimerLabelKey = getLabelKeyByProfileType(profile);

  const profileTypeDisclaimerLbl =
    nominationFlowDisclaimerPageLbl[disclaimerLabelKey as keyof typeof nominationFlowDisclaimerPageLbl];

  const disclaimerCheckboxOnChange = () => {
    setNominationFlow({
      ...nominationFlow,
      isAgreeDisclaimer: !isAgreeDisclaimer,
    });
  };

  const policySectionList = [
    {
      title: profileTypeDisclaimerLbl.policyDetail.title,
      descriptionContent: profileTypeDisclaimerLbl.policyDetail.descriptionContent,
    },
    {
      descriptionContent: profileTypeDisclaimerLbl.secondPolicyDetail.descriptionContent,
      isShowDisclaimerCheckbox: true,
      disclaimerCheckboxOnChange,
      isAgreeDisclaimer,
    },
  ];

  return (
    <Box className="nomination_disclaimer_container">
      <DisclaimerContainer policySectionList={policySectionList} title={profileTypeDisclaimerLbl.title} />
    </Box>
  );
};

export default NominationDisclaimerContainer;
