import React, { useContext, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import { ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS } from '../../../../constants/constants';
import en from '../../../../translations/en';
import { IAssoSubsidAdminAdminHousekeeping } from '../../../../interfaces';

import { formatDMYDateWithSpace } from '../../../../helpers';

import { AssoSubsidAdminEmployeeAction, AssoSubsidAdminEmployeeState } from '../../../../context';

import DeleteButton from './DeleteButton';
import ExpandButton from './ExpandButton';
import DeactivateButton from './DeactivateButton';
import DeactivateButtonHint from './DeactivateButtonHint';
import { BaseTable } from '../../../../components';

const TABLE_SCHEMA = [
  {
    key: 'type',
    isHiddenField: false,
  },
  {
    key: 'housekeepingDate',
    isHiddenField: false,
    sxProps: {
      maxWidth: 0.25,
    },
  },
  {
    key: 'createBy',
    isHiddenField: false,
    sxProps: {
      maxWidth: 0.25,
    },
  },
  {
    key: 'employeeName',
    isHiddenField: false,
  },
  {
    key: 'status',
    isHiddenField: false,
  },
  {
    key: 'action',
    isHiddenField: false,
    sxProps: {
      maxWidth: 100,
    },
  },
];

const AssoSubsidAdminProfileHousekeeping = () => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.dependent;
  const tableColumnHeaders = en.admin.feature.personalInformation.profileHousekeepingDetails.tableColumnHeaders;
  const [isExpand, setIsExpand] = useState(false);
  const { isEditingAdminProfile, housekeepingList, editEmployeeDetail, employeeDetail } =
    useContext(AssoSubsidAdminEmployeeState) || {};
  const { setEditEmployeeDetail, setHousekeepingList } = useContext(AssoSubsidAdminEmployeeAction) || {};
  const { removePendingDeactivation, isDeactivateProfileConfirmButtonClicked } = editEmployeeDetail || {};

  const removeHousekeepingData = () => {
    const tempList = housekeepingList?.filter(
      (item) => item.status !== ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.PENDING,
    );
    setHousekeepingList(tempList);
    setEditEmployeeDetail({ ...editEmployeeDetail, removePendingDeactivation: true });
  };

  // [ETP-5963] Jenkins control show/hide Employee+dependent deactivate button. default Show the button
  const isHideDeactivateButton = window.config?.isAssoSubsidAdminPageHideAllDeactivateButton || false;

  const isViewButtonShown = useMemo(() => {
    return housekeepingList?.length >= 2;
  }, [housekeepingList]);

  const transformedTableHeaders = useMemo(() => {
    return TABLE_SCHEMA.map((item) => {
      return {
        label: tableColumnHeaders[item.key as keyof typeof tableColumnHeaders],
        sxProps: {
          fontSize: 14,
          alignItems: 'left',
          overflowX: 'visible',
          whiteSpace: 'nowrap',
          maxWidth: 100,
          px: 0.5,
          justifyContent: 'end',
          minWidth: item.key === 'createBy' ? 220 : 200,
          ...item.sxProps,
        },
        isHiddenField: item.isHiddenField,
      };
    });
  }, [housekeepingList]);

  const getTableContentValue = (tableHeader: string, dataItem: IAssoSubsidAdminAdminHousekeeping) => {
    switch (tableHeader) {
      case 'housekeepingDate':
        return dataItem.housekeepingDate === en.common.empty
          ? en.common.empty
          : formatDMYDateWithSpace(dataItem.housekeepingDate ?? dataItem.housekeepingDate);
      case 'type':
        return dataItem.type;
      case 'createBy':
        return dataItem.createdBy?.roleName === en.common.empty
          ? en.common.empty
          : `${dataItem.createdBy?.roleName} (${dataItem.createdBy?.employeeId})`;
      case 'employeeName':
        return dataItem.employeeName === en.common.empty
          ? en.common.empty
          : `${employeeDetail?.profileDetail?.firstName} ${employeeDetail?.profileDetail?.lastName}`;
      case 'status':
        return dataItem.status;
      case 'action':
        return (
          isEditingAdminProfile && (
            <DeleteButton
              isAllowDelete={dataItem.status === ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.PENDING}
              deleteAction={removeHousekeepingData}
            />
          )
        );
      default:
        return en.common.empty;
    }
  };

  const transformedTableContents = useMemo(() => {
    const tmpData = isExpand ? housekeepingList : housekeepingList.slice(0, 1);
    return tmpData.map((dataItem: IAssoSubsidAdminAdminHousekeeping) => {
      return {
        sxProps: {
          display: 'flex',
          position: 'relative',
          overflowX: 'visible',
        },
        hover: false,
        columns: TABLE_SCHEMA.map(({ key, isHiddenField }) => {
          const newValue = getTableContentValue(key, dataItem);
          return {
            columnkey: key,
            value: newValue,
            sxProps: {
              px: 0.5,
              fontSize: 14,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              alignItems: 'left',
              justifyContent: 'end',
              minWidth: key === 'createBy' ? 220 : 200,
            },
            isHiddenField,
          };
        }),
      };
    });
  }, [
    isViewButtonShown,
    isExpand,
    housekeepingList,
    isEditingAdminProfile,
    removePendingDeactivation,
    isDeactivateProfileConfirmButtonClicked,
  ]);

  return (
    <Box
      className="asso_subsid_admin_profile_housekeeping"
      sx={{
        mt: 1,
        width: '100%',
        overflowX: 'visible',
      }}
    >
      {/* [ETP-5963] Jenkins control show/hide Employee+dependent deactivate button */}
      {!isHideDeactivateButton && (
        <>
          <DeactivateButton />
          {isDeactivateProfileConfirmButtonClicked && <DeactivateButtonHint />}
        </>
      )}

      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
        tableContainerSXProps={{ overflowX: 'hidden !important' }}
      />
      {transformedTableContents?.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Typography variant="body_2_bold" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
            {fieldLabel.emptyHouseKeepingListRecord}
          </Typography>
        </Box>
      )}
      {isViewButtonShown && <ExpandButton isExpand={isExpand} setIsExpand={setIsExpand} />}
    </Box>
  );
};

export default AssoSubsidAdminProfileHousekeeping;
