import React, { useContext, useMemo, useState } from 'react';

import { ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS } from '../../../../constants/constants';
import en from '../../../../translations/en';
import { IAssoSubsidAdminAdminHousekeeping } from '../../../../interfaces';

import { AssoSubsidAdminEmployeeAction, AssoSubsidAdminEmployeeState } from '../../../../context';

import { AdminActionButton } from '../../../Common';
import { CancelDialog } from '../../../../components';

const DeactivateButton = () => {
  const { deactivateButton, confirmDeactivateProfileDialog } =
    en.admin.feature.personalInformation.profileHousekeepingDetails;
  const { isEditingAdminProfile, housekeepingList, employeeDetail, editEmployeeDetail } =
    useContext(AssoSubsidAdminEmployeeState) || {};
  const { setEditEmployeeDetail } = useContext(AssoSubsidAdminEmployeeAction) || {};
  const [isShowCancelPopup, setIsShowCancelPopup] = useState<boolean>(false);
  const { isDeactivateProfileConfirmButtonClicked } = editEmployeeDetail || {};
  const isFetchingDeactivateProfile = useMemo(() => {
    return (
      housekeepingList?.some(
        (obj: IAssoSubsidAdminAdminHousekeeping) =>
          obj.status === ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.PENDING ||
          obj.status === ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.COMPLETED ||
          obj.status === ADMIN_ASSO_SUBSID_HOUSE_KEEPING_LIST_STATUS.IN_PROGRESS,
      ) || employeeDetail?.benefitDetail?.isActive === false
    );
  }, [housekeepingList]);

  return (
    <>
      <AdminActionButton
        textLabel={deactivateButton}
        onClick={() => {
          setIsShowCancelPopup(true);
        }}
        isEnabled={isEditingAdminProfile && !isDeactivateProfileConfirmButtonClicked && !isFetchingDeactivateProfile}
      />
      <CancelDialog
        open={isShowCancelPopup}
        showCancelIcon={false}
        handleConfirmAction={async () => {
          setEditEmployeeDetail({ ...editEmployeeDetail, isDeactivateProfileConfirmButtonClicked: true });
        }}
        handleCloseDialog={() => {
          setIsShowCancelPopup(false);
        }}
        dialogContent={{
          title: confirmDeactivateProfileDialog.title,
          message: confirmDeactivateProfileDialog.description,
          noTitle: en.common.no,
          yesTitle: confirmDeactivateProfileDialog.yes,
        }}
      />
    </>
  );
};

export default DeactivateButton;
