import { HTTP_REQUEST_TYPE, ADMIN_API } from '../../constants/api';
import en from '../../translations/en';

import { sendHttpRequest } from '../api';

const getConcession = async () => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.USER_API.concession,
    apiTitle: en.errorAlert.getConcession,
  });
  return resp;
};

const getConcessionEmpNonEmp = async (concessionId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.USER_API.concessionEmpNonEmp,
    apiTitle: en.errorAlert.getConcessionEmpNonEmp,
    payload: { concessionId },
  });
  return resp;
};

const uploadAssSubiProfile = async (file: string, filename: string) => {
  const [error, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.UPLOAD_FILE_API.assoSubsid,
    apiTitle: en.errorAlert.getUploadAssSubiProfile,
    payload: { file, filename },
  });
  if (error) {
    return error;
  }
  return resp;
};

export { getConcession, getConcessionEmpNonEmp, uploadAssSubiProfile };
