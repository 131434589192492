import { CSSProperties } from 'react';
import { createTheme } from '@mui/material/styles';
import { CATHAY_FONT_NAME, FONT_WEIGHT } from '../constants/font';

import { light as lightFont, regular as regularFont, medium as mediumFont, bold as boldFont } from './font';

declare module '@mui/material/styles' {
  export interface Theme {
    color: {
      primary: {
        cathay_jade: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        medium_jade: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        light_jade: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      secondary: {
        dark_sand: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        sand: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        light_sand: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        navy: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        cathay_saffron: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        dark_grey: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
          option_9: CSSProperties['color'];
          option_10: CSSProperties['color'];
        };
        grey: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
          option_9: CSSProperties['color'];
        };
        slate: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        light_slate: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      utility: {
        link: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        error: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        alert: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        unselectable: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      cabin_class: {
        economy: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        premium_economy: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        business: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        first: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      membership: {
        green: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        silver: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        gold: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        diamond: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        registered_user: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      etp: {
        grey: CSSProperties['color'];
        option_1: CSSProperties['color'];
        option_2: CSSProperties['color'];
        option_3: CSSProperties['color'];
        option_4: CSSProperties['color'];
        option_5: CSSProperties['color'];
      };
    };
    status: {
      selected: CSSProperties['color'];
      important: CSSProperties['color'];
    };
    boxShadow: {
      selected: string;
      important: string;
      searchBar: string;
    };
    backgroundColor: {
      popup: CSSProperties['color'];
      wheat: CSSProperties['color'];
      rockGrey: CSSProperties['color'];
      trackColor: CSSProperties['color'];
    };
    cabinClass: {
      first: CSSProperties['color'];
      business: CSSProperties['color'];
      econ: CSSProperties['color'];
    };
    bookingStatus: {
      background: {
        cancelled: CSSProperties['color'];
        flown: CSSProperties['color'];
      };
    };
    nomination: {
      plusOne: CSSProperties['color'];
      travelNominee: CSSProperties['color'];
      background: CSSProperties['color'];
    };
    inactiveNomination: {
      background: CSSProperties['color'];
      textBorder: CSSProperties['color'];
      border: CSSProperties['color'];
    };
    nextYearNomination: {
      plusOne: CSSProperties['color'];
      travelNominee: CSSProperties['color'];
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    color: {
      primary: {
        cathay_jade: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        medium_jade: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        light_jade: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      secondary: {
        dark_sand: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        sand: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        light_sand: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        navy: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        cathay_saffron: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        dark_grey: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
          option_9: CSSProperties['color'];
          option_10: CSSProperties['color'];
        };
        grey: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
          option_9: CSSProperties['color'];
        };
        slate: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        light_slate: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      utility: {
        link: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        error: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        alert: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        unselectable: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      cabin_class: {
        economy: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        premium_economy: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        business: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        first: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      membership: {
        green: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        silver: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        gold: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        diamond: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
        registered_user: {
          option_1: CSSProperties['color'];
          option_2: CSSProperties['color'];
          option_3: CSSProperties['color'];
          option_4: CSSProperties['color'];
          option_5: CSSProperties['color'];
          option_6: CSSProperties['color'];
          option_7: CSSProperties['color'];
          option_8: CSSProperties['color'];
        };
      };
      etp: {
        grey: CSSProperties['color'];
        option_1: CSSProperties['color'];
        option_2: CSSProperties['color'];
        option_3: CSSProperties['color'];
        option_4: CSSProperties['color'];
        option_5: CSSProperties['color'];
      };
    };
    status: {
      selected: CSSProperties['color'];
      important: CSSProperties['color'];
    };
    boxShadow: {
      selected: string;
      important: string;
      searchBar: string;
    };
    backgroundColor: {
      popup: CSSProperties['color'];
      wheat: CSSProperties['color'];
      rockGrey: CSSProperties['color'];
      trackColor: CSSProperties['color'];
    };
    cabinClass: {
      first: CSSProperties['color'];
      business: CSSProperties['color'];
      econ: CSSProperties['color'];
    };
    bookingStatus: {
      background: {
        cancelled: CSSProperties['color'];
        flown: CSSProperties['color'];
      };
    };
    nomination: {
      plusOne: CSSProperties['color'];
      travelNominee: CSSProperties['color'];
      background: CSSProperties['color'];
    };
    inactiveNomination: {
      background: CSSProperties['color'];
      textBorder: CSSProperties['color'];
      border: CSSProperties['color'];
    };
    nextYearNomination: {
      plusOne: CSSProperties['color'];
      travelNominee: CSSProperties['color'];
    };
  }

  interface Palette {
    cathayJade: Palette['primary'];
    cathayJadeLight: Palette['secondary'];
    gray: Palette['secondary'];
    datePicker: Palette['secondary'];
    boxShadow: Palette['secondary'];
    borderColor: Palette['secondary'];
    bgColor: Palette['secondary'];
    text: Palette['text'];
    flightCard: Palette['secondary'];
    flightInfo: Palette['secondary'];
    plusOne: Palette['secondary'];
  }

  interface PaletteOptions {
    cathayJade: PaletteOptions['primary'];
    cathayJadeLight: Palette['secondary'];
    gray: PaletteOptions['secondary'];
    datePicker: PaletteOptions['secondary'];
    boxShadow: PaletteOptions['secondary'];
    borderColor: PaletteOptions['secondary'];
    bgColor: PaletteOptions['secondary'];
    flightCard: PaletteOptions['secondary'];
    flightInfo: PaletteOptions['secondary'];
    plusOne: PaletteOptions['secondary'];
  }

  interface TypographyVariants {
    large_title_1_light: React.CSSProperties;
    large_title_1_regular: React.CSSProperties;
    large_title_1_medium: React.CSSProperties;
    large_title_1_bold: React.CSSProperties;
    large_title_light: React.CSSProperties;
    large_title_regular: React.CSSProperties;
    large_title_medium: React.CSSProperties;
    large_title_bold: React.CSSProperties;
    title_1_light: React.CSSProperties;
    title_1_regular: React.CSSProperties;
    title_1_medium: React.CSSProperties;
    title_1_bold: React.CSSProperties;
    title_2_light: React.CSSProperties;
    title_2_regular: React.CSSProperties;
    title_2_medium: React.CSSProperties;
    title_2_bold: React.CSSProperties;
    headline_light: React.CSSProperties;
    headline_regular: React.CSSProperties;
    headline_medium: React.CSSProperties;
    headline_bold: React.CSSProperties;
    body_1_light: React.CSSProperties;
    body_1_regular: React.CSSProperties;
    body_1_medium: React.CSSProperties;
    body_1_bold: React.CSSProperties;
    body_2_light: React.CSSProperties;
    body_2_regular: React.CSSProperties;
    body_2_medium: React.CSSProperties;
    body_2_bold: React.CSSProperties;
    navigation_light: React.CSSProperties;
    navigation_regular: React.CSSProperties;
    navigation_medium: React.CSSProperties;
    navigation_bold: React.CSSProperties;
    fine_print_light: React.CSSProperties;
    fine_print_regular: React.CSSProperties;
    fine_print_medium: React.CSSProperties;
    fine_print_bold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    large_title_1_light?: React.CSSProperties;
    large_title_1_regular?: React.CSSProperties;
    large_title_1_medium?: React.CSSProperties;
    large_title_1_bold?: React.CSSProperties;
    large_title_light?: React.CSSProperties;
    large_title_regular?: React.CSSProperties;
    large_title_medium?: React.CSSProperties;
    large_title_bold?: React.CSSProperties;
    title_1_light?: React.CSSProperties;
    title_1_regular?: React.CSSProperties;
    title_1_medium?: React.CSSProperties;
    title_1_bold?: React.CSSProperties;
    title_2_light?: React.CSSProperties;
    title_2_regular?: React.CSSProperties;
    title_2_medium?: React.CSSProperties;
    title_2_bold?: React.CSSProperties;
    headline_light?: React.CSSProperties;
    headline_regular?: React.CSSProperties;
    headline_medium?: React.CSSProperties;
    headline_bold?: React.CSSProperties;
    body_1_light?: React.CSSProperties;
    body_1_regular?: React.CSSProperties;
    body_1_medium?: React.CSSProperties;
    body_1_bold?: React.CSSProperties;
    body_2_light?: React.CSSProperties;
    body_2_regular?: React.CSSProperties;
    body_2_medium?: React.CSSProperties;
    body_2_bold?: React.CSSProperties;
    navigation_light?: React.CSSProperties;
    navigation_regular?: React.CSSProperties;
    navigation_medium?: React.CSSProperties;
    navigation_bold?: React.CSSProperties;
    fine_print_light?: React.CSSProperties;
    fine_print_regular?: React.CSSProperties;
    fine_print_medium?: React.CSSProperties;
    fine_print_bold?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    large_title_1_light: true;
    large_title_1_regular: true;
    large_title_1_medium: true;
    large_title_1_bold: true;
    large_title_light: true;
    large_title_regular: true;
    large_title_medium: true;
    large_title_bold: true;
    title_1_light: true;
    title_1_regular: true;
    title_1_medium: true;
    title_1_bold: true;
    title_2_light: true;
    title_2_regular: true;
    title_2_medium: true;
    title_2_bold: true;
    headline_light: true;
    headline_regular: true;
    headline_medium: true;
    headline_bold: true;
    body_1_light: true;
    body_1_regular: true;
    body_1_medium: true;
    body_1_bold: true;
    body_2_light: true;
    body_2_regular: true;
    body_2_medium: true;
    body_2_bold: true;
    navigation_light: true;
    navigation_regular: true;
    navigation_medium: true;
    navigation_bold: true;
    fine_print_light: true;
    fine_print_regular: true;
    fine_print_medium: true;
    fine_print_bold: true;
  }
}

//  TODO: seperate color out as default theme, and reference color variable in the exported theme directly
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1024,
      md: 1200,
      lg: 1536,
      xl: 1800,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#3c3c3c',
      light: '#FFFFFF',
      contrastText: '#66686A',
    },
    secondary: {
      light: '#FCFCFB',
      main: '#D6D6D6',
      dark: '#F5F5F5',
    },
    cathayJade: {
      main: '#005D63',
      light: '#0f748f',
      contrastText: '#2B9198',
      dark: '#939393',
    },
    cathayJadeLight: {
      dark: '#58806c',
      main: '#6FA088',
      light: '#8BB39F',
      contrastText: '#387E79',
    },
    gray: {
      light: '#EEEEEE',
      main: '#808285',
      dark: '#4C4C4C',
      contrastText: '#EBEBEC',
    },
    text: {
      primary: '#000000',
      secondary: '#2D2D2D',
      disabled: '#AEAEAE',
    },
    warning: {
      light: '#AD1E1E',
      main: '#D11F00',
      dark: '#6F6F6F',
      contrastText: '#CD4961',
    },
    boxShadow: {
      main: '0px 0px 2px #7F646464',
      light: '0px 0px 4px #8686863F',
      dark: '0px 4px 10px 0px #0000001A',
    },
    borderColor: {
      main: '#0000001f',
      dark: '#00000026',
      light: '#116F9A',
      contrastText: '#BCBEC0',
    },
    bgColor: {
      main: '#FFFFFF',
      dark: '#E5E5E5',
      light: '#F8FDFF',
      contrastText: '#F7F7F7',
    },
    datePicker: {
      main: '#EDEDED',
      dark: '#4C4C4C',
    },
    flightCard: {
      main: '#2F888E',
      dark: '#D3E9EA',
      light: '#F1F2F2',
      contrastText: '#F0F0F1',
    },
    flightInfo: {
      main: '#999B9D',
      light: '#99BEC0',
    },
    plusOne: {
      main: '#F9EAD5',
    },
  },
  typography: {
    fontWeightLight: FONT_WEIGHT.light,
    fontWeightRegular: FONT_WEIGHT.regular,
    fontWeightMedium: FONT_WEIGHT.medium,
    fontWeightBold: FONT_WEIGHT.bold,
    fontFamily: CATHAY_FONT_NAME,
    large_title_1_light: {
      fontSize: '2rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '40px',
      display: 'block',
    },
    large_title_1_regular: {
      fontSize: '2rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '40px',
      display: 'block',
    },
    large_title_1_medium: {
      fontSize: '2rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '40px',
      display: 'block',
    },
    large_title_1_bold: {
      fontSize: '2rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '40px',
      display: 'block',
    },
    large_title_light: {
      fontSize: '1.75rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '32px',
      display: 'block',
    },
    large_title_regular: {
      fontSize: '1.75rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '32px',
      display: 'block',
    },
    large_title_medium: {
      fontSize: '1.75rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '32px',
      display: 'block',
    },
    large_title_bold: {
      fontSize: '1.75rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '32px',
      display: 'block',
    },
    title_1_light: {
      fontSize: '1.5rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '30px',
      display: 'block',
    },
    title_1_regular: {
      fontSize: '1.5rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '30px',
      display: 'block',
    },
    title_1_medium: {
      fontSize: '1.5rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '30px',
      display: 'block',
    },
    title_1_bold: {
      fontSize: '1.5rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '30px',
      display: 'block',
    },
    title_2_light: {
      fontSize: '1.25rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '24px',
      display: 'block',
    },
    title_2_regular: {
      fontSize: '1.25rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '24px',
      display: 'block',
    },
    title_2_medium: {
      fontSize: '1.25rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '24px',
      display: 'block',
    },
    title_2_bold: {
      fontSize: '1.25rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '24px',
      display: 'block',
    },
    headline_light: {
      fontSize: '1.125rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '24px',
      display: 'block',
    },
    headline_regular: {
      fontSize: '1.125rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '24px',
      display: 'block',
    },
    headline_medium: {
      fontSize: '1.125rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '24px',
      display: 'block',
    },
    headline_bold: {
      fontSize: '1.125rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '24px',
      display: 'block',
    },
    body_1_light: {
      fontSize: '1rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '24px',
      display: 'block',
    },
    body_1_regular: {
      fontSize: '1rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '24px',
      display: 'block',
    },
    body_1_medium: {
      fontSize: '1rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '24px',
      display: 'block',
    },
    body_1_bold: {
      fontSize: '1rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '24px',
      display: 'block',
    },
    body_2_light: {
      fontSize: '0.875rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '18px',
      display: 'block',
    },
    body_2_regular: {
      fontSize: '0.875rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '18px',
      display: 'block',
    },
    body_2_medium: {
      fontSize: '0.875rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '18px',
      display: 'block',
    },
    body_2_bold: {
      fontSize: '0.875rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '18px',
      display: 'block',
    },
    navigation_light: {
      fontSize: '0.75rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '16px',
      display: 'block',
    },
    navigation_regular: {
      fontSize: '0.75rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '16px',
      display: 'block',
    },
    navigation_medium: {
      fontSize: '0.75rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '16px',
      display: 'block',
    },
    navigation_bold: {
      fontSize: '0.75rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '16px',
      display: 'block',
    },
    fine_print_light: {
      fontSize: '0.688rem',
      fontWeight: FONT_WEIGHT.light,
      lineHeight: '14px',
      display: 'block',
    },
    fine_print_regular: {
      fontSize: '0.688rem',
      fontWeight: FONT_WEIGHT.regular,
      lineHeight: '14px',
      display: 'block',
    },
    fine_print_medium: {
      fontSize: '0.688rem',
      fontWeight: FONT_WEIGHT.medium,
      lineHeight: '14px',
      display: 'block',
    },
    fine_print_bold: {
      fontSize: '0.688rem',
      fontWeight: FONT_WEIGHT.bold,
      lineHeight: '14px',
      display: 'block',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  color: {
    primary: {
      cathay_jade: {
        option_1: '#00373B',
        option_2: '#004A4F',
        option_3: '#005D63',
        option_4: '#337D82',
        option_5: '#669DA1',
        option_6: '#99BEC0',
        option_7: '#CCDEDF',
        option_8: '#E6EFEF',
      },
      medium_jade: {
        option_1: '#214B48',
        option_2: '#2C6460',
        option_3: '#387E79',
        option_4: '#5F9793',
        option_5: '#87B1AE',
        option_6: '#AFCBC9',
        option_7: '#D7E5E4',
        option_8: '#EBF2F1',
      },
      light_jade: {
        option_1: '#426051',
        option_2: '#58806C',
        option_3: '#6FA088',
        option_4: '#8BB39F',
        option_5: '#A8C6B7',
        option_6: '#C5D9CF',
        option_7: '#E2ECE7',
        option_8: '#E2ECE7',
      },
    },
    secondary: {
      dark_sand: {
        option_1: '#514937',
        option_2: '#6C6149',
        option_3: '#877A5C',
        option_4: '#9F947C',
        option_5: '#B7AF9D',
        option_6: '#CFC9BD',
        option_7: '#E7E4DE',
        option_8: '#F3F1EF',
      },
      sand: {
        option_1: '#736C5B',
        option_2: '#999079',
        option_3: '#C0B498',
        option_4: '#CCC3AC',
        option_5: '#D9D2C1',
        option_6: '#E5E1D5',
        option_7: '#F2F0EA',
        option_8: '#F8F7F4',
      },
      light_sand: {
        option_1: '#827E70',
        option_2: '#DAD2BC',
        option_3: '#DAD2BC',
        option_4: '#E1DBC9',
        option_5: '#E8E4D6',
        option_6: '#F0EDE4',
        option_7: '#F7F6F1',
        option_8: '#FBFAF8',
      },
      navy: {
        option_1: '#131C27',
        option_2: '#192534',
        option_3: '#202F42',
        option_4: '#4C5867',
        option_5: '#79828D',
        option_6: '#A5ABB3',
        option_7: '#D2D5D9',
        option_8: '#E9EAEC',
      },
      cathay_saffron: {
        option_1: '#8C0018',
        option_2: '#BB0020',
        option_3: '#EA0029',
        option_4: '#EE3353',
        option_5: '#F2667E',
        option_6: '#F699A9',
        option_7: '#FACCD4',
        option_8: '#FCE6EA',
      },
      dark_grey: {
        option_1: '#2D2D2D',
        option_2: '#3C3C3C',
        option_3: '#4C4C4C',
        option_4: '#6F6F6F',
        option_5: '#939393',
        option_6: '#B7B7B7',
        option_7: '#DBDBDB',
        option_8: '#EDEDED',
        option_9: '#747474',
        option_10: '#B8B8B8',
      },
      grey: {
        option_1: '#4C4E4F',
        option_2: '#66686A',
        option_3: '#808285',
        option_4: '#999B9D',
        option_5: '#B2B4B5',
        option_6: '#CCCDCE',
        option_7: '#E5E6E6',
        option_8: '#F2F2F3',
        option_9: '#C9C9CC',
      },
      slate: {
        option_1: '#8A8A8B',
        option_2: '#969899',
        option_3: '#BCBEC0',
        option_4: '#C9CBCC',
        option_5: '#D6D8D9',
        option_6: '#E4E5E5',
        option_7: '#F1F2F2',
        option_8: '#F8F8F8',
      },
      light_slate: {
        option_1: '#8A8A8B',
        option_2: '#B8B8B9',
        option_3: '#E6E7E8',
        option_4: '#EBEBEC',
        option_5: '#F0F0F1',
        option_6: '#F5F5F5',
        option_7: '#FAFAFA',
        option_8: '#FCFCFC',
      },
    },
    utility: {
      link: {
        option_1: '#0A425C',
        option_2: '#0D587B',
        option_3: '#116F9A',
        option_4: '#408BAE',
        option_5: '#70A8C2',
        option_6: '#9FC5D6',
        option_7: '#CFE2EA',
        option_8: '#E7F0F5',
      },
      error: {
        option_1: '#7D1200',
        option_2: '#A71800',
        option_3: '#D11F00',
        option_4: '#DA4B33',
        option_5: '#E37866',
        option_6: '#ECA599',
        option_7: '#F5D2CC',
        option_8: '#FAE9E6',
      },
      alert: {
        option_1: '#988100',
        option_2: '#CBAC00',
        option_3: '#FED700',
        option_4: '#FEDF33',
        option_5: '#FEE766',
        option_6: '#FEEF99',
        option_7: '#FEF7CC',
        option_8: '#FEFBE6',
      },
      unselectable: {
        option_1: '#818181',
        option_2: '#ACACAC',
        option_3: '#D7D7D7',
        option_4: '#DFDFDF',
        option_5: '#E7E7E7',
        option_6: '#EFEFEF',
        option_7: '#F7F7F7',
        option_8: '#FBFBFB',
      },
    },
    cabin_class: {
      economy: {
        option_1: '#00373B',
        option_2: '#004A4F',
        option_3: '#005D63',
        option_4: '#337D82',
        option_5: '#669DA1',
        option_6: '#99BEC0',
        option_7: '#CCDEDF',
        option_8: '#E6EFEF',
      },
      premium_economy: {
        option_1: '#2D5060',
        option_2: '#3C6B80',
        option_3: '#4C86A0',
        option_4: '#6F9EB3',
        option_5: '#93B6C6',
        option_6: '#B7CED9',
        option_7: '#DBE6EC',
        option_8: '#EDF3F5',
      },
      business: {
        option_1: '#10203E',
        option_2: '#152B53',
        option_3: '#1B3668',
        option_4: '#485E86',
        option_5: '#7686A4',
        option_6: '#A3AEC2',
        option_7: '#D1D6E0',
        option_8: '#E8EBF0',
      },
      first: {
        option_1: '#4E1A27',
        option_2: '#682334',
        option_3: '#822C42',
        option_4: '#9B5667',
        option_5: '#B4808D',
        option_6: '#CDAAB3',
        option_7: '#E6D4D9',
        option_8: '#F2EAEC',
      },
    },
    membership: {
      green: {
        option_1: '#00373B',
        option_2: '#004A4F',
        option_3: '#005D63',
        option_4: '#337D82',
        option_5: '#669DA1',
        option_6: '#99BEC0',
        option_7: '#CCDEDF',
        option_8: '#E6EFEF',
      },
      silver: {
        option_1: '#3F3F3F',
        option_2: '#545454',
        option_3: '#696A6A',
        option_4: '#878787',
        option_5: '#A5A5A5',
        option_6: '#C3C3C3',
        option_7: '#E1E1E1',
        option_8: '#F0F0F0',
      },
      gold: {
        option_1: '#513C13',
        option_2: '#6C501A',
        option_3: '#886521',
        option_4: '#9F834D',
        option_5: '#B7A279',
        option_6: '#CFC1A6',
        option_7: '#E7E0D2',
        option_8: '#F3EFE9',
      },
      diamond: {
        option_1: '#1B1916',
        option_2: '#24211D',
        option_3: '#2E2A25',
        option_4: '#575450',
        option_5: '#817F7C',
        option_6: '#ABA9A7',
        option_7: '#D5D4D3',
        option_8: '#EAEAE9',
      },
      registered_user: {
        option_1: '#707273',
        option_2: '#969899',
        option_3: '#BCBEC0',
        option_4: '#C9CBCC',
        option_5: '#D6D8D9',
        option_6: '#E4E5E5',
        option_7: '#F1F2F2',
        option_8: '#F8F8F8',
      },
    },
    etp: {
      grey: '#EBEDEC',
      option_1: '#EFF2F6',
      option_2: '#556E9C',
      option_3: '#E7F1F5',
      option_4: '#E8E8E8',
      option_5: '#E9E9E9',
    },
  },
  status: {
    selected: '#11709A',
    important: '#AD1E1E',
  },
  boxShadow: {
    selected: '0px 0px 4px rgba(34, 156, 211, 0.3)',
    important: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    searchBar:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  },
  backgroundColor: {
    popup: 'rgba(76, 76, 76, 0.5)',
    wheat: '#FDFDFD',
    rockGrey: '#F4FCFF',
    trackColor: '#78788029',
  },
  cabinClass: {
    first: '#9B5667',
    business: '#485E86',
    econ: '#337D82',
  },
  bookingStatus: {
    background: {
      cancelled: '#D9D2C1',
      flown: '#CCDEDF',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face ${lightFont}
        @font-face ${regularFont}
        @font-face ${mediumFont}
        @font-face ${boldFont}
      `,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: `0 0 4px
          rgba(100, 100, 100, 0.5)`,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: '#11709A',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: '#C8CED3',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&$checked': {
            color: '#11709A',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: '#D1D1D1',
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: '#808285',
          '&.Mui-selected': {
            color: 'black',
            svg: {
              fill: '#116F9A',
            },
          },
        },
        label: {
          fontSize: '0.625rem',
          fontWeight: FONT_WEIGHT.medium,
          '&.Mui-selected': {
            fontSize: '0.625rem',
            fontWeight: FONT_WEIGHT.medium,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            border: 0,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'transparent',
          },
        },
      },
    },
  },
  nomination: {
    plusOne: '#F9EAD5',
    travelNominee: '#E7F0F5',
    background: 'linear-gradient(180deg,#C1D7D4 1%,#F8F8F8 60%)',
  },
  inactiveNomination: {
    background: '#B9B9BB',
    textBorder: '#C6C2C1',
    border: '#D8D8D8',
  },
  nextYearNomination: {
    plusOne: '#7B9AA6',
    travelNominee: '#2F4664',
  },
});

export default theme;
