import axios from 'axios';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';

import serverError from './serverError';
import { AUTH_API, AXIOS_ERR_CODE_TIMEOUT, HTTP_REQUEST_TYPE, API_CACHE } from '../constants/api';
import { ETP_CLIENT_VER, ETP_CLIENT_ID_KEY, GOOGLE_ANALYTICS_EVENTS, LOGIN_MODE } from '../constants/constants';
import en from '../translations/en';
import {
  displayTokenExp,
  getAccessToken,
  // getNewAccessToken,
} from '../config/authConfig';

import { IUserRole, IAdminRole, IErrorAlert } from '../interfaces';

import { store } from '../app/store';
import { setLoading, setErrorAlert } from '../slice/appSlice';
import { setErn, setHaveAccess, setInitNetworkError } from '../slice/authSlice';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    config: {
      baseURL: string;
      travelRequirementsLink: string;
      employeeTravelPortalLink: string;
      flownSuspensionMoreDetailLink: string;
      myCasesLink: string;
      azureClientId: string;
      azureAuthority: string;
      isSystemMaintenance: boolean;
      systemMaintenanceMessage: string;
      googleAnalyticsMeasurementId: string;
      isHiddenLtFeatureFlag: boolean;
      isDisplayEMPTravelWithFamilyConcessionMsg: boolean;
      isHiddenFlightLoad: boolean;
      azureCacheLocation: string;
      isConfigurationRefreshOnReload: boolean;
      cppBaseURL: string;
      cxDeployEnv: string;
      isShowAdminEditMode: boolean;
      registerMaxNumberOfTravelCompanion: number;
      blockedBookingDepartureDateOnOrAfter: string;
      assoSubsidCompanyListHideRegisterCompanionButton: string[];
      assoSubsidCompanyListHideFutureNominationButton: string[];
      isAssoSubsidAdminPageHideAllDeactivateButton: boolean;
      isAdminPageHideAllOpenUpNominationButton: boolean;
    };
    mockUser: (callback: (ern: string) => void) => void;
  }
}

let user: {
  ern: string;
  userId: string;
  role: IUserRole;
  adminRoles: IAdminRole[];
  userType: string;
  roleList: string[];
  concession: {
    dutyTravel: string[];
    leisureTravel: string[];
  };
} | null = null;

const getEtpClientId = () => {
  let etpClientId = sessionStorage.getItem(ETP_CLIENT_ID_KEY);

  if (!etpClientId) {
    etpClientId = uuidv4();
    sessionStorage.setItem(ETP_CLIENT_ID_KEY, etpClientId);
  }

  return etpClientId;
};

const ETP_CLIENT_ID = getEtpClientId();

console.debug(ETP_CLIENT_ID_KEY, ETP_CLIENT_ID);

let initInProgress = false;

const cacheURL = Object.values(API_CACHE);
const cacheData = cacheURL.reduce(
  (previous: { [key: string]: any }, current: string) => ((previous[current] = null), previous),
  {},
);

const clearCacheData = (clearField: string | string[]) => {
  [clearField].flat().map((key) => {
    if (cacheData[key] !== null) {
      cacheData[key] = null;
    }
  });
};

const clearAllCacheData = () => {
  for (const key in cacheData) {
    cacheData[key] = null;
  }
};

const instance = axios.create({
  baseURL: window.config.baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-ETP-CLIENT-ID': ETP_CLIENT_ID,
    'X-ETP-CLIENT-VER': ETP_CLIENT_VER,
  },
});

instance.interceptors.request.use(async (config) => {
  // const state = store.getState();
  // const accessToken = state.auth.accessToken;

  // const accessToken = initInProgress
  //   ? await getNewAccessToken()
  //   : await getAccessToken();
  const accessToken = await getAccessToken();
  displayTokenExp(accessToken);
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  } else {
    delete config.headers.Authorization;
  }
  // config.headers['X-correlationId'] = uuidv4();
  // config._metadata = config._metadata || {};
  // config._metadata.requestStartedAt = new Date().getTime();

  // use cache
  if (cacheURL.find((item: string) => item === config.url)) {
    if (cacheData[config.url!]) {
      const source = axios.CancelToken.source();
      config.cancelToken = source.token;
      source.cancel(cacheData[config.url!]);
    }
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    // set cache
    if (cacheURL.find((item: string) => item === response.config.url)) {
      if (!cacheData[response.config.url!]) {
        cacheData[response.config.url!] = response.data;
      }
    }
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.resolve({ status: 200, data: error.message });
    }

    return Promise.reject(error);
  },
);

function showErrorAlert({
  title,
  message,
  requestId,
  applicationId,
  noErrorAlert,
  showErrorIcon,
  isEnableMobileEditSearchFlag,
  isShowCommonWarning,
  commonWarningActionFunc,
  dismissCallBackFunc,
  commonWarningReplaceTarget,
  errorStatus,
}: Partial<IErrorAlert>) {
  store.dispatch(
    setErrorAlert({
      title: title || en.errorAlert.genericTitle,
      message: message || en.errorAlert.genericMessage,
      requestId: requestId ? `[${requestId}]` : '',
      applicationId: applicationId ? `[${applicationId}]` : '',
      noErrorAlert,
      showErrorIcon,
      isEnableMobileEditSearchFlag, // related with "Exceeded advance booking period " logic.
      isShowCommonWarning, // related with display "<CommonWarning>" component
      commonWarningActionFunc, // related with display "<CommonWarning>" component
      dismissCallBackFunc, // related with display "<CommonWarning>" component
      commonWarningReplaceTarget, // related with display "<CommonWarning>" component
      errorStatus: errorStatus || '',
    }),
  );
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(() => resolve(null), ms));
}

async function waitForInitToComplete() {
  if (!initInProgress) {
    return;
  }
  await delay(500);
  await waitForInitToComplete();
  return;
}

export async function initClientApi() {
  if (user) {
    return;
  }

  if (initInProgress) {
    await waitForInitToComplete();
    // init completed - continue
    // do we need to handle init error ?
    return;
  }
  console.debug('API USER INIT IN PROGRESS');
  initInProgress = true;
  // assume call by getHttpRequestResp each time when there is no user;
  // const config = getAuthHeader();
  // const response = await instance.get(AUTH_API.init, config);
  try {
    const loginMode = sessionStorage.getItem(LOGIN_MODE.key);
    // only login mode is asso-subsid will pass the param via the api call
    // e.g /init?loginMode=assoSubsid
    const response = await instance.get(AUTH_API.init, { params: { loginMode: loginMode } });
    const { status: httpStatus, data: result } = response || {};

    //if (httpStatus === 200) {
    if (httpStatus >= 200 && httpStatus < 300) {
      user = result.user;
      if (loginMode === LOGIN_MODE.values.assoSubsid && user?.userId) {
        store.dispatch(setErn(user.userId));
      }
      // TODO: save role list / other user info in session to redux
      console.debug(user);
    } else {
      console.error('API USER INIT STATUS NOT 200', response);
      throw new Error('');
    }
    store.dispatch(setHaveAccess(true));
  } catch (err: any) {
    console.error('API USER INIT ERROR', err);

    const { detail } = err.response?.data?.errors || {};

    if (detail && detail.toLowerCase()?.includes(en.errorAlert.userNotAuthorized.toLowerCase())) {
      store.dispatch(setHaveAccess(false));
    } else {
      store.dispatch(setInitNetworkError(true));
    }

    throw err; // error throw back outside getHttpRequestResp
  } finally {
    initInProgress = false;
    console.debug('API USER INIT IN END');
  }
}

async function getRoleApi() {
  try {
    // const config = getAuthHeader();
    // const response = await instance.get(AUTH_API.getRole, config);
    const response = await instance.get(AUTH_API.getRole);
    const { status: httpStatus, data: result } = response || {};
    if (httpStatus === 200) {
      user = result.user;
    }
  } catch (error) {
    // do nothing
  }
}

async function getChangeRoleApi(role: string) {
  try {
    // const config = getAuthHeader();
    // const response = await instance.post(AUTH_API.changeRole, { role }, config);
    const response = await instance.post(AUTH_API.changeRole, { role });
    const { status: httpStatus, data: result } = response || {};
    if (httpStatus === 200) {
      user = result.user;
    }
  } catch (error) {
    // do nothing
  }
}

async function getUserRole() {
  await getRoleApi(); // refresh user info
  return user?.role;
}

async function getRoleList() {
  await getRoleApi(); // refresh user info
  return user?.roleList;
}

async function changeRole(role: string) {
  await getRoleApi(); // refresh user info
  if (user?.roleList?.includes(role)) {
    await getChangeRoleApi(role);
  }
  return user?.role;
}

const getHttpRequestResp = async ({ method, path, payload }: { method: string; path: string; payload?: any }) => {
  let response;
  try {
    await initClientApi();

    if (method === HTTP_REQUEST_TYPE.get) {
      response = await instance.get(`${path}?${payload ? qs.stringify(payload) : ''}`);
    } else if (method === HTTP_REQUEST_TYPE.post) {
      response = await instance.post(path, payload);
    } else if (method === HTTP_REQUEST_TYPE.delete) {
      response = await instance.delete(path, {
        data: payload,
      });
    }
  } catch (err: any) {
    if (err.code === AXIOS_ERR_CODE_TIMEOUT) {
      response = {
        // TODO: confirm error format
        // error: {
        //   status: 0,
        //   title: "TIME OUT",
        // },
        data: {
          errors: {
            status: 'ERROR',
            title: 'Timeout',
            detail: 'Timeout',
          },
        },
      };
    } else if (err.response?.data?.errors) {
      // api error
      response = err.response;
      console.error('error response', err.response.data.errors);
    } else if (err.response) {
      response = err.response;
      console.error('error response', err.response.data.errors);
    } else if (err.message && err.message === 'Network Error') {
      response = {
        data: {
          errors: {
            status: 'ERROR',
            title: 'Network Error',
            detail: 'Network Error',
          },
        },
      };
      console.log('Network Error', err);
    } else if (err.request) {
      console.log(err.request);
      if (err.request.error) {
        response = {
          data: {
            errors: {
              status: 'ERROR',
              title: 'Request Error',
              detail: `Request Error: ${err.request.error}`,
            },
          },
        };
      }
      response = {
        data: {
          errors: {
            status: 'ERROR',
            title: 'Request Error',
            detail: 'Request Error',
          },
        },
      };
    } else {
      console.log('error message', err.message);
      response = {
        data: {
          errors: {
            status: 'ERROR',
            title: 'Error',
            detail: err.message,
            requestId: err.requestId,
            applicationId: err.applicationId,
          },
        },
      };
    }
  }

  const { status: httpStatus, data: result } = response || {};

  if (httpStatus >= 200 && httpStatus < 300) {
    return result;
  }

  throw new serverError(result);
};

/**
 * axios http request wrapper
 * @return
 *  [err] when there is error
 *  [, resp] when request was successful
 */
let requestCount = 0;
const sendHttpRequest = ({
  method,
  path,
  apiTitle,
  payload,
  needLoading = true,
  hideErrorDialog = false,
}: {
  method: string;
  path: string;
  apiTitle?: string;
  payload?: any;
  needLoading?: boolean;
  hideErrorDialog?: boolean;
}) => {
  ReactGA.event({
    category: GOOGLE_ANALYTICS_EVENTS.category.callingAPI,
    action: `${GOOGLE_ANALYTICS_EVENTS.category.callingAPI} ${path}`,
  });

  if (needLoading) {
    requestCount++;
    store.dispatch(setLoading(true));
  }

  return getHttpRequestResp({
    method,
    path,
    payload,
  })
    .then((data) => [null, data])
    .catch((err) => {
      // TODO: error handling
      const { detail, applicationId, requestId } = err || {};

      ReactGA.event({
        category: GOOGLE_ANALYTICS_EVENTS.category.callingAPIError,
        action: `${GOOGLE_ANALYTICS_EVENTS.category.callingAPIError} ${path}`,
        label: `${requestId ? `[${requestId}] ` : ''}B${detail}`,
      });

      if (hideErrorDialog) {
        return [err];
      }

      if (err instanceof serverError) {
        showErrorAlert({
          title: apiTitle,
          message: detail,
          applicationId,
          requestId,
          ...(payload?.dismissCallBackFunc && {
            dismissCallBackFunc: payload.dismissCallBackFunc,
          }),
          errorStatus: err.status,
        });
        return [err.toJson()];
      }

      showErrorAlert({
        title: apiTitle,
      });
      return [err];
    })
    .finally(() => {
      if (needLoading) {
        requestCount--;
        if (requestCount === 0) {
          store.dispatch(setLoading(false));
        }
      }
    });
};

export {
  delay,
  sendHttpRequest,
  getUserRole,
  getRoleList,
  changeRole,
  showErrorAlert,
  clearCacheData,
  clearAllCacheData,
};
