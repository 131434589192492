import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import en from '../../../../translations/en';
import {
  DATE_FORMAT,
  FARE_TYPES,
  TRAVEL_PURPOSE,
  TRAVEL_TYPES,
  CARRIER_OPTION_MAP_TRANSLATION,
  ID_TICKET_TYPE,
} from '../../../../constants/constants';
import { TravellerIcon } from '../../../../assets/images';

import { formatDateAsString, isEmptyOrUndefined, isLtZoneType } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectBooking } from '../../../../slice/bookingSlice';

const SearchResultHeader = () => {
  const theme = useTheme();
  const { startDate, travelDetails, travelType, leisureTravelDetails } = useAppSelector(selectBooking) || {};

  const concession = travelDetails.title;
  const isDT = travelType === TRAVEL_PURPOSE.employeeDutyTravel;
  const totalTravellerCount =
    (leisureTravelDetails?.adultCount || 0) +
    (leisureTravelDetails?.childrenCount || 0) +
    (leisureTravelDetails?.infantsCount || 0);

  const carrierGroupKey =
    CARRIER_OPTION_MAP_TRANSLATION?.[leisureTravelDetails.carrierGroup as keyof typeof CARRIER_OPTION_MAP_TRANSLATION];
  const carrierGroupLabel =
    en.booking.carrierGroupLabels?.[carrierGroupKey as keyof typeof en.booking.carrierGroupLabels];

  let ltConcessionLabel = `${TRAVEL_TYPES.ZONE} : ${carrierGroupLabel}`;
  if (!isDT && leisureTravelDetails.fareType === FARE_TYPES.ID) {
    ltConcessionLabel = leisureTravelDetails?.label;
  }

  const { type: ltType, sub: ltSub } = leisureTravelDetails || {};
  // non-standby type means not `Zone` + not `SUBLO FOC` concession
  const isSubloadFocConcession = ltSub === ID_TICKET_TYPE.SUBLO && ltType === TRAVEL_TYPES.FOC;
  const isLtNonStandbyType = !isLtZoneType(ltType) && !isSubloadFocConcession && !isEmptyOrUndefined(ltType);

  return (
    <Box
      className="mobile_search_result_header"
      sx={{
        display: 'flex',
        flexDirection: isDT ? 'column' : 'row',
        textAlign: 'left',
        width: '100%',
        alignItems: isDT ? undefined : 'center',
        pr: 9,
      }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        variant="body_1_medium"
      >
        {isDT ? concession : ltConcessionLabel}
      </Typography>
      {!isDT && isLtNonStandbyType && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box
            sx={{
              width: '4px',
              height: '4px',
              borderRadius: '50%',
              bgcolor: theme.color.secondary.dark_grey.option_6,
              ml: 1,
              mr: 1,
            }}
          />
          <TravellerIcon fill={theme.palette.primary.light} />
          <Typography variant="body_1_medium" sx={{ ml: 1 }}>
            {totalTravellerCount}
          </Typography>
        </Box>
      )}
      <Typography variant="body_2_regular">
        {isDT && formatDateAsString(startDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}
      </Typography>
    </Box>
  );
};

export default SearchResultHeader;
