import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch } from 'react-redux';

import {
  USER_ROLE,
  TRAVEL_PURPOSE,
  DATE_FORMAT,
  CANCEL_BOOKING_ACTION,
  PAYMENT_CONCEPT,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { Frontend } from 'booking';
import { IBookingFlowOverlayProps, OVERLAY_VARIANT } from '../Common/BookingFlowOverlay';

import { showErrorAlert } from '../../../services/api';
import { getBooking, getBookingLT, cancelBooking, cancelBookingLT, refundBookingLT } from '../../../services/booking';
import { getAdminBooking, cancelBooking as cancelBookingAdmin } from '../../../services/admin/booking';
import {
  getBooking as getBookingDelegation,
  cancelBooking as cancelBookingDelegation,
} from '../../../services/delegation/booking';

import {
  BookingCompleted,
  BookingCompletedHeader,
  BookingFlowOverlay,
  BookingReferenceHeader,
  BookingSummary,
  ConfirmCancel,
  LtCancelBookingDialog,
} from '../..';
import { formatDateAsString, getTicketDetailsDataList, isOWOALBooking, getLocalDate } from '../../../helpers';

import { BookingSummaryState, BookingSummaryAction } from '../../../context';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectApp,
  setCancelBookingSuccessData,
  setRefundBookingSuccessData,
  setSnackBarData,
  setRetrieveAndPaySuccessData,
  setNormalPrepaidSuccessData,
} from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectAuth } from '../../../slice/authSlice';
import {
  selectBooking,
  setIsShowCancelDialog,
  initialState as bookingSliceInitialState,
  setStartDate,
  setDestination,
  setBookingStep,
  setTpAppRef,
  setViewBookingApplicationId,
  setIsCalledPrepaidAuth,
  setRetrieveAndPayCppParams,
  setPrepaidAuthFlowType,
} from '../../../slice/bookingSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { RebookFlowContainer } from '../Rebook';
import { MobileRefundDialog, FlownRefundDialog } from '../Refund';

const BookingFlowOverlayWrapper = ({
  isDrawer,
  open,
  titleComponent,
  handleCloseClick,
  isOverlayHeaderHidden,
  data,
  component,
  handleOpenRebookFlow,
  handleOpenRebookCompleted,
}: IBookingFlowOverlayProps) => {
  const dispatch = useDispatch();
  // remove pax flow
  const {
    isOpenRemovePaxMode,
    routeTo: removePaxModeRoute,
    isOpenRebookFlow,
    isOpenRebookCompleted,
    isRetrieveAndPayFlow,
  } = useContext(BookingSummaryState) || {};

  const {
    setTravellerList,
    setRemovalTravellerList,
    setRemovalFormData,
    setIsFlightRebook,
    setOriginalFlightDate,
    setBookingSummaryData,
    setIsRetrieveAndPayFlow,
    setIsExpandWarning,
    setIsOpenNotAllowRetrieveAndPaySegmentStatusWarning,
  } = useContext(BookingSummaryAction) || {};

  const getTitle = () => {
    // for remove pax mode
    const enBreadcrumbs = en.booking.confirmation.removePax.breadcrumbs;
    if (removePaxModeRoute?.step === 1) return enBreadcrumbs.removeTraveller;
    if (removePaxModeRoute?.step === 2) return enBreadcrumbs.reviewConfirm;

    return undefined;
  };

  useEffect(() => {
    handleOpenRebookFlow?.(isOpenRebookFlow);
  }, [isOpenRebookFlow]);

  useEffect(() => {
    handleOpenRebookCompleted?.(isOpenRebookCompleted);
  }, [isOpenRebookCompleted]);

  return (
    <BookingFlowOverlay
      {...{
        isDrawer,
        open,
        isOverlayHeaderHidden,
        data,
        component,

        ...(isOpenRemovePaxMode
          ? {
              title: getTitle(),
            }
          : {
              ...(isRetrieveAndPayFlow
                ? {
                    title: en.booking.retrieveAndPay.title,
                    handleBackClick() {
                      setIsRetrieveAndPayFlow(false);
                    },
                    handleCloseClick,
                  }
                : {
                    titleComponent,
                    handleCloseClick: () => {
                      // belong to close
                      setTravellerList([]);
                      setRemovalTravellerList([]);
                      setRemovalFormData({
                        bookingReference: '',
                        salutation: null,
                        lastName: '',
                        firstName: '',
                        isFormChecked: false,
                        hasTravelWithData: false,
                      });

                      setBookingSummaryData(null); // etp-3025 add
                      // belong to rebook logic
                      setIsFlightRebook(false); // etp-2915 hardcode for test

                      dispatch(setStartDate(formatDateAsString(new Date(), DATE_FORMAT.date)));
                      dispatch(setDestination(null));
                      dispatch(setCancelBookingSuccessData(null));
                      setOriginalFlightDate(null);
                      setIsExpandWarning(true);
                      setIsOpenNotAllowRetrieveAndPaySegmentStatusWarning(true);

                      handleCloseClick && handleCloseClick();
                    },
                  }),
            }),
      }}
    />
  );
};

const BookingSummaryOverlay = ({
  bookingSelected,
  handleClose: handleCloseFromParent,
  data,
}: {
  bookingSelected: Frontend.ParsedBooking | null;
  handleClose: () => void;
  data: any;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { ern } = useAppSelector(selectAuth) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { cancelBookingSuccessData, isDesktop } = useAppSelector(selectApp, shallowEqual) || {};
  const { role } = useAppSelector(selectUser) || {};
  const { isShowCancelDialog } = useAppSelector(selectBooking) || {};

  const { type: roleType, id: roleId } = role || {};
  const { bookingResult, applicationId, isFromCompleted } = data || {};
  const { isOpenRemoveToCancelDialog, isOpenConfirmToCancelDialog, isOpenFlownRefundDialog, bookingEligibility } =
    useContext(BookingSummaryState) || {};

  const {
    setBookingEligibility,
    setBookingSummaryData,
    setIsOpenRemovePaxMode,
    setTravellerList,
    setIsOpenRemoveToCancelDialog,
    setIsOpenConfirmToCancelDialog,
    setRemovalTravellerList,
    setRemovalFormData,
    setIsExpandWarning,
    setIsOpenFlownRefundDialog,
    setIsRetrieveAndPayFlow,
  } = useContext(BookingSummaryAction) || {};

  const [bookingSummary, setBookingSummary] = useState<Frontend.ParsedBooking | null | undefined>(
    bookingResult?.booking,
  );
  const [openRebookFlow, setOpenRebookFlow] = useState(false);
  const [openRebookCompleted, setOpenRebookCompleted] = useState(false);

  const { recLoc, type } = bookingSummary || {};
  const isDT = type === TRAVEL_PURPOSE.employeeDutyTravel;

  const [isCheckedIn, setIsCheckedIn] = useState<boolean | null | undefined>(bookingResult?.isCheckedIn);
  const [isTicketExpired, setIsTicketExpired] = useState<boolean | null | undefined>(bookingResult?.isTicketExpired);

  const [refreshBookingError, setRefreshBookingError] = useState<
    Pick<Frontend.Booking, 'amadeusError' | 'requestId' | 'applicationId'> | null | undefined
  >(
    bookingResult && bookingResult.amadeusError
      ? {
          amadeusError: bookingResult?.amadeusError,
          requestId: bookingResult?.requestId,
          applicationId: bookingResult?.applicationId,
        }
      : undefined,
  );

  const [isOpenCurrentCancelDialog, setIsOpenCurrentCancelDialog] = useState(false);

  // get current booking API data
  const getBookingAction = useCallback(
    async (response = null) => {
      if (!response) {
        const { type } = data || {};
        if (type === TRAVEL_PURPOSE.employeeDutyTravel) {
          if (roleType === USER_ROLE.admin) {
            response = await getAdminBooking(applicationId);
          } else if (roleType === USER_ROLE.delegation) {
            response = await getBookingDelegation(roleId, applicationId);
          } else {
            response = await getBooking(ern, applicationId);
          }
        } else if (type === TRAVEL_PURPOSE.employeeLeisureTravel) {
          response = await getBookingLT(ern, applicationId);
        }
      }

      if (response) {
        const { booking, isCheckedIn, bookingEligibility, amadeusError, requestId, applicationId, isTicketExpired } =
          response || {};

        setBookingSummary(booking);
        setIsCheckedIn(isCheckedIn);
        setIsTicketExpired(isTicketExpired);
        setBookingSummaryData?.(booking);
        setBookingEligibility?.(bookingEligibility);

        if (amadeusError) {
          setRefreshBookingError({ amadeusError, requestId, applicationId });
        }
      } else {
        // close view booking detail overlay when no resp
        handleCloseFromParent?.();
      }
    },
    [data?.type, roleType, roleId, applicationId, ern],
  );

  useEffect(() => {
    // reset context data when enter view detail page
    setIsOpenRemovePaxMode(false);
    setIsExpandWarning(true);
    setIsRetrieveAndPayFlow(false); // etp-4713 add

    if (applicationId) {
      let bookingTimer: any = setTimeout(() => {
        getBookingAction();

        // check if tune on for re-pricing case and call re-pricing API.
        //...

        clearTimeout(bookingTimer);
        bookingTimer = null;
      }, 250);
    } else {
      // clear viewed booking detail data
      setBookingSummary(null);
      setBookingSummaryData?.(null);
      setTravellerList([]);
      setRemovalTravellerList([]);
      setRemovalFormData({
        bookingReference: '',
        salutation: null,
        lastName: '',
        firstName: '',
        isFormChecked: false,
        hasTravelWithData: false,
      });
    }
  }, [applicationId]);

  useEffect(() => {
    if (!bookingResult && bookingSummary && !bookingSelected) {
      setTimeout(() => setBookingSummary(null), 250);
    } else if (!bookingSummary && bookingResult) {
      setTimeout(() => {
        setBookingSummary(bookingResult.booking);
        setIsCheckedIn(bookingResult.isCheckedIn);
        setIsTicketExpired(bookingResult.isTicketExpired);
        setBookingEligibility?.(bookingResult.bookingEligibility);
        setBookingSummaryData?.(bookingResult.booking);
      }, 250);
    }

    if (!bookingSummary && !bookingResult) {
      // clear viewed booking detail data
      setBookingSummary(null);
      setIsCheckedIn(false);
      setIsTicketExpired(false);
      setBookingSummaryData?.(null);
      setBookingEligibility?.(null);
    }
  }, [bookingResult, bookingSummary, bookingSelected]);

  useEffect(() => {
    if (refreshBookingError) {
      const { amadeusError, requestId, applicationId } = refreshBookingError || {};
      showErrorAlert({
        title: en.errorAlert.getBooking,
        message: amadeusError,
        requestId,
        applicationId,
      });
    }
  }, [refreshBookingError]);

  useEffect(() => {
    // after cancel booking from confirm cancel page
    // need to refresh
    if (cancelBookingSuccessData?.flightNumber || cancelBookingSuccessData?.removePaxLabel) {
      if (isFromCompleted) {
        navigate('/');
      }
    }
  }, [cancelBookingSuccessData?.flightNumber, cancelBookingSuccessData?.removePaxLabel]);

  useEffect(() => {
    setIsOpenCurrentCancelDialog(!!isShowCancelDialog);
  }, [isShowCancelDialog]);

  useEffect(() => {
    if (!isOpenCurrentCancelDialog) {
      dispatch(setIsShowCancelDialog(bookingSliceInitialState.isShowCancelDialog));
    }
  }, [isOpenCurrentCancelDialog]);

  const bookingResultForOverlay = {
    booking: bookingSummary,
  };

  const handleCloseDialog = () => {
    dispatch(setIsShowCancelDialog(false));
  };

  const { carrier, flightNum } = bookingSummary?.ticketsBySegment?.[0] || {};
  const flightNumber = `${carrier}${flightNum}`;
  const isOWOALFlight = isOWOALBooking(flightNum || '');

  const isCancelAndRefund = bookingEligibility?.CANCEL_BOOKING && bookingEligibility?.REFUND;

  const cancelBookingAction = async () => {
    let response;

    if (roleType === USER_ROLE.admin) {
      response = await cancelBookingAdmin(bookingSummary?.applicationId || '');
    } else if (roleType === USER_ROLE.delegation) {
      response = await cancelBookingDelegation(roleId, bookingSummary?.applicationId || '');
    } else if (isDT) {
      response = await cancelBooking(ern, bookingSummary?.applicationId || '');
    } else {
      let action = CANCEL_BOOKING_ACTION.cancelBookingAndTicket;

      if (bookingSummary?.paymentConcept === PAYMENT_CONCEPT.PREPAID) {
        action = CANCEL_BOOKING_ACTION.cancelBooking;
      }

      response = await cancelBookingLT(ern, bookingSummary?.applicationId || '', action);
    }

    if (response) {
      dispatch(
        setCancelBookingSuccessData({
          flightNumber: isOpenRemoveToCancelDialog || isOpenConfirmToCancelDialog ? '' : flightNumber,
          isShow: true,
          removePaxLabel: getLocalDate() + '',
        }),
      );
      getBookingAction(response);

      const travellerDetails = getTicketDetailsDataList({
        ticketsBySegment: response?.booking?.ticketsBySegment || [],
        etpPassengerList: response?.booking?.etpPassengerList || [],
        configurations,
      });
      setTravellerList(travellerDetails);
      // TODO: backend refund integration
      dispatch(setRefundBookingSuccessData({ applicationId: response?.applicationId }));

      // close dialog
      handleCloseDialog();

      setBookingEligibility(null);
      setIsOpenRemovePaxMode(false);
      setIsOpenRemoveToCancelDialog(false);
      setIsOpenConfirmToCancelDialog(false);
    }
  };

  const confirmRefundAction = async () => {
    let response;

    if (isCancelAndRefund) {
      response = await cancelBookingLT(ern, bookingSummary?.applicationId || '', CANCEL_BOOKING_ACTION.cancelAndRefund);
    } else {
      response = await refundBookingLT(ern, bookingSummary?.applicationId || '');
    }

    if (response) {
      getBookingAction(response);

      // Update Traveller details
      const travellerDetails = getTicketDetailsDataList({
        ticketsBySegment: response?.booking?.ticketsBySegment || [],
        etpPassengerList: response?.booking?.etpPassengerList || [],
        configurations,
      });
      setTravellerList(travellerDetails);

      //update More action button list
      setBookingEligibility?.(response?.bookingEligibility);

      // Open Close to refund box
      setIsOpenFlownRefundDialog(false);

      dispatch(setRefundBookingSuccessData({ applicationId: response.applicationId }));

      dispatch(
        setSnackBarData({
          message: en.booking.confirmation.cancelActions.refund.refundSnackBarMsg,
          isShow: true,
        }),
      );
    }
  };

  const handleBookingFlowOverlayCloseClick = () => {
    // clear some default prepaid redux data
    dispatch(setIsCalledPrepaidAuth(false));
    dispatch(setRetrieveAndPayCppParams(null));

    dispatch(setBookingStep(null));

    dispatch(setPrepaidAuthFlowType(''));
    dispatch(setRetrieveAndPaySuccessData(null));
    dispatch(setNormalPrepaidSuccessData(null));

    dispatch(setViewBookingApplicationId(''));
    dispatch(setTpAppRef(''));

    setIsRetrieveAndPayFlow(false);

    handleCloseFromParent?.();
  };

  return (
    <>
      <BookingFlowOverlayWrapper
        isDrawer={true}
        open={bookingSelected !== null}
        titleComponent={BookingReferenceHeader}
        handleCloseClick={handleBookingFlowOverlayCloseClick}
        isOverlayHeaderHidden={false}
        handleOpenRebookFlow={(isOpen) => {
          setOpenRebookFlow(isOpen);
        }}
        handleOpenRebookCompleted={(isOpen) => {
          setOpenRebookCompleted(isOpen);
        }}
        data={{
          ...data,
          recLoc: isOWOALFlight ? en.common.empty : recLoc,
          getBookingAction,
          isCheckedIn,
          isTicketExpired,
          bookingSummary,
        }}
        component={BookingSummary}
      />
      {/* TODO refactor as 1 component */}
      {isOpenCurrentCancelDialog &&
        (isDesktop ? (
          <LtCancelBookingDialog
            open={isOpenCurrentCancelDialog}
            handleCancelBookingAction={cancelBookingAction}
            handleCloseDialog={handleCloseDialog}
            dialogContent={{
              title: en.booking.cancelBooking.title,
              bookingResult: bookingResultForOverlay,
              yesTitle: en.common.yesCancel,
              noTitle: en.common.no,
            }}
          />
        ) : (
          <BookingFlowOverlay
            component={ConfirmCancel}
            title={en.booking.confirmation.confirmCancel.title}
            open={isOpenCurrentCancelDialog}
            isDrawer={true}
            data={{
              bookingResult: bookingResultForOverlay,
              handleCloseClick: handleCloseDialog,
              handleCancelBookingAction: cancelBookingAction,
            }}
          />
        ))}
      {/* open confirm to flown refund dialog */}
      {isOpenFlownRefundDialog &&
        (isDesktop ? (
          <FlownRefundDialog
            open={isOpenFlownRefundDialog}
            handleCancelBookingAction={confirmRefundAction}
            handleCloseDialog={() => {
              setIsOpenFlownRefundDialog(false);
            }}
            dialogContent={{
              title: isCancelAndRefund
                ? en.booking.confirmation.cancelActions.refund.cancelAndRefundBookingOverlayTitle
                : en.booking.confirmation.cancelActions.refund.refundBookingOverlayTitle,
              bookingResult: bookingResultForOverlay,
              yesTitle: en.common.yesConfirm,
              noTitle: en.common.no,
            }}
          />
        ) : (
          <BookingFlowOverlay
            component={MobileRefundDialog}
            title={en.booking.confirmation.cancelActions.refund.reviewRefund}
            open={isOpenFlownRefundDialog}
            isDrawer={true}
            data={{
              bookingResult: bookingResultForOverlay,
              handleCloseClick: () => {
                setIsOpenFlownRefundDialog(false);
              },
              handleCancelBookingAction: confirmRefundAction,
            }}
          />
        ))}
      {openRebookFlow && <RebookFlowContainer isRebookFromMyBooking={data?.isRebookFromMyBooking} />}
      {openRebookCompleted && (
        <BookingFlowOverlay
          component={BookingCompleted}
          open={openRebookCompleted}
          variant={OVERLAY_VARIANT.BOOKING_COMPLETED}
          titleComponent={BookingCompletedHeader}
          isDrawer={true}
          data={{
            bookingResult: bookingResultForOverlay,
            isRebook: true,
            isRebookFlow: true,
          }}
        />
      )}
    </>
  );
};

export default BookingSummaryOverlay;
