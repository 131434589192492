import React, { useRef } from 'react';
import { useTheme, Autocomplete, TextField, SxProps, Theme } from '@mui/material';

import { DownArrowIcon } from '../../../assets/images';
import { UI_STYLES } from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';

import { useAppSelector } from '../../../app/hooks';

import { selectApp } from '../../../slice/appSlice';

export interface IConcessionDropdownContainer<T> {
  dropdownOptions: T[];
  dropdownLabel: string;
  onChangeDropdownOptionItem: (newValue: T | null) => void;
  selectedOptionItem: T | null;
  inputPropsCustomForTextField?: React.InputHTMLAttributes<HTMLInputElement>;
  autoCompleteDropdownCustomStyles?: SxProps<Theme>;
}

const ConcessionDropdownContainer = <T,>({
  dropdownOptions,
  dropdownLabel,
  onChangeDropdownOptionItem,
  selectedOptionItem,
  inputPropsCustomForTextField,
  autoCompleteDropdownCustomStyles,
}: IConcessionDropdownContainer<T>) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Autocomplete
      disablePortal
      options={dropdownOptions}
      size="small"
      sx={{
        ...(isDesktop ? { width: '220px', mr: '8px' } : { width: '100%', mb: 1 }),
        '.MuiFilledInput-root': {
          height: UI_STYLES.concessionTravellerHeight,
          borderRadius: 0.5,
          border: `1px solid ${theme.color.secondary.slate.option_3}`,
          background: theme.palette.primary.light,
          color: theme.color.secondary.dark_grey.option_3,
          pt: 0,
          '&.Mui-error': {
            border: `1px solid ${theme.color.secondary.slate.option_3}`,
          },
          '&.Mui-selected, &:hover, &.Mui-filled': {
            background: 'white',
          },
          '&.Mui-focused': {
            background: theme.palette.primary.light,
            border: `1px solid ${theme.color.secondary.slate.option_3}`,
            '&.Mui-error': {
              border: `1px solid ${theme.color.secondary.slate.option_3}`,
            },
          },
          '&.Mui-disabled': {
            background: theme.color.utility.unselectable.option_7,
          },
          '&: after, &: before': {
            display: 'none',
          },
          // input style
          '.MuiFilledInput-input': {
            mt: 1.75,
            fontWeight: FONT_WEIGHT.medium,
          },
        },
        '.MuiInputLabel-root': {
          color: theme.color.secondary.grey.option_3,
          '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
            color: theme.color.secondary.grey.option_3,
          },
        },
        ...autoCompleteDropdownCustomStyles,
      }}
      clearIcon={null}
      popupIcon={<DownArrowIcon />}
      value={selectedOptionItem}
      onChange={(_, newValue: T | null) => {
        onChangeDropdownOptionItem(newValue);
      }}
      includeInputInList
      renderInput={(params) => {
        return (
          <TextField
            sx={{
              height: UI_STYLES.concessionTravellerHeight,
            }}
            variant="filled"
            {...params}
            inputRef={inputRef}
            inputProps={{
              ...params.inputProps,
              disabled: true,
              ...inputPropsCustomForTextField,
            }}
            label={dropdownLabel}
          />
        );
      }}
    />
  );
};
export default ConcessionDropdownContainer;
