import { FormErrors } from 'redux-form';
import { validateRequiredFields, isLevelZEmployee, isAssoSubsid } from '../../../helpers';
import { IDtTravelDetails } from '../../../interfaces';

const levelZREquiredFields = ['purpose', 'description', 'costCentre', 'accountCode'];

const nonLevelZRequiredFields = [...levelZREquiredFields, 'endorserGalaCXyId'];

const assoSubsidNonLevelZRequiredFields = [
  ...levelZREquiredFields,
  'supervisorEmployeeId',
  'supervisorGalaCXyId',
  'supervisorName',
];

export const validate = (values: IDtTravelDetails, props: any): FormErrors<IDtTravelDetails> => {
  const errors: FormErrors<IDtTravelDetails> = {};
  const isLevelZUser = isLevelZEmployee(props);

  if (isAssoSubsid(props)) {
    validateRequiredFields(values, isLevelZUser ? levelZREquiredFields : assoSubsidNonLevelZRequiredFields, errors);
  } else {
    // Level Z user will not validate endorserGalaCXyId field
    validateRequiredFields(values, isLevelZUser ? levelZREquiredFields : nonLevelZRequiredFields, errors);
  }
  return errors;
};
