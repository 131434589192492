import React, { useContext, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { CheckMarkIcon, ExclamationMark } from '../../../assets/images';
import { PAYMENT_MEDIUM } from '../../../constants/constants';

import {
  getAdminCurrentContext,
  checkIsAssoSubsidAdminPage,
  getAdminCurrentProfileContextData,
  isAssoSubsid,
} from '../../../helpers';

import { AssoSubsidAdminEmployeeAction } from '../../../context';
import { inactivateAssoSubsidBillingAgreement, inactivateBillingAgreement } from '../../../services/admin/employee';

import { CancelDialog } from '../../../components';
import { AdminActionButton, SnackbarComponent } from '../..';

// admin page/ asso-subsid page -> Inactivate billing agreement
const InactivateBillingAgreementContainer = () => {
  const theme = useTheme();

  const [isShowCancelPopup, setIsShowCancelPopup] = useState<boolean>(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);

  const adminCurrentContext = getAdminCurrentContext();
  const isAssoSubsidAdminPage = checkIsAssoSubsidAdminPage();
  const adminProfileData = getAdminCurrentProfileContextData();
  const { employeeId: userId, revision: profileRevision } = adminProfileData;

  const { employeeDetail, editEmployeeDetail, isEditingAdminProfile } = adminCurrentContext.currentContextState || {};

  const { setEmployeeDetail, setIsEditingAdminProfile, setEditEmployeeDetail, setEditEmployeeError } =
    adminCurrentContext.currentContextAction || {};
  const { setProfileDetail } = useContext(AssoSubsidAdminEmployeeAction) || {};

  const { profile, profileDetail } = employeeDetail || {};
  const isEmptyEditEmployeeDetail = editEmployeeDetail === null; // no fields change

  // enable inactivate billing agreement button condition:
  // in edit mode + no field edit + isPayPalBillingAgreementSetup + paymentMedium is Paypal
  const checkIsPaypalPaymentWithSetupBillingAgreement = (profile: any) => {
    return profile?.isPayPalBillingAgreementSetup && profile?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT;
  };

  const isEnabledInactivateButton = useMemo(() => {
    const currentProfile = isAssoSubsidAdminPage ? profileDetail : profile;
    return (
      isEditingAdminProfile &&
      isEmptyEditEmployeeDetail &&
      checkIsPaypalPaymentWithSetupBillingAgreement(currentProfile)
    );
  }, [isEditingAdminProfile, isEmptyEditEmployeeDetail, profileDetail, profile]);

  const { inactivateBillingAgreement: inactivateBillingAgreementLbl } =
    en.admin.feature.personalInformation.paymentDetails;

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <AdminActionButton
        textLabel={inactivateBillingAgreementLbl.title}
        onClick={() => {
          setIsShowCancelPopup(true);
        }}
        isEnabled={isEnabledInactivateButton}
      />

      {!isEmptyEditEmployeeDetail && profile?.isPayPalBillingAgreementSetup && (
        <Typography
          variant="navigation_regular"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: theme.color.secondary.dark_grey.option_2,
            mt: 1,
          }}
        >
          <ExclamationMark
            height="14"
            width="14"
            style={{ marginRight: 4, flex: 'none' }}
            fill={theme.color.secondary.dark_grey.option_2}
          />
          {inactivateBillingAgreementLbl.notes}
        </Typography>
      )}

      <CancelDialog
        open={isShowCancelPopup}
        handleConfirmAction={async () => {
          const result = isAssoSubsid(profileDetail)
            ? await inactivateAssoSubsidBillingAgreement({
                userId,
                profileRevision,
              })
            : await inactivateBillingAgreement({
                userId,
                profileRevision,
              });

          if (result) {
            // Asso/Subsid Admin Maintain Employee Details page
            if (isAssoSubsidAdminPage) {
              setProfileDetail({
                ...employeeDetail.profileDetail,
                isPayPalBillingAgreementSetup: false,
              });
            } else {
              // CX Admin Maintain Employee Details page
              setEmployeeDetail({
                ...employeeDetail,
                profile: {
                  ...profile,
                  isPayPalBillingAgreementSetup: false,
                },
                editFlownSuspensionList: undefined,
              });
            }

            // reset to view mode
            setIsEditingAdminProfile(false);
            setEditEmployeeDetail(null);
            setEditEmployeeError(null);

            // show succeed snackBar
            setIsOpenSnackbar(true);
            setTimeout(() => {
              setIsOpenSnackbar(false);
            }, 3000);
          }
        }}
        handleCloseDialog={() => {
          setIsShowCancelPopup(false);
        }}
        showCancelIcon={false}
        dialogContent={{
          title: `${inactivateBillingAgreementLbl.title}${inactivateBillingAgreementLbl.questionMark}`,
          message: inactivateBillingAgreementLbl.message,
          noTitle: en.common.no,
          yesTitle: inactivateBillingAgreementLbl.yesInactivate,
        }}
      />

      <SnackbarComponent
        open={isOpenSnackbar}
        toastNotification={inactivateBillingAgreementLbl.inactivateSucceed}
        IconComponent={<CheckMarkIcon />}
        handleClose={() => {}}
      />
    </Box>
  );
};

export default InactivateBillingAgreementContainer;
