import { Box, useTheme, Typography, SxProps, Theme } from '@mui/material';

import { StarIcon, AddIconExcludeBorder } from '../../../assets/images';

import { IParagraphMasterContainerProps } from '../../../containers/Common/Paragraph/ParagraphMasterContainer';

import { HorizontalDivider } from '../../../components';

interface ICartContainer<T> {
  isShowStar: boolean;
  currentCartItemQuantity?: number;
  maxCartItemQuantity?: number;
  cartLabel: {
    headerTitle: string;
    headerDescription: string;
    headerSubTitle: string;
    secondCartItemListTitle: string;
    isNextYearNominationFlow: boolean;
  };
  containerCustomProps?: SxProps<Theme>;
  cartItemList: T[];
  secondCartItemList: T[];
  isAllowAddToCart?: boolean;
  addToCartTitle?: string;
  addNomineeToCartAction?: () => void;
  cartContentDescriptionProps?: {
    component: React.ComponentType<any>;
    // if check paragraph details, please refer to <ParagraphMasterContainer>'s interface
    componentProps:
      | IParagraphMasterContainerProps
      // allow custom props to override the component for flexibility
      | {
          [key: string]: any;
        };
  };
}

const CartContainer = <T,>({
  isShowStar = false,
  currentCartItemQuantity,
  maxCartItemQuantity,
  cartLabel,
  containerCustomProps = {},
  cartItemList = [],
  secondCartItemList = [],
  isAllowAddToCart = false,
  addToCartTitle = '',
  addNomineeToCartAction,
  cartContentDescriptionProps,
}: ICartContainer<T>) => {
  const theme = useTheme();

  const CartContentDescriptionComponent = cartContentDescriptionProps?.component;

  const textLabelColor = cartLabel.isNextYearNominationFlow ? 'white' : theme.color.secondary.dark_grey.option_3;

  return (
    <Box
      className="cart_container"
      sx={{
        backgroundColor: theme.color.secondary.dark_grey.option_8,
        borderRadius: '8px',
        p: 2,
        pb: 1,
        mb: 2,
        ...containerCustomProps,
      }}
    >
      <Box className="cart_header" sx={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
        {isShowStar && (
          <Box sx={{ mr: 0.625, mt: 0.375 }}>
            <StarIcon />
          </Box>
        )}

        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <Box
            className="cart_header_title"
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Typography
              className="cart_title"
              variant="headline_medium"
              sx={{
                color: textLabelColor,
              }}
            >
              {cartLabel.headerTitle}
            </Typography>

            {currentCartItemQuantity !== undefined && maxCartItemQuantity !== undefined && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'last baseline',
                  ml: 'auto',
                }}
              >
                <Typography variant="headline_bold" sx={{ mt: '1px' }} color={textLabelColor}>
                  {currentCartItemQuantity || 0}
                </Typography>
                <Typography variant="navigation_medium" color={textLabelColor}>{`/${maxCartItemQuantity}`}</Typography>
              </Box>
            )}
          </Box>

          <Box className="header_description" sx={{ mt: 1, minHeight: '16px' }}>
            <Typography variant="navigation_regular" color={textLabelColor}>
              {cartLabel.headerDescription}
            </Typography>
          </Box>
        </Box>
      </Box>

      {CartContentDescriptionComponent && (
        <CartContentDescriptionComponent {...cartContentDescriptionProps?.componentProps} />
      )}

      {cartItemList.length > 0 && (
        <Box className="cart_content" sx={{ mt: 1.5, position: 'relative' }}>
          <Typography
            variant="headline_medium"
            sx={{
              mt: -0.5,
              mb: 2,
              color: textLabelColor,
            }}
          >
            {cartLabel.headerSubTitle}
          </Typography>

          {cartItemList.map((item: T, index: number) => {
            const { component: CustomComponent } = item as unknown as { component: React.ComponentType<any> };
            if (CustomComponent) return <CustomComponent key={`${CustomComponent.name}_${index}`} {...item} />;
            return null;
          })}
        </Box>
      )}

      {secondCartItemList.length > 0 && (
        <Box className="second_cart_content" sx={{ mt: 1.5, position: 'relative' }}>
          {cartItemList.length > 0 && (
            <HorizontalDivider
              customStyles={{
                mx: 0,
                ...(cartLabel.isNextYearNominationFlow && {
                  background: theme.color.secondary.slate.option_3,
                  color: theme.color.secondary.slate.option_2,
                }),
              }}
            />
          )}
          <Typography
            variant="headline_medium"
            sx={{
              mt: -0.5,
              mb: 2,
              color: textLabelColor,
            }}
          >
            {cartLabel.secondCartItemListTitle}
          </Typography>

          {secondCartItemList.map((item: T, index: number) => {
            const { component: CustomComponent } = item as unknown as { component: React.ComponentType<any> };
            if (CustomComponent) return <CustomComponent key={`${CustomComponent.name}_${index}`} {...item} />;
            return null;
          })}
        </Box>
      )}

      {isAllowAddToCart && (
        <Box
          className="add_to_cart_title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: '8px',
            py: '13px',
            px: 2,
            boxShadow: theme.palette.boxShadow.dark,
            cursor: 'pointer',
            mt: 1,
            mb: 1,
            position: 'relative',
          }}
          onClick={addNomineeToCartAction}
        >
          <Box sx={{ width: 24, height: 24 }}>
            <AddIconExcludeBorder width={24} height={24} />
          </Box>
          <Typography variant="body_2_medium" color={theme.color.utility.link.option_3}>
            {addToCartTitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CartContainer;
