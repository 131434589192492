import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Box, Typography, useTheme, IconButton, Grid } from '@mui/material';

import { BackIcon } from '../../../assets/images';
import {
  TRAVELLER_TYPE,
  SEAT_CLASS,
  CARRIER_OPTION,
  OWOAL_TYPE,
  FARE_TYPES,
  ID_TICKET_TYPE,
  ALLOTMENT_TYPE,
  STFD_TYPE,
  DATE_FORMAT,
} from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import en from '../../../translations/en';

import { IAllotmentDetails, ILtConcession, ILtConcessionGroup, IRestrictionBeneficiaryType } from '../../../interfaces';
import { IDisplayDetails } from '../../AdminMaintainEmployeeDetail';

import {
  getDisplayLabel,
  getInvalidFJClassTravellerWarningMessage,
  checkIsFocFare,
  checkIsId50Fare,
  checkIsZoneFare,
  formatDMYDateWithSpace,
  formatDateAsString,
  isWidow,
  getFirstDayOfYear,
} from '../../../helpers';
import { handleBackToUserProfile } from '../../../helpers/user';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { ConcessionInProfileState } from '../../../context';

import { ConcessionWithTraveller, ConcessionWithYear } from '../../../containers';
import { CommonWarning } from '../../../components';
import { LabelValueContainer } from '../../AdminMaintainEmployeeDetail';

const ConcessionDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser) || {};

  const {
    concessionYearDropdownData: { selectedOptionItem: selectedYear, dropdownOptions: yearOptions },
    nominatedPaxListDropdownData: { selectedOptionItem: selectedTraveller },
    concessionWithAllotmentData: {
      isFetching: isFetchingConcessionWithAllotment,
      originData: {
        concession: ltConcessionList,
        allotment: ltAllotmentList,
        restrictionBeneficiaryType: ltRestrictionBeneficiaryType,
      },
    },
  } = useContext(ConcessionInProfileState) || {};

  const [validConcession, setValidConcession] = useState<ILtConcessionGroup[]>([]);

  const desktopContainerWidth = '452px';

  const concessionPageLbl = en.userProfile.concession;

  const relationshipDisplayLabel = !selectedTraveller
    ? ''
    : // for Widow role business logic, please refer to previous story [ETP-4829]
      `(${
        selectedTraveller?.beneficiaryTypeCode === TRAVELLER_TYPE.employee && !isWidow(profile)
          ? concessionPageLbl.self
          : selectedTraveller?.relationship
      }) `;

  // [ETP-5614] suppose max number of concession years is 2 ( current + next year)
  const isDisplayNextYear = yearOptions.length === 2 && selectedYear === yearOptions[1];

  const asOfDateLabel = isDisplayNextYear
    ? getFirstDayOfYear(selectedYear, DATE_FORMAT.ddmmmyyyy)
    : formatDateAsString(new Date(), DATE_FORMAT.ddmmmyyyy);

  useEffect(() => {
    if (selectedTraveller) {
      const concessionWithTraveller = ltConcessionList.filter((item: ILtConcession) => {
        return selectedTraveller.eligibleConcession?.includes(item._id);
      });
      // filter valid concession
      const tempConcession: ILtConcession[] = [];
      concessionWithTraveller.map((concessionItem: ILtConcession) => {
        const errConcession = ltRestrictionBeneficiaryType.find(
          (restrictionItem: IRestrictionBeneficiaryType) =>
            concessionItem?.restriction.beneficiaryType?.includes(restrictionItem._id) &&
            selectedTraveller.beneficiaryTypeCode === restrictionItem.beneficiaryType &&
            selectedTraveller?.age < restrictionItem.ageToYear,
        );
        concessionItem.label = concessionPageLbl.viewMore;
        concessionItem.expanded = false;
        delete concessionItem.errConcession;
        if (errConcession) {
          concessionItem.errConcession = {
            age: errConcession?.ageToYear,
            class: errConcession?.bookingClass,
          };
        }
        tempConcession.push(concessionItem);
      });
      const allotmentDetails: IAllotmentDetails[] = ltAllotmentList;
      tempConcession.map((concessionItem: ILtConcession, index: number) => {
        const defId = concessionItem.concessionDef?._id;
        const groupId = concessionItem?.group;
        const validAllotment = allotmentDetails?.filter(
          (item: IAllotmentDetails) =>
            item.concessionDefId === defId &&
            item.concessionGroupId === groupId &&
            ((concessionItem.allotmentType === ALLOTMENT_TYPE.P && concessionItem.allotmentType === item.quotaType) ||
              item.dependentId === selectedTraveller.dependentId),
        );

        const sortedList = _.sortBy(validAllotment, (item: IAllotmentDetails) => item.startDate);
        tempConcession[index].allotmentDetails = sortedList;
      });
      // concession sorting by carrierGroup
      // CX
      const tempConcessionOfCX = tempConcession.filter((item) => item.concessionDef.carrierGroup === CARRIER_OPTION.CX);

      // OW
      const tempConcessionOfOW = tempConcession.filter((item) => item.concessionDef.carrierGroup === OWOAL_TYPE.OW);

      // OAL
      const tempConcessionOfOAL = tempConcession.filter((item) => item.concessionDef.carrierGroup === OWOAL_TYPE.OAL);

      // concession sorting by discount
      const sortFunc = (concessions: ILtConcession[]) => {
        const noSubLoadFocConcessions: ILtConcession[] = [];
        const subLoadFocConcessions: ILtConcession[] = [];
        const zoneConcessions: ILtConcession[] = [];
        const id50Concessions: ILtConcession[] = [];
        const oneworldConcessions: ILtConcession[] = [];
        const otherAirlineConcessions: ILtConcession[] = [];
        const othersConcession: ILtConcession[] = [];

        // handle the rest of concession which would be out of ZONE CX/FOC/ID50/OW/OAL in future.
        concessions.forEach((item: ILtConcession) => {
          const { fareType, fareDiscount, travelType, carrierGroup } = item.concessionDef || {};

          // Handle NOSUB concession
          if (travelType === ID_TICKET_TYPE.NOSUB && fareType === FARE_TYPES.ID) {
            if (checkIsFocFare({ fareType, fareDiscount })) noSubLoadFocConcessions.push(item);
            if (checkIsId50Fare({ fareType, fareDiscount })) id50Concessions.push(item);
            return;
          } else if (travelType === ID_TICKET_TYPE.SUBLO && fareType) {
            if (checkIsFocFare({ fareType, fareDiscount })) subLoadFocConcessions.push(item);
            if (checkIsZoneFare({ fareType, fareDiscount })) {
              // handle ZONE CX concession
              zoneConcessions.push(item);
              return;
            }

            // handle SUBLO concession ( LT ZONE CX / OWOAL)
            if (carrierGroup === OWOAL_TYPE.OW) {
              // handle OW concession
              oneworldConcessions.push(item);
              return;
            } else if (carrierGroup === OWOAL_TYPE.OAL) {
              // handle OAL concession
              otherAirlineConcessions.push(item);
              return;
            }

            return;
          } else {
            othersConcession.push(item);
            return;
          }
        });

        //  re-group all data
        // oc, zone, id50 groups need to groupBy displayName
        const groupNoSubLoadFoc = _.groupBy(
          _.sortBy(noSubLoadFocConcessions, (item: ILtConcession) => item.displayName),
          (item: ILtConcession) => item?.concessionDef?.displayName,
        );
        const groupSubLoadFoc = _.groupBy(
          _.sortBy(subLoadFocConcessions, (item: ILtConcession) => item.displayName),
          (item: ILtConcession) => item?.concessionDef?.displayName,
        );
        const groupZone = _.groupBy(
          _.sortBy(zoneConcessions, (item: ILtConcession) => item.displayName),
          (item: ILtConcession) => item?.concessionDef?.displayName,
        );
        const groupID50 = _.groupBy(
          _.sortBy(id50Concessions, (item: ILtConcession) => item.displayName),
          (item: ILtConcession) => item?.concessionDef?.displayName,
        );
        const groupOW = _.groupBy(
          _.sortBy(oneworldConcessions, (item: ILtConcession) => item.displayName),
          (item: ILtConcession) => item?.concessionDef?.displayName,
        );
        const groupOAL = _.groupBy(
          _.sortBy(otherAirlineConcessions, (item: ILtConcession) => item.displayName),
          (item: ILtConcession) => item?.concessionDef?.displayName,
        );
        const groupOC = _.groupBy(
          _.sortBy(othersConcession, (item: ILtConcession) => item.displayName),
          (item: ILtConcession) => item?.concessionDef?.displayName,
        );
        // map data
        const foc: ILtConcessionGroup[] = [];
        const zone: ILtConcessionGroup[] = [];
        const id50: ILtConcessionGroup[] = [];
        const ow: ILtConcessionGroup[] = [];
        const oal: ILtConcessionGroup[] = [];
        const other: ILtConcessionGroup[] = [];
        _.values(groupNoSubLoadFoc)?.map((item: ILtConcession[]) => {
          // use first index's displayName, coz they have same name
          foc.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
        });
        _.values(groupSubLoadFoc)?.map((item: ILtConcession[]) => {
          foc.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
        });
        _.values(groupZone)?.map((item: ILtConcession[]) => {
          zone.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
        });
        _.values(groupID50)?.map((item: ILtConcession[]) => {
          id50.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
        });
        _.values(groupOW)?.map((item: ILtConcession[]) => {
          ow.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
        });
        _.values(groupOAL)?.map((item: ILtConcession[]) => {
          oal.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
        });
        _.values(groupOC)?.map((item: ILtConcession[]) => {
          other.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
        });
        return [...foc, ...zone, ...id50, ...ow, ...oal, ...other];
      };
      const tempSortFunc = [
        ...sortFunc(tempConcessionOfCX),
        ...sortFunc(tempConcessionOfOW),
        ...sortFunc(tempConcessionOfOAL),
      ];
      setValidConcession(tempSortFunc);
    }
  }, [selectedTraveller, ltAllotmentList]);

  const getTravelClass = (classType?: string) => {
    const flightClassLbl = en.booking.flightClass;
    switch (classType) {
      case SEAT_CLASS.econ:
        return flightClassLbl.economy;
      case SEAT_CLASS.premiumEcon:
        return flightClassLbl.premiumEconomy;
      case SEAT_CLASS.business:
        return flightClassLbl.business;
      case SEAT_CLASS.first:
        return flightClassLbl.first;
      default:
        return flightClassLbl.economy;
    }
  };

  const TravelClassComponent = (concessionItem: ILtConcession, classItem?: string) => {
    // add stfdType logic, show highest booking class and regrade class/priority when stfdType==='ENTITLEMENT',other case keep show all
    // etp-3872 the requirement need to the special handle
    const isEntitlement = concessionItem?.stfdType === STFD_TYPE.ENTITLEMENT;
    let bookingClass: string[] = [];
    if (isEntitlement) {
      bookingClass = _.sortBy(concessionItem?.bookingClass, [
        SEAT_CLASS.first,
        SEAT_CLASS.business,
        SEAT_CLASS.premiumEcon,
        SEAT_CLASS.econ,
      ]);
    }
    return (
      <Box key={classItem} sx={{ display: 'flex', mt: 1.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="navigation_regular" sx={{ color: theme.color.secondary.grey.option_2 }}>
            {concessionPageLbl.boardingPriority}
          </Typography>
          <Typography variant="body_1_medium" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
            {concessionItem.concessionDef.carrierGroup === 'CX'
              ? `${concessionItem.boardingPriority}${isEntitlement ? bookingClass?.[0] : classItem}/${
                  concessionItem.regradeClass || classItem
                }${concessionItem.regradeBoardingPriority}`
              : en.common.empty}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, pl: 2 }}>
          <Typography variant="navigation_regular" sx={{ color: theme.color.secondary.grey.option_2 }}>
            {concessionPageLbl.travelClass}
          </Typography>
          <Typography variant="body_1_medium" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
            {getTravelClass(classItem)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const AllotmentDetailsComponent = (concessionItem: ILtConcession) => {
    const allotmentDetails = concessionItem?.allotmentDetails || [];
    const numberOfAllotmentSectors = concessionItem?.numberOfAllotmentSectors;
    const {
      travelValidity,
      allotmentType,
      allotedTicketSector,
      used: usedLabel,
      available,
      unlimited,
      individual,
    } = concessionPageLbl;
    if (numberOfAllotmentSectors !== -1) {
      // 1. only when numberOfAllotmentSectors !== -1, will show unlimited case
      // 2. other case will show every item in allotmentDetails list
      return allotmentDetails?.map((data: IAllotmentDetails, i: number) => {
        const { startDate, endDate, quotaType, quota, used } = data || {};
        const fieldList = [
          {
            label: travelValidity,
            value: `${formatDMYDateWithSpace(startDate)} - ${formatDMYDateWithSpace(endDate)}`,
            column: 12,
          },
          {
            label: allotmentType,
            value: getDisplayLabel(configurations.labels, allotmentType, quotaType),
            column: 6,
          },
          {
            label: allotedTicketSector,
            value: quota.toString(),
            column: 6,
          },
          {
            label: usedLabel,
            value: used.toString(),
            column: 6,
          },
          {
            label: available,
            value: (quota - used).toString(),
            column: 6,
          },
        ];

        return (
          <Box
            key={i}
            sx={{
              ...(allotmentDetails?.length > i + 1
                ? {
                    borderBottom: `1px solid ${theme.color.secondary.light_slate.option_3}`,
                    py: 2,
                  }
                : {
                    mt: 2,
                  }),
            }}
          >
            <Grid container spacing={2}>
              {fieldList.map((itemData: IDisplayDetails, index: number) => {
                return (
                  <LabelValueContainer
                    key={index}
                    {...itemData}
                    labelVariant="navigation_regular"
                    valueVariant="body_1_medium"
                  />
                );
              })}
            </Grid>
          </Box>
        );
      });
    } else {
      // when numberOfAllotmentSectors !== -1
      const fieldList = [
        {
          label: allotmentType,
          value: individual,
          column: 6,
        },
        {
          label: allotedTicketSector,
          value: unlimited,
          column: 6,
        },
      ];
      return (
        <Grid className="concession_expanded_allotment_details" container spacing={2}>
          {fieldList.map((itemData: IDisplayDetails, index: number) => {
            return (
              <LabelValueContainer
                key={index}
                {...itemData}
                labelVariant="navigation_regular"
                valueVariant="body_1_medium"
              />
            );
          })}
        </Grid>
      );
    }
  };

  return (
    <Box
      className="concession_detail_container"
      sx={{
        ...(isDesktop
          ? {
              maxWidth: desktopContainerWidth,
            }
          : {}),
      }}
    >
      {!isDesktop && (
        <IconButton onClick={() => handleBackToUserProfile(navigate)} sx={{ py: 1.625, pl: 0 }}>
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Box
        className="concession_detail_header"
        sx={{
          ...(isDesktop
            ? {
                width: desktopContainerWidth,
                mb: 2,
              }
            : {
                mb: 1.5,
              }),
          mx: 'auto',
          display: 'block',
          fontWeight: FONT_WEIGHT.regular,
          color: theme.color.secondary.dark_grey.option_3,
          fontSize: theme.typography.headline_regular.fontSize,
        }}
      >
        {`${concessionPageLbl.concessionFor} `}

        {!isFetchingConcessionWithAllotment && (
          <>
            <Typography variant="headline_regular" sx={{ display: 'inline', fontWeight: FONT_WEIGHT.medium }}>
              {`${selectedTraveller?.label || en.common.empty} `}
            </Typography>
            {relationshipDisplayLabel}
          </>
        )}

        {`${concessionPageLbl.as} `}
        <Typography variant="headline_regular" sx={{ display: 'inline', fontWeight: FONT_WEIGHT.medium }}>
          {asOfDateLabel}
        </Typography>
      </Box>

      {!isDesktop && (
        <>
          <ConcessionWithYear />
          <ConcessionWithTraveller />
        </>
      )}

      <Box className="concession_detail_content" sx={{ display: 'flex', flexDirection: 'column' }}>
        {!isFetchingConcessionWithAllotment &&
          validConcession.length > 0 &&
          validConcession.map((concessionGroupItem: ILtConcessionGroup, index: number) => {
            // hide the concession only if (the concession has no concession) || ( has concession but have no alloted quota and numberOfAllotmentSectors != -1)
            if (
              !concessionGroupItem?.data?.length ||
              concessionGroupItem.data.every(
                (concessionItem: ILtConcession) =>
                  concessionItem.numberOfAllotmentSectors !== -1 && _.isEmpty(concessionItem.allotmentDetails),
              )
            ) {
              return <></>;
            }

            return (
              <>
                <Typography className="concession_group_item_title" sx={{ mt: 2, mb: 1 }} variant="headline_medium">
                  {concessionGroupItem?.title}
                </Typography>
                {concessionGroupItem?.data.map((concessionItem: ILtConcession, idx: number) => {
                  const allotmentDetails = concessionItem?.allotmentDetails || [];
                  const numberOfAllotmentSectors = concessionItem?.numberOfAllotmentSectors;
                  const unlimitedQuota = numberOfAllotmentSectors === -1;
                  const isShowAllotmentDetails = unlimitedQuota || allotmentDetails?.length > 0;
                  const hideConcession = !unlimitedQuota && _.isEmpty(allotmentDetails);
                  const concessionItemKey = `${concessionItem.displayName}_${idx}`;
                  if (hideConcession) {
                    return <Box key={concessionItemKey}></Box>;
                  }
                  return (
                    <Box
                      className="concession_group_item"
                      key={concessionItemKey}
                      onClick={() => {
                        const cloneValidConcession = _.clone(validConcession);
                        const expanded = cloneValidConcession[index]?.data[idx]?.expanded;
                        const { viewLess, viewMore } = concessionPageLbl;
                        cloneValidConcession[index].data[idx].expanded = !expanded;
                        cloneValidConcession[index].data[idx].label = !expanded ? viewLess : viewMore;
                        setValidConcession(cloneValidConcession);
                      }}
                      sx={{
                        p: 2,
                        mb: isDesktop ? 1.5 : 2,
                        minHeight: '92px',
                        width: isDesktop ? desktopContainerWidth : '100%',
                        boxShadow: theme.boxShadow.important,
                        borderRadius: 1,
                        backgroundColor: theme.palette.bgColor.main,
                        '&:before': {
                          display: 'none',
                        },
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <Box
                        className="concession_display_name"
                        sx={{
                          px: 0,
                        }}
                      >
                        <Typography variant="body_1_medium" sx={{ width: '100%', mb: 1.5 }}>
                          {concessionItem.displayName}
                          {concessionItem?.errConcession && (
                            <CommonWarning
                              customStyles={{ px: 0, my: 1.5 }}
                              isShowIcon={true}
                              msgColor={theme.color.secondary.dark_grey.option_1}
                              msgText={getInvalidFJClassTravellerWarningMessage(
                                en.error.invalidAgeFirst,
                                concessionItem?.errConcession?.age,
                                en.error.invalidAgeSecond,
                                concessionItem?.errConcession?.class,
                              )}
                            />
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignSelf: 'end',
                        }}
                      >
                        <Typography
                          variant="body_2_bold"
                          sx={{
                            width: '75px',
                            textAlign: 'end',
                            color: theme.color.utility.link.option_3,
                          }}
                        >
                          {concessionItem.label}
                        </Typography>
                      </Box>
                      {concessionItem.expanded && (
                        <Box className="concession_expanded_container" sx={{ p: 0 }}>
                          <Box
                            className="concession_expanded_travel_class_details"
                            sx={{
                              ...(isShowAllotmentDetails && {
                                borderBottom: `1px solid ${theme.color.secondary.light_slate.option_3}`,
                                pb: 1.5,
                              }),
                            }}
                          >
                            <Typography
                              variant="body_2_bold"
                              sx={{
                                color: theme.color.secondary.dark_grey.option_1,
                                mt: 2,
                              }}
                            >
                              {concessionPageLbl.travelClassDetails}
                            </Typography>
                            {concessionItem.concessionDef.carrierGroup === CARRIER_OPTION.CX
                              ? concessionItem.bookingClass.map((classItem: string) =>
                                  TravelClassComponent(concessionItem, classItem),
                                )
                              : TravelClassComponent(concessionItem)}
                          </Box>
                          {isShowAllotmentDetails && (
                            <>
                              <Typography
                                variant="body_2_bold"
                                sx={{
                                  color: theme.color.secondary.dark_grey.option_1,
                                  mt: 2,
                                  mb: 1,
                                }}
                              >
                                {concessionPageLbl.allotmentDetails}
                              </Typography>
                              {AllotmentDetailsComponent(concessionItem)}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </>
            );
          })}

        {!isFetchingConcessionWithAllotment && validConcession.length < 1 && (
          <Typography
            variant="body_1_regular"
            sx={{
              textAlign: 'center',
              color: theme.color.secondary.grey.option_3,
              ...(isDesktop && { mt: 1 }),
            }}
          >
            {concessionPageLbl.notFindConcession}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ConcessionDetails;
