import React, { useState, useContext, useEffect, useRef } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography, useTheme, Box } from '@mui/material';
import _ from 'lodash';

import en from '../../../../translations/en';
import {
  BOOKING_CLASS,
  CARRIER_OPTION,
  CARRIER_OPTION_MAP_TRANSLATION,
  FARE_TYPES,
  ID_TICKET_TYPE,
  OWOAL_TYPE,
  TRAVEL_TYPES,
  LABEL_CATEGORY,
} from '../../../../constants/constants';
import { FONT_WEIGHT } from '../../../../constants/font';
import { CancelRebookIcon as FlightIcon } from '../../../../assets/images';
import {
  IButtonValue,
  IKeyValue,
  ILtSelectedConcessionDetailType,
  ILtTravelTypeOption,
  ILtTravelTypeOptionItem,
} from '../../../../interfaces';
import { Configuration } from 'configuration';

import { useAppSelector } from '../../../../app/hooks';

import { selectUser } from '../../../../slice/userSlice';
import { selectConfiguration } from '../../../../slice/configurationSlice';

import { TravelTypeListLtState, TravelTypeListLtAction } from '../../../../context';

import { getSeatClass, getUniqueNestedFieldValues, sort } from '../../../../helpers';

import { ExpandedConcessionDetails, NoTravelConcessionComponent } from '../../..';

type IUpdatedCarrierItem = {
  value: string;
  buttonWidth: string;
};

type ITravelTypeItem = {
  title: string;
  concessionOptions: ILtTravelTypeOption[];
};

type IHandleTravelTypeItemOnChange = (event: React.MouseEvent<HTMLElement>, value: IButtonValue) => void;

const SubLoadCarrierContainer = ({
  updatedCarrierList,
  handleCarrierOptionOnClick,
  selectedSubLoadCarrierGroup,
}: {
  updatedCarrierList: IUpdatedCarrierItem[];
  handleCarrierOptionOnClick: (carrierGroup: string) => void;
  selectedSubLoadCarrierGroup: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      className="sub_load_carrier_container"
      sx={{
        m: 0,
        mt: 1,
        pb: 0.5,
        boxShadow: 'none',
        borderRadius: 1,
      }}
    >
      <Box
        className="sub_load_carrier_options_label"
        sx={{
          display: 'flex',
          alignContent: 'center',
          pl: 0.5,
        }}
      >
        <FlightIcon style={{ width: '18px', height: '18px' }} />
        <Typography
          variant="navigation_regular"
          sx={{ textAlign: 'left', mb: 0.5, pl: 0.25, color: theme.color.secondary.dark_grey.option_3 }}
        >
          {en.booking.travelType.preferredCarrier}
        </Typography>
      </Box>

      <Box
        className="sub_load_carrier_options"
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {updatedCarrierList.map((item: IUpdatedCarrierItem, index: number) => {
          const matchedTranslationKey =
            CARRIER_OPTION_MAP_TRANSLATION?.[item.value as keyof typeof CARRIER_OPTION_MAP_TRANSLATION] || '';
          const isMatchedCarrierGroup = selectedSubLoadCarrierGroup === item.value;

          return (
            <ToggleButton
              key={index}
              value={item.value}
              sx={{
                minWidth: item.buttonWidth,
                height: '32px',
                textAlign: 'center',
                border: 'none',
                mr: 1,
                mt: 1,
                px: 1.5,
                py: 0,
                boxSizing: 'unset',
                boxShadow: theme.boxShadow.important,
                ...(isMatchedCarrierGroup
                  ? {
                      color: 'white',
                      background: theme.color.utility.link.option_3,
                      fontWeight: FONT_WEIGHT.bold,
                      fontSize: theme.typography.navigation_bold.fontSize,
                      '&:hover': {
                        color: 'white',
                        background: theme.color.utility.link.option_3,
                        fontWeight: FONT_WEIGHT.bold,
                        fontSize: theme.typography.navigation_bold.fontSize,
                      },
                    }
                  : {
                      color: theme.color.secondary.dark_grey.option_1,
                      background: 'white',
                      fontWeight: FONT_WEIGHT.regular,
                      fontSize: theme.typography.navigation_regular.fontSize,
                      '&:hover': {
                        background: theme.color.utility.unselectable.option_7,
                      },
                    }),
              }}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                handleCarrierOptionOnClick(item.value);
              }}
            >
              {en.booking.carrierGroupLabels?.[matchedTranslationKey as keyof typeof en.booking.carrierGroupLabels] ||
                ''}
            </ToggleButton>
          );
        })}
      </Box>
    </Box>
  );
};

const getConcessionDetailItems = (val: ILtTravelTypeOptionItem, unitLbl: string) => {
  const { advanceBookingDay, boardingPriority, bookingClass, regradeClass } = val || {};
  const { travelType: travelTypeLbl } = en.booking;

  const list: IKeyValue[] = [
    {
      key: travelTypeLbl.boardingPriority,
      value: boardingPriority || en.common.empty,
    },
    {
      key: travelTypeLbl.dtAdvanceBookingDays,
      value: `${advanceBookingDay?.value} ${unitLbl || ''}`,
    },
    {
      key: travelTypeLbl.travelClass,
      value: sort(bookingClass?.slice(), BOOKING_CLASS) || [],
    },
    {
      key: travelTypeLbl.ugsaClass,
      value: getSeatClass(regradeClass),
    },
  ];
  return list;
};

const DefaultConcessionOptionHeader = ({ label }: { label: string }) => {
  return (
    <Box
      className="default_concession_option_header"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="body_2_regular"
        sx={{
          whiteSpace: 'pre-wrap',
          textAlign: 'left',
          maxWidth: 'calc(100% - 65px)',
          px: 1,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const TravelTypeItem = ({
  title,
  concessionOptions,
  selectedId,
  handleTravelTypeItemOnChange,
  handleCarrierOptionOnClick,
  updatedCarrierList,
  selectedSubLoadCarrierGroup,
  customRef,
}: {
  title: ITravelTypeItem['title'];
  concessionOptions: ITravelTypeItem['concessionOptions'];
  selectedId: string | null;
  handleTravelTypeItemOnChange: IHandleTravelTypeItemOnChange;
  handleCarrierOptionOnClick: (carrierGroup: string) => void;
  updatedCarrierList: IUpdatedCarrierItem[];
  selectedSubLoadCarrierGroup: string;
  customRef: React.RefObject<HTMLDivElement>;
}) => {
  const theme = useTheme();

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const unitOptions = configurations.labels.filter(
    (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.unit,
  );

  return (
    <Box
      className="travel_type_item_section"
      sx={{
        mx: 'auto',
        mb: 1,
        width: '100%',
        borderBottom: `1px solid ${theme.color.secondary.light_slate.option_3}`,
        '&:last-of-type': {
          borderBottom: 0,
        },
        pb: 1.5,
      }}
      ref={customRef}
    >
      <Typography
        variant="body_2_medium"
        color={theme.color.secondary.dark_grey.option_3}
        sx={{
          mt: 1,
          mb: 1.5,
        }}
      >
        {title}
      </Typography>

      {concessionOptions.map((concessionOptionItem: ILtTravelTypeOption, concessionOptionItemIndex: number) => {
        const { id, val } = concessionOptionItem;
        const { sub, label, type, advanceBookingDay } = val;
        const isCurrentSelected = id === selectedId;

        // Remark: this Zone is not include the SUBLO FOC
        const isZone = type === TRAVEL_TYPES.ZONE;

        let isToggleButtonSelected = isCurrentSelected;
        if (isZone) {
          isToggleButtonSelected = isCurrentSelected && !!selectedSubLoadCarrierGroup;
        }

        const matchedUnitOption = unitOptions.find((unitOption) => unitOption.code === advanceBookingDay?.unit);
        return (
          <Box
            className="concession_option_item"
            key={concessionOptionItemIndex}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              mb: 1,
              color: theme.color.secondary.dark_grey.option_1,
              borderRadius: 1,
              border: 0,
              borderTopColor: 'borderColor.main',
              boxShadow: theme.palette.boxShadow.dark,
            }}
          >
            {isZone && updatedCarrierList.length > 0 && (
              <Box
                className="zone_concession_option_item"
                sx={{
                  width: '100%',
                  p: 2,
                  borderRadius: 1,
                  ...(isToggleButtonSelected
                    ? {
                        border: `2px solid ${theme.color.utility.link.option_3}`,
                        background: theme.backgroundColor.rockGrey,
                        boxShadow: theme.boxShadow.selected,
                      }
                    : {
                        cursor: 'pointer',
                        '&:hover': {
                          background: 'rgba(0, 0, 0, 0.04)',
                        },
                      }),
                }}
                onClick={() => {
                  // preselect CX as option when Zone nothing being selected
                  if (!selectedSubLoadCarrierGroup) {
                    handleCarrierOptionOnClick(CARRIER_OPTION.CX);
                  }
                }}
              >
                <DefaultConcessionOptionHeader {...{ label, sub }} />
                <SubLoadCarrierContainer
                  {...{
                    updatedCarrierList,
                    handleCarrierOptionOnClick,
                    selectedSubLoadCarrierGroup,
                  }}
                />
              </Box>
            )}

            {!isZone && (
              <ToggleButton
                value={{ id, val }}
                disabled={false}
                selected={isToggleButtonSelected}
                onChange={handleTravelTypeItemOnChange}
                sx={{
                  flexDirection: 'column',
                  width: '100%',
                  minHeight: '56px',
                  border: `2px solid transparent`,
                  color: 'inherit',
                  '&.Mui-disabled': {
                    border: 0,
                    background: theme.color.utility.unselectable.option_7,
                    color: theme.color.secondary.grey.option_2,
                    boxShadow: 'none !important',
                  },
                  '&.Mui-selected, &.Mui-selected:hover': {
                    color: theme.color.secondary.dark_grey.option_1,
                    border: `2px solid ${theme.color.utility.link.option_3}`,
                    background: theme.backgroundColor.rockGrey,
                    boxShadow: theme.boxShadow.selected,
                  },
                  ...(isCurrentSelected && isZone
                    ? {
                        background: 'transparent',
                      }
                    : {
                        background: 'white',
                      }),
                }}
              >
                <DefaultConcessionOptionHeader {...{ label, sub }} />

                {isCurrentSelected && (
                  <Box sx={{ px: 1, width: '100%' }}>
                    <ExpandedConcessionDetails
                      concessionDetailItems={getConcessionDetailItems(val, matchedUnitOption?.label || '')}
                    />
                  </Box>
                )}
              </ToggleButton>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const getTravelTypeList = (travelTypeOptions: ILtTravelTypeOption[]) => {
  // grouping "SUBLO" and "NOSUB" item
  //  when the "SUBLO" item is exist,show zone option & SUBLO FOC concession data (if exist)
  // when the "NOSUB" item is exist, need to check if FOC、ID50 and other is exist
  const [subLoadTravelOptions, noSubTravelOptions] = _.partition(
    travelTypeOptions,
    (item: ILtTravelTypeOption) => item.val?.sub === ID_TICKET_TYPE.SUBLO,
  );

  const noSubFocSection = {
    title: TRAVEL_TYPES.FOC,
    concessionOptions: noSubTravelOptions.filter(
      (item) => item.val?.fareType === FARE_TYPES.ID && item.val?.type === TRAVEL_TYPES.FOC,
    ),
  };

  const subLoadZoneSection = { title: _.capitalize(TRAVEL_TYPES.STANDBY), concessionOptions: subLoadTravelOptions };

  const id50Section = {
    title: TRAVEL_TYPES.ID50,
    concessionOptions: noSubTravelOptions.filter(
      (item) => item.val?.fareType === FARE_TYPES.ID && item.val?.type === TRAVEL_TYPES.ID50,
    ),
  };

  const otherSection = {
    title: _.capitalize(TRAVEL_TYPES.OTHER),
    concessionOptions: noSubTravelOptions.filter(
      (item: ILtTravelTypeOption) =>
        item.val?.fareType !== FARE_TYPES.ID &&
        (item.val?.type !== TRAVEL_TYPES.ID50 || item.val?.type !== FARE_TYPES.FOC),
    ),
  };

  return [noSubFocSection, subLoadZoneSection, id50Section, otherSection].filter((i) => i.concessionOptions.length > 0);
};

const getUpdatedCarrierList = (uniqueCarrierList: string[]) => {
  const updatedCarrierList: IUpdatedCarrierItem[] = [];

  const isContainCX = uniqueCarrierList?.includes(CARRIER_OPTION.CX);
  const isContainOtherCarrier =
    uniqueCarrierList?.includes(OWOAL_TYPE.OAL) || uniqueCarrierList?.includes(OWOAL_TYPE.OW);

  if (isContainCX) {
    updatedCarrierList.push({ value: CARRIER_OPTION.CX, buttonWidth: '44px' });
  }

  if (isContainOtherCarrier) {
    updatedCarrierList.push({ value: CARRIER_OPTION.OTHER_CARRIER, buttonWidth: '82px' });
  }

  if (isContainCX && isContainOtherCarrier) {
    updatedCarrierList.push({ value: CARRIER_OPTION.ALL_CARRIER, buttonWidth: '64px' });
  }

  return updatedCarrierList;
};

const LtTravelTypeContainer = ({
  concessionOnChange,
  selectedTypeId,
  ltCurrentDetails, // from parent's useState()
  isShowConcessionOverlay,
}: {
  concessionOnChange: (value: IButtonValue) => void;
  selectedTypeId: string;
  ltCurrentDetails: ILtSelectedConcessionDetailType;
  isShowConcessionOverlay: boolean;
}) => {
  const { concession } = useAppSelector(selectUser) || {};
  const { leisureTravel } = concession || {};
  const { travelTypeOptions, selectedCarrierGroup } = useContext(TravelTypeListLtState) || {};
  const { setSelectedCarrierGroup } = useContext(TravelTypeListLtAction);

  const travelTypeList = getTravelTypeList(travelTypeOptions);

  const uniqueCarrierList = getUniqueNestedFieldValues(leisureTravel, 'concessionDef.carrierGroup');
  const updatedCarrierList = getUpdatedCarrierList(uniqueCarrierList);
  const selectedSubLoadCarrierGroup = selectedCarrierGroup;

  const [selectedId, setSelectedId] = useState<string | null>(selectedTypeId);

  const travelTypeItemRefs: React.RefObject<HTMLDivElement>[] = travelTypeList.map(() => useRef(null));

  // for NON-Zone concession item on change (including: NOSUB FOC, SUBLO FOC, ID50...etc)
  const handleTravelTypeItemOnChange = (event: React.MouseEvent<HTMLElement>, value: IButtonValue) => {
    if (value !== null) {
      setSelectedId(value.id);
      concessionOnChange(value); // from parent `ConcessionSelectionContainer`, will save it as redux or context data.
    }

    // clear selected carrier option if you have selected it before
    if (value && value.val.type !== TRAVEL_TYPES.ZONE && selectedCarrierGroup) setSelectedCarrierGroup('');
  };

  // for Zone: CX, Other Carrier, Any Carrier
  const handleCarrierOptionOnClick = (carrierGroup: string) => {
    const matchedZone = _.find(travelTypeOptions, (option) => option.val.type === TRAVEL_TYPES.ZONE);
    if (matchedZone) {
      setSelectedId(matchedZone.id);

      if (carrierGroup) {
        const { id, val } = matchedZone;
        setSelectedCarrierGroup(carrierGroup);

        // from parent `ConcessionSelectionContainer`, will save it as redux or context data.
        concessionOnChange({
          id,
          displaySubtitle: '',
          val: {
            ...val,
            carrierGroup,
          },
        });
      }
    }
  };

  // handle carrier group in context
  useEffect(() => {
    let tmpSelectedSubLoadCarrierGroup = selectedCarrierGroup;
    if (isShowConcessionOverlay) {
      if (ltCurrentDetails.type === TRAVEL_TYPES.ZONE) {
        tmpSelectedSubLoadCarrierGroup =
          ltCurrentDetails.carrierGroup && ltCurrentDetails.carrierGroup !== selectedCarrierGroup
            ? ltCurrentDetails.carrierGroup
            : selectedCarrierGroup;
      } else {
        // clear carrier group when selected NON-ZONE concession.
        tmpSelectedSubLoadCarrierGroup = '';
      }

      // set as context
      setSelectedCarrierGroup(tmpSelectedSubLoadCarrierGroup);
    }
  }, [isShowConcessionOverlay, ltCurrentDetails.carrierGroup]);

  return (
    <ToggleButtonGroup
      className="lt_travel_type_container"
      orientation="vertical"
      sx={{
        '&.MuiToggleButtonGroup-root': {
          width: '100%',
        },
        px: 1,
      }}
      exclusive
    >
      {travelTypeList.length > 0 ? (
        travelTypeList.map((travelTypeItem: ITravelTypeItem, index: number) => {
          const currentTravelTypeItemRef = travelTypeItemRefs[index];
          return (
            <TravelTypeItem
              key={index}
              {...{
                ...travelTypeItem,
                selectedId,
                handleTravelTypeItemOnChange,
                handleCarrierOptionOnClick,
                updatedCarrierList,
                selectedSubLoadCarrierGroup,
                customRef: currentTravelTypeItemRef,
              }}
            />
          );
        })
      ) : (
        <NoTravelConcessionComponent />
      )}
    </ToggleButtonGroup>
  );
};

export default LtTravelTypeContainer;
