import { useContext, useEffect } from 'react';
import { Box, useTheme, Typography, ToggleButton } from '@mui/material';

import { NOMINATION_TYPE } from '../../../../constants/constants';
import en from '../../../../translations/en';
import { AirCraftBackgroundImage, AirCraftNightBackgroundImage } from '../../../../assets/images';

import { checkIsNextYearNominationFlow, getLabelKeyByProfileType } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectUser } from '../../../../slice/userSlice';
import { NominationAction, NominationState } from '../../../../context';

export const NomineeTypeToggleView = ({
  value,
  selected,
  title,
  description,
  isNextYearNominationFlow,
  onChange,
}: {
  value: string;
  selected: boolean;
  title: string;
  description: string;
  isNextYearNominationFlow: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, value: any) => void;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const getButtonSelectedBg = (value: string) => {
    const isPlusOne = value === NOMINATION_TYPE.plusOne;
    const nextYearColor = isPlusOne ? theme.nextYearNomination.plusOne : theme.nextYearNomination.travelNominee;
    const currentYearColor = isPlusOne ? theme.palette.plusOne.main : theme.color.utility.link.option_8;
    return isNextYearNominationFlow ? nextYearColor : currentYearColor;
  };

  return (
    <ToggleButton
      value={value}
      selected={selected}
      sx={{
        my: isDesktop ? 2 : 1,
        px: 2,
        pt: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: isDesktop ? '76px' : '92px',
        background: 'white',
        boxShadow: theme.boxShadow.important,
        border: '2px solid transparent',
        borderRadius: '8px',
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type), &.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderRadius: 1,
        },
        '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.MuiToggleButtonGroup-grouped:not(:first-of-type), &.Mui-selected:hover.MuiToggleButtonGroup-grouped:not(:first-of-type)':
          {
            background: getButtonSelectedBg(value),
            border: `2px solid ${theme.color.secondary.dark_grey.option_3}`,
          },
      }}
      onChange={onChange}
    >
      <Typography
        variant={selected ? 'body_1_medium' : 'body_1_regular'}
        color={selected && isNextYearNominationFlow ? 'white' : theme.color.secondary.dark_grey.option_1}
      >
        {title}
      </Typography>

      <Typography
        variant="navigation_regular"
        sx={{
          mt: 0.5,
          color: selected && isNextYearNominationFlow ? 'white' : theme.color.secondary.grey.option_2,
          lineHeight: '16px',
        }}
        align="left"
      >
        {description}
      </Typography>

      <Box
        className="aircraft_background_image"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${isNextYearNominationFlow ? AirCraftNightBackgroundImage : AirCraftBackgroundImage})`,
          backgroundPosition: 'right 0 top -16px',
          backgroundSize: 'auto 90px',
          backgroundRepeat: 'no-repeat',
          opacity: selected ? 1 : 0,
        }}
      />
    </ToggleButton>
  );
};

const NomineeTypeSelectionContainer = () => {
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser) || {};
  const { nominationFlow } = useContext(NominationState) || {};
  const { setNominationFlow } = useContext(NominationAction) || {};

  const { nominationFlowSelectedType, nominationViewModeData } = nominationFlow || {};

  const isNextYearNominationFlow = checkIsNextYearNominationFlow();
  const baseNominationLbl = en.userProfile.nomination.nominationFlow;
  const nominationFlowLbl = isNextYearNominationFlow
    ? en.userProfile.nomination.nextYearNominationFlow
    : baseNominationLbl;

  const labelKeyByProfileType = getLabelKeyByProfileType(profile);

  useEffect(() => {
    setNominationFlow({
      ...nominationFlow,
      isNominationTypeValid: !!nominationFlowSelectedType,
    });
  }, [nominationFlowSelectedType]);

  return (
    <Box
      className="nominee_type_selection_container"
      sx={{
        mb: 9.5,
        ...(isDesktop && {
          width: '488px',
          mx: 'auto',
        }),
      }}
    >
      <Typography
        variant="headline_medium"
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          mb: 2,
        }}
      >
        {nominationFlowLbl.nomineeType}
      </Typography>

      {Object.values(NOMINATION_TYPE)
        // [ETP-4978] filter out if nominated quota full
        .filter((nominationType: string) => {
          return (
            nominationViewModeData[nominationType]?.list?.length <
              nominationViewModeData[nominationType]?.maxNumberOfPerson &&
            // [ETP-6033] include isEnabled check for show/hide nominee type
            nominationViewModeData[nominationType]?.isEnabled
          );
        })
        .map((nominationType: string) => {
          const nominationTypeKey = baseNominationLbl?.[nominationType as keyof typeof baseNominationLbl];
          const profileTypeKeyUnderNominationTypeKey = nominationTypeKey?.[
            labelKeyByProfileType as keyof typeof nominationTypeKey
          ] as { description: string };
          return (
            <NomineeTypeToggleView
              key={nominationType}
              value={nominationFlowSelectedType}
              selected={nominationFlowSelectedType === nominationType}
              title={(nominationTypeKey as { title: string })?.title || ''}
              description={profileTypeKeyUnderNominationTypeKey?.description || ''}
              isNextYearNominationFlow={isNextYearNominationFlow}
              onChange={() => {
                setNominationFlow({
                  ...nominationFlow,
                  nominationFlowSelectedType: nominationType,
                });
              }}
            />
          );
        })}
    </Box>
  );
};

export default NomineeTypeSelectionContainer;
