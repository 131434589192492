import React from 'react';
import { Button, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { SelectionArrowRightIcon } from '../../assets/images';

const RoleItems = ({
  text,
  handleOnClick,
  customStyles,
}: {
  text?: string;
  handleOnClick?: () => Promise<void> | void;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '48px',
        pl: 1.5,
        pr: 0,
        mt: 1,
        borderRadius: '8px',
        background: theme.color.secondary.slate.option_8,
        ...customStyles,
      }}
      onClick={handleOnClick}
    >
      <Typography sx={{ color: theme.color.utility.link.option_3 }} variant="body_2_regular">
        {text}
      </Typography>
      <SelectionArrowRightIcon style={{ marginLeft: 'auto' }} color={theme.color.utility.link.option_3} />
    </Button>
  );
};

export default RoleItems;
