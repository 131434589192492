import React, { useContext } from 'react';
import { Box, useTheme, IconButton, Typography, Checkbox } from '@mui/material';
import { CheckBoxCheckIcon, CheckBoxIcon } from '../../../../assets/images';
import { BackIcon } from '../../../../assets/images';
import en from '../../../../translations/en';

import { getLabelKeyByProfileType } from '../../../../helpers';

import { NominationAction, NominationState } from '../../../../context';

import { selectApp } from '../../../../slice/appSlice';
import { selectUser } from '../../../../slice/userSlice';
import { useAppSelector } from '../../../../app/hooks';

import { NominationPageTitle, ParagraphMasterContainer } from '../../..';

const CompanionNominationPolicyContainer = () => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser) || {};
  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};

  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { isAgreeDisclaimer, routeTo } = companionRegistrationFlow || {};
  const { step: routeToStep } = routeTo || {};

  const nominationPolicyPageLbl = en.userProfile.nomination.common.nominationPolicy;

  const disclaimerLabelKey = getLabelKeyByProfileType(profile);

  const nominationTypeDisclaimerLbl =
    nominationPolicyPageLbl[disclaimerLabelKey as keyof typeof nominationPolicyPageLbl];

  const boxItemStyle = {
    p: 2,
    width: '100%',
    mx: 'auto',
    background: 'white',
    boxShadow: theme.boxShadow.important,
    borderRadius: '8px',
    mb: 2,
  };

  return (
    <Box className="companion_nomination_policy_container">
      {!isDesktop && (
        <IconButton
          onClick={() => {
            setCompanionRegistrationFlow({
              ...companionRegistrationFlow,
              routeTo: {
                step: routeToStep - 1,
              },
            });
          }}
          sx={{ py: 1.625, pl: 0 }}
        >
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Box
        sx={{
          mb: '40%',
          ...(isDesktop
            ? {
                mt: 2,
                width: '488px',
                mx: 'auto',
              }
            : {
                mt: 2.25,
              }),
        }}
      >
        {nominationTypeDisclaimerLbl.title && <NominationPageTitle title={nominationTypeDisclaimerLbl.title} />}

        <ParagraphMasterContainer
          content={nominationTypeDisclaimerLbl.descriptionContent}
          paragraphVariant="body_1_regular"
          paragraphTypoSxProps={{
            color: theme.color.secondary.dark_grey.option_1,
          }}
          paragraphSxProps={{
            mt: 1.5,
          }}
        />

        <Box
          sx={{
            ...boxItemStyle,
          }}
        >
          {nominationTypeDisclaimerLbl.policyDetail.title && (
            <Typography sx={{ pb: 2, color: theme.color.secondary.dark_grey.option_1 }} variant="body_1_bold">
              {nominationTypeDisclaimerLbl.policyDetail.title}
            </Typography>
          )}
          <ParagraphMasterContainer
            content={nominationTypeDisclaimerLbl.policyDetail.descriptionContent}
            multiParagraphContainerSxProps={{ p: 0 }}
            paragraphTypoSxProps={{
              color: theme.color.secondary.dark_grey.option_1,
            }}
          />
        </Box>

        <Box
          sx={{
            ...boxItemStyle,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            <Checkbox
              sx={{
                ml: '-9px',
                mt: '-6px',
                '&.MuiCheckbox-root': {
                  color: theme.status.selected,
                },
              }}
              checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
              icon={<CheckBoxIcon stroke={theme.status.selected} />}
              color="info"
              checked={isAgreeDisclaimer}
              onChange={() =>
                setCompanionRegistrationFlow({
                  ...companionRegistrationFlow,
                  isAgreeDisclaimer: !isAgreeDisclaimer,
                })
              }
            />

            <ParagraphMasterContainer
              content={nominationTypeDisclaimerLbl.secondPolicyDetail.descriptionContent}
              paragraphTypoSxProps={{
                color: theme.color.secondary.dark_grey.option_1,
              }}
              multiParagraphContainerSxProps={{
                p: 0,
                my: 0,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanionNominationPolicyContainer;
