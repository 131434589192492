import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { DATE_FORMAT, ADMIN_CONCESSION, ALLOTMENT_TYPE } from '../../../constants/constants';
import { imgAdminTableArrowRight } from '../../../assets/images';

import { IAdminConcession, IAdminQuotaList, IAdminAllotmentType } from '../../../interfaces';

import {
  adminConcessionCarrierGroupSort,
  checkAgeInEligibilityDependent,
  checkEligibilityWithOALEligibility,
  checkEligibilityNominationType,
  formatDateAsString,
  getAdminPersonalDisplayName,
  getAdminCurrentContext,
  checkIsAssoSubsidAdminPage,
} from '../../../helpers';

import { ConcessionInProfileState } from '../../../context';

import { BaseTable } from '../../../components';
import { ConcessionWithTraveller, ConcessionWithYear } from '../../../containers';

const ConcessionList = () => {
  const theme = useTheme();
  const adminCurrentContext = getAdminCurrentContext();
  const {
    concessionWithAllotmentData: {
      originData: { concession: concessionList = [] },
    },
    nominatedPaxListDropdownData: { selectedOptionItem: selectedDependant },
  } = useContext(ConcessionInProfileState) || {};

  const fieldLabel = en.admin.adminConcession.fields;

  const { employeeDetail } = adminCurrentContext.currentContextState || {};
  const { setFocusConcession, setConcessionName } = adminCurrentContext.currentContextAction || {};

  /* 
  Remark:
  
  profileDetail - only apply for Asso/Subsid Admin Maintain Employee Details page
  personalInfo - only apply for CX Admin Maintain Employee Details page
  
  They are a bit difference structure but in same data source
  */
  const isAssoSubsidAdminPage = checkIsAssoSubsidAdminPage();
  const { personalInfo, profileDetail } = employeeDetail || {};
  const { firstName, middleName, lastName } = isAssoSubsidAdminPage ? profileDetail : personalInfo || {};

  const employeeName = getAdminPersonalDisplayName({
    firstName,
    lastName,
    middleName,
  });

  const [filterData, setFilterData] = useState<IAdminConcession[]>([]);

  const handleRowDataOnClick = (rowData: any) => {
    // selected EMP
    if (selectedDependant.dependentId === null) {
      setConcessionName({
        name: employeeName,
        relationship: ADMIN_CONCESSION.EMPLOYEE,
        dependentId: null,
        beneficiaryTypeCode: ADMIN_CONCESSION.EMP,
      });
    } else {
      setConcessionName(selectedDependant);
    }

    setFocusConcession(rowData);
  };

  const tableSchema = [
    {
      key: 'concession',
    },
    {
      key: 'travelFromDate',
    },
    {
      key: 'travelToDate',
    },
    {
      key: 'allotedTicket',
    },
    {
      key: 'used',
    },
    {
      key: 'bookingPriority',
    },
    {
      key: 'concessionStatus',
    },
  ];

  const getTableContentValue = (tableHeader: string, dataItem: any) => {
    const isAllotmentUnlimited = dataItem?.numberOfAllotmentSectors === -1;
    const quoteItem = dataItem?.focusQuota;

    switch (tableHeader) {
      case 'concession':
        return dataItem?.displayName;
      case 'travelFromDate':
        return isAllotmentUnlimited ? en.common.empty : formatDateAsString(quoteItem?.startDate, DATE_FORMAT.ddmmmyyyy);
      case 'travelToDate':
        return isAllotmentUnlimited ? en.common.empty : formatDateAsString(quoteItem?.endDate, DATE_FORMAT.ddmmmyyyy);
      case 'allotedTicket':
        return isAllotmentUnlimited ? fieldLabel.unlimited : quoteItem?.quota;
      case 'used':
        return isAllotmentUnlimited ? en.common.empty : quoteItem?.used;
      case 'bookingPriority':
        return dataItem?.boardingPriority;
      case 'concessionStatus':
        return isAllotmentUnlimited ? fieldLabel.active : quoteItem?.status;
      default:
        return en.common.empty;
    }
  };

  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item, index) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          pb: 0.25,
          pr: item.key === 'concessionStatus' ? 3 : 2,
          flex: index + 1 === tableSchema.length || item.key === 'concession' ? 1.5 : 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
          width: '135px',
          ml: -2,
        },
        isHiddenField: false,
        sortingFunc: null,
      };
    });
  }, [filterData]);

  const transformedTableContents = useCallback(
    (item: any) => {
      const tmpData = _.cloneDeep(item) || {};
      if (!tmpData) {
        return [];
      }

      const temTableArray = [];
      temTableArray.push(tmpData);

      return temTableArray.map((dataItem: any) => {
        return {
          id: dataItem._id,
          concessionId: dataItem._id,
          sxProps: {
            display: 'flex',
            position: 'relative',
            '&:: after': {
              content: `""`,
              width: '28px',
              height: '28px',
              position: 'absolute',
              right: 0,
              top: '50%',
              marginTop: '-14px',
              backgroundSize: 'cover',
              backgroundImage: `url(${imgAdminTableArrowRight})`,
            },
          },
          columns: tableSchema.map(({ key }, index) => {
            const columnSxProps = {};
            const newValue = getTableContentValue(key, dataItem);

            return {
              columnkey: key,
              value: newValue,
              sxProps: {
                py: 1.25,
                pr: key === 'concessionStatus' ? 3 : 2,
                overflow: 'hidden',
                cursor: 'pointer',
                flex: index + 1 === tableSchema.length || key === 'concession' ? 1.5 : 1,
                display: 'flex',
                alignItems: 'center',
                width: '135px',
                ml: -2,
                ...columnSxProps,
              },
              isHiddenField: false,
              columnOnClick: dataItem?.handleColumnOnClick,
            };
          }),
          rowOnClick: () => {
            handleRowDataOnClick(dataItem);
          },
        };
      });
    },
    [selectedDependant, filterData],
  );

  const checkConcessionByQuota = (concession: IAdminConcession, allotmentType: keyof IAdminAllotmentType) => {
    return (
      concession.allotmentType === allotmentType &&
      concession.quotaList?.some((quota: IAdminQuotaList) => quota?.quotaType === allotmentType)
    );
  };

  useEffect(() => {
    // when implement filter action
    let filterList: any[] = [];

    filterList = concessionList?.filter((item: IAdminConcession) => {
      // check eligibilityDependent if contains filter user's beneficiaryTypeCode
      const eligibility =
        item?.eligibilityDependent?.some(
          (eligibility) =>
            eligibility?.beneficiaryType === selectedDependant?.beneficiaryTypeCode &&
            checkAgeInEligibilityDependent(eligibility, selectedDependant),
        ) &&
        checkEligibilityNominationType(item?.nominationType || [], selectedDependant) &&
        checkEligibilityWithOALEligibility(item?.withOALEligibility || false, selectedDependant);

      if (!eligibility) return false;

      return item?.numberOfAllotmentSectors === -1
        ? true
        : checkConcessionByQuota(item, ALLOTMENT_TYPE.P) || checkConcessionByQuota(item, ALLOTMENT_TYPE.I);
    });

    setFilterData(filterList);
  }, [selectedDependant, concessionList]);

  const tableSummary = useMemo(() => {
    return adminConcessionCarrierGroupSort(filterData, selectedDependant).map((list: any) => {
      return (
        <>
          <Typography sx={{ mt: 4 }} variant="title_2_bold">
            {list.title}
          </Typography>
          {list.data.map((item: any, index: number) => {
            return (
              <>
                <BaseTable
                  key={index}
                  tableHeaders={index === 0 ? transformedTableHeaders : []}
                  tableHeadersSXProps={{ display: 'flex' }}
                  tableContents={transformedTableContents(item)}
                />
              </>
            );
          })}
        </>
      );
    });
  }, [filterData]);

  return (
    <Box
      className="concession_list"
      sx={{
        mt: 4,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box className="concession_list_dropdowns_container" sx={{ display: 'flex' }}>
        <ConcessionWithYear
          autoCompleteDropdownCustomStyles={{
            width: '200px',
            mr: '16px',
          }}
          inputPropsCustomForTextField={{
            disabled: false,
          }}
        />
        <ConcessionWithTraveller
          autoCompleteDropdownCustomStyles={{
            width: '240px',
          }}
          inputPropsCustomForTextField={{
            disabled: false,
          }}
        />
      </Box>
      {tableSummary}
    </Box>
  );
};

export default ConcessionList;
